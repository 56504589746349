import React from 'react';
import Container from "@material-ui/core/Container";
import BreadCrumb from "../HtmlComponents/BreadCrumb";
import TableGewinnEdit from "../HtmlComponents/TableGewinnEdit";
import {Helmet} from "react-helmet";
import makeTitle from "../Helper/makeTitle";

function SalesGewinnEditPage(){
    return (
        <Container maxWidth="xl">
            <Helmet>
                <title>{makeTitle('Gewinn-Konfiguration')}</title>
            </Helmet>
            <BreadCrumb links={[
                {label: 'Verkäufe', url: '/sales'},
                {label: 'Gewinn', url: '/sales/gewinn'},
                {label: 'Gewinn-Konfiguration'}
            ]} help={"sales"} />
            <TableGewinnEdit />
        </Container>
    );
}
export default SalesGewinnEditPage;