import React from 'react';
import MaterialTable from "material-table";

import {DefaultPaper} from "../Layout/BaseStyle";
import { connect } from 'react-redux';
import {
    toggleBackdrop
} from "../Actions/Actions";
import useMountEffect from "../Helper/MountEffect";
import {withRouter} from 'react-router-dom'
import tableIcons from "../Layout/TableIcons";
import Grid from "@material-ui/core/Grid";
import {doReloadGewinnPage, doSaveGewinn} from "../Actions/SalesActions";
import {FromEuroOutput} from "../Helper/CurrencyFormat";
import Typography from "@material-ui/core/Typography";
import {CircularProgress} from "@material-ui/core";


function TableGewinnEdit(props){

    const dofetch = () => {
        props.reloadGewinnPage();
    };
    //run once
    useMountEffect(() => {
        dofetch();
    });

    let is_loading_table = false;
    if(props.getProducts === null) is_loading_table = true;

    return (
        <Grid container>
            <Grid item xs={12}>
                {is_loading_table && (<DefaultPaper><Typography variant={"h2"} style={{textAlign:"center"}}><CircularProgress /></Typography></DefaultPaper>)}
                {!is_loading_table && (
                    <MaterialTable title="Einstellung der Produktkosten" icons={tableIcons} columns={[
                        { title: "ASIN", field: "asin", editable: 'never' },
                        { title: "SKU", field: "sku", editable: 'never' },
                        { title: "Produkt", field: "title", editable: 'never' },
                        { title: "Einkaufspreis", field: "ek", type: "currency",currencySetting: { locale: 'de', currencyCode: "EUR" } },
                        { title: "Versandkosten", field: "versand", type: "currency",currencySetting: { locale: 'de', currencyCode: "EUR" } },
                        { title: "Steuersatz", field: "tax", lookup: { 0: '0%', 7: '7% (7-12.20: 5%)',19:'19% (7-12.20: 16%)' }  }
                    ]}
                   data={props.getProducts}
                   editable={{
                       onRowUpdate: (newData, oldData) =>
                           new Promise((resolve, reject) => {
                               setTimeout(() => {
                                   {
                                       const data = props.getProducts;
                                       const index = data.indexOf(oldData);
                                       let new_line = data[index];
                                       new_line.ek = FromEuroOutput(newData.ek);
                                       new_line.versand = FromEuroOutput(newData.versand);
                                       new_line.tax = newData.tax;
                                       if(new_line.ek === null) new_line.ek = 0;
                                       if(new_line.versand === null) new_line.versand = 0;
                                       props.saveGewinn(new_line);
                                   }
                                   resolve()
                               }, 1000)
                           })
                   }}
                   options={{
                       pageSize: 25,
                       pageSizeOptions: [10,25,50,100,200],
                       actionsColumnIndex: 6,
                       cellStyle: {
                           fontSize: 12
                       }
                   }}
                    />
                )}
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        getProducts: state.sales.gewinn_items,
        getUserData: state.account.user
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    reloadGewinnPage: () => dispatch(doReloadGewinnPage()),
    saveGewinn: (new_obj) => dispatch(doSaveGewinn(new_obj))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TableGewinnEdit))