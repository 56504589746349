import React from 'react';
import BaseStyle, {DefaultPaper, GRAPH_PALETTE} from "../Layout/BaseStyle";
import { connect } from 'react-redux';
import {toggleBackdrop} from "../Actions/Actions";
import useMountEffect from "../Helper/MountEffect";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {doFetchSpions} from "../Actions/SpionActions";


const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload) {
        return (
            <DefaultPaper>
                <Typography variant={"h5"}>
                    {label}
                </Typography>
                {payload.map((payl,index) => (
                    <p key={"ctooltip_"+index} style={{margin:0}}><span style={{fontSize:18,display:'inline-block',minWidth:50,paddingRight:5,fontWeight: "bold",color:GRAPH_PALETTE[index]}}>{payl.value}</span>{payl.name}</p>
                ))}
            </DefaultPaper>
        );
    }

    return null;
};

function GraphSpion(props){
    const basestyle = BaseStyle();
    const [chartView,setChartView] = React.useState("value");
    const dofetch = () => {
    };
    //run once
    useMountEffect(() => {
        dofetch();
    });
    const handleChartViewChange = (event) => {
        setChartView(event.target.value);
    }
    const data = props.graphlines.chart;
    const datamax = props.graphlines.max;


    return (
        <DefaultPaper>
            <Grid container justify="space-between">
                <Grid item md={6}>
                    <Typography variant={"h6"}>
                        Verkäufe der TOP 4
                    </Typography>
                </Grid>
                <Grid item>
                    <FormControl>
                        <Select
                            value={chartView}
                            onChange={handleChartViewChange}
                        >
                            <MenuItem value={"value"}>Einheiten</MenuItem>
                            <MenuItem value={"umsatz"}>Umsatz</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <div className={basestyle.spacer}></div>
            <ResponsiveContainer width="100%" height={300}>
                <LineChart>
                    <CartesianGrid stroke="#eee" />
                    {data.map((s,index) => {
                            return (<Line connectNulls type="monotone" dataKey={chartView} data={s.data} name={s.name}
                                          key={s.date}
                                           dot={{strokeWidth: 2}} strokeWidth={3} stroke={GRAPH_PALETTE[index]}/>);
                    })}
                    <XAxis dataKey="date"  tickMargin={10} allowDuplicatedCategory={false} reversed={true} padding={{ left: 30, right: 30}} />
                    <YAxis dataKey={chartView} tickLine={false} tickMargin={10} domain={['auto', datamax]} padding={{ top: 30 }} />
                    <Tooltip offset={50} content={<CustomTooltip />} />
                </LineChart>
            </ResponsiveContainer>
        </DefaultPaper>
    );
}

const mapStateToProps = (state) => {
    return {
        getCount: state.spions.items.length,
        getSpions: state.spions.items,
        graphlines: state.spions.graphlines
    };
};
const mapDispatchToProps = dispatch => ({
    fetchSpions: () => dispatch(doFetchSpions()),
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GraphSpion)