const formatDate = (date) => {
    if(typeof date === "string" && date.length === 10) return date;
    let month = date.getMonth()+1;
    let month_append = "";
    let day_append = "";
    if(month < 10) month_append = "0";
    if(date.getDate() < 10) day_append = "0";
    return date.getFullYear()+"-"+month_append+month+"-"+day_append+date.getDate();
};
export const formatDateGerman = (date) => {
    let month = date.getMonth()+1;
    let month_append = "";
    let day_append = "";
    if(month < 10) month_append = "0";
    if(date.getDate() < 10) day_append = "0";
    return day_append+date.getDate()+"."+month_append+month+"."+date.getFullYear();
};
export const getDateByString = str => {
    if(str !== null && str.length === 10) {
        var parts = str.split("-");
        var dt = new Date(parseInt(parts[0], 10),
            parseInt(parts[1], 10) - 1,
            parseInt(parts[2], 10));

        return dt;
    }
    return null;
};
Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}
export default formatDate;