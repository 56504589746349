import React from "react";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import BaseStyle from "../Layout/BaseStyle";
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import {NumberFormat} from "./NumberFormat";

export function TrendDisplay(props){
    const basestyle = BaseStyle();

    let rev = false;
    if(typeof props.reversed !== "undefined"){
        rev = true;
    }
    let intval = props.value;
    let strnumber = intval;
    if(intval > 0){
        strnumber = <span>+<NumberFormat value={strnumber} /></span>;
    }else{
        strnumber = <span><NumberFormat value={strnumber} /></span>;
    }
    if(typeof props.append !== "undefined"){
        strnumber = <span>{strnumber} {props.append}</span>;
    }
    if(intval === false){
        intval = 1;
        strnumber = <AllInclusiveIcon />;
    }
    if(intval === null){
        intval = -1;
        strnumber = <AllInclusiveIcon />;
    }
    let out_number = <Box>{strnumber}</Box>;
    let icon = <ArrowBackIcon />;
    let color_class = null;
    if((!rev && intval > 0) || (rev && intval < 0)){
        out_number = <Box>{strnumber}</Box>;
        color_class = basestyle.ChipError;
        icon = <ArrowDownwardIcon />;
    }else if((!rev && intval < 0) || (rev && intval > 0)){
        out_number = <Box>{strnumber}</Box>;
        color_class = basestyle.ChipSuccess;
        icon = <ArrowUpwardIcon />;
    }

    return (
        <span className={props.className}><Chip className={color_class} avatar={icon} label={out_number} variant="outlined" /></span>
    );
}