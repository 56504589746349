import React, {useEffect} from 'react';
import Container from "@material-ui/core/Container";
import BreadCrumb from "../HtmlComponents/BreadCrumb";
import {Helmet} from "react-helmet";
import makeTitle from "../Helper/makeTitle";
import BaseStyle, {DefaultPaper, DefaultPaperAuto} from "../Layout/BaseStyle";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {connect} from "react-redux";
import {addErrorItem, doSubmitContactForm, setContactFormSendStatus} from "../Actions/Actions";
import useMountEffect from "../Helper/MountEffect";
import {Link} from "react-router-dom";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {CircularProgress} from "@material-ui/core";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import GraphProductDetailSales from "../HtmlComponents/GraphProductDetailSales";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import {FaRoad} from "react-icons/all";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DoneIcon from '@material-ui/icons/Done';
import AlarmIcon from '@material-ui/icons/Alarm';
import CreateIcon from '@material-ui/icons/Create';

//status: planning, scheduled, done
const roadmap = [
    {
        open: true,
        label: "2020 Q4:",
        items: [
            {
                title: "Rankingüberwachung weiterer Marktplatz",
                text: "Überwachen der Produkt-Rankings auf anderen europäischen Marktplätzen. Erste Version mit Marktplatz UK (England).",
                status: "done"
            },
            {
                title: "Produktrezensionen",
                text:"Laden von Produktrezensionen. Zusätzlich zu den Sternebewertungen soll eine Übersicht aller verfassten Produktrezensionen entstehen. Bei neuen Rezensionen soll eine Information an den Verkäufer gesendet werden.",
                status: "scheduled"
            },
            {
                title: "Überarbeitung von Benachrichtigungen per Email",
                text:"Überarbeitung aller Email-Benachrichtungen. Zudem Einstellungsmöglichkeiten zum Intervall einzelner Benachrichtigungen.",
                status: "planning"
            },
            {
                title: "Marktplätze Europa",
                text:"Laden von Produkten und Bestellungen von anderen europäischen Marktplätzen: UK, FR, IT, ES",
                status: "planning"
            }
        ]
    },
    {
        label: "2021 Q1:",
        items: [
            {
                title: "Monatsabschluss",
                text:"Bereitstellen einer Zusammenfassung der Ergebnisse aus dem Vormonat. Darunter Bestellvolumen, Gewinn und abzuführende Mwst.",
                status: "planning"
            },
            {
                title: "Marktplätze Europa - Ranking",
                text:"Erweitern der Ranking-Überwachung auf andere europäischen Marktplätze: UK, FR, IT, ES",
                status: "planning"
            }
        ]
    }
];


function HelpPage(props){
    const basestyle = BaseStyle();

    useEffect(e => {
    });
    useMountEffect(() => {
    });
    console.log(props.match.params);

    let category = null;
    let title = "Hilfe";
    if(typeof props.match.params.category !== "undefined"){
        category = props.match.params.category;
        if(category=="lager") title = "Lager - Hilfeseite und Anleitungen";
        if(category=="ranking") title = "Ranking - Hilfeseite und Anleitungen";
        if(category=="spion") title = "Spion / Produkt-Überwachung - Hilfeseite und Anleitungen";
        if(category=="product") title = "Produktanalyse - Hilfeseite und Anleitungen";
        if(category=="sales") title = "Verkäufe - Hilfeseite und Anleitungen";
        if(category=="roadmap") title = "Roadmap - Weiterentwicklung der Sellerava-Anwendung";
    }

    return (
        <Container maxWidth="md">
            <Helmet>
                <title>{makeTitle(title)}</title>
            </Helmet>
            {category ? (
                    <BreadCrumb links={[
                        {label: 'Hilfe',url:"/help"},
                        {label: title}
                    ]} />
                )
            :(
                <BreadCrumb links={[
                    {label: 'Hilfe'}
                ]} />
            )}
            <Typography variant={"h4"}>{title}</Typography>
            <div className={basestyle.spacer} />
                {!category && (
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <DefaultPaper style={{textAlign:"center"}} className={basestyle.helpPaper}>
                            <Typography variant={"h5"}>Roadmap</Typography>
                            <div className={basestyle.spacer} />
                            <div className={basestyle.grow}>
                                <FaRoad style={{fontSize: "6rem",margin: "0 auto"}} />
                            </div>
                            <div className={basestyle.spacer} />
                            <Button component={Link} to="/help/roadmap" variant={"contained"} color={"primary"}>Roadmap anzeigen</Button>
                        </DefaultPaper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DefaultPaper style={{textAlign:"center"}} className={basestyle.helpPaper}>
                            <Typography variant={"h5"}>Lager</Typography>
                            <div className={basestyle.spacer} />
                            <Typography variant={"body1"} className={basestyle.grow}>Anleitungen für die Verwaltung von Lagerbeständen, Lieferungen, Bestellhinweisen, Verpackungsmaterialien und Lieferanten.</Typography>
                            <div className={basestyle.spacer} />
                            <Button component={Link} to="/help/lager" variant={"contained"} color={"primary"}>Hilfe öffnen</Button>
                        </DefaultPaper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DefaultPaper style={{textAlign:"center"}} className={basestyle.helpPaper}>
                            <Typography variant={"h5"}>Ranking</Typography>
                            <div className={basestyle.spacer} />
                            <Typography variant={"body1"} className={basestyle.grow}>Anleitungen für die Verwaltung von Keywords und Auswertung von Rankingdaten.</Typography>
                            <div className={basestyle.spacer} />
                            <Button component={Link} to="/help/ranking" variant={"contained"} color={"primary"}>Hilfe öffnen</Button>
                        </DefaultPaper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DefaultPaper style={{textAlign:"center"}} className={basestyle.helpPaper}>
                            <Typography variant={"h5"}>Spion</Typography>
                            <div className={basestyle.spacer} />
                            <Typography variant={"body1"} className={basestyle.grow}>Anleitungen für die Verwaltung und Auswertung von Produkt-Überwachungen.</Typography>
                            <div className={basestyle.spacer} />
                            <Button component={Link} to="/help/spion" variant={"contained"} color={"primary"}>Hilfe öffnen</Button>
                        </DefaultPaper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DefaultPaper style={{textAlign:"center"}} className={basestyle.helpPaper}>
                            <Typography variant={"h5"}>Produkte</Typography>
                            <div className={basestyle.spacer} />
                            <Typography variant={"body1"} className={basestyle.grow}>Anleitungen für die Auswertung von Ihren Produkten.</Typography>
                            <div className={basestyle.spacer} />
                            <Button component={Link} to="/help/product" variant={"contained"} color={"primary"}>Hilfe öffnen</Button>
                        </DefaultPaper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DefaultPaper style={{textAlign:"center"}} className={basestyle.helpPaper}>
                            <Typography variant={"h5"}>Verkäufe</Typography>
                            <div className={basestyle.spacer} />
                            <Typography variant={"body1"} className={basestyle.grow}>Informationen zum Verkauf-Dashboard und der Gewinnberechnung.</Typography>
                            <div className={basestyle.spacer} />
                            <Button component={Link} to="/help/sales" variant={"contained"} color={"primary"}>Hilfe öffnen</Button>
                        </DefaultPaper>
                    </Grid>
                </Grid>
                )}
                {category==="roadmap" && (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <DefaultPaperAuto>
                                <Typography>
                                    Legende:<br />
                                    <CreateIcon style={{position:"relative",top:4}} /> in Planung - <AlarmIcon style={{position:"relative",top:4}} /> in Entwicklung - <DoneIcon style={{position:"relative",top:4}} color={"primary"} /> Umgesetzt
                                </Typography>
                            </DefaultPaperAuto>
                            {roadmap.map((over_item) => (
                                <ExpansionPanel defaultExpanded={over_item.open}>
                                    <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>{over_item.label}</ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Typography variant={"body1"}>
                                            <List>
                                                {over_item.items.map((item) => {
                                                    let done_icon = <AlarmIcon />;
                                                    if(item.status==="planning") done_icon = <CreateIcon />;
                                                    if(item.status==="done") done_icon = <DoneIcon color={"primary"} />;
                                                    return (
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            {done_icon}
                                                        </ListItemIcon>
                                                        <ListItemText primary={item.title} secondary={item.text} />
                                                    </ListItem>);
                                                })}
                                            </List>
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            ))}
                        </Grid>
                    </Grid>
                )}
                {category==="lager" && (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <ExpansionPanel>
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>Lieferanten: Anlegen und Lieferzeit hinterlegen.</ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography variant={"body1"}>Sie können Lieferanten für Ihre Amazon Produkte und Verpackungsmaterialien anlegen und die Liefer- und Vorwarnzeit für jeden Lieferanten konfigurieren. Ordnen Sie Ihren Produkten einen Standard-Lieferanten zu, um eine möglichst genaue Lieferzeit berechnen zu können.</Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel>
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>Verpackungen: Anlegen und an Amazon Produkte binden</ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography variant={"body1"}>Sie können Verpackungsmaterialien und Zusatzprodukte wie z.B. Flyer mit dem aktuellen Lagerbestand im System anlegen. Sie können den Lagerbestand in verschiedenen Einheiten (z.B. Gramm) anlegen. Bei Ihren Amazon Produkten können Sie die Verpackungen hinterlegen und die im Durchschnitt verbrauchte Menge angeben. Je nach Einstellung wird der Lagerbestand bei einer Amazon Bestellung je Einheit oder einmalig um diesen Wert verringert. Den Verlauf des Lagerbestands können Sie jederzeit am Produkt einsehen.</Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel>
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>Bestellungen: Tracken Ihrer Lieferungen</ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography variant={"body1"}>Sie können Ihre Warenlieferungen in Sellerava tracken. Bestellungen können am betreffenden Produkt und mit einem voraussichtlichen Lieferdatum erstellt werden. In den Account-Einstellungen können Sie eigene Status definieren, um Ihren Workflow noch genauer abbilden zu können. Die Status „Bestellt“, „Abgeschlossen“ und „Archiviert“ sind vorgegeben. Verwenden Sie den Status „Abgeschlossen“, um eine automatische Anpassung des Lagerbestands bei Verpackungsmaterialien auszulösen zu können. Eine Anpassung des Lagerbestands bei Amazon Produkten ist durch Sellerava nicht möglich. Der Lagerbestand für Ihre Produkte wird regelmäßig aus der Seller-Central exportiert.</Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel>
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>Out-Of-Stock: Berechnung der Reichweite des Produktbestands</ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography variant={"body1"}>Zur Berechnung des Out-Of-Stock-Datums werden die historischen Bestellvolumen herangezogen und in verschiedene Zeiträume eingeteilt. Sie können die Gewichtung der Zahlen je Zeitraum in den Account-Einstellungen anpassen. Aus den Bestellvolumen wird ein durchschnittlicher Absatz pro Tag errechnet, welcher vorgibt, wie lange das Produkt noch auf Lager sein wird. Für Verpackungsmaterialien kann nur das automatisch abgezogene Volumen zur Berechnung genutzt werden.</Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel>
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>Lieferzeit und Bestellhinweise: Verstehen und arbeiten mit Lagerbestandswarnungen</ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{display:"block"}}>
                                    <Typography variant={"body1"}>Sie erhalten für Produkte, die einen geringen Lagerbestand aufweisen, einen Bestellhinweis per E-Mail. Der Zeitraum vor Out-Of-Stock wird in Hinweisbereich und kritischen Bereich eingeteilt. Die länge dieser Zeiträume sind von verschiedenen Einstellungen abhängig.</Typography>
                                    <ul>
                                        <li>Allgemeine Warnzeit: In den Account-Einstellungen können Sie eine allgemeine Vorwarnzeit einstellen, welche auch auf Produkte mit hinterlegter Lieferzeit angewendet wird.</li>
                                        <li>Lieferanten Zeit: Sofern Sie dem Produkt einen Standard-Lieferanten zugewiesen haben, wird die Lieferzeit und die Vorwarnzeit des Lieferanten dazu gerechnet.</li>
                                        <li>Produkt Bearbeitungszeit: Wenn Sie das Produkt noch weiter vorbereiten oder verarbeiten müssen, können Sie noch eine zusätzliche Bearbeitungszeit am Produkt hinterlegen.</li>
                                    </ul>
                                    <Typography variant={"body1"}>Die o.g. Zeiteinstellungen werden addiert und sind eine Indikation für den Termin, wann Sie circa nachbestellen müssen. Aktuell findet keine Berechnung anhand von Werktagen statt. Stellen Sie daher sicher, dass Sie genug Zeitpuffer für die Bestellung und Lieferung haben.</Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>


                        </Grid>
                    </Grid>
                )}
                {category==="ranking" && (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <ExpansionPanel>
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>Wie funktioniert die Ranking-Überwachung?</ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography variant={"body1"}>
                                        Sie können Keywords zur Überwachung Ihrer Suchplatzierungen anlegen. Diese werden täglich nach Ihren Produkten durchsucht und überwacht. Gesponserte Platzierungen werden dabei ignoriert. Das System überwacht die ersten 5 Suchseiten jedes Keywords.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel>
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>Was ist der Sichtbarkeitstrend?</ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography variant={"body1"}>
                                        Der Sichtbarkeitstrend zeigt Ihnen die Entwicklung der Rankings Ihres Verkäuferkontos an. Für die Berechnung werden die Platzierungen auf den hinterlegten Keywords verwendet. Steigen Ihre Produkte im Ranking, steigt der Sichtbarkeitstrend. Hingegen fällt der Trend, sobald die Produkte im Ranking absteigen oder gänzlich verschwinden. Der Wert des Sichtbarkeitstrends ist als Indikation gedacht und kann nicht 1:1 mit der Performance anderer Accounts verglichen werden. Der Sichtbarkeitstrend zeigt Ihnen daher die relative Entwicklung Ihres Rankings.
                                        Während Ihnen beim Sichtbarkeitstrend die Differenz zum Vergleichstag (z.B. gleicher Tag der Vorwoche) angezeigt wird, bezieht sich der „Durchschnittliche Sichtbarkeitstrend“ auf den aktuellen Zeitraum (z.B. aktueller Monat) und der Differenz zum vorherigen Zeitraum (z.B. Vormonat). Durch tägliche Schwankungen im Ranking ist dieser Wert aussagekräftiger und sollte regelmäßig kontrolliert werden.
                                        <br /><br />
                                        Für die Berechnung werden nur Rankings herangezogen, bei denen Ihre Produkte auch platziert sind. Keywords ohne Platzierung Ihrer Produkte fließen daher nicht in die Berechnung ein.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel>
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>Wie überwache ich Keywords?</ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography variant={"body1"}>
                                        Auf der Keyword-Übersicht sehen Sie alle hinterlegten Keywords. Erstellen Sie neue Keywords mit dem runden Plus-Button, um Ihre Produkte im Ranking tracken zu können.
                                        <br /><br />
                                        In der Tabelle finden Sie Informationen über das Keyword und die gefundenen Ranking-Daten. Der Status zeigt an, ob das Keyword aktuell überwacht wird, oder ob es deaktiviert ist. Sofern Sie den Alarm-Service an einem Keyword aktiviert haben, steht im Status zusätzlich „Alarm aktiv“.
                                        In den Spalten „Top Position“ und „Produkte“ wird zusätzlich der Trend angezeigt. Dieser signalisiert, wie sich das Ranking in den letzten 30 Tagen verändert hat.
                                        <br /><br />
                                        Durch die Mehrfachauswahl in der Tabelle können Sie Keywords aktivieren, deaktivieren und löschen.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel>
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>Wie nutze ich die Alarm-Funktion?</ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography variant={"body1"}>
                                        Sie können den Alarm-Service für ein Keyword, oder ein bestimmtes Produkt für Keyword aktivieren. Tragen Sie anschließend einfach die Ranking-Schwelle ein, um sich bei der Über- oder Unterschreitung dieser Schwelle informieren zu lassen. Eine Übersicht über alle aktiven Alarme finden Sie auf der Ranking-Übersicht nach einem Klick auf den grauen „Benachrichtigungen“-Button.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                    </Grid>
                )}
                {category==="spion" && (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <ExpansionPanel>
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>Einführung in die Übersichtsseite und Produkt-Seite</ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography variant={"body1"}>
                                        Auf der Übersicht sehen Sie alle Produkte, die Sie überwachen. Über der Produktliste ist eine Grafik mit den Verkäufen der Top 4. Sie können die Produktliste nach Status und Marktplatz filtern. Außerdem können Sie alle gesammelten Daten aller überwachten Produkte für einen Zeitraum exportieren und z.B. in Excel weiterverarbeiten.
                                        <br />
                                        In der Tabelle sehen Sie die Kategorie und den Verkaufsrang in der Kategorie. Umso höher das Produkt im Verkaufsrang ist (1 = bester Rang), umso mehr Verkäufe finden statt.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel>
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>Wie funktioniert die Produkt-Überwachung?</ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography variant={"body1"}>
                                        Bei Amazon gibt es seit langem die Möglichkeit den aktuellen Lagerbestand eines fremden Produkts zu ermitteln. Das als „999 Methode“ bezeichnete Vorgehen hat sich als nicht wirkungsvoll erwiesen, da einerseits Produkte durchaus mehr als 1000 Stück im Lager haben können, auf der anderen Seite viele Faktoren diese Methode beeinflussen. Nicht auf eine einzelne Bestellung genau, jedoch für fast jedes Produkt einsetzbar, nutzen wir nun den aktuellen Verkaufsrang eines Produkts, um den durchschnittlichen Absatz zu ermitteln.
                                        <br />
                                        Es gibt Statistiken, welche das Verkaufsvolumen für bestimmte Kategorien und den jeweiligen Verkaufsrang analysiert haben. Wir nutzen diese Statistiken und ermitteln so täglich den Verkaufsrang, die Kategorie und das daraus resultierende Verkaufsvolumen.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel>
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>Wie überwache ich Keywords?</ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography variant={"body1"}>
                                        Auf der Keyword-Übersicht sehen Sie alle hinterlegten Keywords. Erstellen Sie neue Keywords mit dem runden Plus-Button, um Ihre Produkte im Ranking tracken zu können.
                                        <br /><br />
                                        In der Tabelle finden Sie Informationen über das Keyword und die gefundenen Ranking-Daten. Der Status zeigt an, ob das Keyword aktuell überwacht wird, oder ob es deaktiviert ist. Sofern Sie den Alarm-Service an einem Keyword aktiviert haben, steht im Status zusätzlich „Alarm aktiv“.
                                        In den Spalten „Top Position“ und „Produkte“ wird zusätzlich der Trend angezeigt. Dieser signalisiert, wie sich das Ranking in den letzten 30 Tagen verändert hat.
                                        <br /><br />
                                        Durch die Mehrfachauswahl in der Tabelle können Sie Keywords aktivieren, deaktivieren und löschen.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel>
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>Wie erstelle ich einen neuen Spion?</ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography variant={"body1"}>
                                        Über der Tabelle befindet sich ein runder Plus-Button, womit Sie einen neuen Spion anlegen können. Wählen Sie die gewünschten Marktplätze bzw. den Marktplatz aus und tragen Sie eine ASIN ein. Da das System die Produkte täglich überwacht, schauen Sie sich nach ein paar Tagen die Ergebnisse an.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                    </Grid>
                )}
                {category==="sales" && (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <ExpansionPanel>
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>Einführung in das Sales Dashboard</ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography variant={"body1"}>
                                        Auf dem Sales Dashboard bekommen Sie eine Übersicht über Ihre Verkaufszahlen, die meist verkauften Produkte und das Verkaufsvolumen je Kategorie. Der Zeitraum, auf den sich die Zahlen beziehen, kann oben rechts beliebig angepasst werden.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel>
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>Gewinn berechnen und auswerten</ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography variant={"body1"}>
                                        Sofern Sie bei Ihren Produkten Ihre Kosten hinterlegt haben, wird der Gewinn bei einer Bestellung berechnet. Unter Verkäufe -> Gewinn erhalten Sie eine Detailübersicht der Gewinnberechnung. Sofern Sie Umsatzsteuerbefreit (z.B. Kleinunternehmer) sind, müssen Sie dies in den Account-Einstellungen vermerken.
                                        <br /><br />
                                        Sie können unter Verkäufe -> Gewinn -> Konfigurieren die Kosten zeitsparend in einer Tabelle für viele Produkte angeben.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                    </Grid>
                )}
                {category==="product" && (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <ExpansionPanel>
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>Produkt Übersicht</ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography variant={"body1"}>
                                        Über die Produktübersicht können Sie schnell zu den Detailansichten Ihrer Produkte wechseln. Mittels Filter können Sie sich Produkte anhand ihres Status, der Versandart und Kategorie anzeigen lassen. Mit einem Klick auf das Produkt in der Tabelle gelangen Sie zur Detailseite des Produkts.
                                        <br />
                                        Zusätzlich zu den Basisinformationen des Produkts wird in der Tabelle auch der Verkaufsrang, der aktuelle BuyBox-Zustand, die Bewertung, der Lagerbestand und Gewinn angezeigt. Sie können die Spalten zur Sortierung nutzen.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel>
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>Wie lege ich ein neues Produkt an?</ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography variant={"body1"}>
                                        Wir empfehlen allen Sellerava-Nutzern die Schnittstelle zur Amazon Sellercentral einzurichten. Dadurch können alle Produkte täglich und automatisch importiert werden, sowie viele Zusatzinformationen abgefragt werden.
                                        <br />
                                        Sofern Sie keine Schnittstelle nutzen, können Sie mit einem Klick auf den runden Plus-Button eine neue ASIN anlegen. Ein unabhängiges System lädt anschließend Basisinformationen zu dem Produkt herunter.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel>
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>Produktseite: Allgemein</ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography variant={"body1"}>
                                        Auf der Produktseite erhalten Sie viele unterschiedliche Informationen zum Produkt. Oben sehen Sie neben dem Produkttitel und dem Link zum Angebot Kurzinformationen für den schnellen Überblick. Rechts neben dem Produktbild sehen Sie die Bewertung, den Verkaufsrang und Basisinformationen, wie den Lagerbestand und die Kategorie.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel>
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>Produktseite: Verkäufe und Umsatz</ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography variant={"body1"}>
                                        Die Grafik zeigt Ihnen die verkauften Einheiten, den Umsatz und den Gewinn der letzten 30 Tage.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel>
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>Produktseite: Verkaufsrang und BuyBox-Anteil</ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography variant={"body1"}>
                                        Die Grafik zeigt Ihnen den Verlauf des Verkaufsrangs der letzten 30 Tage. Der BuyBox-Anteil ist der 7-Tage-Durchschnitt in Prozent.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel>
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>Produktseite: Bewertungen</ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography variant={"body1"}>
                                        Auf der linken Seite sehen Sie den prozentualen Anteil je Sternebewertung. Amazon ändert regelmäßig die Gewichtung der Gesamtbewertung, weswegen Sie die Anteile nicht direkt auf die Gesamtbewertung rechnen können. In der Mitte ist der Bewertungsverlauf (Anzahl Bewertungen) und der Sterneverlauf der letzten 180 Tage abgebildet.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel>
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>Produktseite: Ranking</ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography variant={"body1"}>
                                        In der Rankingtabelle sind alle Keywords aufgeführt, auf denen das Produkt zu finden ist oder zu finden war. Mit einem Klick auf das Keyword gelangen Sie zur Detailseite des Keywords.
                                        <br />
                                        Sie können direkt in der Tabelle einen Ranking-Alarm für das Produkt auf dem spezifischen Keyword erstellen.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel>
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>Produktseite: Angebot</ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography variant={"body1"}>
                                        Sofern Sie nicht der einzige Anbieter des Produkts sind, erhalten Sie in dieser Tabelle eine Übersicht über die Konkurrenz, deren Preis und Konditionen. Ihr eigenes Angebot ist dabei in Grün hinterlegt.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel>
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>Produktseite: Preisverlauf</ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography variant={"body1"}>
                                        Sofern sich Ihr Produkt häufig verkauft und Sie verschiedene Preis-Strategien verfolgen, können Sie in dieser 180 Tage Auswertung sehen, wie sich der Preis auf Ihre Verkaufszahlen auswirkt.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel>
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>Produktseite: Gewinn</ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography variant={"body1"}>
                                        Für die Gewinnberechnung müssen Sie bei einem Produkt den „All-Inclusive“-Einkaufspreis und die Versandkosten pro Amazon-Bestellung sowie den anzuwendenden Steuersatz angeben. Eine Beispielrechnung finden Sie in der Mitte. Auf der rechten Seite sehen Sie die Margin-Übersicht.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel>
                                <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>Produktseite: Lager & Versand</ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography variant={"body1"}>
                                        In diesem Reiter können Sie einige Einstellungen für die Lagerverwaltung und Verpackungsmaterialien tätigen. Nähere Informationen dazu finden Sie in dem Hilfebereich „Lager“.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                    </Grid>
                )}
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className={basestyle.spacer} />
                        <div className={basestyle.spacer} />
                        <div className={basestyle.spacer} />
                        <DefaultPaperAuto style={{textAlign:"center"}}>
                            <Typography variant={"h5"}>Kontakt aufnehmen</Typography>
                            <div className={basestyle.spacer} />
                            <Typography variant={"body1"}>Gerne unterstützen wir Sie auch persönlich bei Ihren Fragen. Am einfachsten ist es für uns, wenn Sie uns eine kurze formlose Anfrage senden.</Typography>
                            <div className={basestyle.spacer} />
                            <Button component={Link} to="/help/feedback" variant={"contained"} color={"primary"}>Kontakt aufnehmen</Button>
                        </DefaultPaperAuto>
                    </Grid>
                </Grid>
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
    };
};
const mapDispatchToProps = dispatch => ({
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HelpPage)