import React from 'react';
import MaterialTable from "material-table";
import BaseStyle from "../Layout/BaseStyle";
import AddBtnSpion from "./AddBtnSpion";
import { connect } from 'react-redux';
import {
    toggleBackdrop
} from "../Actions/Actions";
import useMountEffect from "../Helper/MountEffect";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import GraphSpion from "./GraphSpion";
import FilterSpion from "./FilterSpion";
import Box from "@material-ui/core/Box";
import CountryFlag from "./CountryFlag";
import {withRouter} from 'react-router-dom'
import tableIcons from "../Layout/TableIcons";
import {doDeleteSpion, reloadSpionPage} from "../Actions/SpionActions";


export const FlagInTable = (prop) => (<div style={{textAlign:"center"}}><CountryFlag country={prop.country} /></div>);

function TableSpion(props){
    const basestyle = BaseStyle();
    const [deleteDialog,setDeleteDialog] = React.useState(null);

    const dofetch = () => {
        props.reloadSpionPage();
    };
    const handleDeleteClose = () => {
        setDeleteDialog(null);
    };
    const handleDetailOpen = (id) => {
        //setForward(<Redirect to={'/spion/'+id} />);
        props.history.push('/spion/'+id)
    };
    const handleDelete = (id,asin,status) => {
          setDeleteDialog((
              <Dialog
                  open={true}
                  onClose={handleDeleteClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
              >
                  <DialogTitle id="alert-dialog-title">{"Produktüberwachung beenden?"}</DialogTitle>
                  <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                          {status===1 && (<Box>Möchten Sie die gesammelten Daten von ASIN "{asin}" wirklich löschen?</Box>)}
                          {status===0 && (<Box>Möchten Sie die Überwachung von ASIN "{asin}" wirklich beenden?</Box>)}
                      </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                      <Button onClick={handleDeleteClose} color="primary">
                          Abbrechen
                      </Button>
                      <Button onClick={() => {
                          //delete spion
                          props.toggleBackdrop(true);
                          props.deleteSpion(id).then(() => {
                              handleDeleteClose();
                              props.toggleBackdrop(false);
                          });
                      }} color="error" autoFocus>
                          {status===1 && (<Box>Löschen</Box>)}
                          {status===0 && (<Box>Beenden</Box>)}
                      </Button>
                  </DialogActions>
              </Dialog>
          ));
    };
    //run once
    useMountEffect(() => {
        dofetch();
    });

    return (
        <div>
            <FilterSpion />
            {(props.filterStatus !== "canceled" && props.getSpions.length > 0) && (<GraphSpion />)}

            <AddBtnSpion />
            {deleteDialog}
            <MaterialTable title="Produkt-Spion" icons={tableIcons} columns={[
                {
                    field: 'pic',
                    title: 'Bild',
                    sorting: false,
                    cellStyle: { paddingTop:0,paddingBottom:0,textAlign: "center" },
                    export: false,
                    render: rowData => <img src={rowData.pic} alt={"Produktbild"} className={basestyle.TableImage}/>
                },
                {
                    title: 'Marktplatz',
                    field: 'marketplace',
                    /*customSort: (a, b) => {
                        return ([a.marketplace, b.marketplace].sort()[0] === b.marketplace)?0:-1;
                    },*/
                    render: rowData => {
                        let flag = rowData.marketplace;
                        return <FlagInTable country={flag} />;
                    }/*,
                    lookup: {
                        "de": (<FlagInTable country={"DE"} />),
                        "uk": (<FlagInTable country={"GB"} />),
                        "it": (<FlagInTable country={"IT"} />),
                        "es": (<FlagInTable country={"ES"} />),
                        "fr": (<FlagInTable country={"FR"} />)
                    }*/
                },
                { title: "ASIN", field: "asin" },
                {
                    field: 'title',
                    title: 'Produkt',
                    render: rowData => (
                        <div>
                            <span>{rowData.title}</span>
                        </div>
                    )
                    /*<br />
                            <small>{rowData.asin}</small>*/
                },
                {
                    title: 'Status',
                    field: 'status',
                    render: rowData => {
                        if(rowData.status === 0) {
                            return <Box color="success.main">Aktiv</Box>;
                        }else{
                            return <Box color="warning.main">Beendet</Box>;
                        }
                    },
                    lookup: { 0: "aktiv", 1: "beendet" }
                },
                { title: "Kategorie", field: "category" },
                { title: "VK-Rang", field: "vkrang", type: "numeric" },
                { title: "Tgl. Sales", field: "absatz", type: "numeric" },
                { title: "Tgl. Umsatz", field: "umsatz", type: "currency",currencySetting: { locale: 'de', currencyCode: "EUR" } },
            ]}
           data={props.getSpions}
           actions={[
               /*rowData => ({
                icon: tableIcons.ViewDetail,
                tooltip: "Detailansicht",
                onClick: (event, rowData) => {
                    handleDetailOpen(rowData.id);
                },
                hidden: (rowData.title == null)
               }),*/
               rowData => ({
                   icon: ((rowData.status===1)?tableIcons.Delete:tableIcons.Stop),
                   tooltip: ((rowData.status===1)?'Spion löschen':'Spion beenden'),
                   onClick: (event, rowData) => {
                       handleDelete(rowData.id, rowData.asin,rowData.status);
                   }
               })
           ]}
           onRowClick={(event,rowData) => {
               handleDetailOpen(rowData.id);
           }}
           options={{
               exportButton: true,
               exportDelimiter: ";",
               exportAllData: true,
               pageSize: 20,
               /*selection: true, not working with actions*/
               actionsColumnIndex: 0,
               cellStyle: {
                   fontSize: 12
               }
           }}/>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        getCount: state.spions.items.length,
        getSpions: state.spions.items,
        filterStatus: state.spions.filter.status
    };
};
const mapDispatchToProps = dispatch => ({
    deleteSpion: (id) => dispatch(doDeleteSpion(id)),
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    reloadSpionPage: () => dispatch(reloadSpionPage())
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TableSpion))