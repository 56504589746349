import React from 'react';
import clsx from "clsx";
import './App.css';
import AppBody from './AppBody';
import {makeStyles} from "@material-ui/core";
import { createMuiTheme,MuiThemeProvider  } from '@material-ui/core/styles';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import AccountControl from "./AccountControl";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from "@material-ui/core/Divider";
import GlobalStateProvider from "./GlobalStateProvider";
import {BrowserRouter} from 'react-router-dom';
import Link from "@material-ui/core/Link";
import BaseStyle from "./Layout/BaseStyle";
import NavContent from "./HtmlComponents/NavContent";
import {connect} from 'react-redux';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {NON_REACT_HOME_URL, removeErrorItem} from "./Actions/Actions";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import DocumentMeta from 'react-document-meta';
import {SnackbarProvider} from "notistack";
import {Helmet} from "react-helmet";



const theme = createMuiTheme({
    typography: {
        h1: {
            color: '#757575'
        },
        h2: {
            color: '#757575'
        },
        h3: {
            color: '#757575'
        },
        h4: {
            color: '#757575'
        }
    },
    palette: {
        type: 'light',
        primary: {
            main: '#2c3e50',
        },
        secondary: {
            main: '#f39c12',
            //contrastText: '#ffcc00',
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // Zum Beispiel von Red 500 zu Red 300 oder Red 700 zu wechseln.
        tonalOffset: 0.2,
    },
});


export const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        maxWidth: '100%',
        boxSizing: 'border-box'
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    backdrop: {
        zIndex: theme.zIndex.tooltip + 1,
        color: '#fff',
    }
}));


function App(props) {
    const classes = useStyles();
    const basestyle = BaseStyle();
    const [state, setState] = React.useState({
        navopen: false
    });
    const toggleDrawer = (open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ navopen: open });
    };
    const meta = {
        viewport: "minimum-scale=1, initial-scale=1, width=device-width"
    };

    return (
        <GlobalStateProvider>
          <MuiThemeProvider  theme={theme}>
              <SnackbarProvider maxSnack={3}>
                <DocumentMeta {...meta} />
                <Helmet>
                    <meta name="robots" content="NOINDEX, NOFOLLOW, NOODP" />
                    <meta name="description" content="Sellerava Amazon Tool" />
                </Helmet>
                <BrowserRouter>
                    <div className={classes.root}>
                        <AppBar position="fixed"
                                className={clsx(classes.appBar, {
                                    [classes.appBarShift]: state.navopen,
                                })}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)} className={clsx(classes.menuButton, state.navopen && classes.hide)}>
                                    <MenuIcon />
                                </IconButton>
                                <Link component={"a"} href={NON_REACT_HOME_URL}>
                                    <Typography variant="h6" noWrap style={{ color: '#18bc9c' }}>
                                        <b>Seller</b>ava
                                    </Typography>
                                </Link>
                                <div className={basestyle.grow} />
                                <AccountControl />
                            </Toolbar>
                        </AppBar>
                        <Drawer className={classes.drawer} open={state.navopen} variant="persistent" anchor="left" classes={{
                            paper: classes.drawerPaper,
                        }}>

                            <div className={classes.drawerHeader}>
                                <IconButton onClick={toggleDrawer(false)}>
                                    <ChevronLeftIcon />
                                </IconButton>
                            </div>
                            <Divider />

                            {props.user.isLoggedIn && (
                                <NavContent toggleDrawer={toggleDrawer} />
                            )}
                        </Drawer>
                        <main
                            className={clsx(classes.content, {
                                [classes.contentShift]: state.navopen,
                            })}
                        >
                            <div className={classes.drawerHeader} />
                            <AppBody />
                        </main>
                        {props.alerts.map((e,index) => (
                            <Snackbar key={index} anchorOrigin={{vertical:'top',horizontal:'center'}} open={e.open} autoHideDuration={5000} onClose={() => {
                                props.removeError(index);
                            }}>
                                <MuiAlert severity={e.variant} variant={"filled"}>
                                    {e.msg} {e.code > 0 && "("+e.code+")"}
                                </MuiAlert>
                            </Snackbar>
                        ))}
                        <Backdrop className={classes.backdrop} open={props.backdropOpen}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </div>
                </BrowserRouter>
              </SnackbarProvider>
          </MuiThemeProvider>
        </GlobalStateProvider>
    );
}

const mapStateToProps = (state) => {
    return {
        backdropOpen: state.visuals.backdrop,
        alerts: state.visuals.alerts,
        user: state.account.user
    };
};
const mapDispatchToProps = dispatch => ({
    removeError: (index) => dispatch(removeErrorItem(index)),
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
