import React from 'react';
import MaterialTable from "material-table";

import BaseStyle from "../Layout/BaseStyle";
import { connect } from 'react-redux';
import {
    toggleBackdrop
} from "../Actions/Actions";
import useMountEffect from "../Helper/MountEffect";
import {withRouter} from 'react-router-dom'
import tableIcons from "../Layout/TableIcons";
import Grid from "@material-ui/core/Grid";
import {doFetchProductOffers} from "../Actions/ProductActions";
import {CircularProgress} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import DoneIcon from "@material-ui/icons/Done";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PanToolIcon from '@material-ui/icons/PanTool';
import Rating from "@material-ui/lab/Rating";
import {EuroFormat} from "../Helper/CurrencyFormat";

function TableProductDetailOffers(props){
    const basestyle = BaseStyle();

    const dofetch = () => {
        props.loadOffers(props.productId);
    };
    //const handleDetailOpen = (id) => {
        //props.history.push('/ranking/'+id);
    //};
    //run once
    useMountEffect(() => {
        dofetch();
    });

    /*let title_app = "";
    if(props.ranking_loaded === 0){
        title_app = <Typography variant={"subtitle2"} className={basestyle.smallLoading}><CircularProgress size={14} /> Laden...</Typography>;
    }*/
    let title = (<span><Typography>Angebote</Typography><Typography variant={"subtitle2"} className={basestyle.success}>Ihr Angebot ist grün hinterlegt.</Typography></span>);

    return (
        <Grid container>
            <Grid item xs={12}>
                {props.loaded === 0 && (<div style={{textAlign:"center"}}><CircularProgress color={"secondary"} /></div>)}

                {props.loaded !== 0 && (
                    <MaterialTable title={title} icons={tableIcons}
                    columns={[
                        { title: "Nr.", field: "nr" },
                        {
                            title: "BuyBox",
                            field: "buybox",
                            render: rowData => {
                                if(rowData.buybox === "1"){
                                    return <DoneIcon className={basestyle.success} />;
                                }else{
                                    return "";
                                }
                            }
                        },
                        {
                            title: "Zustand",
                            field:"condition",
                            render: rowData => {
                                let style = null;
                                let icon = null;
                                let label = rowData.condition;
                                if(rowData.condition==="open_box"){
                                    style = basestyle.warningLightColor;
                                    label = "Geöffnet";
                                    icon = <PriorityHighIcon className={style} />;
                                }else if(rowData.condition==="new"){
                                    style = basestyle.success;
                                    label = "Neuware";
                                    icon = <DoneIcon className={style} />;
                                }else if(rowData.condition==="used"){
                                    style = basestyle.errorColor;
                                    label = "Gebraucht";
                                    icon = <PriorityHighIcon className={style} />;
                                }else{
                                    style = null;
                                    icon = <HelpOutlineIcon className={style} />;
                                }
                                return <Chip label={label} className={style} icon={icon}  variant="outlined" />;
                            }
                        },
                        {
                            title: "Preis",
                            field: "price",
                            type: "currency",
                            currencySetting: { locale: 'de', currencyCode: "EUR" },
                            render: rowData => {
                                return (<span><EuroFormat value={parseFloat(rowData.price)}/> + <EuroFormat value={parseFloat(rowData.ship_price)}/></span>);
                            }
                        },
                        {
                            title: "Bewertung",
                            field: "feedback",
                            render: rowData => {
                                return (
                                    <div style={{textAlign:"center"}}>
                                        <Rating name="read-only" value={5 / 100 * parseFloat(rowData.feedback)} size="small" readOnly /><br />
                                        {rowData.feedback_count}
                                    </div>
                                );
                            }
                        },
                        {
                            title: "Versandoption",
                            field:"fba",
                            render: rowData => {
                                let style = null;
                                let icon = null;
                                let label = rowData.condition;
                                if(rowData.fba === "1"){
                                    style = basestyle.success;
                                    label = "FBA";
                                    icon = <DoneIcon className={style} />;
                                }else if(rowData.fba === "0"){
                                    style = basestyle.warningLightColor;
                                    label = "Eigenversand";
                                    icon = <PanToolIcon className={style} />;
                                }
                                return <Chip label={label} className={style} icon={icon}  variant="outlined" />;
                            }
                        }
                    ]}
                    data={props.getList}
                    options={{
                        actionsColumnIndex: 0,
                        cellStyle: {
                            fontSize: 12
                        },
                        rowStyle: rowData => {
                            if(rowData.myoffer === "1") {
                                return {
                                    backgroundColor: '#f9fbe7'
                                };
                            }
                        }
                    }
                    }/>
                )}
            </Grid>
        </Grid>
    );
}

/*
[
                        { title: "Keyword", field: "keyword" },
                        {
                            title: "Status",
                            field: "found_status",
                            render: rowData => {
                                if(rowData.found_status === "found") {
                                    return <Box color="success.main">Platziert</Box>;
                                }else if(rowData.found_status === "not_found") {
                                    return <Box color="warning.main">Nicht platziert</Box>;
                                }else if(rowData.found_status === "fetch_open") {
                                    return <Box color="warning.main">Ausstehend</Box>;
                                }else{
                                    return <Box color="error.main">Unbekannt</Box>;
                                }
                            },
                            lookup: { "found": "Platziert", "not_found": "Nicht platziert", "fetch_open": "Ausstehend", "unknown":"Unbekannt" }
                        },
                        {
                            title: "Durchschn. Position",
                            field: "position",
                            type: 'numeric',
                            defaultSort: 'asc',
                            render: rowData => {
                                if(rowData.position !== 10000) {
                                    return <NumberFormat value={rowData.position}/>;
                                }else{
                                    return '';
                                }
                            }
                        },
                        { title: "Zuletzt gefunden", field: "last", type: 'date' }
                    ]
 */

const mapStateToProps = (state) => {
    return {
        getList: state.products.single_offers,
        loaded: state.products.single_offers.length
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    loadOffers: (id) => dispatch(doFetchProductOffers(id))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TableProductDetailOffers))