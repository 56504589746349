import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import BaseStyle from "../Layout/BaseStyle";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import {InputLabel} from "@material-ui/core";
import useMountEffect from "./MountEffect";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import {addErrorItem, toggleBackdrop} from "../Actions/Actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

const LieferantenDialog = (props) => {
    const basestyle = BaseStyle();
    const [newEntry,setNewEntry] = React.useState(true);
    const [form,setForm] = React.useState({
        Name: "",
        Telefon: "",
        Website: "",
        Email: "",
        Lieferzeit: "",
        Warnen: "",
        Status: 0
    });

    const Change = (field,e) => {
        setForm({
            ...form,
            [field]: e.target.value
        });
    };
    const handleSave = () => {
        let valid = true;
        if(form.Name.length < 2) valid = false;
        if(form.Lieferzeit < 0 || form.Lieferzeit > 360) valid = false;
        if(form.Warnen < 0 || isNaN(form.Warnen)) valid = false;

        if(valid){
            props.save(form);
        }else{
            props.addError("Bitte kontrollieren Sie die Pflichtfelder auf Vollständigkeit!");
        }

    };

    useMountEffect(() => {
           if(typeof props.data !== "undefined" && props.data !== null){
               setForm(props.data);
               setNewEntry(false);
           }
    });

    return (
        <Dialog open={true} onClose={props.abort} maxWidth={"md"} fullWidth>
            <DialogTitle>Lieferant {newEntry && "hinzufügen"}{!newEntry && "bearbeiten"}</DialogTitle>
            <DialogContent>
                {!newEntry && (
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="lieferant-status-label">Status</InputLabel>
                                <Select
                                    labelId="lieferant-status-label"
                                    value={form.Status}
                                    onChange={(e) => Change("Status",e)}
                                >
                                    <MenuItem value={0}>Aktiv</MenuItem>
                                    <MenuItem value={1}>Inaktiv / Ruhend</MenuItem>
                                    <MenuItem value={2}>Archiviert / Gelöscht (unwiderruflich)</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                )}
                <div className={basestyle.spacer} />
                <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                        <TextField
                            required
                            value={form.Name}
                            onChange={(e) => Change("Name",e)}
                            autoFocus
                            label="Name des Lieferanten"
                            type="text"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            value={form.Telefon}
                            onChange={(e) => Change("Telefon",e)}
                            label="Telefon"
                            type="text"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Website</InputLabel>
                            <Input
                                value={form.Website}
                                onChange={(e) => Change("Website",e)}
                                type="text"
                                startAdornment={<InputAdornment position="start">http(s)://</InputAdornment>}
                            />
                            <FormHelperText>Inkl. Bearbeitungszeit</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            value={form.Email}
                            onChange={(e) => Change("Email",e)}
                            label="E-Mail"
                            type="text"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <div className={basestyle.spacer} />
                <Divider />
                <div className={basestyle.spacer} />
                <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                        <FormControl fullWidth required>
                            <InputLabel>Lieferzeit</InputLabel>
                            <Input
                                value={form.Lieferzeit}
                                onChange={(e) => Change("Lieferzeit",e)}
                                type="text"
                                endAdornment={<InputAdornment position="end">Tage</InputAdornment>}
                            />
                            <FormHelperText>Inkl. Bearbeitungszeit</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Warnung vorher senden</InputLabel>
                            <Input
                                value={form.Warnen}
                                onChange={(e) => Change("Warnen",e)}
                                type="text"
                                endAdornment={<InputAdornment position="end">Tage</InputAdornment>}
                            />
                            <FormHelperText>Falls Sie weitere Vorlaufzeit benötigen</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
                <div className={basestyle.spacer} />
                <Typography variant={"subtitle2"}>* Pflichtfelder</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.abort} color="primary">
                    Abbrechen
                </Button>
                <Button onClick={handleSave} color="primary">
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>
    );
};
const mapStateToProps = (state) => {
    return {
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    addError: (msg,code) => dispatch(addErrorItem(msg,code))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(LieferantenDialog))