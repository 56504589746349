import {addSuccessItem, API_URL, catchErrors, handleErrors, toggleBackdrop} from "./Actions";
import {getPickerDataQuery} from "../Helper/getPickerDataQuery";
import {receiveProductDetail} from "./ProductActions";


export const RECIEVE_LIEFERANTEN = 'RECIEVE_LIEFERANTEN';
export const RECIEVE_LAGER_BESTELLUNGEN = 'RECIEVE_LAGER_BESTELLUNGEN';
export const SET_LAGER_BESTELLUNG_FILTER_STATUS = 'SET_LAGER_BESTELLUNG_FILTER_STATUS';
export const SET_LAGER_BESTELLUNG_FILTER_PRODUCT = 'SET_LAGER_BESTELLUNG_FILTER_PRODUCT';

export function receiveLieferanten(json){
    return {
        type: RECIEVE_LIEFERANTEN,
        value: json.result
    }
}
export function receiveBestellungen(json){
    return {
        type: RECIEVE_LAGER_BESTELLUNGEN,
        value: json.result
    }
}
export function setLagerBestellungFilterStatus(val_array){
    return {
        type: SET_LAGER_BESTELLUNG_FILTER_STATUS,
        value: val_array
    }
}
export function setLagerBestellungFilterProduct(val_id){
    return {
        type: SET_LAGER_BESTELLUNG_FILTER_PRODUCT,
        value: val_id
    }
}


/***FETCH API***/

export function doFetchLieferanten(status){
    return async (dispatch, getState) => {
        if(typeof status === "undefined") status = "";
        await fetch(API_URL +'/Lager.php?action=Lieferanten&status='+status, {
            credentials: 'include'
        }).then(handleErrors).then(
            response => {
                dispatch(receiveLieferanten(response));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}
export function doCreateLieferant(new_obj){
    return async (dispatch, getState) => {
        await fetch(API_URL +'/Lager.php?action=Lieferant', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(new_obj)
        }).then(handleErrors).then(
            response => {
                dispatch(addSuccessItem("Erfolgreich gespeichert!"));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}

export function doFetchBestellungen(){
    return async (dispatch, getState) => {
        let status = getState().lager.filterStatus;
        if(status) status = status.join(",");
        let product = getState().lager.filterProduct;
        await fetch(API_URL +'/Lager.php?action=Bestellungen&status='+status+"&product="+product, {
            credentials: 'include'
        }).then(handleErrors).then(
            response => {
                dispatch(receiveBestellungen(response));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}
export function doCreateBestellung(new_obj){
    return async (dispatch, getState) => {
        await fetch(API_URL +'/Lager.php?action=Bestellung', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(new_obj)
        }).then(handleErrors).then(
            response => {
                dispatch(addSuccessItem("Erfolgreich gespeichert!"));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}
export function addCustomLieferStatus(text){
    return async (dispatch, getState) => {
        await fetch(API_URL +'/Lager.php?action=addBestellungStatus', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                text: text
            })
        }).then(handleErrors).then(
            response => {
                dispatch(addSuccessItem("Der Status wurde hinzugefügt!"));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}
export function deleteCustomLieferStatus(id){
    return async (dispatch, getState) => {
        await fetch(API_URL +'/Lager.php?action=deleteBestellungStatus', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                id: id
            })
        }).then(handleErrors).then(
            response => {
                dispatch(addSuccessItem("Der Status wurde gelöscht!"));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}
export function saveLagerSettings(settings){
    return async (dispatch, getState) => {
        await fetch(API_URL +'/Lager.php?action=LagerSettings', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(settings)
        }).then(handleErrors).then(
            response => {
                dispatch(addSuccessItem("Erfolgreich gespeichert!"));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}
export function saveLagerProductSettings(settings){
    return async (dispatch, getState) => {
        await fetch(API_URL +'/Lager.php?action=LagerProductSettings', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(settings)
        }).then(handleErrors).then(
            response => {
                dispatch(addSuccessItem("Erfolgreich gespeichert!"));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}
export function saveProductStock(productid,menge){
    return async (dispatch, getState) => {
        await fetch(API_URL +'/Lager.php?action=LagerProductUpdate', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                productid: productid,
                Menge: menge
            })
        }).then(handleErrors).then(
            response => {
                dispatch(addSuccessItem("Lagerbestand erfolgreich aktualisiert!"));
                dispatch(receiveProductDetail({
                    ...getState().products.single,
                    stock: menge
                }));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}
export function addVerpackungBinding(data){
    return async (dispatch, getState) => {
        await fetch(API_URL +'/Lager.php?action=VerpackungBinding', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(data)
        }).then(handleErrors).then(
            response => {
                var new_arr = getState().products.single.verpackung_binding.filter((i) => i.BindingID !== data.BindingID);
                if(typeof data.action !== "undefined" && data.action === "delete"){
                    //do nothing
                }else{
                    if(!isNaN(response.result) && response.result > 0){
                        data.BindingID = response.result;
                    }
                    new_arr.push(data);
                }
                dispatch(addSuccessItem("Verpackungsdaten erfolgreich aktualisiert!"));
                dispatch(receiveProductDetail({
                    ...getState().products.single,
                    verpackung_binding: new_arr
                }));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}
