import MaterialTable from "material-table";
import React from "react";
import tableIcons from "../Layout/TableIcons";


function MiniTable(props){

    const tableLikeDefaultPaper = {
        marginBottom: 20,
        padding: 20,
        height: 'calc(100% - 40px)',
        '& h1': {
            fontSize: 30
        }
    };

    return (
        <MaterialTable title={props.title} style={tableLikeDefaultPaper} icons={tableIcons} columns={props.columns} data={props.data} options={{
            header: typeof props.header !== "undefined" ? props.header : false,
            paging: false,
            search: false,
            showTitle: (typeof props.title !== "undefined"),
            toolbar: (typeof props.title !== "undefined"),
            cellStyle: { padding:8 }
        }} />
    );
}
export default MiniTable;