import React from 'react';
import BaseStyle, {DefaultPaperAuto} from "../Layout/BaseStyle";
import { connect } from 'react-redux';
import {
    NON_REACT_HOME_URL,
    toggleBackdrop
} from "../Actions/Actions";
import useMountEffect from "../Helper/MountEffect";
import {Link as RouterLink, withRouter} from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {formatDateGerman, getDateByString} from "../Helper/Date";
import Button from "@material-ui/core/Button";


function PreBodyComponents(props){
    const basestyle = BaseStyle();

    //run once
    useMountEffect(() => {
    });

    let datestr = "";

    if(typeof props.getUserData.trial !== "undefined") {
        datestr = formatDateGerman(getDateByString(props.getUserData.trial.end_of_trial));
    }
    let trial_banner = "";
    if(typeof props.getUserData.trial !== "undefined" && props.getUserData.trial.start_discount_hours > 0){
        trial_banner = (<DefaultPaperAuto className={basestyle.bluepaper} elevation={12}>
            <Typography variant={"h4"} className={basestyle.whiteColor}>Sie erhalten 45% Rabatt, wenn Sie innerhalb von <b>{props.getUserData.trial.start_discount_hours} Std.</b> das Basis-Paket buchen!</Typography>
            <p>Ihre Testphase läuft weiter bis zum {datestr}. Anschließend erhalten Sie für 3 Monate einen Preisnachlass von 45% (8 €)!</p>
            <Button component={RouterLink} to={"/abonnements"} variant={"contained"} color={"secondary"} style={{marginTop: 13}}>Zu den Paketen</Button>
        </DefaultPaperAuto>);
    }else if(typeof props.getUserData.trial !== "undefined"){
        trial_banner = (<DefaultPaperAuto className={basestyle.bluepaper} elevation={12}>
                            <Typography variant={"h4"} className={basestyle.whiteColor}>Kostenlose Testphase: {props.getUserData.trial.remaining_days} Tage übrig.</Typography>
                            <p>Sie können Ihre Testphase auch <u>nach der Buchung</u> bis einschließlich {datestr} nutzen!</p>
                            <Button component={RouterLink} to={"/abonnements"} variant={"contained"} color={"secondary"} style={{marginTop: 13}}>Zu den Paketen</Button>
                        </DefaultPaperAuto>);
    }

    if(window.location.href.indexOf("abonnements") > -1){
        trial_banner = "";
    }
    if(props.getUserData.BenutzerStatus === "1"){
        trial_banner = "";
    }

    return (
        <div>
            {trial_banner !== "" && (
                <div>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            {trial_banner}
                        </Grid>
                    </Grid>
                    <div className={basestyle.spacer} />
                    <div className={basestyle.spacer} />
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        getUserData: state.account.user
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(PreBodyComponents))