import React from 'react';
import BaseStyle, {DefaultPaper} from "../Layout/BaseStyle";
import { connect } from 'react-redux';
import useMountEffect from "../Helper/MountEffect";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import {
    doFetchProductCategories,
    reloadProductsPage,
    resetFilterProductAndRefresh,
    setFilterProductAndRefresh
} from "../Actions/ProductActions";
import {Link as RouterLink} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {doFetchLieferanten} from "../Actions/LagerActions";
import {useQuery} from "../Helper/getPickerDataQuery";


function FilterProduct(props){
    const basestyle = BaseStyle();
    const query = useQuery();
    const [isStock,setStock] = React.useState(false);
    //run once
    useMountEffect(() => {
        if(props.variant && props.variant === "lager"){
            setStock(true);
        }else{
            setStock(false);
        }
        props.reloadProductCategories();
        props.doFetchLieferanten();
        if(query.get("lieferant")){
            props.setFilterProduct("lieferant",query.get("lieferant"),isStock);
        }
    });
    const resetFilter = () => {
        props.resetFilterProductAndRefresh(isStock);
    };
    const handleFilterAction = (prop,val) => {


        props.setFilterProduct(prop,val,isStock);
    };


    return (

        <Grid container spacing={3} alignItems={"stretch"}>
            <Grid item xs={12} md={"auto"}>
                <DefaultPaper>
                    <Typography variant={"h6"}>
                        Filter
                    </Typography>
                    <div className={basestyle.spacer} />
                    <Grid container spacing={3}>
                        <Grid item>
                            <FormControl>
                                <InputLabel shrink htmlFor="marketplace-label">
                                    Marktplatz:
                                </InputLabel>
                                <Select
                                    value={props.getFilter.marketplace}
                                    onChange={(e) => {handleFilterAction('marketplace',e.target.value)}}
                                    inputProps={{
                                        id: 'marketplace-label',
                                    }}
                                >
                                    <MenuItem value={"all"}>Alle Marktplätze</MenuItem>
                                    <MenuItem value={"de"}>Deutschland</MenuItem>
                                    <MenuItem value={"uk"}>Vereinigtes Königreich</MenuItem>
                                    {/*<MenuItem value={"es"}>Spanien</MenuItem>
                                    <MenuItem value={"it"}>Italien</MenuItem>
                                    <MenuItem value={"fr"}>Frankreich</MenuItem>*/}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl>
                                <InputLabel shrink htmlFor="status-label">
                                    Status:
                                </InputLabel>
                                <Select
                                    value={props.getFilter.status}
                                    onChange={(e) => {handleFilterAction('status',e.target.value)}}
                                    inputProps={{
                                        id: 'status-label',
                                    }}
                                >
                                    <MenuItem value={"all"}>Alle</MenuItem>
                                    <MenuItem value={0}>Aktiv</MenuItem>
                                    <MenuItem value={"outofstock"}>Out-Of-Stock</MenuItem>
                                    <MenuItem value={"critical"}>Kritisch</MenuItem>
                                    <MenuItem value={"notify"}>Hinweis</MenuItem>
                                    <MenuItem value={5}>Inaktiv</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl>
                                <InputLabel shrink htmlFor="fullfilment-label">
                                    Versandart:
                                </InputLabel>
                                <Select
                                    value={props.getFilter.fullfilment}
                                    onChange={(e) => {handleFilterAction('fullfilment',e.target.value)}}
                                    inputProps={{
                                        id: 'fullfilment-label',
                                    }}
                                >
                                    <MenuItem value={"all"}>Alle</MenuItem>
                                    <MenuItem value={1}>FBA</MenuItem>
                                    <MenuItem value={0}>Eigenversand</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl>
                                <InputLabel shrink htmlFor="categories-label">
                                    Kategorie:
                                </InputLabel>
                                <Select
                                    value={props.getFilter.category}
                                    onChange={(e) => {handleFilterAction('category',e.target.value)}}
                                    inputProps={{
                                        id: 'categories-label',
                                    }}
                                >
                                    <MenuItem key={"all"} value={"all"}>Alle</MenuItem>
                                    {props.getCategories.map((cat) => (<MenuItem key={cat.id} value={cat.id}>{cat.name}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {isStock && (
                            <Grid item>
                                <FormControl>
                                    <InputLabel shrink htmlFor="lieferant-label">
                                        Lieferant:
                                    </InputLabel>
                                    <Select
                                        value={props.getFilter.lieferant}
                                        onChange={(e) => {handleFilterAction('lieferant',e.target.value)}}
                                        inputProps={{
                                            id: 'lieferant-label',
                                        }}
                                    >
                                        <MenuItem value={"all"}>Alle</MenuItem>

                                        {props.getLieferanten.map((item) =>
                                            <MenuItem key={item["LieferantID"]} value={item.LieferantID}>
                                                {item.Name}
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                        {isStock && (
                            <Grid item>
                                <FormControl>
                                    <InputLabel shrink htmlFor="producttype-label">
                                        Art:
                                    </InputLabel>
                                    <Select
                                        value={props.getFilter.producttype}
                                        onChange={(e) => {handleFilterAction('producttype',e.target.value)}}
                                        inputProps={{
                                            id: 'producttype-label',
                                        }}
                                    >
                                        <MenuItem value={1}>Amazon Produkte</MenuItem>
                                        <MenuItem value={2}>Verpackungsmaterial</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Button onClick={() => resetFilter()} size={"small"} variant={"contained"} color={"default"} style={{marginTop: 13}}>Filter zurücksetzen</Button>
                        </Grid>
                    </Grid>
                </DefaultPaper>
            </Grid>
            {isStock && (
                <Grid item xs={12} md={"auto"}>
                    <DefaultPaper>
                        <Typography variant={"h6"} align={"center"}>
                            Out of Stock
                        </Typography>
                        <Typography variant={"h2"} align={"center"} color={"error"}>
                            {props.stock_count_out}
                        </Typography>
                        <div className={basestyle.spacer} />
                        <div style={{textAlign:"center"}}>
                            <Button onClick={() => handleFilterAction('status',"outofstock")} variant={"contained"} color={"secondary"} style={{marginTop: 13}}>Anzeigen</Button>
                        </div>
                    </DefaultPaper>
                </Grid>
            )}
            {isStock && (
            <Grid item xs={12} md={"auto"}>
                <DefaultPaper>
                    <Typography variant={"h6"} align={"center"}>
                        Kritisch
                    </Typography>
                    <Typography variant={"h2"} align={"center"} color={"error"}>
                        {props.stock_count_critical}
                    </Typography>
                    <div className={basestyle.spacer} />
                    <div style={{textAlign:"center"}}>
                        <Button onClick={() => handleFilterAction('status',"critical")} variant={"contained"} color={"secondary"} style={{marginTop: 13}}>Anzeigen</Button>
                    </div>
                </DefaultPaper>
            </Grid>
            )}
            {isStock && (
            <Grid item xs={12} md={"auto"}>
                <DefaultPaper>
                    <Typography variant={"h6"} align={"center"}>
                        Hinweis
                    </Typography>
                    <Typography variant={"h2"} align={"center"}>
                        {props.stock_count}
                    </Typography>
                    <div className={basestyle.spacer} />
                    <div style={{textAlign:"center"}}>
                        <Button onClick={() => handleFilterAction('status',"notify")} variant={"contained"} color={"secondary"} style={{marginTop: 13}}>Anzeigen</Button>
                    </div>
                </DefaultPaper>
            </Grid>
            )}
        </Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        getFilter: state.products.filter,
        getCategories: state.products.categories,
        stock_count: state.products.items_stock_c,
        stock_count_critical: state.products.items_stock_c_critical,
        stock_count_out: state.products.items_stock_out,
        getLieferanten: state.lager.lieferanten
    };
};
const mapDispatchToProps = dispatch => ({
    setFilterProduct: (type,val,is_stock_page) => dispatch(setFilterProductAndRefresh(type,val,is_stock_page)),
    resetFilterProductAndRefresh: (is_stock_page) => dispatch(resetFilterProductAndRefresh(is_stock_page)),
    reloadProductsPage: () => dispatch(reloadProductsPage()),
    doFetchLieferanten: (status) => dispatch(doFetchLieferanten(status)),
    reloadProductCategories: () => dispatch(doFetchProductCategories())
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FilterProduct)