import React, {useEffect} from 'react';
import MaterialTable from "material-table";

import BaseStyle, {DefaultPaper} from "../Layout/BaseStyle";
import { connect } from 'react-redux';
import {
    toggleBackdrop
} from "../Actions/Actions";
import useMountEffect from "../Helper/MountEffect";
import {withRouter} from 'react-router-dom';
import tableIcons from "../Layout/TableIcons";
import Grid from "@material-ui/core/Grid";
import SaveIcon from '@material-ui/icons/Save';
import {
    doCreateBestellung,
    doFetchBestellungen,
    doFetchLieferanten,
    setLagerBestellungFilterProduct, setLagerBestellungFilterStatus
} from "../Actions/LagerActions";
import Chip from "@material-ui/core/Chip";
import AddIcon from "@material-ui/icons/Add";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import {makeStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import LagerBestellungDialog from "../Helper/LagerBestellungDialog";
import Alert from "@material-ui/lab/Alert";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import formatDate from "../Helper/Date";


const localStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    chip: {
        margin: 2,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


function TableLagerBestellungen(props){
    const basestyle = BaseStyle();
    const classes = localStyles();
    const [popup,setPopup] = React.useState(null);

    const dofetch = () => {
        props.toggleBackdrop(true);
        props.doFetchLieferanten();
        props.doFetchBestellungen().then(() => {
            props.toggleBackdrop(false);
        });
    };


    const handleStatusFilter = (e) => {
        props.setFilterStatus(e.target.value);
    };

    const handleNewClick = () => {
        handleEditOpen(0,props.productId);
    };
    const getDetail = (id) => {
        let data = props.getBestellungen.filter((s) => s.BestellID === id);
        return (data.length === 1)?data[0]:null;
    };
    const handleEditOpen = (id,productid) => {

        setPopup(<LagerBestellungDialog
            data={getDetail(id)}
            productid={productid}
            abort={() => setPopup(null)}
            defaultLieferant={props.settings ? props.settings.LieferantID : null}
            save={(data) => {
                props.toggleBackdrop(true);
                data.Arrival = formatDate(data.Arrival);
                props.createBestellung(data).then(() => {
                    setPopup(null);
                    dofetch();
                });
            }}
        />);
    };

    useEffect(() => {
        if(props.filterStatus !== null) {
            dofetch();
        }
    },[props.filterStatus]);

    //run once
    useMountEffect(() => {
        props.setFilterProductId(null);
        if(typeof props.productId !== "undefined"){
            props.setFilterProductId(props.productId);
        }
        //dofetch();
        props.setFilterStatus(props.statuslist.filter((s) => s[0]!==1002).map((s) => s[0]));
    });
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                {typeof props.productId !== "undefined" && (
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<AddIcon />}
                        onClick={handleNewClick}
                    >
                        Lagerbestellung
                    </Button>
                )}
                {typeof props.productId === "undefined" && (
                    <Alert severity={"info"}>Bestellungen müssen auf der Produktseite unten im Reiter "Lager & Versand" erstellt werden. </Alert>
                )}
                {popup}
            </Grid>

            {typeof props.productId !== "undefined" && (
                <Grid item xs={12} md={6}>
                    <DefaultPaper>
                        <Typography variant={"h6"}>Einstellungen: Produkt und Lager</Typography>
                        <div className={basestyle.spacer} />
                        {props.getLieferanten.length > 0 ? (
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth>
                                        <InputLabel id="product-lieferant-label">Standard-Lieferant</InputLabel>
                                        <Select
                                            labelId="product-lieferant-label"
                                            value={props.settings.LieferantID}
                                            onChange={(e) => props.onSettingChange({...props.settings,LieferantID: e.target.value})}
                                        >
                                            <MenuItem key={"nothing"} value={null}>
                                                Kein Lieferant
                                            </MenuItem>
                                            {props.getLieferanten.map((item) => (
                                                <MenuItem key={item.LieferantID} value={item.LieferantID}>
                                                    {item.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth>
                                        <InputLabel>Extra Bearbeitungszeit</InputLabel>
                                        <Input
                                            value={props.settings.Bearbeitungszeit}
                                            onChange={(e) => props.onSettingChange({...props.settings,Bearbeitungszeit: e.target.value})}
                                            type="number"
                                            inputProps={{style:{textAlign:"right"}}}
                                            endAdornment={<InputAdornment position="end">Tage</InputAdornment>}
                                        />
                                        <FormHelperText>Falls Sie weitere Zeit benötigen</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button onClick={props.onSave} variant="contained" color="primary" component="span" style={{top: 12}}>
                                        <SaveIcon />
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : (<Alert severity={"info"}>Bitte legen Sie zuerst einen Lieferanten an!</Alert>)}
                    </DefaultPaper>
                </Grid>
            )}
            <Grid item xs={12} md={6}>
                <DefaultPaper>
                    <Typography variant={"h6"}>Filter</Typography>
                    <div className={basestyle.spacer} />
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <FormControl fullWidth>
                                <InputLabel id="stock-order-status-filter-label">Status ({props.filterStatus && props.filterStatus.length})</InputLabel>
                                <Select
                                    labelId="stock-order-status-filter-label"
                                    multiple
                                    value={(props.filterStatus)?props.filterStatus:[]}
                                    onChange={handleStatusFilter}
                                    input={<Input id="stock-order-status-filter" />}
                                    renderValue={(selected) => (
                                        <div className={classes.chips}>
                                            {selected.map((value) => (
                                                <Chip size={"small"} key={value} label={props.statuslist.filter(s => s[0]===value)[0][1]} className={classes.chip} />
                                            ))}
                                        </div>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {props.statuslist.map((item) => (
                                        <MenuItem key={item[0]} value={item[0]}>
                                            {item[1]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DefaultPaper>
            </Grid>
            <Grid item xs={12}>
            {popup}
            <MaterialTable title="Bestellungen" icons={tableIcons} columns={[
                {
                    title: "Status",
                    field: "Status",
                    render: rowData => {
                        var clr = "secondary";
                        if(rowData.Status === "1001") clr = "primary";
                        if(rowData.Status !== "") return <Chip size="small" color={clr} label={rowData.StatusText} />;
                        return <Chip size="small" label={"Unbekannt"} />;
                    }
                },
                {
                    title: "Referenz-Nr",
                    field: "ReferenzNr"
                },
                {
                    title: "Lieferant",
                    field: "Name"
                },
                ((typeof props.productId === "undefined") ? {
                    title: "Produkt",
                    field: "ProductASIN",
                    cellStyle: { minWidth:240 },
                    render: rowData => {
                        return (
                            <div>
                                <b>{rowData.ProductTitle}</b><br />
                                <small>ASIN: {rowData.ProductASIN} | SKU: {rowData.ProductSKU} | EAN: {rowData.ProductEAN}</small>
                            </div>
                        );
                    }
                } : {}),
                {
                    title: "Menge",
                    field: "Menge"
                },
                {
                    title: "Lieferdatum",
                    field: "Arrival",
                    type: "date"
                },
                {
                    title: "Erstellt",
                    field: "Created",
                    type: "date",
                    defaultSort: "desc"
                }
            ]}
               data={props.getBestellungen}
               actions={[
               ]}
               onRowClick={(event,rowData) => {
                   handleEditOpen(rowData.BestellID,rowData.ProductID);
               }}
               options={{
                   pageSize: (typeof props.productId === "undefined") ? 50 : 5,
                   pageSizeOptions: [5,10,25,50,100,200],
                   actionsColumnIndex: 0,
                   cellStyle: {
                       fontSize: 12
                   }
           }}/>
        </Grid>
    </Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        getBestellungen: state.lager.bestellungen,
        getUserData: state.account.user,
        statuslist: state.account.user.StockOrderStatusList,
        filterStatus: state.lager.filterStatus,
        getLieferanten: state.lager.lieferanten
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    doFetchBestellungen: () => dispatch(doFetchBestellungen()),
    doFetchLieferanten: (status) => dispatch(doFetchLieferanten(status)),
    createBestellung: (data) => dispatch(doCreateBestellung(data)),
    setFilterStatus: (filter_array) => dispatch(setLagerBestellungFilterStatus(filter_array)),
    setFilterProductId: (filter_id) => dispatch(setLagerBestellungFilterProduct(filter_id))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TableLagerBestellungen))