import React from 'react';
import BaseStyle, {DefaultPaper} from "../Layout/BaseStyle";
import { connect } from 'react-redux';
import {
    addSuccessItem
} from "../Actions/Actions";
import useMountEffect from "../Helper/MountEffect";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import ExportSpion from "./ExportSpion";
import {doDownloadSpionGesamtExport, reloadSpionPage, setFilterSpion} from "../Actions/SpionActions";


function FilterSpion(props){
    const basestyle = BaseStyle();
    //const [filterMarketplace,setFilterMarketplace] = React.useState("all");
    //const [filterStatus,setFilterStatus] = React.useState("all");

    const handleFilterMarketplace = (event) => {
        //setFilterMarketplace(event.target.value);
        props.setFilterSpion("marketplace",event.target.value)
        props.reloadSpionPage();
    }
    const handleFilterStatus = (event) => {
        console.warn(event.target.value);
        //setFilterStatus(event.target.value);
        props.setFilterSpion("status",event.target.value)
        props.reloadSpionPage();

    }

    //run once
    useMountEffect(() => {
    });

    return (

        <Grid container spacing={3} alignItems={"stretch"}>
            <Grid item md={8} xs={12}>
                <DefaultPaper>
                    <Typography variant={"h6"}>
                        Filter
                    </Typography>
                    <div className={basestyle.spacer} />
                    <Grid container spacing={3}>
                        <Grid item>
                            <FormControl>
                                <InputLabel shrink htmlFor="marketplace-label">
                                    Marktplatz:
                                </InputLabel>
                                <Select
                                    value={props.filter.marketplace}
                                    onChange={handleFilterMarketplace}
                                    inputProps={{
                                        id: 'marketplace-label',
                                    }}
                                >
                                    <MenuItem value={"all"}>Alle Marktplätze</MenuItem>
                                    <MenuItem value={"de"}>Deutschland</MenuItem>
                                    <MenuItem value={"us"}>Vereinigte Staaten</MenuItem>
                                    <MenuItem value={"uk"}>Vereinigtes Königreich</MenuItem>
                                    <MenuItem value={"es"}>Spanien</MenuItem>
                                    <MenuItem value={"it"}>Italien</MenuItem>
                                    <MenuItem value={"fr"}>Frankreich</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl>
                                <InputLabel shrink htmlFor="status-label">
                                    Status:
                                </InputLabel>
                                <Select
                                    value={props.filter.status}
                                    onChange={handleFilterStatus}
                                    inputProps={{
                                        id: 'status-label',
                                    }}
                                >
                                    <MenuItem value={"all"}>Aktiv & Beendet</MenuItem>
                                    <MenuItem value={"active"}>Aktiv</MenuItem>
                                    <MenuItem value={"canceled"}>Beendet</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DefaultPaper>
            </Grid>

            <Grid item md={4} xs={12}>
                <ExportSpion />
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        getCount: state.spions.items.length,
        getSpions: state.spions.items,
        graphlines: state.spions.graphlines,
        filter: state.spions.filter
    };
};
const mapDispatchToProps = dispatch => ({
    setFilterSpion: (type,val) => dispatch(setFilterSpion(type,val)),
    reloadSpionPage: () => dispatch(reloadSpionPage()),
    exportDownload: (from,to) => dispatch(doDownloadSpionGesamtExport(from,to)),
    addSuccess: (msg,code) => dispatch(addSuccessItem(msg,code))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FilterSpion)