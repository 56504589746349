import React from "react";
import CurrencyFormat from 'react-currency-format';

export const NumberFormat = (prop) => {
  if(prop.value !== null) {
    return (<CurrencyFormat value={prop.value} displayType={'text'} thousandSeparator={"."} suffix={''}
                            decimalSeparator={","}/>);
  }else{
    return null;
  }

};