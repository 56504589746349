import React from 'react';
import MaterialTable from "material-table";

import BaseStyle from "../Layout/BaseStyle";
import { connect } from 'react-redux';
import {
    toggleBackdrop
} from "../Actions/Actions";
import useMountEffect from "../Helper/MountEffect";
import {withRouter} from 'react-router-dom'
import tableIcons from "../Layout/TableIcons";
import Grid from "@material-ui/core/Grid";
import {doFetchProductsStock} from "../Actions/ProductActions";


function TableProductsStock(props){
    const basestyle = BaseStyle();

    const dofetch = () => {
        props.reloadProductsPage();
    };
    const handleDetailOpen = (id) => {
        //setForward(<Redirect to={'/spion/'+id} />);
        props.history.push('/products/'+id);
    };
    //run once
    useMountEffect(() => {
        dofetch();
    });

    return (
        <Grid container>
            <Grid item xs={12}>
            <MaterialTable title="Produkt Lagerbestand" icons={tableIcons} columns={[
                { title: "ASIN", field: "asin" },
                { title: "SKU", field: "sku" },
                { title: "Produkt", field: "title" },
                {
                    title: "Im Lager",
                    field: "stock",
                    type: "numeric"
                },
                {
                    title: "Verkäufe (7T)",
                    field: "sales",
                    type: "numeric"
                },
                {
                    title: "Reichweite bis",
                    field: "zero_date",
                    defaultSort: "asc",
                    type: "date"
                }
            ]}
               data={props.getProducts}
               actions={[
               ]}
               onRowClick={(event,rowData) => {
                   handleDetailOpen(rowData.id);
               }}
               options={{
                   pageSize: 15,
                   pageSizeOptions: [15,25,50,100,200],
                   /*selection: true, not working with actions*/
                   actionsColumnIndex: 0,
                   cellStyle: {
                       fontSize: 12
                   }
           }}/>
        </Grid>
    </Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        getProducts: state.products.items_stock,
        getUserData: state.account.user
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    reloadProductsPage: () => dispatch(doFetchProductsStock())
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TableProductsStock))