import React from 'react';
import Container from "@material-ui/core/Container";
import BreadCrumb from "../HtmlComponents/BreadCrumb";
import {Helmet} from "react-helmet";
import makeTitle from "../Helper/makeTitle";
import Grid from "@material-ui/core/Grid";
import DateRangePicker from "../HtmlComponents/DateRangePicker";
import MaterialTable from "material-table";
import tableIcons from "../Layout/TableIcons";
import {toggleBackdrop} from "../Actions/Actions";
import {
    doFetchGewinnDetailsOrder, doFetchGewinnDetailsPosten,
    doResetGewinnDetailsData
} from "../Actions/SalesActions";
import {connect} from "react-redux";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import useMountEffect from "../Helper/MountEffect";
import BaseStyle from "../Layout/BaseStyle";
import {Link as RouterLink} from "react-router-dom";
import Button from "@material-ui/core/Button";

function SalesGewinnDetailsPage(props){
    const basestyle = BaseStyle();
    const [PostenView,setPostenView] = React.useState(false);
    const reloadView = () => {
        props.resetGewinnDetailData();
        if(PostenView === true){
            reloadViewPosten();
        }else{
            reloadViewOrders();
        }
    };
    const reloadViewPosten = () => {
        props.loadGewinnPosten();
    };
    const reloadViewOrders = () => {
        props.loadGewinnOrder();
    };
    const PostenViewChange = (e) => {
        setPostenView(e.target.checked);
        if(e.target.checked && props.gewinn_posten == null){
            reloadViewPosten();
        }else if(!e.target.checked && props.gewinn_orders == null){
            reloadViewOrders();
        }
    };

    useMountEffect(() => {
        //reloadView();
    });

    let tabledata = [];
    let tablecols = [];

    if(PostenView){
        tabledata = props.gewinn_posten;
        tablecols = [
            {
                title: "Datum",
                field: "date",
                type: "date"
            },
            {
                title: "Bestellnr.",
                field: "orderid"
            },
            {
                title: "ASIN",
                field: "asin"
            },
            {
                title: "Produkt",
                field: "title"
            },
            {
                title: "Anzahl",
                field: "count"
            },
            {
                title: "Preis",
                field: "price",
                type: "currency",
                currencySetting: { locale: 'de', currencyCode: "EUR" }
            },
            {
                title: "Versand-Anteil",
                field: "price_ship",
                type: "currency",
                currencySetting: { locale: 'de', currencyCode: "EUR" }
            },
            {
                title: "Gebühren",
                field: "fee",
                type: "currency",
                currencySetting: { locale: 'de', currencyCode: "EUR" }
            },
            {
                title: "Einkaufspreis",
                field: "ek",
                type: "currency",
                currencySetting: { locale: 'de', currencyCode: "EUR" }
            },
            {
                title: "Versandkosten (real)",
                field: "ek_ship",
                type: "currency",
                currencySetting: { locale: 'de', currencyCode: "EUR" }
            },
            {
                title: "Gewinn",
                field: "gewinn",
                type: "currency",
                currencySetting: { locale: 'de', currencyCode: "EUR" }
            }
        ];
    }else{
        tabledata = props.gewinn_orders;
        tablecols = [
            {
                title: "Datum",
                field: "date",
                type: "date"
            },
            {
                title: "Bestellnr.",
                field: "orderid"
            },
            {
                title: "Posten",
                field: "count"
            },
            {
                title: "Gesamtpreis",
                field: "price",
                type: "currency",
                currencySetting: { locale: 'de', currencyCode: "EUR" }
            },
            {
                title: "Gebühren",
                field: "fee",
                type: "currency",
                currencySetting: { locale: 'de', currencyCode: "EUR" }
            },
            {
                title: "Einkaufspreis",
                field: "ek",
                type: "currency",
                currencySetting: { locale: 'de', currencyCode: "EUR" }
            },
            {
                title: "Versandkosten (real)",
                field: "ek_ship",
                type: "currency",
                currencySetting: { locale: 'de', currencyCode: "EUR" }
            },
            {
                title: "Gewinn",
                field: "gewinn",
                type: "currency",
                currencySetting: { locale: 'de', currencyCode: "EUR" }
            }
        ];
    }

    return (
        <Container maxWidth="xl">
            <Helmet>
                <title>{makeTitle('Gewinn-Details')}</title>
            </Helmet>
            <BreadCrumb links={[
                {label: 'Verkäufe', url: '/sales'},
                {label: 'Gewinn'}
            ]} help={"sales"} />
            <Grid container spacing={3} justify="flex-end">
                <Grid item xs={12} md={"auto"}>
                    <Button component={RouterLink} to={"/sales/gewinn/edit"} variant={"contained"} color={"primary"} style={{marginTop: 13}}>Gewinn konfigurieren</Button>
                </Grid>
                <div className={basestyle.grow} />
                <Grid item xs={12} md={"auto"}>
                    <FormGroup style={{marginTop: 13}}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={PostenView}
                                    onChange={PostenViewChange}
                                    value="1"
                                    color="primary"
                                />
                            }
                            label="Ansicht je Bestellposten"
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={"auto"}>
                    <DateRangePicker hide_grouping onChange={reloadView}/>
                </Grid>
            </Grid>
            <div className={basestyle.spacer} />
            {props.gewinn_orders !== null && (
                <MaterialTable title={"Gewinn-Details"} data={tabledata} icons={tableIcons}
                   columns={tablecols} />
            )}
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        gewinn_orders: state.sales.gewinn_detail_items,
        gewinn_posten: state.sales.gewinn_detail_items_posten
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    loadGewinnOrder: () => dispatch(doFetchGewinnDetailsOrder()),
    loadGewinnPosten: () => dispatch(doFetchGewinnDetailsPosten()),
    resetGewinnDetailData: () => dispatch(doResetGewinnDetailsData()),
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SalesGewinnDetailsPage);