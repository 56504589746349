import React from 'react';
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core";
import StarIcon from '@material-ui/icons/StarBorder';
import {EuroFormat} from "../Helper/CurrencyFormat";
import BaseStyle from "../Layout/BaseStyle";
import {doFetchTiers} from "../Actions/Actions";
import {connect} from "react-redux";
import useMountEffect from "../Helper/MountEffect";


const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
    cardHeader: {
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
}));

function AbonnementPricing(props) {
    const classes = useStyles();
    const basestyle = BaseStyle();

    const handleActionClick = (tier) => {
        if(typeof props.onAction === "function") {
            props.onAction(tier);
        }
    };

    useMountEffect(() => {
       props.loadTiers();
    });

    return (
            <Grid container spacing={5} alignItems="flex-end">
                {props.tiers.map((tier,index) => {
                    let subgrid = null;
                    let maingrid = <Grid item key={tier.title} xs={12} sm={6} md={3}>
                                        <Card>
                                            <CardHeader
                                                title={tier.title}
                                                subheader={tier.subheader}
                                                titleTypographyProps={{align: 'center'}}
                                                subheaderTypographyProps={{align: 'center'}}
                                                action={tier.title === 'Expert' ? <StarIcon/> : null}
                                                className={classes.cardHeader}
                                            />
                                            <CardContent>
                                                <div className={classes.cardPricing}>
                                                    {(tier.price!==null) && (
                                                        <div>
                                                                {props.payCycle==1 && (
                                                                    <Typography component="h4" variant="h4" color="textPrimary">
                                                                        {Math.floor(tier.price[0])===tier.price[0] && (<EuroFormat value={tier.price[0]} decimal={0} />)}
                                                                        {Math.floor(tier.price[0])!==tier.price[0] && (<EuroFormat value={tier.price[0]} decimal={2} />)}
                                                                        &nbsp;*
                                                                        <Typography variant="caption" color="textSecondary">
                                                                            /mntl.
                                                                        </Typography>
                                                                    </Typography>
                                                                )}
                                                                {(props.payCycle==1 && index==0 && props.is_start_discount) && (
                                                                    <Typography variant="h6" color="error">
                                                                        3 Monate lang nur:
                                                                        {Math.floor(tier.price[0])===tier.price[0] && (<EuroFormat value={tier.price[0]-8} decimal={0} />)}
                                                                        {Math.floor(tier.price[0])!==tier.price[0] && (<EuroFormat value={tier.price[0]-8} decimal={2} />)}
                                                                    </Typography>
                                                                )}
                                                                {props.payCycle==6 && (
                                                                    <div>
                                                                        <Typography component="h4" variant="h4" color="textPrimary">
                                                                            {Math.floor(tier.price[1])===tier.price[1] && (<EuroFormat value={tier.price[1]} decimal={0} />)}
                                                                            {Math.floor(tier.price[1])!==tier.price[1] && (<EuroFormat value={tier.price[1]} decimal={2} />)}
                                                                            &nbsp;*
                                                                            <Typography variant="caption" color="textSecondary">
                                                                                / 6 mon.
                                                                            </Typography>
                                                                        </Typography>
                                                                        {tier.has_discount && (
                                                                            <Typography variant="h6" color="error">
                                                                                <s><EuroFormat value={tier.price[0]*6} /></s> 10% Rabatt
                                                                            </Typography>
                                                                        )}
                                                                    </div>
                                                                )}
                                                                {props.payCycle==12 && (
                                                                    <div>
                                                                        <Typography component="h4" variant="h4" color="textPrimary">
                                                                            {Math.floor(tier.price[2])===tier.price[2] && (<EuroFormat value={tier.price[2]} decimal={0} />)}
                                                                            {Math.floor(tier.price[2])!==tier.price[2] && (<EuroFormat value={tier.price[2]} decimal={2} />)}
                                                                            &nbsp;*
                                                                            <Typography variant="caption" color="textSecondary">
                                                                                / jährlich
                                                                            </Typography>
                                                                        </Typography>
                                                                        {tier.has_discount && (
                                                                            <Typography variant="h6" color="error">
                                                                                <s><EuroFormat value={tier.price[0]*12} /></s> 20% Rabatt
                                                                            </Typography>
                                                                        )}
                                                                    </div>
                                                                )}
                                                        </div>
                                                    )}
                                                </div>
                                                <ul>
                                                    {tier.description.map((line) => (
                                                        <Typography component="li" variant="subtitle1" align="center" key={line}>
                                                            {line}
                                                        </Typography>
                                                    ))}
                                                </ul>
                                            </CardContent>
                                            <CardActions>
                                                <Button fullWidth variant={tier.buttonVariant} color="primary" onClick={() => {
                                                    handleActionClick(tier)
                                                }}>
                                                    {tier.buttonText}
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>;
                    if(index === 3){
                        subgrid = <Grid xs={12} style={{textAlign: "center"}}>
                                    <div className={basestyle.spacer} /><div className={basestyle.spacer} /><div className={basestyle.spacer} />
                                    <Typography variant={"h4"}>Weitere Pakete</Typography>
                                </Grid>;
                    }

                    return ([maingrid,subgrid]);
                })}
            </Grid>
    );
}
const mapStateToProps = (state) => {
    return {
        tiers: state.account.tiers,
    };
};
const mapDispatchToProps = dispatch => ({
    loadTiers: () => dispatch(doFetchTiers())
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AbonnementPricing)