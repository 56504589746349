import {
    addSuccessItem, addWarningItem,
    API_URL, catchErrors,
    handleErrors,
    toggleBackdrop
} from "./Actions";
import {getPickerDataQuery} from "../Helper/getPickerDataQuery";

export const RECEIVE_KEYWORDS = 'RECEIVE_KEYWORDS';
export const RECEIVE_RANKING_POINTS = 'RECEIVE_RANKING_POINTS';
export const RECEIVE_RANKING_DETAILS = 'RECEIVE_RANKING_DETAILS';
export const RECEIVE_RANKING_PRODUCTS = 'RECEIVE_RANKING_PRODUCTS';
export const RECEIVE_RANKING_ALARMS = 'RECEIVE_RANKING_ALARMS';
export const RECEIVE_RANKING_TREND_CHART = 'RECEIVE_RANKING_TREND_CHART';
export const RECEIVE_RANKING_TREND_DATA = 'RECEIVE_RANKING_TREND_DATA';

export function receiveKeywords(json){
    return {
        type: RECEIVE_KEYWORDS,
        items: json.result
    }
}
export function receiveRankingPoints(json){
    return {
        type: RECEIVE_RANKING_POINTS,
        points: json.result
    }
}
export function receiveRankingDetails(json){
    return {
        type: RECEIVE_RANKING_DETAILS,
        data: json.result
    }
}
export function receiveRankingProducts(json){
    return {
        type: RECEIVE_RANKING_PRODUCTS,
        data: json.result
    }
}
export function receiveRankingTrendChart(json){
    return {
        type: RECEIVE_RANKING_TREND_CHART,
        data: json.result
    }
}
export function receiveRankingTrend(json){
    return {
        type: RECEIVE_RANKING_TREND_DATA,
        data: json.result
    }
}


export function receiveRankingAlarms(json){
    return {
        type: RECEIVE_RANKING_ALARMS,
        data: json.result
    }
}


/***FETCH API***/


export function reloadRankingPage(){
    return async (dispatch, getState) => {
        dispatch(toggleBackdrop(true));
        dispatch(doFetchKeywords())
            //.then(() => dispatch(doFetchSpionGraph()))
            .then(() => dispatch(toggleBackdrop(false)));
    }
}
export function doFetchKeywords(){
    return async (dispatch, getState) => {
        await fetch(API_URL +'/Ranking.php?action=Keywords', {
            credentials: 'include'
        }).then(handleErrors).then(
            response => {
                dispatch(receiveKeywords(response));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}
export function doDeleteKeywords(arr){
    return (dispatch, getState) => {

        return fetch(API_URL +'/Ranking.php?action=deleteKeyword', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                "id_array": JSON.stringify(arr)
            })
        })
            .then(handleErrors)
            .then( response => dispatch(addWarningItem(arr.length+" Keywords wurden gelöscht!")),dispatch(doFetchKeywords()))
            .catch(s => dispatch(catchErrors(s)));
    };
}
export function doFetchRankingPoints(){
    return (dispatch, getState) => {
        fetch(API_URL +'/Ranking.php?action=Points', {
            credentials: 'include'
        }).then(handleErrors).then(
            response => dispatch(receiveRankingPoints(response))
        ).catch(s => dispatch(catchErrors(s)));
    };
}
export function doToggleKeywords(arr,status){
    return (dispatch, getState) => {
        let statusstring = "deaktiviert";
        if(status === 0){
            statusstring = "aktiviert";
        }
        return fetch(API_URL +'/Ranking.php?action=editKeyword', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                "id_array": JSON.stringify(arr),
                "status": status
            })
        })
            .then(handleErrors)
            .then( response => dispatch(addWarningItem(arr.length+" Keywords wurden "+statusstring+"!")),dispatch(doFetchKeywords()))
            .catch(s => dispatch(catchErrors(s)));
    };
}
export function doAddKeyword(kw,mp){
    return (dispatch, getState) => {
        return fetch(API_URL +'/Ranking.php?action=Keyword', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                "kw": kw,
                "mp": mp
            })
        })
            .then(handleErrors)
            .then( response => dispatch(addSuccessItem("Keyword wurde angelegt!")),dispatch(doFetchKeywords()))
            .catch(s => dispatch(catchErrors(s)));
    };
}
export function doFetchKeywordDetail(id){
    return (dispatch, getState) => {
        dispatch(receiveRankingDetails({result:{}}));
        fetch(API_URL +'/Ranking.php?action=KeywordDetails&id='+id, {
            credentials: 'include'
        }).then(handleErrors).then(
            response => dispatch(receiveRankingDetails(response))
        ).catch(s => dispatch(catchErrors(s)));
    };
}
export function doFetchRankingProducts(id){
    return (dispatch, getState) => {
        fetch(API_URL +'/Ranking.php?action=KeywordProducts&id='+id, {
            credentials: 'include'
        }).then(handleErrors).then(
            response => dispatch(receiveRankingProducts(response))
        ).catch(s => dispatch(catchErrors(s)));
    };
}
export function doFetchRankingTrendChart(id){
    return (dispatch, getState) => {
        fetch(API_URL +'/Ranking.php?action=RankingTrendChart', {
            credentials: 'include'
        }).then(handleErrors).then(
            response => dispatch(receiveRankingTrendChart(response))
        ).catch(s => dispatch(catchErrors(s)));
    };
}
export function doFetchAlarms(){
    return (dispatch, getState) => {
        return fetch(API_URL +'/Ranking.php?action=Alarms', {
            credentials: 'include'
        }).then(handleErrors).then(
            response => dispatch(receiveRankingAlarms(response))
        ).catch(s => dispatch(catchErrors(s)));
    };
}
export function doDeleteAlarms(idlist){
    return (dispatch, getState) => {
        return fetch(API_URL +'/Ranking.php?action=deleteAlarm', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                "idlist": idlist
            })
        })
            .then(handleErrors)
            .then( response => dispatch(addSuccessItem("Die ausgewählten Alarm-Services wurden beendet!")))
            .catch(s => dispatch(catchErrors(s)));
    };
}
export function doAddAlarm(kw,product,position){
    return (dispatch, getState) => {
        return fetch(API_URL +'/Ranking.php?action=Alarm', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                "keyword": kw,
                "product": product,
                "position": position
            })
        })
        .then(handleErrors)
        .then( response => dispatch(addSuccessItem("Alarm-Service wurde aktiviert!")))
        .catch(s => dispatch(catchErrors(s)));
    };
}
export function doDeleteAlarm(kw,product){
    return (dispatch, getState) => {
        return fetch(API_URL +'/Ranking.php?action=deleteAlarm', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                "keyword": kw,
                "product": product
            })
        })
            .then(handleErrors)
            .then( response => dispatch(addSuccessItem("Alarm-Service wurde beendet!")))
            .catch(s => dispatch(catchErrors(s)));
    };
}
export function doLoadTrendData(){
    return (dispatch, getState) => {
        let request_date = getPickerDataQuery(getState().account.pickerdata);
        fetch(API_URL +'/Ranking.php?action=TrendData&'+request_date, {
            credentials: 'include'
        }).then(handleErrors).then(
            response => dispatch(receiveRankingTrend(response))
        ).catch(s => dispatch(catchErrors(s)));
    };
}