import formatDate from "./Date";
import {useLocation} from "react-router";

export const getPickerDataQuery = (pdata) => {
    let pdata2 = Object.assign({}, pdata);//wichtig, objekt muss kopiert werden!!!
    pdata2.preset = pdata2.preset_real;
    delete pdata2.preset_real;
    if(pdata2.from == null){
        delete pdata2.from;
    }else {
        pdata2.from = formatDate(pdata2.from);
    }
    if(pdata2.to == null){
        delete pdata2.to;
    }else {
        pdata2.to = formatDate(pdata2.to);
    }
    return Object.keys(pdata2).map(key => key + '=' + pdata2[key]).join('&');
};

export function useQuery() {
    return new URLSearchParams(useLocation().search);
};