import React from 'react';
import BaseStyle, {DefaultPaper, GRAPH_PALETTE} from "../Layout/BaseStyle";
import { connect } from 'react-redux';
import {toggleBackdrop} from "../Actions/Actions";
import useMountEffect from "../Helper/MountEffect";
import {Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {EuroFormat} from "../Helper/CurrencyFormat";
import {doFetchSpionGraph, doFetchSpions} from "../Actions/SpionActions";


const CustomTooltip = ({ active, payload, label,tmpdata }) => {
    if (active && payload) {
        return (
            <DefaultPaper>
                <Typography variant={"h5"}>
                    {label}
                </Typography>
                {payload.map((payl,index) => {
                    let umsatz = 0;
                    tmpdata.map((tmpindex) => {
                       if(tmpindex.date === payl.payload.date){
                           umsatz = tmpindex.umsatz;
                       }
                       return tmpindex;
                    });
                    return (<div key={"ctooltip_"+index}>
                        <p style={{margin:0}}><span style={{fontSize:18,display:'inline-block',minWidth:50,paddingRight:5,fontWeight: "bold",color:GRAPH_PALETTE[index]}}>{payl.value}</span>{payl.name}</p>
                        <p style={{margin:0}}><span style={{fontSize:18,display:'inline-block',minWidth:50,paddingRight:5,fontWeight: "bold",color:GRAPH_PALETTE[5]}}><EuroFormat value={umsatz} /></span>Umsatz</p>
                    </div>);
                })}
            </DefaultPaper>
        );
    }

    return null;
};

function GraphSpionDetail(props){
    const basestyle = BaseStyle();

    //run once
    useMountEffect(() => {
        props.fetchSpionGraph(props.spionid);
    });

    const data = (props.graphlines.chart.length === 1)? props.graphlines.chart[0] : [];
    const datamax = props.graphlines.max;


    return (
        <DefaultPaper>
            <Grid container justify="space-between">
                <Grid item md={6}>
                    <Typography variant={"h6"}>
                        Verkäufe und Umsatz
                    </Typography>
                </Grid>
            </Grid>
            <div className={basestyle.spacer} />
            <ResponsiveContainer width="100%" height={300}>
                <ComposedChart data={data.data}>
                    <CartesianGrid stroke="#eee" />
                    <Bar yAxisId="right"  dataKey="umsatz" barSize={20} name="Umsatz" fill={GRAPH_PALETTE[5]}/>
                    <Line yAxisId="left" connectNulls type="monotone" dataKey="value" name="Verkaufte Artikel"
                          dot={{strokeWidth: 2}} strokeWidth={3} stroke={GRAPH_PALETTE[0]}/>
                    <XAxis dataKey="date"  tickMargin={10} allowDuplicatedCategory={false} reversed={true} padding={{ left: 30, right: 30}} />
                    <YAxis yAxisId="left" dataKey="value" tickLine={false} tickMargin={10} domain={[0, datamax+10]} padding={{ top: 30 }} />
                    <Tooltip offset={50} content={<CustomTooltip tmpdata={data.data} />} />
                    <YAxis yAxisId="right" orientation="right" dataKey="umsatz" tickLine={false} tickMargin={10} padding={{ top: 30 }} />
                </ComposedChart>
            </ResponsiveContainer>
        </DefaultPaper>
    );
}
/*
 */

const mapStateToProps = (state) => {
    return {
        getCount: state.spions.items.length,
        getSpions: state.spions.items,
        graphlines: state.spions.graphlines
    };
};
const mapDispatchToProps = dispatch => ({
    fetchSpions: () => dispatch(doFetchSpions()),
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    fetchSpionGraph: (id) => dispatch(doFetchSpionGraph(id))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GraphSpionDetail)