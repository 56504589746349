import React from 'react';
import Container from "@material-ui/core/Container";
import BreadCrumb from "../HtmlComponents/BreadCrumb";
import {Helmet} from "react-helmet";
import makeTitle from "../Helper/makeTitle";
import BaseStyle, {DefaultPaper} from "../Layout/BaseStyle";
import MaterialTable from "material-table";
import {doAddChangelogWord, doDeleteChangelogWord, doFetchChangelogSettings, toggleBackdrop} from "../Actions/Actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import useMountEffect from "../Helper/MountEffect";
import tableIcons from "../Layout/TableIcons";
import ConfirmDialog from "../Helper/ConfirmDialog";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

function ChangelogSettingPage(props){
    const basestyle = BaseStyle();
    const [dialog,setDialog] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [formdata, setFormdata] = React.useState({
        wordlist: ""
    });
    useMountEffect(() => {
        props.fetchSettings();
    });
    const handleDelete = (wordid) => {
        setDialog(<ConfirmDialog title={"Schlüsselwort löschen?"} text={"Möchten Sie das Schlüsselwort wirklich löschen?"} onClose={()=>{ setDialog(null); }} onClick={() => {
            props.deleteWord(wordid);
            setDialog(null);
        }} />);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleAddClick = () =>{
        if(formdata.wordlist.length > 1){
            props.toggleBackdrop(true);
            props.addWords(formdata.wordlist).then(res => {
                props.fetchSettings();
                props.toggleBackdrop(false);
                handleClose();
            });
        }
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleTextChange = event => {
        setFormdata({ ...formdata, wordlist: event.target.value.trim() });
    };

    return (
        <Container maxWidth="xl">
            {dialog}
            <Helmet>
                <title>{makeTitle('Einstellungen für Änderungsalarm')}</title>
            </Helmet>
            <BreadCrumb links={[
                {label: 'Änderungsalarm'}
            ]} />
            <DefaultPaper>
                <h1>Einstellungen für Änderungsalarm</h1>
                <p>Hier können Sie Wörter, Begriffe bzw. Phrasen festlegen, die bei einer Textänderung eine
                    E-Mail an Sie auslösen. Sie können jederzeit bei Ihren Produkten im Bereich
                    "Produktanalyse" den gesamten Änderungsverlauf einsehen.</p>

                <p className="text-danger">Sobald Sie Begriffe hinterlegt haben, erhalten Sie nur Benachrichtigungen,
                    wenn die Wörter zutreffen. Um bei allen Änderungen benachrichtigt zu werden, müssen Sie die Liste
                    wieder leeren!</p>

            </DefaultPaper>
            <div>
                <Tooltip title="Schlüsselwort hinzufügen" aria-label="add">
                    <Fab color="primary" className={basestyle.fab} onClick={handleClickOpen}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
                <Dialog open={open} maxWidth={"md"} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Schlüsselwort hinzufügen</DialogTitle>
                    <DialogContent>
                        <FormGroup row>
                            <TextField
                                margin="dense"
                                id="wordlist"
                                label="Schlüsselwörter"
                                type="text"
                                onChange={handleTextChange}
                                fullWidth
                                multiline
                                rows="4"
                            />
                        </FormGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="default">
                            Abbrechen
                        </Button>
                        <Button onClick={handleAddClick} color="primary">
                            Speichern
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <div className={basestyle.spacer} />
            <MaterialTable title={"Schlüsselwörter"} icons={tableIcons} columns={[
                {
                    title: 'Schlüsselwort',
                    field: 'Word'
                },
                {
                    title: 'Erstellt',
                    field: 'Created',
                    type: 'date'
                }
            ]}
           actions={[
               rowData => ({
                   icon: tableIcons.Delete,
                   tooltip: 'Wort löschen',
                   onClick: (event, rowData) => {
                       handleDelete(rowData.WordID);
                   }
               })
           ]}
           data={props.changelog_settings}
            options={{
                actionsColumnIndex: 2,
                pageSize: 50,
                pageSizeOptions: [10,25,50,100,200]
            }}
            />
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        changelog_settings: state.account.changelog_settings,
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    fetchSettings: () => dispatch(doFetchChangelogSettings()),
    deleteWord: (wordid) => dispatch(doDeleteChangelogWord(wordid)),
    addWords: (wordlist) => dispatch(doAddChangelogWord(wordlist))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ChangelogSettingPage))