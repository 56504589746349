import React, {useEffect} from 'react';
import {Helmet} from "react-helmet";
import makeTitle from "../Helper/makeTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {
    addErrorItem,
    addWarningItem,
    doCreateBooking,
    doFetchBillingToken
} from "../Actions/Actions";
import {connect} from "react-redux";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AbonnementPricing from "../HtmlComponents/AbonnementPricing";
import {withRouter} from "react-router-dom";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import useMountEffect from "../Helper/MountEffect";
import DropIn from "braintree-web-drop-in-react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {EuroFormat} from "../Helper/CurrencyFormat";
import Alert from "@material-ui/lab/Alert";

function AbonnementPage(props){
    const [payCycle,setpayCycle] = React.useState(1);
    const [currPage,setCurrPage] = React.useState(1);
    const [currPrice,setCurrPrice] = React.useState(null);
    const [currPlan,setCurrPlan] = React.useState(null);
    const [dropinInstance,setDropinInstance] = React.useState(null);
    const [booking_btn_disabled,set_booking_btn_disabled] = React.useState(false);

    const [address,setAddress] = React.useState({ name:"",vorname:"",firma1:"",firma2:"",strasse:"",ort:"",plz:"" });
    const [addressChanged,setAddressChanged] = React.useState(false);
    const [hasError,setError] = React.useState({ name:false,vorname:false,firma1:false,firma2:false,strasse:false,ort:false,plz:false });
    const handleAction = (tier) => {
        if(tier.id === 999){
            props.history.push('/help/tarif');
        }else{
            setCurrPlan(tier);
            if(payCycle===1){
                setCurrPrice(tier.price[0]);
            }else if(payCycle===6){
                setCurrPrice(tier.price[1]);
            }else if(payCycle===12){
                setCurrPrice(tier.price[2]);
            }
            setCurrPage(2);
        }
    };

    const handleAddressChange = (type,obj) => {
        let newval = obj.target.value;
        setAddressChanged(true);
        setAddress({...address,[type]: newval});
        checkValidation(type,newval);
    };
    const checkValidation = (type,newval) => {
        let curr_val = newval;
        let new_errors = hasError;
        if(typeof newval === "undefined"){
            curr_val = address[type];
        }
        let return_status = false;
        if(type==="plz") {
            if (!curr_val || curr_val.length !== 5) {
                new_errors[type] = true;
                return_status = true;
            } else {
                new_errors[type] = false;
            }
        }
        if(type==="vorname" || type==="name" || type==="ort" || type==="strasse") {
            if (!curr_val || curr_val.length < 2) {
                new_errors[type] = true;
                return_status = true;
            } else {
                new_errors[type] = false;
            }
        }
        setError(new_errors);
        return return_status;
    };

    const handleBookingSubmit = () => {
        let tmp_status = null;
        let has_errors = false;
        for(const itm of Object.keys(address)){
            tmp_status = checkValidation(itm,address[itm]);
            if(tmp_status){
                has_errors = true;
            }
        }

        if(has_errors){
            props.addWarning("Bitte überprüfen Sie Ihre Eingaben.");
        }else{
            set_booking_btn_disabled(true);
            dropinInstance.requestPaymentMethod().then((p) => {
                if(p.type !== "CreditCard" || p.threeDSecureInfo.status === "authenticate_successful") {
                    props.handleBooking(address, p.nonce, payCycle, currPlan,p).then((r) => {
                        if (typeof r.result.result !== "undefined" && r.result.result == "success") {
                            props.history.push('/abonnements/thank-you');
                        } else {
                            props.addError("Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie uns über das Kontaktformular.");
                        }
                    });
                }else{
                    props.addWarning("Bitte versuchen Sie erneut die Verifikation zu starten.");
                    set_booking_btn_disabled(false);
                    dropinInstance.clearSelectedPaymentMethod();
                }
            }).catch((e) => {
                props.addWarning("Bitte überprüfen Sie Ihre Zahlungsmethode.");
                set_booking_btn_disabled(false);
            });
        }
    };

    const handleCycle1 = () => { setpayCycle(1); };
    const handleCycle2 = () => { setpayCycle(6); };
    const handleCycle3 = () => { setpayCycle(12); };

    const handleDropInInstance = (instance) => {
        setDropinInstance(instance);
    };
    useEffect(() => {
        if(!addressChanged) {
            setAddress({
                name: props.user.BenutzerBillingName,
                vorname: props.user.BenutzerBillingVorname,
                firma1: props.user.BenutzerBillingFirma1,
                firma2: props.user.BenutzerBillingFirma2,
                strasse: props.user.BenutzerBillingStrasse,
                ort: props.user.BenutzerBillingOrt,
                plz: props.user.BenutzerBillingPLZ
            });
            props.fetchToken();
        }
    },[props.user]);


    let is_start_discount = typeof props.user.trial !== "undefined" && props.user.trial.start_discount_hours > 0;

    return (
        <div>
            <Helmet>
                <title>{makeTitle("Abonnements")}</title>
            </Helmet>
            {currPage===1 && (
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                        {props.user.TarifStatus === false && (
                            <Alert severity="error">Sie haben kein aktives Abonnement mehr. Bitte schließen Sie zur weiteren Nutzung von Sellerava ein Abonnement Ihrer Wahl ab.</Alert>
                        )}
                        {props.user.TarifStatus !== false && (
                            <Alert severity="info">Wenn Sie bereit ein aktives Paket haben, wird dieses automatisch gekündigt. Sie können alle Features bereit jetzt nutzen, zahlen jedoch erst ab Laufzeitbeginn.</Alert>
                        )}
                        {is_start_discount && (
                            <Alert severity="info">Nutzen Sie den "Early-Bird"-Rabatt! Buchen Sie das Basic-Paket (monatliche Zahlung) und erhalten Sie 3x 8€ Rabatt.</Alert>
                        )}
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                                Paket buchen
                            </Typography>
                            <Typography variant="h5" align="center" color="textSecondary" component="p">
                                Jedes Hauptpaket enthält alle Features mit 500 Keywords und 100 Produkte für den Spion.
                            </Typography>
                            <Typography align="center" color="textSecondary" component="p">
                                * Rechnungsstellung erfolgt ohne Ausweis der Umsatzsteuer nach §19 UStG.
                            </Typography>

                        </Grid>
                        <Grid item xs={12} style={{textAlign:"center"}}>
                            <ButtonGroup color="primary" aria-label="outlined primary button group">
                                <Button onClick={handleCycle1} variant={(payCycle===1)?"contained":""}>Monatlich</Button>
                                <Button onClick={handleCycle2} variant={(payCycle===6)?"contained":""}>Halbjährlich</Button>
                                <Button onClick={handleCycle3} variant={(payCycle===12)?"contained":""}>Jährlich</Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <AbonnementPricing payCycle={payCycle} onAction={handleAction} is_start_discount={is_start_discount} />
                        </Grid>
                    </Grid>
                </div>
            )}
            {currPage===2 && (
                <Container maxWidth={"sm"}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} style={{textAlign:"center"}}>
                            <Typography component="h3" variant="h3" align="center" color="textPrimary" gutterBottom>
                                Paket "{currPlan.title}" buchen
                            </Typography>
                            <Button onClick={() => setCurrPage(1)} variant={"outlined"}>Anderes Paket wählen</Button>
                        </Grid>
                        <Grid item xs={12} style={{textAlign:"center"}}>
                            <Typography component="h5" variant="h5" align="left" color="textPrimary" gutterBottom>
                                Ihre Rechnungsadresse:
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                    <FormGroup row>
                                        <TextField
                                            label="Firma"
                                            type="text"
                                            value={address.firma1}
                                            onChange={(t) => handleAddressChange("firma1",t)}
                                            fullWidth
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <FormGroup row>
                                        <TextField
                                            label="Firma (2. Zeile)"
                                            type="text"
                                            value={address.firma2}
                                            onChange={(t) => handleAddressChange("firma2",t)}
                                            fullWidth
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormGroup row>
                                        <TextField
                                            label="Vorname"
                                            type="text"
                                            value={address.vorname}
                                            onChange={(t) => handleAddressChange("vorname",t)}
                                            fullWidth
                                            error={hasError.vorname}
                                            helperText={hasError.vorname && "Pflichtfeld"}
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormGroup row>
                                        <TextField
                                            label="Name"
                                            type="text"
                                            value={address.name}
                                            onChange={(t) => handleAddressChange("name",t)}
                                            fullWidth
                                            error={hasError.name}
                                            helperText={hasError.name && "Pflichtfeld"}
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <FormGroup row>
                                        <TextField
                                            label="Straße + Hausnummer"
                                            type="text"
                                            value={address.strasse}
                                            onChange={(t) => handleAddressChange("strasse",t)}
                                            fullWidth
                                            error={hasError.strasse}
                                            helperText={hasError.strasse && "Pflichtfeld"}
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormGroup row>
                                        <TextField
                                            label="Postleitzahl"
                                            type="text"
                                            value={address.plz}
                                            onChange={(t) => handleAddressChange("plz",t)}
                                            fullWidth
                                            error={hasError.plz}
                                            helperText={hasError.plz && "Pflichtfeld"}
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <FormGroup row>
                                        <TextField
                                            label="Ort / Stadt"
                                            type="text"
                                            value={address.ort}
                                            onChange={(t) => handleAddressChange("ort",t)}
                                            fullWidth
                                            error={hasError.ort}
                                            helperText={hasError.ort && "Pflichtfeld"}
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{textAlign:"center"}}>
                            <Typography component="h5" variant="h5" align="left" color="textPrimary" gutterBottom>
                                Ihre Zahlungsmethode:
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <DropIn
                                        options={{
                                            authorization: props.billing_token,
                                            paypal: {
                                                flow: 'vault'
                                            },
                                            locale: 'de_DE',
                                            threeDSecure: {
                                                amount: currPrice,
                                                email: props.user.BenutzerEmail,
                                                productCode: "DIG",
                                                billingAddress: {
                                                    givenName: address.vorname,
                                                    surname: address.name,
                                                    streetAddress: address.strasse,
                                                    locality: address.ort,
                                                    postalCode: address.plz,
                                                }
                                            }
                                        }}
                                        onInstance={(instance) => handleDropInInstance(instance)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{textAlign:"center"}}>
                            <Typography component="h5" variant="h5" align="left" color="textPrimary" gutterBottom>
                                Ihr Warenkorb:
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h5" component="h2">
                                                {currPlan.title}
                                            </Typography>
                                            <Typography color="textSecondary">
                                                Laufzeit: {payCycle} Monate (wird autom. verlängert)
                                            </Typography>
                                            <Typography color="textSecondary">
                                                Kündigung: Jederzeit zum Ende der Abrechnungsperiode.
                                            </Typography>
                                            {(is_start_discount && currPlan.id == 11 && payCycle==1) && (
                                                <Typography color="error">
                                                    Rabatt: die ersten 3 Monate lang 8€ Rabatt.
                                                </Typography>
                                            )}
                                            <Typography variant={"h4"}>
                                                <EuroFormat value={currPrice} />
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant={"contained"} color={"secondary"} size={"large"} disabled={booking_btn_disabled} onClick={handleBookingSubmit}>Jetzt buchen</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            )}
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        user: state.account.user,
        billing_token:state.account.billing_token
    };
};
const mapDispatchToProps = dispatch => ({
    addError: (msg,code) => dispatch(addErrorItem(msg,code)),
    addWarning: (msg,code) => dispatch(addWarningItem(msg,code)),
    fetchToken: () => dispatch(doFetchBillingToken()),
    handleBooking: (address,nonce,cycle,plan,debug) => dispatch(doCreateBooking(address,nonce,cycle,plan,debug))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AbonnementPage))