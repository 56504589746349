import {fade, makeStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import styled from "@material-ui/core/styles/styled";
import {green} from "@material-ui/core/colors";

//export const GRAPH_PALETTE = ["#2c3e50","#2A4B58","#28575F","#266467","#24706E","#227D76","#208A7E","#1E9685","#1CA38D","#1AAF94","#18BC9C"];
export const GRAPH_PALETTE = ["#2c3e50","#28575F","#24706E","#208A7E","#1CA38D","#18BC9C"];
export const GRAPH_PALETTE_LIGHT = ["#18BC9C","#46B185","#74A66E","#A39A57","#D18F40","#FF8429"];
//export const GRAPH_PALETTE_4 = ["#18BC9C","#53864d","#d9d08b","#FF8429"];
//export const GRAPH_PALETTE_4 = ["#8a75ff","#00a1ff","#00b4d8","#18bc9c"];
export const GRAPH_PALETTE_4 = ["#3f51b5","#2196f3","#00bcd4","#009688"];


//export const GRAPH_PALETTE_5 = ["#2C3E50","#227D76","#18BC9C","#A39A57","#FF8429"];
export const GRAPH_PALETTE_5 = ["#00145c","#00438a","#006d9e","#00969c","#2fbc8f"];
export const success = green[800];


export const sellerava_green = "#18bc9c";




const BaseStyle = makeStyles(theme => ({
    spacer: {
        height: '20px'
    },
    inputMargin: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    grow: {
        flexGrow: 1,
    },
    NavbarsectionRight: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    NavBarButton: {
        color: fade(theme.palette.common.white, 0.8),
        '&:hover': {
            color: fade(theme.palette.common.white, 0.6),
        }
    },
    nestedList: {
        backgroundColor: fade(theme.palette.common.black, 0.05),
        '& li': {
            paddingLeft: theme.spacing(4) + 'px !important',
        }
    },
    TableImage: {
        height: 45,
        //maxWidth: "100%",
        marginTop: 7
    },
    fab: {
        margin: theme.spacing(2)
    },
    DropdownListItem: {
        cursor: "pointer",
        '&:hover': {
            backgroundColor: fade(theme.palette.common.black,0.2)
        }
    },
    DropdownListPaper: {
        marginTop: 35,
        '& ul': {
            paddingTop:0,
            maxHeight: 300
        }
    },
    MessageUnread: {
        '& span': {
            fontWeight: "bold"
        }
    },
    MessageRead: {
        '& span': {
            fontWeight: "normal"
        }
    },
    warningColor: {
        color: fade(theme.palette.warning.dark,1)+ " !important"
    },
    backgroundColor: {
        color: fade(theme.palette.warning.dark,1)+ " !important"
    },
    warningLightColor: {
        color: fade(theme.palette.warning.light,1)+ " !important"
    },
    warningLightColorBg: {
        backgroundColor: fade(theme.palette.warning.light,1)+ " !important"
    },
    errorColor: {
        color: fade(theme.palette.error.dark,1)+ " !important"
    },
    errorColorBg: {
        backgroundColor: fade(theme.palette.error.dark,1)+ " !important"
    },
    errorLightColor: {
        color: fade(theme.palette.error.light,1)+ " !important"
    },
    errorLightColorBg: {
        backgroundColor: fade(theme.palette.error.light,1)+ " !important"
    },
    selleravaColor: {
        color: sellerava_green+ " !important"
    },
    success: {
        color: success+ " !important"
    },
    successBg: {
        backgroundColor: success+ " !important"
    },
    successLightColor: {
        color: fade(theme.palette.success.light,1)+ " !important"
    },
    successLightColorBg: {
        backgroundColor: fade(theme.palette.success.light,1)+ " !important"
    },
    warningBox: {
        color: theme.palette.getContrastText(theme.palette.warning.main)+ " !important",
        backgroundColor: theme.palette.warning.main+ " !important"
    },
    ChipError: {
        borderColor: theme.palette.error.main+ " !important",
        color: theme.palette.error.main+ " !important",
        "& *" : {
            color: theme.palette.error.main+ " !important"
        }
    },
    ChipSuccess: {
        borderColor: theme.palette.success.main+ " !important",
        color: theme.palette.success.main+ " !important",
        "& *" : {
            color: theme.palette.success.main+ " !important"
        }
    },
    whiteColor: {
        color: "#fff !important",
    },
    inlineTextIcon: {
        fontSize: 16,
        top: 2,
        position: 'relative'
    },
    spaceBetweenChilds: {
        '& > *': {
            margin: theme.spacing(0.5)
        }
    },
    darkPrimary: {
        color: theme.palette.primary.dark
    },
    lightPrimary: {
        color: theme.palette.primary.light
    },
    routerLink: {
        textDecoration: 'none',
        color: 'inherit',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    visibleLink: {
        textDecoration: 'underline'
    },
    smallLoading: {
        paddingLeft:5,
        paddingTop:2,
        fontSize:14,
        color: fade(theme.palette.common.black,0.5)
    },
    smallLoadingIcon: {
        position: "relative",
        top: 2
    },
    GraphAreaFloatRight: {
        '& .recharts-area': {
            float: "right"
        }
    },
    trend_abs_bottomright: {
        position: "absolute",
        bottom:20,
        right:20
    },
    bluepaper: {
        background: theme.palette.primary.light,
        color: theme.palette.primary.contrastText
    },
    tablehead_1: {
        borderBottom: "4px solid "+GRAPH_PALETTE_4[0],
        fontSize:19
    },
    tablehead_2: {
        borderBottom: "4px solid "+GRAPH_PALETTE_4[1],
        fontSize:19
    },
    tablehead_3: {
        borderBottom: "4px solid "+GRAPH_PALETTE_4[3],
        fontSize:19
    },
    tablehead_4: {
        borderBottom: "4px solid "+GRAPH_PALETTE_4[3],
        fontSize:19
    },
    tablehead_5: {
        borderBottom: "4px solid "+GRAPH_PALETTE_4[2],
        fontSize:19
    },
    tablehead_6: {
        borderBottom: "4px solid "+GRAPH_PALETTE_4[2],
        fontSize:19
    },
    helpPaper: {
        display: 'flex',
        flexDirection: 'column'
    },
    marginBottom5: {
        marginBottom: 5
    }
}));

export const DefaultPaper = styled(Paper)({
    marginBottom: 20,
    padding: 20,
    height: 'calc(100% - 40px)',
    '& h1': {
        fontSize: 30
    }
});
export const DefaultPaperAuto = styled(Paper)({
    marginBottom: 20,
    padding: 20,
    position: "relative",
    '& h1': {
        fontSize: 30
    }
});

export default BaseStyle;