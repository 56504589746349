import React from 'react';
import {DefaultPaper, GRAPH_PALETTE} from "../Layout/BaseStyle";
import { connect } from 'react-redux';
import {toggleBackdrop} from "../Actions/Actions";
import useMountEffect from "../Helper/MountEffect";
import {Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import Typography from "@material-ui/core/Typography";
import {EuroFormat} from "../Helper/CurrencyFormat";
import {doFetchProductGraphSales} from "../Actions/ProductActions";


const CustomTooltip = ({ active, payload, label,tmpdata }) => {
    if (active && payload) {
        return (
            <DefaultPaper>
                <Typography variant={"h5"}>
                    {label}
                </Typography>
                {payload.map((payl,index) => {
                    let umsatz = 0;
                    let gewinn = 0;
                    tmpdata.map((tmpindex) => {
                       if(tmpindex.date === payl.payload.date){
                           umsatz = tmpindex.umsatz;
                           gewinn = tmpindex.gewinn;
                       }
                       return tmpindex;
                    });
                    return (<div>
                        <p style={{margin:0}}><span style={{fontSize:18,display:'inline-block',minWidth:50,paddingRight:5,fontWeight: "bold",color:GRAPH_PALETTE[index]}}>{payl.value}</span>{payl.name}</p>
                        <p style={{margin:0}}><span style={{fontSize:18,display:'inline-block',minWidth:50,paddingRight:5,fontWeight: "bold",color:GRAPH_PALETTE[5]}}><EuroFormat value={umsatz} /></span>Umsatz</p>
                        <p style={{margin:0}}><span style={{fontSize:18,display:'inline-block',minWidth:50,paddingRight:5,fontWeight: "bold",color:GRAPH_PALETTE[2]}}><EuroFormat value={gewinn} /></span>Gewinn</p>
                    </div>);
                })}
            </DefaultPaper>
        );
    }

    return null;
};

function GraphProductDetailSales(props){

    //run once
    useMountEffect(() => {
        props.fetchProductGraph(props.productId);
    });

    const data = (props.graphlines.length > 0)? props.graphlines : [];

    /*<DefaultPaper>
            <Grid container justify="space-between">
                <Grid item md={6}>
                    <Typography variant={"h6"}>
                        Verkäufe und Umsatz
                    </Typography>
                </Grid>
            </Grid>
            <div className={basestyle.spacer} />
        </DefaultPaper>*/

    return (
            <ResponsiveContainer width="100%" height={300}>
                <ComposedChart data={data}>
                    <CartesianGrid stroke="#eee" />
                    <Bar yAxisId="right"  dataKey="umsatz" barSize={20} name="Umsatz" fill={GRAPH_PALETTE[5]}/>
                    <Bar yAxisId="right"  dataKey="gewinn" barSize={20} name="Gewinn" fill={GRAPH_PALETTE[2]}/>
                    <Line yAxisId="left" connectNulls type="monotone" dataKey="einheiten" name="Verkaufte Artikel"
                          dot={{strokeWidth: 2}} strokeWidth={3} stroke={GRAPH_PALETTE[0]}/>
                    <XAxis dataKey="date"  tickMargin={10} allowDuplicatedCategory={false} padding={{ left: 30, right: 30}} />
                    <YAxis yAxisId="left" dataKey="einheiten" tickLine={false} tickMargin={10} padding={{ top: 30 }} />
                    <Tooltip offset={50} content={<CustomTooltip tmpdata={data} />} />
                    <YAxis yAxisId="right" orientation="right" dataKey="umsatz" tickLine={false} tickMargin={10} padding={{ top: 30 }} />
                </ComposedChart>
            </ResponsiveContainer>
    );
}
/*
 */

const mapStateToProps = (state) => {
    return {
        graphlines: state.products.single_sales
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    fetchProductGraph: (id) => dispatch(doFetchProductGraphSales(id))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GraphProductDetailSales)