import React from "react";
import Typography from "@material-ui/core/Typography";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";
import BaseStyle, {DefaultPaper} from "../Layout/BaseStyle";
import {addSuccessItem} from "../Actions/Actions";
import {connect} from "react-redux";
import {doDownloadSpionGesamtExport} from "../Actions/SpionActions";


function ExportSpion(props){
    const basestyle = BaseStyle();
    const [exportDateFrom,setExportDateFrom] = React.useState(null);
    const [exportDateTo,setExportDateTo] = React.useState(null);

    const handleExportDateFrom = (date) => {
        setExportDateFrom(date);
    };
    const handleExportDateTo = (date) => {
        setExportDateTo(date);
    };
    const handleExportClick = () => {
        if(exportDateFrom !== null && exportDateFrom !== "Invalid Date"
            && exportDateTo !== null && exportDateTo !== "Invalid Date"
            && exportDateFrom < exportDateTo){
            let spion = null;
            if(typeof props.spionid !== "undefined"){
                spion = props.spionid;
            }
            props.exportDownload(exportDateFrom,exportDateTo,spion).then(()=> {
                setExportDateFrom(null);
                setExportDateTo(null);
                props.addSuccess("Ihre Datei wird jetzt heruntergeladen!");
            });
        }
    };

    return (
        <DefaultPaper>
            <Typography variant={"h6"}>
                Export {(props.spionid==null)?"Gesamt-Daten":"Produktdaten"}
            </Typography>
            <div className={basestyle.spacer} />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={3}>
                    <Grid item xs={5}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="Von:"
                            format="dd.MM.yyyy"
                            value={exportDateFrom}
                            onChange={handleExportDateFrom}
                            KeyboardButtonProps={{
                                'aria-label': 'Datum ändern',
                            }}
                            style={{ marginTop:0,marginBottom:0 }}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog2"
                            label="Bis:"
                            format="dd.MM.yyyy"
                            value={exportDateTo}
                            onChange={handleExportDateTo}
                            KeyboardButtonProps={{
                                'aria-label': 'Datum ändern',
                            }}
                            style={{ marginTop:0,marginBottom:0 }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button onClick={handleExportClick} variant="contained" style={{marginTop:12}}><GetAppIcon /></Button>
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        </DefaultPaper>
    );
}


const mapStateToProps = (state) => {
    return {
    };
};
const mapDispatchToProps = dispatch => ({
    exportDownload: (from,to,spionid) => dispatch(doDownloadSpionGesamtExport(from,to,spionid)),
    addSuccess: (msg,code) => dispatch(addSuccessItem(msg,code))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExportSpion);