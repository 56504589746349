import React, {useRef} from 'react';
import {Helmet} from "react-helmet";
import makeTitle from "../Helper/makeTitle";
import Grid from "@material-ui/core/Grid";
import BaseStyle, {DefaultPaperAuto} from "../Layout/BaseStyle";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {
    addErrorItem, addSuccessItem,
    addWarningItem,
    doResendActivation, doTryActivation
} from "../Actions/Actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import useMountEffect from "../Helper/MountEffect";

function ActivationPage(props){
    const basestyle = BaseStyle();

    const [code,setCode] = React.useState("");
    const [fieldStatus,setFieldStatus] = React.useState({ inp: false,btn1: false,btn2: false });
    const [timerCount,setTimerCount] = React.useState(0);

    const timerRef = useRef(timerCount);
    timerRef.current = timerCount;

    const tryCode = () => {
        if(code.length === 5) {
            setFieldStatus({inp: true, btn1: true, btn2: true});
            props.tryActivation(code).then((res) => {
                if (res.result) {
                    props.addSuccess("Die Aktivierung war erfolgreich!");
                    window.location.reload();
                } else {
                    props.addError("Der Aktivierungscode ist nicht korrekt!");
                    setFieldStatus({...fieldStatus,inp: false, btn1: false});
                }
            });
        }else{
            props.addError("Der Aktivierungscode ist nicht korrekt!");
        }
    };
    const resendCode = () => {
        setFieldStatus({ ...fieldStatus,btn2:true });
        props.resendMail().then((res) => {
            if(res.result){
                props.addSuccess("Die Bestätigungsmail wurde erneut gesendet.");
            }else{
                props.addError("Die Bestätigungsmail kann nur alle 2 Minuten gesendet werden!");
            }
            setTimerCount(120);
        });
    };

    useMountEffect(() => {
        timer();
    });

    const timer = () => {
        if(timerRef.current > 0) {
            setTimerCount(timerRef.current - 1);
            setFieldStatus({ ...fieldStatus,btn2:true });
        }else{
            setFieldStatus({ ...fieldStatus,btn2:false });
        }
        setTimeout(() => {
            timer();
        },1000);
    };


    return (
        <div>
            <Helmet>
                <title>{makeTitle("E-Mail bestätigen")}</title>
            </Helmet>
            <Grid container justify={"center"}>
                <Grid item xs={12} md={4} lg={3}>
                    <DefaultPaperAuto style={{textAlign:"center"}}>
                        <Typography variant={"h3"}>E-Mail bestätigen</Typography>
                        <div className={basestyle.spacer} />
                        <Typography variant={"body1"}>Wir haben Ihnen eine E-Mail mit einem Bestätigungscode gesendet. Bitte geben Sie den Code in das folgende Feld ein.</Typography>
                        <div className={basestyle.spacer} />
                        <FormGroup row>
                            <TextField
                                label="Aktivierungscode"
                                type="text"
                                variant={"outlined"}
                                value={code}
                                onChange={(t) => setCode(t.target.value)}
                                fullWidth
                                disabled={fieldStatus.inp}
                            />
                        </FormGroup>
                        <div className={basestyle.spacer} />
                        <Button variant={"contained"} color={"primary"} fullWidth onClick={tryCode} disabled={fieldStatus.btn1}>Code prüfen</Button>
                        <div className={basestyle.spacer} />
                        <hr />
                        <div className={basestyle.spacer} />
                        <Button variant={"outlined"} color={"primary"} fullWidth onClick={resendCode} disabled={fieldStatus.btn2}>erneut senden {timerCount>0 && (<span>&nbsp;({timerCount})</span>)}</Button>
                    </DefaultPaperAuto>
                </Grid>
            </Grid>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        user: state.account.user
    };
};
const mapDispatchToProps = dispatch => ({
    addError: (msg,code) => dispatch(addErrorItem(msg,code)),
    addWarning: (msg,code) => dispatch(addWarningItem(msg,code)),
    addSuccess: (msg,code) => dispatch(addSuccessItem(msg,code)),
    tryActivation: (code) => dispatch(doTryActivation(code)),
    resendMail: () => dispatch(doResendActivation())
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ActivationPage))