import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import BaseStyle from "../Layout/BaseStyle";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import useMountEffect from "./MountEffect";
import {addErrorItem, toggleBackdrop} from "../Actions/Actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {parseNumberText} from "./CurrencyFormat";
import {InputAdornment} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";

const StockUpdateDialog = (props) => {
    const basestyle = BaseStyle();
    const [form,setForm] = React.useState({
        Menge: ""
    });

    const Change = (field,e) => {
        setForm({
            ...form,
            [field]: e.target.value
        });
    };
    const handleSave = () => {
        let valid = true;
        let menge = parseNumberText(form.Menge);
        if(isNaN(menge) || menge < 0) valid = false;

        if(valid){
            props.save({...form,Menge: menge});
        }else{
            props.addError("Bitte kontrollieren Sie die Pflichtfelder auf Vollständigkeit!");
        }

    };

    useMountEffect(() => {
           if(typeof props.data !== "undefined" && props.data !== null){
               setForm(props.data);
           }
    });

    return (
        <Dialog open={true} onClose={props.abort} maxWidth={"xs"} fullWidth>
            <DialogTitle>Lagerbestand anpassen</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item md={12}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="new_stock_entry">Neuer Lagerbestand</InputLabel>
                            <Input
                                id="new_stock_entry"
                                value={form.Menge}
                                inputProps={{
                                    style:{textAlign:"right"}
                                }}
                                onChange={(e) => Change("Menge",e)}
                                endAdornment={<InputAdornment position="end">{props.data.Einheit}</InputAdornment>}
                            />
                            <FormHelperText>Geben Sie einen neuen Lagerbestand ein.</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.abort} color="primary">
                    Abbrechen
                </Button>
                <Button onClick={handleSave} color="primary">
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>
    );
};
const mapStateToProps = (state) => {
    return {
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    addError: (msg,code) => dispatch(addErrorItem(msg,code))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(StockUpdateDialog))