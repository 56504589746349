import React from 'react';
import {DefaultPaper, GRAPH_PALETTE} from "../Layout/BaseStyle";
import { connect } from 'react-redux';
import {toggleBackdrop} from "../Actions/Actions";
import useMountEffect from "../Helper/MountEffect";
import { CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import Typography from "@material-ui/core/Typography";
import {doFetchProductGraphRank} from "../Actions/ProductActions";


const CustomTooltip = ({ active, payload, label,tmpdata }) => {
    if (active && payload) {
        return (
            <DefaultPaper>
                <Typography variant={"h5"}>
                    {label}
                </Typography>
                {payload.map((payl,index) => {
                    return (<div>
                        <p style={{margin:0}}>
                            <span style={{fontSize:18,display:'inline-block',minWidth:50,paddingRight:5,fontWeight: "bold",color:GRAPH_PALETTE[index]}}>
                                {payl.value}
                                {payl.name==="BuyBox-Anteil" && "%"}
                            </span>
                            {payl.name}
                        </p>
                    </div>);
                })}
            </DefaultPaper>
        );
    }

    return null;
};
const toPercent = (decimal, fixed = 0) => {
    return `${(decimal).toFixed(fixed)}%`;
};

function GraphProductDetailRank(props){

    //run once
    useMountEffect(() => {
        props.fetchProductGraph(props.productId);
    });

    const data = (props.graphlines.length > 0)? props.graphlines : [];

    /*<DefaultPaper>
            <Grid container justify="space-between">
                <Grid item md={6}>
                    <Typography variant={"h6"}>
                        Verkaufsrang und BuyBox-Anteil
                    </Typography>
                </Grid>
            </Grid>
            <div className={basestyle.spacer} />
        </DefaultPaper>*/

    return (
            <ResponsiveContainer width="100%" height={300}>
                <ComposedChart data={data}>
                    <CartesianGrid stroke="#eee" />
                    <Line yAxisId="left" type="monotone" dataKey="buybox" name="BuyBox-Anteil" stroke={GRAPH_PALETTE[5]} />
                    <Line yAxisId="right" connectNulls type="monotone" dataKey="rank" name="Verkaufsrang"
                          dot={{strokeWidth: 2}} strokeWidth={3} stroke={GRAPH_PALETTE[0]} />
                    <XAxis dataKey="date"  tickMargin={10} allowDuplicatedCategory={false} padding={{ left: 30, right: 30}} />
                    <YAxis yAxisId="left" dataKey="buybox" tickFormatter={toPercent} domain={[0,100]} tickLine={false} tickMargin={10} padding={{ top: 30 }} />
                    <YAxis yAxisId="right" orientation="right" dataKey="rank" tickLine={false} domain={['auto','auto']} tickMargin={10} padding={{ top: 30 }} reversed />
                    <Tooltip offset={50} content={<CustomTooltip tmpdata={data} />} />
                </ComposedChart>
            </ResponsiveContainer>
    );
}
/*
 */

const mapStateToProps = (state) => {
    return {
        graphlines: state.products.single_rank
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    fetchProductGraph: (id) => dispatch(doFetchProductGraphRank(id))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GraphProductDetailRank)