import React from 'react';
import Container from "@material-ui/core/Container";
import { toggleBackdrop} from "../Actions/Actions";
import {connect} from "react-redux";
import {useParams} from "react-router";
import BaseStyle, {DefaultPaper} from "../Layout/BaseStyle";
import useMountEffect from "../Helper/MountEffect";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LoadingPage from "./LoadingPage";
import CountryFlag from "../HtmlComponents/CountryFlag";
import Link from "@material-ui/core/Link";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Chip from "@material-ui/core/Chip";
import {EuroFormat} from "../Helper/CurrencyFormat";
import BreadCrumb from "../HtmlComponents/BreadCrumb";
import ExportSpion from "../HtmlComponents/ExportSpion";
import GraphSpionDetail from "../HtmlComponents/GraphSpionDetail";
import {doFetchSpionDetail} from "../Actions/SpionActions";
import {Helmet} from "react-helmet";
import makeTitle from "../Helper/makeTitle";
import Alert from '@material-ui/lab/Alert';

function SpionDetailPage(props){
    const basestyle = BaseStyle();
    let { spionId } = useParams();

    //run once
    useMountEffect(() => {
        props.loadSpion(spionId);
    },[]);

    if(props.spionDetail !== null && typeof props.spionDetail.title !== "undefined") {
        let headline = 'Spion: '+props.spionDetail.title;
        if(props.spionDetail.title === null) headline = "Produktdaten zu "+props.spionDetail.asin+" werden noch verarbeitet...";
        return (
            <Container maxWidth="xl">
                <Helmet>
                    <title>{makeTitle('Spion: '+props.spionDetail.title)}</title>
                </Helmet>
                <BreadCrumb links={[
                    {label: 'Spion',url:'/spion'},
                    {label: headline}
                ]} help={"spion"} />
                {props.spionDetail.category === "Unspecified" && (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Alert severity="warning"><b>Verkaufszahlen könnten ungenau sein!</b> Wir konnten zu diesem Produkt keine Kategorie zuordnen oder die Produktkategorie wird vom Spion nicht unterstützt. Bitte wenden Sie sich bei Fragen an unser Team!</Alert>
                        </Grid>
                    </Grid>
                )}
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                        <DefaultPaper style={{marginBottom:0}}>
                            <Typography variant={"h1"}>
                                <span style={{position:'relative',top:6}}><CountryFlag country={props.spionDetail.marketplace} /></span>
                                &nbsp;{headline}
                            </Typography>
                            <Typography variant={"subtitle1"}>
                                <Link component="a" href={props.spionDetail.link} target="_blank"><OpenInNewIcon className={basestyle.inlineTextIcon} /> {props.spionDetail.link}</Link>
                            </Typography>
                            <div className={basestyle.spaceBetweenChilds}>
                                {props.spionDetail.category_path !== "" && <Chip label={"Kategorie: "+props.spionDetail.category_path} />}
                                {props.spionDetail.marke !== null && <Chip label={"Marke: "+props.spionDetail.marke} />}
                                {(props.spionDetail.hersteller !== "" && props.spionDetail.hersteller !== null) && <Chip label={"Hersteller: "+props.spionDetail.hersteller} />}
                            </div>
                        </DefaultPaper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <ExportSpion spionid={props.spionDetail.id} />
                    </Grid>
                </Grid>
                <Grid container spacing={3} alignItems="stretch">
                    <Grid item xs={12} md={4}>
                        <DefaultPaper style={{alignItems: 'center',display: 'flex'}}>
                            <img src={props.spionDetail.pic} alt="Produktbild" style={{ maxWidth: '100%',maxHeight: '250px',margin:'0 auto' }} />
                        </DefaultPaper>
                    </Grid>
                    <Grid item md={8}>
                        <Grid container spacing={3} style={{height:'105%'}} alignItems="stretch">
                            <Grid item md={6} xs={12}>
                                <DefaultPaper style={{textAlign:'center'}}>
                                    <Typography variant={"h4"} style={{marginTop:30}}>Verkaufsrang</Typography>
                                    <Typography className={basestyle.lightPrimary} variant={"h2"}>{props.spionDetail.vkrang}</Typography>
                                </DefaultPaper>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <DefaultPaper style={{textAlign:'center'}}>
                                    <Typography variant={"h4"} style={{marginTop:30}}>Ø Umsatz pro Tag</Typography>
                                    <Typography className={basestyle.lightPrimary} variant={"h2"}>
                                        <EuroFormat value={props.spionDetail.umsatz} />
                                    </Typography>
                                </DefaultPaper>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <DefaultPaper style={{textAlign:'center'}}>
                                    <Typography variant={"h4"} style={{marginTop:30}}>Ø Verkäufe pro Tag</Typography>
                                    <Typography color={"secondary"} variant={"h2"}>{props.spionDetail.absatz}</Typography>
                                </DefaultPaper>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <DefaultPaper style={{textAlign:'center'}}>
                                    <Typography variant={"h4"} style={{marginTop:30}}>Bewertung</Typography>
                                    <Typography className={basestyle.lightPrimary} variant={"h6"}>Coming soon!</Typography>
                                </DefaultPaper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <div className={basestyle.spacer} />
                {spionId > 0 && (<GraphSpionDetail spionid={spionId} />)}
            </Container>
        );
    }else{
        return <LoadingPage />;
    }
}


const mapStateToProps = (state) => {
    return {
        spionDetail: state.spions.single
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    loadSpion: (id) => dispatch(doFetchSpionDetail(id))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SpionDetailPage);