import React from 'react';
import {DefaultPaper, GRAPH_PALETTE} from "../Layout/BaseStyle";
import { connect } from 'react-redux';
import {toggleBackdrop} from "../Actions/Actions";
import useMountEffect from "../Helper/MountEffect";
import {Area, Bar, CartesianGrid, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import Typography from "@material-ui/core/Typography";
import {EuroFormat} from "../Helper/CurrencyFormat";
import {doFetchProductGraphPricing} from "../Actions/ProductActions";
import {CircularProgress} from "@material-ui/core";


const CustomTooltip = ({ active, payload, label,tmpdata }) => {
    if (active && payload) {
        return (
            <DefaultPaper>
                <Typography variant={"h5"}>
                    {label}
                </Typography>
                {payload.map((payl,index) => {
                    let price_gewinn = 0;
                    tmpdata.map((tmpindex) => {
                        if(tmpindex.date === payl.payload.date){
                            price_gewinn = tmpindex.gewinn;
                        }
                        return tmpindex;
                    });
                    if(payl.value[0] !== null && payl.value[1] !== null) {
                        return (<div>
                            <p style={{margin: 0}}>
                            <span style={{
                                fontSize: 18,
                                display: 'inline-block',
                                minWidth: 50,
                                paddingRight: 5,
                                fontWeight: "bold",
                                color: GRAPH_PALETTE[index]
                            }}>
                                <EuroFormat value={payl.value[0]}/> - <EuroFormat value={payl.value[1]}/>
                            </span>
                                {payl.name}
                            </p>
                            {price_gewinn !== null && (
                                <p style={{margin: 0}}>
                                <span style={{
                                    fontSize: 18,
                                    display: 'inline-block',
                                    minWidth: 50,
                                    paddingRight: 5,
                                    fontWeight: "bold",
                                    color: GRAPH_PALETTE[index]
                                }}>
                                    <EuroFormat value={price_gewinn}/>
                                </span>
                                    durchschn. Gewinn
                                </p>
                            )}
                        </div>);
                    }else{
                        return "";
                    }
                })}
            </DefaultPaper>
        );
    }

    return null;
};

function GraphProductDetailPricing(props){

    //run once
    useMountEffect(() => {
        props.fetchProductGraph(props.productId);
    });

    const data = (props.graphlines.length > 0)? props.graphlines : [];

    /*<DefaultPaper>
            <Grid container justify="space-between">
                <Grid item md={6}>
                    <Typography variant={"h6"}>
                        Verkäufe und Umsatz
                    </Typography>
                </Grid>
            </Grid>
            <div className={basestyle.spacer} />
        </DefaultPaper>*/

    return (
        <div>
            {props.loaded === 0 && (<div style={{textAlign:"center"}}><CircularProgress color={"secondary"} /></div>)}

            {props.loaded !== 0 && (
                <ResponsiveContainer width="100%" height={300}>
                    <ComposedChart data={data}>
                        <CartesianGrid stroke="#eee" />
                        <Bar  dataKey="gewinn" barSize={5} name="Gewinn" fill={GRAPH_PALETTE[5]}/>
                        <Area dataKey="priceRange" name="Preisspanne" connectNulls />
                        <XAxis dataKey="date"  tickMargin={10} allowDuplicatedCategory={false} padding={{ left: 30, right: 30}} />
                        <YAxis tickLine={false} tickMargin={10} padding={{ top: 30 }} />
                        <Tooltip offset={50} content={<CustomTooltip tmpdata={data} />} />
                    </ComposedChart>
                </ResponsiveContainer>
            )}
        </div>
    );
}
/*
 */

const mapStateToProps = (state) => {
    return {
        graphlines: state.products.single_pricing,
        loaded: state.products.single_pricing.length
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    fetchProductGraph: (id) => dispatch(doFetchProductGraphPricing(id))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GraphProductDetailPricing)