import formatDate, {getDateByString} from "../Helper/Date";
import {doFetchKeywords} from "./RankingActions";

export const IS_LOCAL = (window.location.href.indexOf("amz-check-local") > 0 || window.location.href.indexOf("localhost") > 0);
export const IS_TEST = (window.location.href.indexOf("test.") > 0);
export const API_URL = (IS_LOCAL)?'http://api.amz-check-local.com':(IS_TEST?'https://test.api.sellerava.com':'https://api.sellerava.com');
export const NON_REACT_HOME_URL = (IS_LOCAL) ?'http://amz-check-local.com':(IS_TEST?'https://test.sellerava.com':'https://sellerava.com');


export const TIME_PRESETS = [
    { name: "today",friendly: "Heute" },
    { name: "yesterday",friendly: "Gestern" },
    { name: "7_days",friendly: "7 Tage" },
    { name: "this_month",friendly: "aktueller Monat" },
    { name: "last_month",friendly: "letzter Monat" },
];

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_USERDATA = 'GET_USERDATA';
export const TOGGLE_BACKDROP = 'TOGGLE_BACKDROP';
export const ADD_ERROR_ITEM = 'ADD_ERROR_ITEM';
export const REMOVE_ERROR_ITEM = 'REMOVE_ERROR_ITEM';

export const SET_PICKER_DATA = 'SET_PICKER_DATA';
export const SET_PICKER_DATA_GROUPING = 'SET_PICKER_DATA_GROUPING';
export const SET_PICKER_DATA_PRESET = 'SET_PICKER_DATA_PRESET';
export const SET_PICKER_DATA_FROM = 'SET_PICKER_DATA_FROM';
export const SET_PICKER_DATA_TO = 'SET_PICKER_DATA_TO';
export const SET_PICKER_DATA_PRESET_REAL = 'SET_PICKER_DATA_PRESET_REAL';
export const SET_CONTACTFORM_STATUS = 'SET_CONTACTFORM_STATUS';
export const GET_ACCOUNT_SETTINGS = 'GET_ACCOUNT_SETTINGS';
export const SET_SETTING_FORM = 'SET_SETTING_FORM';
export const SET_PICKER_DATA_SETDATA = 'SET_PICKER_DATA_SETDATA';
export const GET_CHANGELOG_SETTINGS = 'GET_CHANGELOG_SETTINGS';
export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const GET_TIERS = 'GET_TIERS';
export const GET_BILLING_TOKEN = 'GET_BILLING_TOKEN';

/*** STATE API ***/

export function getBillingToken(json){
    return {
        type: GET_BILLING_TOKEN,
        data: json.result
    }
}
export function getTiers(json){
    return {
        type: GET_TIERS,
        data: json.result
    }
}
export function getDashboardData(json){
    return {
        type: GET_DASHBOARD_DATA,
        data: json.result
    }
}
export function getNotifications(json){
    return {
        type: GET_NOTIFICATIONS,
        notifications: json.result
    }
}
export function getUserData(json){
    return {
        type: GET_USERDATA,
        user: json.result
    }
}
export function getAccountSettings(json){
    return {
        type: GET_ACCOUNT_SETTINGS,
        data: json.result
    }
}
export function getChangelogSettings(data){
    return {
        type: GET_CHANGELOG_SETTINGS,
        data: data.result
    }
}
export function toggleBackdrop(open){
    return {
        type: TOGGLE_BACKDROP,
        open
    }
}
export function setSettingData(prop,val){
    return {
        type: SET_SETTING_FORM,
        prop,
        val
    }
}


export function addErrorItem(msg,code){
    if(typeof msg === "undefined" || msg.length < 2) return { type: '' };
    return {
        type: ADD_ERROR_ITEM,
        variant: 'error',
        msg,
        code
    }
}
export function addSuccessItem(msg,code){
    if(typeof msg === "undefined" || msg.length < 2) return { type: '' };
    return {
        type: ADD_ERROR_ITEM,
        variant: 'success',
        msg,
        code
    }
}
export function addWarningItem(msg,code){
    if(typeof msg === "undefined" || msg.length < 2) return { type: '' };
    return {
        type: ADD_ERROR_ITEM,
        variant: 'warning',
        msg,
        code
    }
}
export function removeErrorItem(index){
    return {
        type: REMOVE_ERROR_ITEM,
        index
    }
}

export function setPickerData(prop,val){
    if(prop === "set_data"){
        return {
            type: SET_PICKER_DATA_SETDATA,
            val
        }
    }
    if(prop === "grouping"){
        return {
            type: SET_PICKER_DATA_GROUPING,
            val
        }
    }
    if(prop === "preset"){
        return {
            type: SET_PICKER_DATA_PRESET,
            val
        }
    }
    if(prop === "preset_real"){
        return {
            type: SET_PICKER_DATA_PRESET_REAL,
            val
        }
    }
    if(prop === "from"){
        return {
            type: SET_PICKER_DATA_FROM,
            val
        }
    }
    if(prop === "to"){
        return {
            type: SET_PICKER_DATA_TO,
            val
        }
    }
}
export function setContactFormSendStatus(res){
    return {
        type: SET_CONTACTFORM_STATUS,
        status: res
    }
}


/*** FETCH API ***/
export function handleErrors(response) {
    if (!response.ok) {
        throw response;
    }
    return response.json();
}
export function catchErrors(response){
    return async (dispatch, getState) => {
        if(response.status === 404){
            //dispatch(setSiteStatus(response.status));
        }
        if (typeof response.json !== "undefined") {
            response.json().then(err => {
                return dispatch(addErrorItem(err.result, err.status));
            });
        } else {
            console.error(response);
            return addErrorItem("Es ist ein Fehler aufgetreten.");
        }
    }
}
export function handleErrorsFlat(response) {
    if (!response.ok) {
        throw response;
    }
    return response;
}



export function doFetchNotifications(){
    return (dispatch, getState) => {
        fetch(API_URL +'/User.php?action=Notifications', {
            credentials: 'include'
        }).then(handleErrors).then(
            response => dispatch(getNotifications(response))
        );
    };
}
export function doFetchUserData(){
    return (dispatch, getState) => {
        return fetch(API_URL +"/User.php?action=MainData", {
            credentials: 'include'
        })
            .then(handleErrors)
            .then(data => dispatch(getUserData(data)))
            .catch(s => dispatch(catchErrors(s)));
    }
}
export function doSetNotificationsRead(){
    return (dispatch, getState) => {
        return fetch(API_URL +"/User.php?action=NotificationsRead", {
            credentials: 'include',
            method: "POST"
        });
    }
}
export function doSaveTutorial(skip,vktoken,mwstoken){
    return (dispatch, getState) => {
        return fetch(API_URL +"/User.php?action=Tutorial", {
            credentials: 'include',
            method: "POST",
            body: JSON.stringify({
                "skip": skip,
                "vktoken": vktoken,
                "mwstoken": mwstoken
            })
        })
            .then(handleErrors)
            .then(data => dispatch(addSuccessItem(skip?"Sie können die Zugangsdaten später im Account-Bereich hinterlegen.":"Zugangsdaten gespeichert! Daten werden jetzt abgerufen")),dispatch(doFetchUserData()))
            .catch(s => dispatch(catchErrors(s)));
    }
}
export function doSubmitContactForm(topic,text,attach){
    return (dispatch, getState) => {
        dispatch(toggleBackdrop(true));
        dispatch(setContactFormSendStatus(null));
        return fetch(API_URL +"/User.php?action=ContactForm", {
            credentials: 'include',
            method: "POST",
            body: JSON.stringify({
                "topic": topic,
                "text": text,
                "attachments": attach
            })
        })
            .then(handleErrors)
            .then(data => dispatch(addSuccessItem("Ihre Anfrage wurde an uns gesendet!")))
            .then(data => dispatch(toggleBackdrop(false)))
            .then(data => dispatch(setContactFormSendStatus(true)))
            .catch(s => dispatch(catchErrors(s)));
    }
}
export function doFetchAccountSettings(){
    return (dispatch, getState) => {
        return fetch(API_URL +"/User.php?action=AccountSettings", {
            credentials: 'include'
        })
            .then(handleErrors)
            .then(data => dispatch(getAccountSettings(data)))
            .catch(s => dispatch(catchErrors(s)));
    }
}
export function doSaveSettingsApi(){
    return (dispatch, getState) => {
        let state_modified = getState().account.settings_form;
        return doSaveUserSettings("AccountApiSettings", JSON.stringify({
            "merch_id": state_modified.merchant_id,
            "token": state_modified.auth_key,
            "req_orders": state_modified.api_request_orders,
            "req_products": state_modified.api_request_products
        }),state_modified,dispatch);
    }
}
export function doSaveSettingsNotifications(){
    return (dispatch, getState) => {
        let state_modified = getState().account.settings_form;
        return doSaveUserSettings("AccountNotificationsSettings", JSON.stringify({
            "interval": state_modified.email_interval,
            "stock_warning": state_modified.outofstock_warning
        }),state_modified,dispatch);
    }
}
export function doSaveSettingsTaxes(){
    return (dispatch, getState) => {
        let state_modified = getState().account.settings_form;
        return doSaveUserSettings("AccountTaxSettings", JSON.stringify({
            "kleingewerbe": state_modified.kleingewerbe
        }),state_modified,dispatch);
    }
}
export function doSaveSettingsTarif(){
    return (dispatch, getState) => {
        let state_modified = getState().account.settings_form;
        return doSaveUserSettings("AccountInvoiceSettings", JSON.stringify({
            "email": state_modified.billing_email
        }),state_modified,dispatch);
    }
}
export function doCancelAbo(id){
    return (dispatch, getState) => {
        dispatch(toggleBackdrop(true));
        return fetch(API_URL +"/User.php?action=AccountCancelAbo", {
            credentials: 'include',
            method: "POST",
            body: JSON.stringify({
                "paketid": id
            })
        })
            .then(handleErrors)
            .then(data => dispatch(addSuccessItem("Ihr Abonnement wird nicht mehr verlängert. Sie erhalten eine Bestätigung per Email.")))
            .then(data => dispatch(doFetchAccountSettings()))
            .then(data => dispatch(toggleBackdrop(false)))
            .catch(s => {
                dispatch(catchErrors(s));
                dispatch(toggleBackdrop(false));
            });
    }
}
export function doSendInvoice(id){
    return (dispatch, getState) => {
        dispatch(toggleBackdrop(true));
        return fetch(API_URL +"/User.php?action=resendInvoice", {
            credentials: 'include',
            method: "POST",
            body: JSON.stringify({
                "tarifid": id
            })
        })
            .then(handleErrors)
            .then(data => dispatch(addSuccessItem("Sie erhalten die Rechnung in kürze per Email.")))
            .then(data => dispatch(toggleBackdrop(false)))
            .catch(s => {
                dispatch(catchErrors(s));
                dispatch(toggleBackdrop(false));
            });
    }
}


function doSaveUserSettings(endpoint,postdata,state_modified,dispatch){
    dispatch(toggleBackdrop(true));
    return fetch(API_URL +"/User.php?action="+endpoint, {
        credentials: 'include',
        method: "POST",
        body: postdata
    })
    .then(handleErrors)
    .then(data => dispatch(addSuccessItem("Einstellungen gespeichert!")))
    .then(data => dispatch(toggleBackdrop(false)))
    .then(data => dispatch(getAccountSettings({result: state_modified})))//write formdata to real settings object
    .catch(s => {
        dispatch(catchErrors(s));
        dispatch(toggleBackdrop(false));
    });
}



export function doLoadPickerData(){
    return (dispatch, getState) => {
        let props = ["grouping","from","to","preset","preset_real"];
        for(let i=0;i<props.length;i++) {
            let val = localStorage.getItem('pickerdata_' + props[i]);
            if(val !== null && val !== "null") {
                if(props[i] === "from" || props[i] === "to"){
                    val = getDateByString(val);
                }
                dispatch(setPickerData(props[i], val));
            }
        }
        dispatch(setPickerData("set_data",true));
    }
}
export function doSetPickerDataItem(prop,val){
    return (dispatch, getState) => {
        let newval = val;
        if((prop === "from" || prop === "to") && newval !== null) newval = formatDate(newval);
        localStorage.setItem('pickerdata_' + prop, newval);
        dispatch(setPickerData(prop, val));
    }
}
export function doSetPickerData(prop,val){
    return (dispatch, getState) => {
        let tmpformdata = getState().account.pickerdata;

        let new_preset = tmpformdata.preset;
        if (prop === "preset") new_preset = val;

        let new_from = tmpformdata.from;
        if (prop === "from") new_from = val;
        //if (prop === "from" && val !== null && typeof val.getMonth !== "undefined") val = formatDate(val);

        let new_to = tmpformdata.to;
        if (prop === "to") new_to = val;
        //if (prop === "to" && val !== null && typeof val.getMonth !== "undefined") val = formatDate(val);

        if (new_preset !== "custom"
            || (new_preset === "custom" && new_from !== null && new_to !== null)
        ) {
            dispatch(doSetPickerDataItem("preset_real", new_preset));
        }
        return dispatch(doSetPickerDataItem(prop, val));
    };
}
export function doFetchChangelogSettings(){
    return (dispatch, getState) => {
        return fetch(API_URL +"/User.php?action=ChangelogSettings", {
            credentials: 'include'
        })
            .then(handleErrors)
            .then(data => dispatch(getChangelogSettings(data)))
            .catch(s => dispatch(catchErrors(s)));
    }
}
export function doDeleteChangelogWord(wordid){
    return (dispatch, getState) => {

        return fetch(API_URL +'/User.php?action=deleteChangelogSettings', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                "wordid": wordid
            })
        })
            .then(handleErrors)
            .then( response => dispatch(addWarningItem("Das Schlüsselwort wurde gelöscht!")),dispatch(doFetchChangelogSettings()))
            .catch(s => dispatch(catchErrors(s)));
    };
}
export function doAddChangelogWord(wordlist){
    return (dispatch, getState) => {

        return fetch(API_URL +'/User.php?action=addChangelogSettings', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                "wordlist": wordlist
            })
        })
            .then(handleErrors)
            .then( response => dispatch(addSuccessItem("Die Schlüsselworter wurden angelegt!")),dispatch(doFetchChangelogSettings()))
            .catch(s => dispatch(catchErrors(s)));
    };
}
export function doFetchDasboardData(){
    return (dispatch, getState) => {
        return fetch(API_URL +"/User.php?action=DashboardData", {
            credentials: 'include'
        })
            .then(handleErrors)
            .then(data => dispatch(getDashboardData(data)))
            .catch(s => dispatch(catchErrors(s)));
    }
}
export function doFetchTiers(){
    return (dispatch, getState) => {
        return fetch(API_URL +"/User.php?action=AboPaketList", {
            credentials: 'include'
        })
            .then(handleErrors)
            .then(data => dispatch(getTiers(data)))
            .catch(s => dispatch(catchErrors(s)));
    }
}
export function doFetchBillingToken(){
    return (dispatch, getState) => {
        return fetch(API_URL +"/User.php?action=BillingToken", {
            credentials: 'include'
        })
            .then(handleErrors)
            .then(data => dispatch(getBillingToken(data)))
            .catch(s => dispatch(catchErrors(s)));
    }
}
export function doCreateBooking(address,nonce,cycle,plan,debug){
    return (dispatch, getState) => {

        return fetch(API_URL +'/User.php?action=BillingCreate', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                "address": address,
                "nonce": nonce,
                "cycle": cycle,
                "plan": plan,
                "debug": debug
            })
        })
            .then(handleErrors)
            .catch(s => dispatch(catchErrors(s)));
    };
}
export function doChangePayment(address,nonce){
    return (dispatch, getState) => {

        return fetch(API_URL +'/User.php?action=EditBillingPayment', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                "address": address,
                "nonce": nonce
            })
        })
            .then(handleErrors)
            .catch(s => dispatch(catchErrors(s)));
    };
}
export function doTryActivation(code){
    return (dispatch, getState) => {

        return fetch(API_URL +'/User.php?action=ConfirmMailCode', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                "code": code
            })
        })
            .then(handleErrors)
            .catch(s => dispatch(catchErrors(s)));
    };
}
export function doResendActivation(){
    return (dispatch, getState) => {

        return fetch(API_URL +'/User.php?action=ConfirmationMail', {
            credentials: 'include',
            method: 'POST'
        })
        .then(handleErrors)
        .catch(s => dispatch(catchErrors(s)));
    };
}
