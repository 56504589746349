import React from 'react';
import Container from "@material-ui/core/Container";
import { toggleBackdrop} from "../Actions/Actions";
import {connect} from "react-redux";
import {useParams} from "react-router";
import BaseStyle, {DefaultPaper} from "../Layout/BaseStyle";
import useMountEffect from "../Helper/MountEffect";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LoadingPage from "./LoadingPage";
import CountryFlag from "../HtmlComponents/CountryFlag";
import Link from "@material-ui/core/Link";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Chip from "@material-ui/core/Chip";
import {EuroFormat} from "../Helper/CurrencyFormat";
import BreadCrumb from "../HtmlComponents/BreadCrumb";
import {doFetchProductDetail, resetProductDetail} from "../Actions/ProductActions";
import Rating from "@material-ui/lab/Rating";
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import DoneIcon from '@material-ui/icons/Done';
import GraphProductDetailSales from "../HtmlComponents/GraphProductDetailSales";
import GraphProductDetailRank from "../HtmlComponents/GraphProductDetailRank";
import {NumberFormat} from "../Helper/NumberFormat";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../Helper/TabPanel";
import TableProductDetailRanking from "../HtmlComponents/TableProductDetailRanking";
import MiniTable from "../Helper/MiniTable";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import GraphProductDetailRating from "../HtmlComponents/GraphProductDetailRating";
import {CircularProgress} from "@material-ui/core";
import GraphProductDetailPricing from "../HtmlComponents/GraphProductDetailPricing";
import TableProductDetailOffers from "../HtmlComponents/TableProductDetailOffers";
import TableProductDetailGewinn from "../HtmlComponents/TableProductDetailGewinn";
import {Helmet} from "react-helmet";
import makeTitle from "../Helper/makeTitle";
import TableProductDetailLager from "../HtmlComponents/TableProductDetailLager";
import NotFoundPage from "./NotFoundPage";

function ProductDetailPage(props){
    const basestyle = BaseStyle();
    let { productId } = useParams();
    const [activeTab,setActiveTab] = React.useState(0);
    const [loading,setLoading] = React.useState(true);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };



    //run once
    useMountEffect(() => {
        props.toggleBackdrop(true);
        props.resetProductDetail();
        props.loadProduct(productId).then((response)=> {
            props.toggleBackdrop(false);
            setLoading(false);
            if(typeof response !== "undefined" && response.type===2){
                setActiveTab(4);
            }
        });
    },[]);

    if(props.productDetail === false){
        return (<NotFoundPage />);
    }else if(typeof props.productDetail.title !== "undefined" && !loading) {
        let ptype = props.productDetail.type;
        let table_items = [
            {type: 'SKU:', value: props.productDetail.sku},
            {type: 'ASIN:', value: props.productDetail.asin},
            {type: 'Preis:', value: props.productDetail.price_overall},
            {type: 'Ihr Preis:', value: props.productDetail.price},
            {type: 'Lager:', value: props.productDetail.stock},
            {type: 'Kategorie:', value: props.productDetail.category_top+" > "+props.productDetail.category},
            {type: 'Marke:', value: props.productDetail.brand},
            {type: 'Hersteller:', value: props.productDetail.manufacturer}
        ];
        if(ptype === 2){
            table_items = [
                {type: 'SKU:', value: props.productDetail.sku},
                {type: 'Lager:', value: props.productDetail.stock+" "+props.productDetail.stock_einheit},
            ];
        }

        let showAttentionBadges = true;
        let showProductMiddleSection = true;
        let showProductExtenderSection = true;


        if(props.productDetail.marketplace !== "de"){
            showAttentionBadges = false;
            showProductMiddleSection = false;
            showProductExtenderSection = false;
        }
        if(ptype!==1){
            showProductMiddleSection = false;
            showProductExtenderSection = false;
        }
        return (
            <Container maxWidth="xl">
                <Helmet>
                    <title>{makeTitle('Produkt: '+props.productDetail.title)}</title>
                </Helmet>
                <BreadCrumb links={[
                    {label: 'Produkte',url:'/products'},
                    {label: 'Produkt: '+props.productDetail.title}
                ]} help={"product"} />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DefaultPaper style={{marginBottom:0}}>
                            <Typography variant={"h1"}>
                                {ptype===1 && <span style={{position:'relative',top:6}}><CountryFlag country={props.productDetail.marketplace} /></span>}
                                &nbsp;{props.productDetail.title}
                            </Typography>
                            {ptype===1 && (
                                <Typography variant={"subtitle1"}>
                                    <Link component="a" href={props.productDetail.link} target="_blank"><OpenInNewIcon className={basestyle.inlineTextIcon} /> {props.productDetail.link}</Link>
                                </Typography>
                            )}
                            {showAttentionBadges && (
                                <div className={basestyle.spaceBetweenChilds}>
                                    {props.productDetail.badges.map((badge,index) => {
                                        let style = null;
                                        let icon = null;
                                        if(badge.type==="warning"){
                                            style = basestyle.warningLightColor;
                                            icon = <PriorityHighIcon className={style} />;
                                        }else if(badge.type==="success"){
                                            style = basestyle.success;
                                            icon = <DoneIcon className={style} />;
                                        }else if(badge.type==="error"){
                                            style = basestyle.errorColor;
                                            icon = <PriorityHighIcon className={style} />;
                                        }

                                        return <Chip key={"product_badge_"+index} label={badge.text} className={style} icon={icon} variant="outlined" />;
                                    })}
                                </div>
                            )}
                        </DefaultPaper>
                    </Grid>
                </Grid>
                {showProductMiddleSection && (
                    <Grid container spacing={3} alignItems="stretch">
                        <Grid item xs={12} md={4}>
                            <DefaultPaper style={{alignItems: 'center',display: 'flex'}}>
                                <img src={props.productDetail.pic} alt="Produktbild" style={{ maxWidth: '100%',maxHeight: '100%',margin:'0 auto' }} />
                            </DefaultPaper>
                        </Grid>
                        <Grid item md={8}>
                            <Grid container spacing={3} style={{height:'105%'}} alignItems="stretch">
                                <Grid item md={6} xs={12}>
                                    <Grid container spacing={3} style={{height:'105%'}} alignItems="stretch">
                                        <Grid item xs={12}>
                                            <DefaultPaper style={{textAlign:'center'}}>
                                                <Typography variant={"h4"}>Verkaufsrang</Typography>
                                                <Typography className={basestyle.lightPrimary} variant={"h2"}><NumberFormat value={props.productDetail.vkrang} /></Typography>
                                            </DefaultPaper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DefaultPaper style={{textAlign:'center'}}>
                                                <Typography variant={"h4"}>Bewertung</Typography>
                                                <Typography className={basestyle.lightPrimary} variant={"h6"}>
                                                    <Rating name="read-only" value={props.productDetail.rating} size="large" readOnly />
                                                    <Typography>{props.productDetail.ratings} Bewertungen</Typography>
                                                </Typography>
                                            </DefaultPaper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <MiniTable columns={[
                                        { title: "Typ", field: "type" },
                                        {
                                            title: "Wert",
                                            field: "value",
                                            render: rowData => {
                                                if((rowData.type === "Preis:" || rowData.type === "Ihr Preis:" ) && rowData.value !== null){
                                                    return <EuroFormat value={rowData.value} />;
                                                }else {
                                                    return rowData.value;
                                                }
                                            }
                                        }
                                    ]} data={table_items} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <div className={basestyle.spacer} />
                {showProductExtenderSection && (
                <div>
                    <ExpansionPanel>{/* defaultExpanded={true}*/}
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography>Verkäufe und Umsatz</Typography>
                            {props.orderdata_loaded === 0 && (<Typography variant={"subtitle2"} className={basestyle.smallLoading}><CircularProgress size={14} /> Laden...</Typography>)}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {productId > 0 && (<GraphProductDetailSales productId={productId} />)}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography>Verkaufsrang und BuyBox-Anteil</Typography>
                            {props.rankdata_loaded === 0 && (<Typography variant={"subtitle2"} className={basestyle.smallLoading}><CircularProgress size={14} /> Laden...</Typography>)}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {productId > 0 && (<GraphProductDetailRank productId={productId} />)}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography>Bewertungen</Typography>
                            {props.rating_loaded === 0 && (<Typography variant={"subtitle2"} className={basestyle.smallLoading}><CircularProgress size={14} /> Laden...</Typography>)}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container>
                                <Grid item xs={12} md={4}>
                                    <List>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Box pr={3}>
                                                    <Rating name="read-only" value={5} size="large" readOnly />
                                                </Box>
                                            </ListItemAvatar>
                                            <ListItemText>
                                                <Typography variant={"body1"}>Sehr gut</Typography>
                                                <Typography variant={"body2"} color={"textSecondary"}>{props.productDetail.rating_percentage.five+"% Bewertungen mit 5 Sternen"}</Typography>
                                                <LinearProgress variant="determinate" value={parseInt(props.productDetail.rating_percentage.five)} />
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Box pr={3}>
                                                    <Rating name="read-only" value={4} size="large" readOnly />
                                                </Box>
                                            </ListItemAvatar>
                                            <ListItemText>
                                                <Typography variant={"body1"}>Gut</Typography>
                                                <Typography variant={"body2"} color={"textSecondary"}>{props.productDetail.rating_percentage.four+"% Bewertungen mit 4 Sternen"}</Typography>
                                                <LinearProgress variant="determinate" value={parseInt(props.productDetail.rating_percentage.four)} />
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Box pr={3}>
                                                    <Rating name="read-only" value={3} size="large" readOnly />
                                                </Box>
                                            </ListItemAvatar>
                                            <ListItemText>
                                                <Typography variant={"body1"}>Akzeptabel</Typography>
                                                <Typography variant={"body2"} color={"textSecondary"}>{props.productDetail.rating_percentage.three+"% Bewertungen mit 3 Sternen"}</Typography>
                                                <LinearProgress variant="determinate" value={parseInt(props.productDetail.rating_percentage.three)} />
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Box pr={3}>
                                                    <Rating name="read-only" value={2} size="large" readOnly />
                                                </Box>
                                            </ListItemAvatar>
                                            <ListItemText>
                                                <Typography variant={"body1"}>Mangelhaft</Typography>
                                                <Typography variant={"body2"} color={"textSecondary"}>{props.productDetail.rating_percentage.two+"% Bewertungen mit 2 Sternen"}</Typography>
                                                <LinearProgress variant="determinate" value={parseInt(props.productDetail.rating_percentage.two)} />
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Box pr={3}>
                                                    <Rating name="read-only" value={1} size="large" readOnly />
                                                </Box>
                                            </ListItemAvatar>
                                            <ListItemText>
                                                <Typography variant={"body1"}>Ungenügend</Typography>
                                                <Typography variant={"body2"} color={"textSecondary"}>{props.productDetail.rating_percentage.one+"% Bewertungen mit 1 Sternen"}</Typography>
                                                <LinearProgress variant="determinate" value={parseInt(props.productDetail.rating_percentage.one)} />
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    {productId > 0 && (<GraphProductDetailRating productId={productId} />)}
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <div className={basestyle.spacer} />
                    <div className={basestyle.spacer} />
                    <div className={basestyle.spacer} />

                    <DefaultPaper style={{marginBottom:15,padding:0}}>
                        <Tabs
                            value={activeTab}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab label="Ranking" />
                            <Tab label="Angebote" />
                            <Tab label="Preisverlauf" />
                            <Tab label="Gewinn" />
                            <Tab label="Lager & Versand" />
                        </Tabs>
                    </DefaultPaper>
                </div>
                )}
                <TabPanel value={activeTab} index={0}>
                    <TableProductDetailRanking productId={productId} />
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                    <TableProductDetailOffers productId={productId} />
                </TabPanel>
                <TabPanel value={activeTab} index={2}>
                    <GraphProductDetailPricing productId={productId} />
                </TabPanel>
                <TabPanel value={activeTab} index={3}>
                    <TableProductDetailGewinn productId={productId} ek={props.productDetail.ek_price} ship={props.productDetail.ek_ship} str={props.productDetail.ek_taxes} />
                </TabPanel>
                <TabPanel value={activeTab} index={4}>
                    <TableProductDetailLager productId={productId} data={props.productDetail} />
                </TabPanel>
                <div style={{height:500}} />
            </Container>
        );
    }else{
        return <LoadingPage />;
    }
}


const mapStateToProps = (state) => {
    return {
        productDetail: state.products.single,
        rating_loaded: state.products.single_rating.length,
        orderdata_loaded: state.products.single_sales.length,
        rankdata_loaded: state.products.single_rank.length
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    loadProduct: (id) => dispatch(doFetchProductDetail(id)),
    resetProductDetail: () => dispatch(resetProductDetail())
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductDetailPage);