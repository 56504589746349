import React, {useEffect} from "react";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {doLoadPickerData, doSetPickerData, toggleBackdrop} from "../Actions/Actions";
import {connect} from "react-redux";
import useMountEffect from "../Helper/MountEffect";


function DateRangePicker(props){


    const handleFromDate = (value) => {
        props.setPickerdata("from",value);
    };
    const handleToDate = (value) => {
        props.setPickerdata("to",value);
    };
    const handlePreset = (e) => {
        props.setPickerdata("preset",e.target.value);
        if(e.target.value !== "custom"){
            handleFromDate(null);
            handleToDate(null);
        }
    };
    const handleGrouping = (e) => {
        props.setPickerdata("grouping",e.target.value);
    };

    useMountEffect(() => {
        props.doLoadPickerData();
    });
    useEffect((e) => {
        if(props.pickerdata.data_set === true) {
            if (props.pickerdata.preset === "custom" && props.pickerdata.to !== null && props.pickerdata.from !== null) {
                props.onChange();
            } else if (props.pickerdata.preset !== "custom") {
                props.onChange();
            }
        }
        if(typeof props.presets !== "undefined" && props.presets.indexOf(props.pickerdata.preset) === -1){
            props.setPickerdata("preset",props.presets[0]);
            props.onChange();
            console.warn("SELLERAVA-WARNING: Pickerdata Preset nicht in Auswahl. Abfrage wird doppelt ausgeführt.");
        }
    },[props.pickerdata]);
    const getFriendlyName = (index) => {
        if(index==="today") return "Heute";
        if(index==="yesterday") return "Gestern";
        if(index==="7_days") return "7 Tage";
        if(index==="this_month") return "Dieser Monat";
        if(index==="last_month") return "Letzter Monat";
        if(index==="52_weeks") return "52 Wochen";
        if(index==="this_year") return "Dieses Jahr";
        if(index==="last_year") return "Letztes Jahr";
        if(index==="custom") return "Zeitraum";
    };

    let presets = ["today","yesterday","7_days","this_month","last_month","52_weeks","this_year","last_year","custom"];
    if(typeof props.presets !== "undefined"){
        presets = props.presets;
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={2} justify="flex-end">
                {typeof props.hide_grouping === "undefined" && (
                    <Grid item xs={12} md={"auto"}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Auswertung</InputLabel>
                            <Select
                                value={props.pickerdata.grouping}
                                onChange={handleGrouping}
                            >
                                <MenuItem value={"daily"}>Täglich</MenuItem>
                                <MenuItem value={"weekly"}>Wöchentlich</MenuItem>
                                <MenuItem value={"monthly"}>Monatlich</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                <Grid item xs={12} md={"auto"}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Zeitraum</InputLabel>
                        <Select
                            value={props.pickerdata.preset}
                            onChange={handlePreset}
                        >
                            {presets.map((index) => <MenuItem key={index} value={index}>{getFriendlyName(index)}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                {props.pickerdata.preset === "custom" && (
                    <Grid item xs={12} md={"auto"}>
                        <FormControl fullWidth>
                            <DatePicker
                                autoOk
                                format={(props.pickerdata.grouping === "monthly")?"MM.yyyy":"dd.MM.yyyy"}
                                label="Von"
                                views={(props.pickerdata.grouping === "monthly")?["year", "month"]:["year","month","date"]}
                                openTo={(props.pickerdata.grouping === "monthly")?"month":"date"}
                                value={props.pickerdata.from}
                                onChange={handleFromDate}
                                animateYearScrolling
                                fullWidth
                            />
                        </FormControl>
                    </Grid>
                )}
                {props.pickerdata.preset === "custom" && (
                    <Grid item xs={12} md={"auto"}>
                        <FormControl fullWidth>
                            <DatePicker
                                autoOk
                                format={(props.pickerdata.grouping === "monthly")?"MM.yyyy":"dd.MM.yyyy"}
                                label="Bis"
                                views={(props.pickerdata.grouping === "monthly")?["year", "month"]:["year", "month","date"]}
                                openTo={(props.pickerdata.grouping === "monthly")?"month":"date"}
                                value={props.pickerdata.to}
                                onChange={handleToDate}
                                animateYearScrolling
                            />
                        </FormControl>
                    </Grid>
                )}
            </Grid>
        </MuiPickersUtilsProvider>
    );
}


const mapStateToProps = (state) => {
    return {
        pickerdata: state.account.pickerdata
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    setPickerdata: (prop,val) => dispatch(doSetPickerData(prop,val)),
    doLoadPickerData: () => dispatch(doLoadPickerData())
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DateRangePicker);