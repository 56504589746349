import React from "react";

const GlobalStateInitial = { isLoggedIn: null,username: null };

const GlobalStateContext = React.createContext(GlobalStateInitial);
const dispatchStateContext = React.createContext(undefined);

const GlobalStateProvider = ({ children }) => {
    //const [global, setGlobal] = React.useState(GlobalStateInitial.global);
    /*const [text, setText] = React.useState(textInitialState.text);
    const [bool, setBool] = React.useState(boolInitialState.bool);*/
    //const GlobalContextValue = React.useMemo(() => ({global, setGlobal}), [global]);
    /*const textContextValue = React.useMemo(() => ({text, setText}), [text]);
    const boolContextValue = React.useMemo(() => ({bool, setBool}), [bool]);*/

    /*<TextStateContext.Provider value={textContextValue}>
        <BoolStateContext.Provider value={boolContextValue}>-->*/
    /*</BoolStateContext.Provider>
</TextStateContext.Provider>*/

    const [state, dispatch] = React.useReducer(
        (state, newValue) => ({ ...state, ...newValue }),
        GlobalStateInitial
    );
    return (
        <GlobalStateContext.Provider value={state}>
            <dispatchStateContext.Provider value={dispatch}>

                {children}
            </dispatchStateContext.Provider>
        </GlobalStateContext.Provider>
    );
};
export const useGlobalState = () => [
    React.useContext(GlobalStateContext),
    React.useContext(dispatchStateContext)
];

export default GlobalStateProvider;