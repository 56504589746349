import formatDate from "../Helper/Date";
import {
    addSuccessItem,
    addWarningItem,
    API_URL, catchErrors,
    handleErrors,
    handleErrorsFlat,
    toggleBackdrop
} from "./Actions";

export const ADD_SPION = 'ADD_SPION';
export const DELETE_SPION = 'DELETE_SPION';
export const RECEIVE_SPIONS = 'RECEIVE_SPIONS';
export const RECEIVE_SPION_POINTS = 'RECEIVE_SPION_POINTS';
export const RECEIVE_SPIONGRAPH = 'RECEIVE_SPIONGRAPH';
export const SET_SPION_FILTER_MARKETPLACE = 'SET_SPION_FILTER_MARKETPLACE';
export const SET_SPION_FILTER_STATUS = 'SET_SPION_FILTER_STATUS';
export const RECEIVE_SPION_DETAIL = 'RECEIVE_SPION_DETAIL';



export function setFilterSpion(filter,val){
    if(filter === "marketplace"){
        return {type: SET_SPION_FILTER_MARKETPLACE, marketplace: val };
    }else if(filter === "status"){
        return {type: SET_SPION_FILTER_STATUS, status: val };
    }
}

export function receiveSpionDetail(json){
    return {
        type: RECEIVE_SPION_DETAIL,
        single: json.result
    }
}
export function receiveSpions(json){
    return {
        type: RECEIVE_SPIONS,
        spions: json.result.map(child => child),
        receivedAt: Date.now()
    }
}
export function receiveSpionGraph(json){
    return {
        type: RECEIVE_SPIONGRAPH,
        graphlines: json.result
    }
}

export function receiveSpionPoints(json){
    return {
        type: RECEIVE_SPION_POINTS,
        points: json.result
    }
}



/***FETCH API***/


export function reloadSpionPage(){
    return async (dispatch, getState) => {
        dispatch(toggleBackdrop(true));
        dispatch(doFetchSpions())
            .then(() => dispatch(doFetchSpionGraph()))
            .then(() => dispatch(toggleBackdrop(false)));
    }
}

export function doFetchSpionDetail(id){
    return async (dispatch, getState) => {
        //dispatch(toggleBackdrop(true));
        dispatch(receiveSpionDetail({result: null}));
        await fetch(API_URL +'/Spion.php?action=Spion&id='+id, {
            credentials: 'include'
        }).then(handleErrors).then(
            response => {
                dispatch(receiveSpionDetail(response));
            }
        )
        //.then(() => dispatch(toggleBackdrop(false)))
        .catch(s => dispatch(catchErrors(s)));
    };
}

export function doFetchSpions(){
    return async (dispatch, getState) => {
        let filter_mp = getState().spions.filter.marketplace;
        let filter_status = getState().spions.filter.status;
        await fetch(API_URL +'/Spion.php?action=SpionList&marketplace='+filter_mp+'&status='+filter_status, {
            credentials: 'include'
        }).then(handleErrors).then(
            response => {
                dispatch(receiveSpions(response));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}
export function doFetchSpionGraph(id){
    return async (dispatch, getState) => {
        let filter_mp = getState().spions.filter.marketplace;
        let req_url = API_URL +'/Spion.php?action=SpionGraph';
        if(typeof id !== "undefined" && id !== null){
            req_url += '&spionid='+id;
        }else{
            req_url += '&marketplace='+filter_mp;
        }
        await fetch(req_url, {
            credentials: 'include'
        }).then(handleErrors).then(
            response => {
                dispatch(receiveSpionGraph(response));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}

export function doDownloadSpionGesamtExport(from,to,spionid){
    return (dispatch, getState) => {
        dispatch(toggleBackdrop(true));
        let new_from = formatDate(from);
        let new_to = formatDate(to);
        let req_url = API_URL +'/Spion.php?action=SpionGesamtExport';
        req_url += '&from='+new_from;
        req_url += '&to='+new_to;
        if(typeof spionid !== "undefined" && !isNaN(spionid)){
            req_url += '&spionid='+spionid;
        }

        return fetch(req_url, {
            credentials: 'include',
        }).then(handleErrorsFlat)
            .then((response) => response.blob())
            .then((blob) => {

                // 2. Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "spion_export_full.csv");
                // 3. Append to html page
                document.body.appendChild(link);
                // 4. Force download
                link.click();
                // 5. Clean up and remove the link
                link.parentNode.removeChild(link);

                dispatch(toggleBackdrop(false));
            }).catch(s => dispatch(catchErrors(s)));
    };
}
export function doFetchSpionPoints(){
    return (dispatch, getState) => {
        fetch(API_URL +'/Spion.php?action=Points', {
            credentials: 'include'
        }).then(handleErrors).then(
            response => dispatch(receiveSpionPoints(response))
        ).catch(s => dispatch(catchErrors(s)));
    };
}
export function doAddSpion(asin,marketplaces) {
    return (dispatch, getState) => {

        return fetch(API_URL +'/Spion.php?action=Spion', {
            credentials: 'include',
            method: "POST",
            body: JSON.stringify({
                "asin": asin,
                "marketplaces": marketplaces
            })
        })
            .then(handleErrors)
            .then( response => dispatch(addSuccessItem("Spion erfolgreich angelegt!")),dispatch(doFetchSpions()))
            .catch(s => dispatch(catchErrors(s)));
    };
}
export function doDeleteSpion(id) {
    return (dispatch, getState) => {

        return fetch(API_URL +'/Spion.php?action=deleteSpion', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                "id": id
            })
        })
            .then(handleErrors)
            .then( response => dispatch(addWarningItem("Überwachung beendet!")),dispatch(doFetchSpions()))
            .catch(s => dispatch(catchErrors(s)));
    };
}
