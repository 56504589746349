import React from 'react';
import TableSpion from "../HtmlComponents/TableSpion";
import BreadCrumb from "../HtmlComponents/BreadCrumb";
import Grid from "@material-ui/core/Grid";
import {Helmet} from "react-helmet";
import makeTitle from "../Helper/makeTitle";

function SpionPage(){
    return (
            <Grid container>
                <Grid item xs={12}>
                    <Helmet>
                        <title>{makeTitle('Spion')}</title>
                    </Helmet>
                    <BreadCrumb links={[
                        {label: 'Spion'}
                    ]} help={"spion"} />
                    <TableSpion />
                </Grid>
            </Grid>
    );
}
export default SpionPage;