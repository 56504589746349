import React from 'react';

import BaseStyle, {DefaultPaper} from "../Layout/BaseStyle";
import { connect } from 'react-redux';
import useMountEffect from "../Helper/MountEffect";
import {withRouter} from 'react-router-dom'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {parseNumberText, ToEuroOutput} from "../Helper/CurrencyFormat";
import Button from "@material-ui/core/Button";
import TableLagerBestellungen from "./TableLagerBestellungen";
import MiniTable from "../Helper/MiniTable";
import {NumberFormat} from "../Helper/NumberFormat";
import {addVerpackungBinding, saveLagerProductSettings, saveProductStock} from "../Actions/LagerActions";
import StockUpdateDialog from "../Helper/StockUpdateDialog";
import {addErrorItem, addSuccessItem, toggleBackdrop} from "../Actions/Actions";
import MaterialTable from "material-table";
import tableIcons from "../Layout/TableIcons";
import {doFetchVerpackungList} from "../Actions/ProductActions";
import Divider from "@material-ui/core/Divider";

function TableProductDetailLager(props){
    const basestyle = BaseStyle();
    const [lagerSettings,setLagerSettings] = React.useState({ LieferantID: null,Bearbeitungszeit: null });
    const [dialog,setDialog] = React.useState(null);

    const handleSave = () => {
        props.save({
            ...lagerSettings,
            productid: props.productId
        });
    };
    const openStockModifier = () => {
        setDialog(<StockUpdateDialog data={{Menge: ToEuroOutput(parseFloat(props.stock)),Einheit: props.data.stock_einheit}} abort={() => setDialog(null)} save={(data) => {
            props.toggleBackdrop(true);
            props.saveStock(props.productId,data.Menge).then(() => props.toggleBackdrop(false));
            setDialog(null);
        }} />)
    };

    useMountEffect(() => {
        props.loadVerpackungen();
        setLagerSettings({
            LieferantID: props.data.lieferant,
            Bearbeitungszeit: props.data.lieferant_dauer
        });
    });

    const handleSaveBinding = (newData,resolve,reject) => {
        var valid = true;
        let menge = parseNumberText(newData.Menge);
        if(isNaN(menge) || menge < 0 || menge===null) valid = false;

        if(valid || (typeof newData.action !== "undefined" && newData.action === "delete")) {
            props.addVerpackungBinding({
                ...newData,
                ProductID: props.productId,
                Menge: menge
            }).then(() => {
                resolve();
            });
        }else{
            reject();
            props.addError("Bitte kontrollieren Sie Ihre Eingaben auf Fehler!");
        }
    };

    let summe = 0;
    let gewichtung = {
        "3": {sum:0,day:0},
        "7": {sum:0,day:0},
        "30": {sum:0,day:0},
        "60": {sum:0,day:0},
        "180": {sum:0,day:0}
    };
    if(typeof props.sales.Gewichtung !== "undefined"){
        gewichtung = props.sales.Gewichtung;
        summe = props.sales.Sells;
    }

    let table_items = [
        { gewichtung: props.gewichtung_setting[0],time: 3,sales: gewichtung["3"].sum, day: gewichtung["3"].day },
        { gewichtung: props.gewichtung_setting[1],time: 7,sales: gewichtung["7"].sum, day: gewichtung["7"].day },
        { gewichtung: props.gewichtung_setting[2],time: 30,sales: gewichtung["30"].sum, day: gewichtung["30"].day },
        { gewichtung: props.gewichtung_setting[3],time: 60,sales: gewichtung["60"].sum, day: gewichtung["60"].day },
        { gewichtung: props.gewichtung_setting[4],time: 180,sales: gewichtung["180"].sum, day: gewichtung["180"].day }
    ];

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <div className={basestyle.spacer} />
                <Typography variant={"h4"}>Lieferungen & Lagerbestand</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <MiniTable header={true} title={"Gewichtung der Absatzzahlen"} columns={[
                    { title: "Tage", field: "time",type:"numeric" },
                    { title: "Einheiten", field: "sales",type:"numeric" },
                    { title: "pro Tag", field: "day",type:"numeric" },
                    { title: "Gewichtung", field: "gewichtung",type:"numeric", render: rowData => { return rowData.gewichtung+"%"; } }
                ]} data={table_items} />
            </Grid>
            <Grid item xs={12} md={6}>
                {dialog}
                <DefaultPaper style={{textAlign:'center'}}>
                    <Typography variant={"h6"} align={"left"} style={{margin:"16px 21px 25px"}}>Verkaufte Einheiten pro Tag</Typography>
                    <Typography className={basestyle.lightPrimary} variant={"h2"}><NumberFormat value={summe} /></Typography>
                    <div className={basestyle.spacer} />
                    <Typography variant={"body1"}>Die Reichweite des Lagerbestands wird anhand der historischen Absatzzahlen berechnet. Dabei werden die verkauften Einheiten in unterschiedlichen Zeiträumen erfasst und nach der festgelegten Gewichtung aufsummiert. Sie können die Gewichtung in den Account-Einstellungen jederzeit anpassen.</Typography>
                    <div className={basestyle.spacer} />
                    {props.data.type ===2 && (
                        <div>
                            <Typography variant={"subtitle1"}>
                                Aktueller Lagerbestand: {props.data.stock+" "+props.data.stock_einheit}
                            </Typography>
                            <Button variant={"contained"} color={"secondary"} onClick={openStockModifier}>Lagerbestand anpassen</Button>
                        </div>
                    )}
                </DefaultPaper>
            </Grid>

            <Grid item xs={12}>
                <TableLagerBestellungen productId={props.productId} settings={lagerSettings} onSettingChange={setLagerSettings} onSave={handleSave} />
            </Grid>
            <Grid item xs={12}>
                <Divider />
                <div className={basestyle.spacer} />
                <Typography variant={"h4"}>Verpackungsmaterial</Typography>
            </Grid>
            {props.data.type ===1 && (
                <Grid item xs={12}>
                    <MaterialTable title={"Verknüpfte Verpackungsmaterialien"} icons={tableIcons} columns={[
                        {
                            title: "Status",
                            field: "Status",
                            type: "numeric",
                            lookup: { 0: 'Aktiv', 1: 'Inaktiv' },
                            initialEditValue: 0
                        },
                        {
                            title: "Verpackung",
                            field: "VerpackungProductID",
                            type: "numeric",
                            lookup: props.verpackungList
                        },
                        {
                            title: "Menge",
                            field: "Menge"
                        },
                        {
                            title: "Berechnung auf",
                            field: "PerEinheit",
                            type: "numeric",
                            lookup: { 0: 'Bestellung', 1: 'Einheit' },
                            initialEditValue: 0
                        }
                    ]} data={props.data.verpackung_binding}
                                   editable={{
                                       onRowAdd: newData =>
                                           new Promise((resolve, reject) => {
                                               handleSaveBinding(newData,resolve,reject);
                                           }),
                                       onRowUpdate: (newData, oldData) =>
                                           new Promise((resolve, reject) => {
                                               handleSaveBinding(newData,resolve,reject);
                                           }),
                                       onRowDelete: oldData =>
                                           new Promise((resolve, reject) => {
                                               handleSaveBinding({
                                                       ...oldData,
                                                       action:"delete"
                                                   }
                                                   ,resolve,reject);
                                           }),
                                   }}
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <MaterialTable title={"Verpackungsverlauf"} icons={tableIcons} columns={[
                    {
                        title: "Typ",
                        field: "Type",
                        lookup: { 0: 'Manuell', 1: 'Lagerbestellung', 2: 'Versand / Bestellung' }
                    },
                    {
                        title: "Basis-Produkt",
                        field: "BaseProductTitle"
                    },
                    {
                        title: "Menge",
                        field: "Menge"
                    },
                    {
                        title: "Verpackung",
                        field: "ProductTitle"
                    },
                    {
                        title: "Bestellung",
                        field: "AMZOrderID"
                    }
                ]} data={props.data.verpackung_log}
                />
            </Grid>

        </Grid>
    );
}


const mapStateToProps = (state) => {
    return {
        gewichtung_setting: state.account.user.StockGewichtung,
        sales: state.products.single.stock_details,
        stock: state.products.single.stock,
        verpackungList: state.products.verpackungList
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (open) => dispatch(toggleBackdrop(open)),
    save:(settings) => dispatch(saveLagerProductSettings(settings)),
    saveStock:(productid,menge) => dispatch(saveProductStock(productid,menge)),
    loadVerpackungen:() => dispatch(doFetchVerpackungList()),
    addVerpackungBinding:(data) => dispatch(addVerpackungBinding(data)),
    addError: (msg,code) => dispatch(addErrorItem(msg,code))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TableProductDetailLager));