import React from 'react';
import Button from '@material-ui/core/Button';
import Box from "@material-ui/core/Box";
import {NON_REACT_HOME_URL} from "../Actions/Actions";
import {Helmet} from "react-helmet";
import makeTitle from "../Helper/makeTitle";

function LoginExpiredPage(){
    return (
        <Box spacing={3} textAlign='center'>
            <Helmet>
                <title>{makeTitle("Login abgelaufen")}</title>
            </Helmet>
            <h2>Login abgelaufen</h2>
            <p>bitte melden Sie sich erneut an.</p>
            <Button href={NON_REACT_HOME_URL+"/signin"} variant="contained" color="secondary">
                Zur Anmeldung
            </Button>
        </Box>
    );
}
export default LoginExpiredPage;