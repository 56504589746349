import {combineReducers} from "redux";

import {
    ADD_ERROR_ITEM, GET_ACCOUNT_SETTINGS, GET_BILLING_TOKEN, GET_CHANGELOG_SETTINGS, GET_DASHBOARD_DATA,
    GET_NOTIFICATIONS, GET_TIERS,
    GET_USERDATA,
    REMOVE_ERROR_ITEM, SET_CONTACTFORM_STATUS,
    SET_PICKER_DATA_FROM,
    SET_PICKER_DATA_GROUPING,
    SET_PICKER_DATA_PRESET, SET_PICKER_DATA_PRESET_REAL, SET_PICKER_DATA_SETDATA,
    SET_PICKER_DATA_TO, SET_SETTING_FORM,
    TOGGLE_BACKDROP
} from "./Actions";
import {
    ADD_SPION,
    DELETE_SPION,
    RECEIVE_SPION_DETAIL,
    RECEIVE_SPION_POINTS,
    RECEIVE_SPIONGRAPH,
    RECEIVE_SPIONS, SET_SPION_FILTER_MARKETPLACE, SET_SPION_FILTER_STATUS
} from "./SpionActions";
import {
    RECEIVE_PRODUCT_CATEGORIES,
    RECEIVE_PRODUCT_DETAIL, RECEIVE_PRODUCT_GEWINN, RECEIVE_PRODUCT_GRAPH_PRICING,
    RECEIVE_PRODUCT_GRAPH_RANK, RECEIVE_PRODUCT_GRAPH_RATING,
    RECEIVE_PRODUCT_GRAPH_SALES, RECEIVE_PRODUCT_OFFERS, RECEIVE_PRODUCT_RANKING,
    RECEIVE_PRODUCTS, RECEIVE_PRODUCTS_STOCK, RECEIVE_PRODUCTS_STOCK_COUNT, RECEIVE_VERPACKUNG_LIST,
    SET_PRODUCT_FILTER_CATEGORY,
    SET_PRODUCT_FILTER_FULLFILMENT, SET_PRODUCT_FILTER_LIEFERANT,
    SET_PRODUCT_FILTER_MARKETPLACE, SET_PRODUCT_FILTER_PRODUCTTYPE, SET_PRODUCT_FILTER_RESET,
    SET_PRODUCT_FILTER_STATUS
} from "./ProductActions";
import {
    RECEIVE_KEYWORDS, RECEIVE_RANKING_ALARMS,
    RECEIVE_RANKING_DETAILS,
    RECEIVE_RANKING_POINTS,
    RECEIVE_RANKING_PRODUCTS, RECEIVE_RANKING_TREND_CHART, RECEIVE_RANKING_TREND_DATA
} from "./RankingActions";
import {
    LOAD_SALES_BESTSELLERS, LOAD_SALES_CATEGORIESDATA,
    LOAD_SALES_DATA,
    LOAD_SALES_MAINCHART, MAINCHART_LOADING, RECIEVE_GEWINN_DETAIL_POSTEN_TABLE, RECIEVE_GEWINN_DETAIL_TABLE,
    RECIEVE_GEWINN_TABLE,
    SAVE_GEWINN
} from "./SalesActions";
import {
    RECIEVE_LAGER_BESTELLUNGEN,
    RECIEVE_LIEFERANTEN,
    SET_LAGER_BESTELLUNG_FILTER_PRODUCT,
    SET_LAGER_BESTELLUNG_FILTER_STATUS
} from "./LagerActions";

function account(state = {
    notifications: [],
    send_status: null,
    user: { BenutzerID: null,BenutzerName: null,BenutzerBillingName: null,BenutzerBillingFirma1: null,isLoggedIn: null,TutorialStatus: 0 },
    settings: {},
    settings_form: {},
    changelog_settings: [],
    dashboard_data: {},
    tiers: [],
    billing_token: null,
    pickerdata: { grouping: 'daily', preset: "this_month",from: null,to: null,preset_real: "this_month",data_set: false }
},action){
    switch (action.type){
        case GET_BILLING_TOKEN:
            return Object.assign({}, state, {
                ...state,
                billing_token: action.data
            });
        case GET_TIERS:
            return Object.assign({}, state, {
                ...state,
                tiers: action.data
            });
        case GET_DASHBOARD_DATA:
            return Object.assign({}, state, {
                ...state,
                dashboard_data: action.data
            });
        case GET_CHANGELOG_SETTINGS:
            return Object.assign({}, state, {
                ...state,
                changelog_settings: action.data
            });
        case GET_NOTIFICATIONS:
            return Object.assign({}, state, {
                ...state,
                notifications: action.notifications
            });
        case GET_USERDATA:
            return Object.assign({}, state, {
                ...state,
                user: action.user
            });
        case GET_ACCOUNT_SETTINGS:
            return Object.assign({}, state, {
                ...state,
                settings: action.data,
                settings_form: action.data
            });
        case SET_SETTING_FORM:
            return Object.assign({}, state, {
                ...state,
                settings_form: {
                    ...state.settings_form,
                    [action.prop]: action.val
                }
            });
        case SET_PICKER_DATA_GROUPING:
            return Object.assign({}, state, {pickerdata: {...state.pickerdata,grouping: action.val}});
        case SET_PICKER_DATA_PRESET:
            return Object.assign({}, state, {pickerdata: {...state.pickerdata,preset: action.val}});
        case SET_PICKER_DATA_PRESET_REAL:
            return Object.assign({}, state, {pickerdata: {...state.pickerdata,preset_real: action.val}});
        case SET_PICKER_DATA_FROM:
            return Object.assign({}, state, {pickerdata: {...state.pickerdata,from: action.val}});
        case SET_PICKER_DATA_TO:
            return Object.assign({}, state, {pickerdata: {...state.pickerdata,to: action.val}});
        case SET_PICKER_DATA_SETDATA:
            return Object.assign({}, state, {pickerdata: {...state.pickerdata,data_set: action.val}});
        case SET_CONTACTFORM_STATUS:
            return Object.assign({}, state, {send_status: action.status});
        default:
            return state;
    }
}
function spions(state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
    single: {},
    graphlines: { chart: [],max: 0 },
    points: { free:0,blocked:0,sum:0 },
    filter: { marketplace: 'all',status:'all' }
},action){
    switch (action.type){
        case SET_SPION_FILTER_MARKETPLACE:
            return Object.assign({}, state, {
                filter: {
                    ...state.filter,
                    marketplace: action.marketplace
                }
            });
        case SET_SPION_FILTER_STATUS:
            return Object.assign({}, state, {
                filter: {
                    ...state.filter,
                    status: action.status
                }
            });
        case ADD_SPION:
            return Object.assign({}, state, {
                items: [...state.items,{
                    asin: action.asin,
                    id: action.id,
                    status: 0
                }]
            });
        case DELETE_SPION:
            return state.items.map((spion, index) => {
                if (index === action.id) {
                    return Object.assign({}, spion, {
                        status: 2
                    })
                }
                return spion
            });
        case RECEIVE_SPION_DETAIL:
            return Object.assign({}, state, {
                single: action.single
            });
        case RECEIVE_SPIONS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                items: action.spions,
                lastUpdated: action.receivedAt
            });
        case RECEIVE_SPIONGRAPH:
            return Object.assign({}, state, {
                graphlines: action.graphlines
            });
        case RECEIVE_SPION_POINTS:
            return Object.assign({}, state, {
                points: action.points,
            });
        default:
            return state;
    }
}
function ranking(state = {
    items: [],
    single: {},
    alarms: [],
    trend: null,
    single_products: false,
    trend_data: {},
    points: { free:0,blocked:0,sum:0 }
},action){
    switch(action.type) {
        case RECEIVE_RANKING_TREND_CHART:
            return Object.assign({}, state, {
                trend: action.data
            });
        case RECEIVE_RANKING_TREND_DATA:
            return Object.assign({}, state, {
                trend_data: action.data
            });
        case RECEIVE_KEYWORDS:
            return Object.assign({}, state, {
                items: action.items
            });
        case RECEIVE_RANKING_POINTS:
            return Object.assign({}, state, {
                points: action.points,
            });
        case RECEIVE_RANKING_DETAILS:
            return Object.assign({}, state, {
                single: action.data,
            });
        case RECEIVE_RANKING_PRODUCTS:
            return Object.assign({}, state, {
                single_products: action.data,
            });
        case RECEIVE_RANKING_ALARMS:
            return Object.assign({}, state, {
                alarms: action.data,
            });
        default:
            return state;
    }
}
const default_product_filter = { status:0,fullfilment:'all',category: 'all',marketplace: 'de',lieferant:"all",producttype:1 };
const default_stock_einheiten = ["Stück","Millimeter","Zentimeter","Meter","Gramm","Quadratzentimeter","Quadratmeter"];
function products(state = {
    items: [],
    verpackungList: [],
    items_stock: [],
    items_stock_c: 0,
    items_stock_c_critical: 0,
    items_stock_out: 0,
    categories: [],
    single: {},
    single_sales: [],
    single_rank: [],
    single_ranking: [],
    single_rating: [],
    single_pricing: [],
    single_offers: [],
    single_gewinncalc: {},
    filter: default_product_filter,
    stock_einheiten: default_stock_einheiten
},action){
    switch (action.type) {
        case RECEIVE_PRODUCTS:
            return Object.assign({}, state, {
                items: action.items
            });
        case RECEIVE_VERPACKUNG_LIST:
            return Object.assign({}, state, {
                verpackungList: action.items
            });
        case RECEIVE_PRODUCTS_STOCK:
            return Object.assign({}, state, {
                items_stock: action.items
            });
        case RECEIVE_PRODUCTS_STOCK_COUNT:
            return Object.assign({}, state, {
                items_stock_c: action.count.sum,
                items_stock_c_critical: action.count.critical,
                items_stock_out: action.count.out_of_stock
            });
        case RECEIVE_PRODUCT_GEWINN:
            return Object.assign({}, state, {
                single_gewinncalc: action.data
            });
        case RECEIVE_PRODUCT_DETAIL:
            return Object.assign({}, state, {
                single: action.single
            });
        case RECEIVE_PRODUCT_RANKING:
            return Object.assign({}, state, {
                single_ranking: action.items
            });
        case RECEIVE_PRODUCT_OFFERS:
            return Object.assign({}, state, {
                single_offers: action.items
            });
        case RECEIVE_PRODUCT_GRAPH_SALES:
            return Object.assign({}, state, {
                single_sales: action.chart
            });
        case RECEIVE_PRODUCT_GRAPH_PRICING:
            return Object.assign({}, state, {
                single_pricing: action.chart
            });
        case RECEIVE_PRODUCT_GRAPH_RANK:
            return Object.assign({}, state, {
                single_rank: action.chart
            });
        case RECEIVE_PRODUCT_GRAPH_RATING:
            return Object.assign({}, state, {
                single_rating: action.chart
            });
        case RECEIVE_PRODUCT_CATEGORIES:
            return Object.assign({}, state, {
                categories: action.categories
            });
        case SET_PRODUCT_FILTER_STATUS:
            return Object.assign({}, state, {
                filter: {
                    ...state.filter,
                    status: action.val
                }
            });
        case SET_PRODUCT_FILTER_FULLFILMENT:
            return Object.assign({}, state, {
                filter: {
                    ...state.filter,
                    fullfilment: action.val
                }
            });
        case SET_PRODUCT_FILTER_CATEGORY:
            return Object.assign({}, state, {
                filter: {
                    ...state.filter,
                    category: action.val
                }
            });
        case SET_PRODUCT_FILTER_MARKETPLACE:
            return Object.assign({}, state, {
                filter: {
                    ...state.filter,
                    marketplace: action.val
                }
            });
        case SET_PRODUCT_FILTER_LIEFERANT:
            return Object.assign({}, state, {
                filter: {
                    ...state.filter,
                    lieferant: action.val
                }
            });
        case SET_PRODUCT_FILTER_PRODUCTTYPE:
            return Object.assign({}, state, {
                filter: {
                    ...state.filter,
                    producttype: action.val
                }
            });
        case SET_PRODUCT_FILTER_RESET:
            return Object.assign({}, state, {
                filter: default_product_filter
            });
        default:
            return state;
    }
}
function sales(state = {
    gewinn_items: null,
    data: null,
    mainchart: null,
    mainchart_loading: true,
    bestsellers: null,
    categorieschart: null,
    gewinn_detail_items: null,
    gewinn_detail_items_posten: null
},action){
    switch (action.type) {
        case RECIEVE_GEWINN_TABLE:
            return Object.assign({}, state, {
                gewinn_items: action.items
            });
        case RECIEVE_GEWINN_DETAIL_TABLE:
            return Object.assign({}, state, {
                gewinn_detail_items: action.items
            });
        case RECIEVE_GEWINN_DETAIL_POSTEN_TABLE:
            return Object.assign({}, state, {
                gewinn_detail_items_posten: action.items
            });
        case SAVE_GEWINN:
            return Object.assign({}, state, {
                ...state,
                gewinn_items: state.gewinn_items.map(item =>
                    item.id === action.item.id ? action.item : item
                )
            });
        case LOAD_SALES_DATA:
            return Object.assign({}, state, {
                data: action.result
            });
        case LOAD_SALES_MAINCHART:
            return Object.assign({}, state, {
                mainchart: action.result
            });
        case MAINCHART_LOADING:
            return Object.assign({}, state, {
                mainchart_loading: action.result
            });
        case LOAD_SALES_BESTSELLERS:
            return Object.assign({}, state, {
                bestsellers: action.result
            });
        case LOAD_SALES_CATEGORIESDATA:
            return Object.assign({}, state, {
                categorieschart: action.result
            });
        default:
            return state;
    }
}
function visuals(state = {
    backdrop: false,
    alerts: []
},action){
    switch (action.type) {
        case TOGGLE_BACKDROP:
            return Object.assign({},state,{
               backdrop: action.open
            });
        case ADD_ERROR_ITEM:
            return Object.assign({},state,{
                ...state,
                alerts: [...state.alerts,{
                    msg: action.msg,
                    code: action.code,
                    variant: action.variant,
                    open: true
                }]
            });
        case REMOVE_ERROR_ITEM:
            return Object.assign({},state, {
                ...state,
                alerts: state.alerts.map((error, index) => {
                    if (index === action.index) {
                        return Object.assign({}, error, {
                            open: false
                        })
                    }
                    return error
                })
            });
        default:
            return state;
    }

}
function lager(state = {
    lieferanten: [],
    bestellungen: [],
    filterStatus: null,
    filterProduct: null
},action){
    switch (action.type) {
        case RECIEVE_LIEFERANTEN:
            return Object.assign({}, state, {
                lieferanten: action.value
            });
        case RECIEVE_LAGER_BESTELLUNGEN:
            return Object.assign({}, state, {
                bestellungen: action.value
            });
        case SET_LAGER_BESTELLUNG_FILTER_STATUS:
            return Object.assign({}, state, {
                filterStatus: action.value
            });
        case SET_LAGER_BESTELLUNG_FILTER_PRODUCT:
            return Object.assign({}, state, {
                filterProduct: action.value
            });

        default:
            return state;
    }

}

const AppReducer = combineReducers({
    spions,
    account,
    visuals,
    products,
    ranking,
    sales,
    lager
});
export default AppReducer;

