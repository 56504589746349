import React from 'react';
import Container from "@material-ui/core/Container";
import BreadCrumb from "../HtmlComponents/BreadCrumb";
import {Helmet} from "react-helmet";
import makeTitle from "../Helper/makeTitle";
import BaseStyle, {DefaultPaper, GRAPH_PALETTE} from "../Layout/BaseStyle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InnerLoading from "../Helper/innerLoading";
import {ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis} from "recharts";
import DateRangePicker from "../HtmlComponents/DateRangePicker";
import {toggleBackdrop} from "../Actions/Actions";
import {connect} from "react-redux";
import {doLoadTrendData, receiveRankingTrend} from "../Actions/RankingActions";
import {TrendDisplay} from "../Helper/TrendDisplay";
import MiniTable from "../Helper/MiniTable";
import {NumberFormat} from "../Helper/NumberFormat";



const CustomTooltip = ({ active, payload, label,tmpdata }) => {
    if (active && payload) {
        return (
            <DefaultPaper>
                <Typography variant={"h5"}>
                    {label}
                </Typography>
                {payload.map((payl,index) => {
                    let trend_day = 0;
                    tmpdata.map((tmpindex) => {
                        if(tmpindex.date === payl.payload.date){
                            trend_day = tmpindex.trend;
                        }
                        return tmpindex;
                    });
                    return (<div>
                        <p style={{margin:0}}>
                            <span style={{fontSize:18,display:'inline-block',minWidth:50,paddingRight:5,fontWeight: "bold",color:GRAPH_PALETTE[0]}}>{trend_day > 0 && "+"}{trend_day}</span>&nbsp;
                            Keyword-Trend
                        </p>
                    </div>);
                })}
            </DefaultPaper>
        );
    }

    return null;
};

function RankingTrendPage(props){
    const basestyle = BaseStyle();

    const reloadTrendPage = () => {
        props.clearTrendData();
        props.loadTrendData();
    };

    let is_loaded = (typeof props.trend_data.chart !== "undefined");

    let trend = null;
    let trend_avg = null;
    let trend_avg_found_pos = null;
    let trend_avg_pos = null;
    if(is_loaded){
        trend = <TrendDisplay value={props.trend_data.stats.trend} reversed />;
        trend_avg = <TrendDisplay value={props.trend_data.stats.trend_avg} reversed />;
        trend_avg_found_pos = <TrendDisplay value={props.trend_data.stats.trend_found_pos} />;
        trend_avg_pos = <TrendDisplay value={props.trend_data.stats.trend_pos} />;
    }
    return (
        <Container maxWidth="xl">
            <Helmet>
                <title>{makeTitle('Ranking Sichtbarkeit')}</title>
            </Helmet>
            <BreadCrumb links={[
                {label: 'Ranking',url: "/ranking"},
                {label: 'Sichtbarkeit'}
            ]} help={"ranking"} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DateRangePicker onChange={reloadTrendPage} hide_grouping presets={["this_month","last_month","52_weeks","this_year","last_year","custom"]} />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DefaultPaper>
                        <Grid container direction={"column"} alignItems={"stretch"} style={{height:"100%"}}>
                            <Grid item>
                                <Typography variant={"h4"}>Sichtbarkeitstrend{is_loaded && (<span>: <b><NumberFormat value={props.trend_data.stats.last} /></b></span>)} {trend}</Typography>
                            </Grid>
                            <Grid item className={basestyle.grow}>
                                {!is_loaded && (
                                    <InnerLoading />)}
                                {is_loaded && (
                                    <ResponsiveContainer width="100%" height={300}>
                                        <ComposedChart data={props.trend_data.chart}>
                                            <Line connectNulls type="monotone" dataKey="overall" name="Trend"
                                                  dot={false} strokeWidth={3} stroke={GRAPH_PALETTE[0]}/>
                                            <XAxis dataKey="date" tickMargin={15} allowDuplicatedCategory={false} padding={{ left: 20, right: 20}} axisLine={false} />
                                            <Tooltip/>
                                        </ComposedChart>
                                    </ResponsiveContainer>
                                )}
                            </Grid>
                        </Grid>
                    </DefaultPaper>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    {is_loaded && (
                    <MiniTable columns={[
                        { title: "Typ", field: "type" },
                        {
                            title: "Wert",
                            field: "value",
                            render: rowData => {
                                return <NumberFormat value={rowData.value} />;
                            }
                        }
                    ]} data={[
                        {type: "Tief:",value: props.trend_data.stats.low},
                        {type: "Hoch:",value: props.trend_data.stats.high}
                    ]} />
                    )}
                </Grid>
                <Grid item xs={12} md={3}>
                    {is_loaded && (
                        <DefaultPaper style={{textAlign:"center"}}>
                            <Typography variant={"h1"}>{String.fromCharCode(216)} Sichtbarkeit</Typography>
                            <Typography variant={"h2"}><NumberFormat value={props.trend_data.stats.avg} /></Typography>
                            <div>{trend_avg}</div>
                            <br />
                            <Typography variant={"body1"}>im angegebenen Zeitraum</Typography>
                        </DefaultPaper>
                    )}
                </Grid>
                <Grid item xs={12} md={3}>
                    {is_loaded && (
                        <DefaultPaper style={{textAlign:"center"}}>
                            <Typography variant={"h1"}>{String.fromCharCode(216)} Position</Typography>
                            <Typography variant={"h2"}>{props.trend_data.stats.avg_found_pos}</Typography>
                            <div>{trend_avg_found_pos}</div>
                            <br />
                            <Typography variant={"body1"}>bei gefundenen Keywords</Typography>
                        </DefaultPaper>
                    )}
                </Grid>
                <Grid item xs={12} md={3}>
                    {is_loaded && (
                    <DefaultPaper style={{textAlign:"center"}}>
                        <Typography variant={"h1"}>{String.fromCharCode(216)} Position</Typography>
                        <Typography variant={"h2"}>{props.trend_data.stats.avg_pos}</Typography>
                        <div>{trend_avg_pos}</div>
                        <br />
                        <Typography variant={"body1"}>bei allen Keywords</Typography>
                    </DefaultPaper>
                    )}
                </Grid>
            </Grid>

        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        trend_data: state.ranking.trend_data
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    clearTrendData: () => dispatch(receiveRankingTrend({ result: {} })),
    loadTrendData: () => dispatch(doLoadTrendData()),
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RankingTrendPage);