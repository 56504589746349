import React from 'react';
import Container from "@material-ui/core/Container";
import BreadCrumb from "../HtmlComponents/BreadCrumb";
import TableProducts from "../HtmlComponents/TableProducts";
import {Helmet} from "react-helmet";
import makeTitle from "../Helper/makeTitle";

function LagerPage(){
    return (
        <Container maxWidth="xl">
            <Helmet>
                <title>{makeTitle('Lager')}</title>
            </Helmet>
            <BreadCrumb links={[
                {label: 'Lager'}
            ]} help={"lager"} />
            <TableProducts variant={"lager"} />
        </Container>
    );
}
export default LagerPage;