import React from 'react';
import MaterialTable from "material-table";

import BaseStyle from "../Layout/BaseStyle";
import { connect } from 'react-redux';
import {
    toggleBackdrop
} from "../Actions/Actions";
import useMountEffect from "../Helper/MountEffect";
import {withRouter} from 'react-router-dom'
import tableIcons from "../Layout/TableIcons";
import Grid from "@material-ui/core/Grid";
import {CircularProgress} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {doFetchRankingProducts} from "../Actions/RankingActions";
import {TrendDisplay} from "../Helper/TrendDisplay";
import {Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import AddBtnAlarm from "./AddBtnAlarm";
import AddAlertIcon from '@material-ui/icons/AddAlert';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';

function TableRankingDetail(props){
    const basestyle = BaseStyle();
    const [dialog,setDialog] = React.useState(null);

    const dofetch = () => {
        props.loadRankingProducts(props.rankingId);
        setDialog(null);
    };
    //run once
    useMountEffect(() => {
        dofetch();
    });
    const handleDetailOpen = (id) => {
        props.history.push('/products/'+id);
    };
    const handleCreateAlarm = (kw,product) => {
        setDialog(<AddBtnAlarm keyword={kw} product={product} direct={"create"} onAbort={() => setDialog(null)} onCreate={() => dofetch()} />);
    };
    const handleDeleteAlarm = (kw,product) => {
        setDialog(<AddBtnAlarm keyword={kw} product={product} direct={"delete"} onAbort={() => setDialog(null)} onDelete={() => dofetch()} />);
    };

    /*let title_app = "";
    if(props.ranking_loaded === 0){
        title_app = <Typography variant={"subtitle2"} className={basestyle.smallLoading}><CircularProgress size={14} /> Laden...</Typography>;
    }*/
    let title = (<span><Typography>Produkte</Typography></span>);

    return (
        <Grid container>
            <Grid item xs={12}>
                {dialog}
                {props.getProducts === false && (<div style={{textAlign:"center"}}><CircularProgress color={"secondary"} /></div>)}
                {(props.getProducts && props.getProducts.length == 0) && (<div style={{textAlign:"center"}}>Bisher wurden keine Produkte zu diesem Keyword gefunden!</div>)}

                {(props.getProducts && props.getProducts.length > 0) && (
                    <MaterialTable title={title} icons={tableIcons} columns={[
                        {
                            title: "Bild",
                            field: "pic",
                            sorting: false,
                            cellStyle: { paddingTop:0,paddingBottom:0,textAlign: "center" },
                            export: false,
                            render: rowData => <img src={rowData.pic} alt={"Produktbild"} className={basestyle.TableImage}/>
                        },
                        { title: "ASIN", field: "asin" },
                        { title: "SKU", field: "sku" },
                        { title: "Produkt", field: "title" },
                        {
                            title: "Position",
                            field: "pos",
                            type: "numeric",
                            defaultSort: "asc",
                            customSort: (a, b) => {
                                return ((a.pos==null)?10000:a.pos) - ((b.pos==null)?10000:b.pos);
                            },
                            render: rowData => {
                                return (
                                    <Grid container spacing={3} justify="flex-end">
                                        <Grid item>
                                            <Typography variant={"h5"}>{rowData.pos}</Typography>
                                        </Grid>
                                        <Grid item >
                                            <TrendDisplay value={rowData.trend} />
                                        </Grid>
                                    </Grid>
                                );
                            }
                        },
                        {
                            title: "Verlauf (30T)",
                            field: "position_history",
                            cellStyle: { paddingTop:0,paddingBottom:0 },
                            sorting: false,
                            render: rowData => {
                                return (
                                    <ResponsiveContainer width={150} height={45}>
                                        <AreaChart
                                            data={rowData.position_history}
                                            margin={{
                                                top: 5, right: 0, left: 0, bottom: 5,
                                            }}
                                            className={basestyle.GraphAreaFloatRight}
                                        >
                                            <Area type="monotone" dataKey="value" name={"Position"} stroke="#8884d8" fill="#8884d8" connectNulls />
                                            <YAxis hide={true} reversed />
                                            <XAxis hide={true} dataKey="date" />
                                            <Tooltip />
                                        </AreaChart>
                                    </ResponsiveContainer>
                                );
                            }
                        }
                    ]}
                                   data={props.getProducts}
                                   actions={[
                                       rowData => ({
                                           icon: () => {
                                               if(rowData.alarm_status===0){
                                                   return <AddAlertIcon />;
                                               }else{
                                                   return <NotificationsOffIcon color={"secondary"} />;
                                               };
                                           },
                                           tooltip: ((rowData.alarm_status===0)?'Alarm aktivieren':'Alarm löschen'),
                                           onClick: (event, rowData) => {
                                               if(rowData.alarm_status===0) {
                                                   handleCreateAlarm(props.rankingId, rowData.id);
                                               }else{
                                                   handleDeleteAlarm(props.rankingId, rowData.id);
                                               }
                                           }
                                       }),
                                   ]}
                                   onRowClick={(event,rowData) => {
                                       handleDetailOpen(rowData.id);
                                   }}
                                   options={{
                                       actionsColumnIndex: 0,
                                       cellStyle: {
                                           fontSize: 12
                                       }
                   }}/>
                )}
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        getProducts: state.ranking.single_products
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    loadRankingProducts: (id) => dispatch(doFetchRankingProducts(id))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TableRankingDetail))