import React from 'react';
import {DefaultPaper, GRAPH_PALETTE, GRAPH_PALETTE_LIGHT} from "../Layout/BaseStyle";
import { connect } from 'react-redux';
import {toggleBackdrop} from "../Actions/Actions";
import useMountEffect from "../Helper/MountEffect";
import {
    AreaChart,
    CartesianGrid,
    ComposedChart,
    Line,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    Area
} from "recharts";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {doFetchProductGraphRating} from "../Actions/ProductActions";
import {NumberFormat} from "../Helper/NumberFormat";


const CustomTooltip = ({ active, payload, label,tmpdata }) => {
    if (active && payload) {
        return (
            <DefaultPaper>
                <Typography variant={"h5"}>
                    {label}
                </Typography>
                {payload.map((payl,index) => {
                    let rating_avg = 0;
                    tmpdata.map((tmpindex) => {
                       if(tmpindex.date === payl.payload.date){
                           rating_avg = tmpindex.rating_avg;
                       }
                       return tmpindex;
                    });
                    return (<div>
                        <p style={{margin:0}}><span style={{fontSize:18,display:'inline-block',minWidth:50,paddingRight:5,fontWeight: "bold",color:GRAPH_PALETTE[index]}}>{payl.value}</span>{payl.name}</p>
                        {/*<p style={{margin:0}}><span style={{fontSize:18,display:'inline-block',minWidth:50,paddingRight:5,fontWeight: "bold",color:GRAPH_PALETTE[5]}}><NumberFormat value={rating_avg} /></span>Bewertung</p>*/}
                    </div>);
                })}
            </DefaultPaper>
        );
    }

    return null;
};

const toPercent = (decimal, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`;
function GraphProductDetailRating(props){

    //run once
    useMountEffect(() => {
        props.fetchRatingGraph(props.productId);
    });

    const data = (props.graphlines.length > 0)? props.graphlines : [];

    /*<DefaultPaper>
            <Grid container justify="space-between">
                <Grid item md={6}>
                    <Typography variant={"h6"}>
                        Verkäufe und Umsatz
                    </Typography>
                </Grid>
            </Grid>
            <div className={basestyle.spacer} />
        </DefaultPaper>*/

    return (
            <Grid container alignItems="stretch" style={{height:'100%'}}>
                <Grid item xs={12} md={6}>
                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart data={data}>
                            <CartesianGrid stroke="#eee" />
                            <Line connectNulls type="monotone" dataKey="rating_count" name="Anzahl Bewertungen"
                                  dot={false} strokeWidth={3} stroke={GRAPH_PALETTE[0]}/>
                            <XAxis dataKey="date" tickMargin={10} allowDuplicatedCategory={false} padding={{ left: 10, right: 10}} />
                            {/*<YAxis yAxisId="left" label={{ value: 'pv of page', offset:20, position: 'insideTopLeft' }} dataKey="rating_avg" domain={[0,5]} padding={{ top: 30 }} />*/}
                            <Tooltip offset={50} content={<CustomTooltip tmpdata={data} />} />
                            <YAxis dataKey="rating_count" tickLine={false} tickMargin={10} padding={{ top: 30 }} />
                        </ComposedChart>
                    </ResponsiveContainer>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ResponsiveContainer width="100%" height="100%">
                        <AreaChart data={data} stackOffset="expand">
                            <CartesianGrid stroke="#eee" />
                            <Area connectNulls={true} type="monotone" dataKey="rating_1" unit="%" name="1 Stern" stackId="1" stroke={GRAPH_PALETTE_LIGHT[4]} fill={GRAPH_PALETTE_LIGHT[4]} />
                            <Area connectNulls={true} type="monotone" dataKey="rating_2" unit="%" name="2 Sterne" stackId="1" stroke={GRAPH_PALETTE_LIGHT[3]} fill={GRAPH_PALETTE_LIGHT[3]} />
                            <Area connectNulls={true} type="monotone" dataKey="rating_3" unit="%" name="3 Sterne" stackId="1" stroke={GRAPH_PALETTE_LIGHT[2]} fill={GRAPH_PALETTE_LIGHT[2]} />
                            <Area connectNulls={true} type="monotone" dataKey="rating_4" unit="%" name="4 Sterne" stackId="1" stroke={GRAPH_PALETTE_LIGHT[1]} fill={GRAPH_PALETTE_LIGHT[1]} />
                            <Area connectNulls={true} type="monotone" dataKey="rating_5" unit="%" name="5 Sterne" stackId="1" stroke={GRAPH_PALETTE_LIGHT[0]} fill={GRAPH_PALETTE_LIGHT[0]} />
                            <XAxis dataKey="date" allowDuplicatedCategory={false}/>
                            <Tooltip itemSorter={(item) => {return -parseInt(item.name.substring(0,1));}} />
                            <YAxis tickFormatter={toPercent} />
                        </AreaChart>
                    </ResponsiveContainer>
                </Grid>
            </Grid>
    );
}
/*
 */

const mapStateToProps = (state) => {
    return {
        graphlines: state.products.single_rating
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    fetchRatingGraph: (id) => dispatch(doFetchProductGraphRating(id))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GraphProductDetailRating)