import React from "react";
import CurrencyFormat from 'react-currency-format';

export const EuroFormat = (prop) => {
  let decimal = 2;
  if(prop.value === null) prop.value = 0;
  if(typeof prop.decimal !== "undefined") decimal = prop.decimal;
  return (<CurrencyFormat value={prop.value} displayType={'text'} thousandSeparator={"."} suffix={' €'}
                          decimalScale={decimal} fixedDecimalScale={true}
                          decimalSeparator={prop.decimal === 0?"":","}/>);
};
export const PercentageFormat = (prop) => {
  return (<CurrencyFormat value={prop.value} displayType={'text'} suffix={' %'} decimalScale={1} fixedDecimalScale={true}  decimalSeparator={","} />);
};

export const FromEuroOutput = (val) => {
  if(val == null || typeof val !== "string") {
    if (!isNaN(val)) {
      return val;//wenn schon nummer ist
    }
    return 0;
  }else if(val.match(/^[0-9]*\.[0-9][0-9]$/i) !== null){//englische schreibweise
    return parseFloat(val);
  }else if(val.match(/[0-9]|,./i) !== null && val.match(/[A-Z]/i) === null){
    let val2 = val.replace(".", "").replace(".", "").replace(".", "").replace(".", "");
    val2 = val2.replace(",", ".");
    val2 = val2.replace(",", "").replace(",", "").replace(",", "");
    val2 = parseFloat(val2);

    return val2;
  }else{
    return NaN;
  }
};
export const ToEuroOutput = (val,decimals,thousand_seperator) => {
  let val2 = parseFloat(val);
  let dec = 2;
  if(typeof decimals !== "undefined") dec = decimals;
  if(typeof thousand_seperator !== "undefined" && thousand_seperator===false){
    return formatMoney(val2,dec,",","");
  }
  return formatMoney(val2,dec,",",".");
};
export const formatMoney = (a,c,d,t) => {
  let n = a;
  c = isNaN(c = Math.abs(c)) ? 2 : c;
  d = d === undefined ? "." : d;
  t = t === undefined ? "," : t;
  let s = n < 0 ? "-" : "";
  let i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "";
  let j = i.length;
  j = (i.length) > 3 ? j % 3 : 0;
  return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};

export const parseNumberText = (input) => {
  if(typeof input === "number") return input;
  if(typeof input !== "string") return null;
  let newval = input.replace(".","");
  newval = newval.replace(",",".");
  newval = parseFloat(newval);
  return newval;
};