import React from 'react';
import Grid from "@material-ui/core/Grid";
import {doSaveTutorial, toggleBackdrop} from "../Actions/Actions";
import {connect} from "react-redux";
import BaseStyle, {DefaultPaper} from "../Layout/BaseStyle";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from '@material-ui/icons/Clear';
import ConfirmDialog from "../Helper/ConfirmDialog";
import {Helmet} from "react-helmet";
import makeTitle from "../Helper/makeTitle";

function TutorialPage(props){
    const basestyle = BaseStyle();
    const [dialog,setDialog] = React.useState(null);
    const [formdata,setFormdata] = React.useState({ vktoken:null,mwstoken:null });
    const [haserror,setError] = React.useState({ vktoken:false,mwstoken:false });

    const handleVKIDChange = (e) => {
        let newval = e.target.value.trim();
        setFormdata({...formdata, vktoken: newval});
        setError({ ...haserror,vktoken:false});
        if(newval.length < 12 && newval.length > 1) {
            setError({ ...haserror,vktoken:true});
        }
    };
    const handleTokenChange = (e) => {
        let newval = e.target.value.trim();
        setFormdata({ ...formdata,mwstoken:newval });
        setError({ ...haserror,mwstoken:false});
        if((newval.length !== 45 && newval.length > 1) || newval.substr(0,9) !== "amzn.mws.") {
            setError({ ...haserror,mwstoken:true});
        }
    };
    const handleSaveClick = (event) => {
        props.saveTutorial(false,formdata.vktoken,formdata.mwstoken);
    };
    const handleSkipClick = (event) => {
        setDialog(<ConfirmDialog title={"Einrichtung überspringen?"}
                                 text={"Wenn Sie die Einrichtung überspringen, können Sie viele Funktionen nicht nutzen. Bitte holen Sie diesen Schritt zeitnah über Ihren Account-Bereich nach."}
                                 onClose={()=>setDialog(null)}
                                 onClick={e=>handleSkipConfirm(e)} />);
    };
    const handleSkipConfirm = (event) => {
        props.saveTutorial(true,null,null);
        setDialog(null);
    };
    return (
        <Grid container spacing={3} direction="row-reverse">
            <Grid item xs={12} md={8}>
                <Helmet>
                    <title>{makeTitle('Einrichtung')}</title>
                </Helmet>
                {dialog}
                <DefaultPaper>
                    <iframe title={"Einrichtung Hilfevideo"} width="100%" style={{maxWidth:1066}} height="600" src="https://www.youtube.com/embed/8HDCy06ralQ" frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen />{/*?autoplay=1&controls=0&loop=1&modestbranding*/}
                </DefaultPaper>
            </Grid>
            <Grid item xs={12} md={4}>
                <DefaultPaper>
                    <Typography variant={"h1"}>Schnittstelle einrichten</Typography>
                    <p>Bitte registrieren Sie Sellerava als Anwendung in Ihrem Sellercentral-Konto. Folgen Sie dazu der Anleitung in dem Video (für Vollbild doppelklicken).</p>
                    <p>Wir benötigen für die Auswertungen Zugriff auf Ihre Produkte und Bestellungen. Wir erhalten <u>keine</u> Personenbezogenen Daten. Wir gehen sensibel mit den erhaltenen Daten um.</p>
                    <div className={basestyle.spacer} />
                    <Typography variant={"h5"}>1. Anwendung registrieren</Typography>
                    <p>Bitte verfahren Sie wie im Video. Nutzen Sie folgende Entwicklerkennung:</p>
                    <p>Name des Entwicklers: <b>Sellerava</b></p>
                    <p>Entwickler-ID: <b>446074736767</b></p>
                    <div className={basestyle.spacer} />
                    <Typography variant={"h5"}>2. API-Schlüssel eingeben</Typography>
                    <div className={basestyle.spacer} />
                    <FormGroup row>
                        <TextField
                            error={haserror.vktoken}
                            helperText={(haserror.vktoken)?'Ein Verkäufer-Token besteht aus mindestens 12 Zeichen':null}
                            id="vktoken"
                            label="Ihre Verkäufer-ID / Ihr Verkäufertoken"
                            onChange={handleVKIDChange}
                            variant="outlined"
                            fullWidth
                        />
                    </FormGroup>
                    <div className={basestyle.spacer} />
                    <FormGroup row>
                        <TextField
                            error={haserror.mwstoken}
                            helperText={(haserror.mwstoken)?'Ein MWS-Token besteht aus 45 Zeichen und beginnt mit "amzn.mws."':null}
                            id="mwstoken"
                            label="Token für die MWS-Authorisierung"
                            onChange={handleTokenChange}
                            variant="outlined"
                            fullWidth
                        />
                    </FormGroup>
                    <div className={basestyle.spacer} />
                    <Grid container justify="flex-end" spacing={2}>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="default"
                                size="small"
                                startIcon={<ClearIcon />}
                                onClick={handleSkipClick}
                            >
                                Überspringen
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                startIcon={<SaveIcon />}
                                onClick={handleSaveClick}
                            >
                                Speichern
                            </Button>
                        </Grid>
                    </Grid>
                </DefaultPaper>

            </Grid>
        </Grid>
    );
}


const mapStateToProps = (state) => {
    return {
        user: state.account.user
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    saveTutorial: (skip,vktoken,mwstoken) => dispatch(doSaveTutorial(skip,vktoken,mwstoken))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TutorialPage);