import React from 'react';
import Container from "@material-ui/core/Container";
import { toggleBackdrop} from "../Actions/Actions";
import {connect} from "react-redux";
import BaseStyle, {DefaultPaper, DefaultPaperAuto, GRAPH_PALETTE, GRAPH_PALETTE_4} from "../Layout/BaseStyle";
import useMountEffect from "../Helper/MountEffect";
import Grid from "@material-ui/core/Grid";
import BreadCrumb from "../HtmlComponents/BreadCrumb";
import DateRangePicker from "../HtmlComponents/DateRangePicker";
import {
    doLoadCategoriesData,
    doLoadSalesBestsellers,
    doLoadSalesData,
    doLoadSalesMainGraph
} from "../Actions/SalesActions";
import Typography from "@material-ui/core/Typography";
import {EuroFormat, ToEuroOutput} from "../Helper/CurrencyFormat";
import {TrendDisplay} from "../Helper/TrendDisplay";
import MaterialTable from "material-table";
import tableIcons from "../Layout/TableIcons";
import {
    Bar,
    BarChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import {CircularProgress} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";
import Link from "@material-ui/core/Link";
import {Helmet} from "react-helmet";
import makeTitle from "../Helper/makeTitle";
import GraphSales from "../HtmlComponents/GraphSales";


const CustomTooltip = ({ active, payload, label,tmpdata }) => {
    if (active && payload) {
        return (
            <DefaultPaper>
                <Typography variant={"h5"}>
                    {label}
                </Typography>
                {payload.map((payl,index) => {
                    let umsatz = 0;
                    let gewinn = 0;
                    tmpdata.map((tmpindex) => {
                        if(tmpindex.date === payl.payload.date){
                            umsatz = tmpindex.umsatz;
                            gewinn = tmpindex.gewinn;
                        }
                        return tmpindex;
                    });
                    return (<div>
                        <p style={{margin:0}}>
                            <span style={{fontSize:18,display:'inline-block',minWidth:50,paddingRight:5,fontWeight: "bold",color:GRAPH_PALETTE_4[index]}}>
                                {payl.value}
                            </span>
                            {payl.name}
                        </p>
                        {index === 1 && (
                            <div>
                                <p style={{margin:0}}><span style={{fontSize:18,display:'inline-block',minWidth:50,paddingRight:5,fontWeight: "bold",color:GRAPH_PALETTE_4[2]}}><EuroFormat value={umsatz} /></span>Umsatz</p>
                                <p style={{margin:0}}><span style={{fontSize:18,display:'inline-block',minWidth:50,paddingRight:5,fontWeight: "bold",color:GRAPH_PALETTE_4[3]}}><EuroFormat value={gewinn} /></span>Gewinn</p>
                            </div>
                        )}
                    </div>);
                })}

            </DefaultPaper>
        );
    }

    return null;
};
const CustomTooltipCategories = ({ active, payload, label,tmpdata }) => {
    if (active && payload) {
        return (
            <DefaultPaper>
                <Typography variant={"h5"}>
                    {label}
                </Typography>
                {payload.map((payl,index) => {
                    let out_nr = payl.value;
                    if(payl.dataKey === "umsatz" || payl.dataKey === "gewinn"){
                        out_nr = <EuroFormat value={payl.value} />;
                    }
                    return (<div>
                        <p style={{margin:0}}>
                            <span style={{fontSize:18,display:'inline-block',minWidth:50,paddingRight:5,fontWeight: "bold",color:GRAPH_PALETTE[index]}}>
                                {out_nr}
                            </span>
                            {payl.name}
                        </p>
                    </div>);
                })}

            </DefaultPaper>
        );
    }

    return null;
};
const Tick = ({
                                  payload: { value },
                                  verticalAnchor,
                                  visibleTicksCount,
                                  ...rest
                              }) => (
    <text {...rest} className="bar-chart-tick" dy={12}>
        {value}
    </text>
);

function SalesDashboardPage(props){
    const basestyle = BaseStyle();
    const [changed,setChanged] = React.useState(5);

    const handleDetailOpen = (id) => {
        props.history.push('/products/'+id);
    };

    //run once
    useMountEffect(() => {
        //props.toggleBackdrop(true);
        //reloadSalesDashboard();
    });
    const reloadSalesDashboard = () => {
        props.loadDashboardData();
        props.loadDashboardBestsellers();
        props.loadCategoriesData();
        setChanged(Math.random());
    };

    let is_loading = false;
    //let is_loading_mainchart = false;
    let is_loading_bestsellers = false;
    let is_loading_categories = false;
    if(props.sales_data === null) is_loading = true;
    //if(props.sales_mainchart === null) is_loading_mainchart = true;
    if(props.sales_bestsellers === null) is_loading_bestsellers = true;
    if(props.sales_categories === null) is_loading_categories = true;

    return (
        <Container maxWidth="xl">
            <Helmet>
                <title>{makeTitle("Verkäufe")}</title>
            </Helmet>
            <BreadCrumb links={[
                {label: 'Verkäufe'}
            ]} help={"sales"} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DateRangePicker onChange={reloadSalesDashboard}/>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DefaultPaper>
                        <Typography variant={"h5"}>Ihre Verkaufszahlen</Typography>
                        <GraphSales changed={changed} />
                    </DefaultPaper>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                    <DefaultPaperAuto>
                        {is_loading && (<Typography variant={"h3"}><CircularProgress /></Typography>)}
                        {!is_loading && (
                            <div>
                                <TrendDisplay className={basestyle.trend_abs_bottomright} value={props.sales_data.diff_count} append={"%"} reversed />
                                <Typography variant={"h3"} className={basestyle.marginBottom5}>{props.sales_data.count}</Typography>
                            </div>
                        )}
                        <Typography variant={"subtitle2"}>Bestellungen</Typography>
                    </DefaultPaperAuto>
                </Grid>
                <Grid item md={3} xs={12}>
                    <DefaultPaperAuto>
                        {is_loading && (<Typography variant={"h3"}><CircularProgress /></Typography>)}
                        {!is_loading && (
                            <div>
                                <TrendDisplay className={basestyle.trend_abs_bottomright} value={props.sales_data.diff_umsatz} append={"%"} reversed />
                                <Typography variant={"h3"} className={basestyle.marginBottom5}><EuroFormat value={props.sales_data.umsatz} decimal={0} /></Typography>
                            </div>
                        )}
                        <Typography variant={"subtitle2"}>Umsatz</Typography>
                    </DefaultPaperAuto>
                </Grid>
                <Grid item md={3} xs={12}>
                    <DefaultPaperAuto>
                        {is_loading && (<Typography variant={"h3"}><CircularProgress /></Typography>)}
                        {!is_loading && (
                            <div>
                                <TrendDisplay className={basestyle.trend_abs_bottomright} value={props.sales_data.diff_gewinn} append={"%"} reversed />
                                <Typography variant={"h3"} className={basestyle.marginBottom5}><EuroFormat value={props.sales_data.gewinn} decimal={0} /></Typography>
                            </div>
                        )}
                        <Typography variant={"subtitle2"}>Gewinn (<Link component={RouterLink} to={"/sales/gewinn"} className={basestyle.visibleLink}>Details</Link>)</Typography>
                    </DefaultPaperAuto>
                </Grid>
                <Grid item md={3} xs={12}>
                    <DefaultPaperAuto>
                        {is_loading && (<Typography variant={"h2"}><CircularProgress /></Typography>)}
                        {!is_loading && (
                            <div>
                                <TrendDisplay className={basestyle.trend_abs_bottomright} value={props.sales_data.diff_einheiten} append={"%"} reversed />
                                <Typography variant={"h3"} className={basestyle.marginBottom5}>{props.sales_data.einheiten}</Typography>
                            </div>
                        )}
                        <Typography variant={"subtitle2"}>Einheiten</Typography>
                    </DefaultPaperAuto>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {is_loading_bestsellers && (<DefaultPaper><Typography variant={"h2"} style={{textAlign:"center"}}><CircularProgress /></Typography></DefaultPaper>)}
                    {!is_loading_bestsellers && (
                        <MaterialTable title={"Meist verkaufte Produkte"} icons={tableIcons} columns={[
                            {
                                title: "Bild",
                                field: "pic",
                                sorting: false,
                                cellStyle: { paddingTop:0,paddingBottom:0,textAlign: "center" },
                                export: false,
                                render: rowData => <img src={rowData.pic} alt={"Produktbild"} className={basestyle.TableImage}/>
                            },
                            { title: "ASIN", field: "asin" },
                            { title: "SKU", field: "sku" },
                            { title: "Produkt", field: "title" },
                            { title: "Verkäufe", field: "count" },
                            { title: "Umsatz", field: "sum",type: "currency",currencySetting: { locale: 'de', currencyCode: "EUR" } }
                        ]}
                       data={props.sales_bestsellers}
                       onRowClick={(event,rowData) => {
                           handleDetailOpen(rowData.id);
                       }}
                       options={{
                           pageSize: 10,
                           paging: false,
                           search: false,
                           cellStyle: {
                               fontSize: 12
                           }
                       }}
                        />
                    )}
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DefaultPaper>
                        <Typography variant={"h5"}>Verkaufszahlen nach Kategorie</Typography>
                        {is_loading_categories && (<Typography variant={"h2"} style={{textAlign:"center"}}><CircularProgress /></Typography>)}
                        {!is_loading_categories && (
                            <ResponsiveContainer width="100%" height={400}>
                                <BarChart data={props.sales_categories}>
                                    <CartesianGrid stroke="#eee" />
                                    <XAxis dataKey="name" tickMargin={0} interval={0} padding={{ left: 30, right: 30}} />
                                    <YAxis
                                        yAxisId="left"
                                        orientation="left"
                                        interval={1}
                                        tickMargin={10}
                                        scale={"sqrt"}
                                        domain={[0,'auto']}
                                        padding={{ top: 30 }} />
                                    <YAxis
                                        yAxisId="right"
                                        orientation="right"
                                        tickLine={false}
                                        scale={"sqrt"}
                                        tickFormatter={val => {return ToEuroOutput(val);}}
                                        domain={[0,'auto']}
                                        tickMargin={10}
                                        padding={{ top: 30 }} />
                                    <Tooltip content={<CustomTooltipCategories />} offset={50} />
                                    <Bar yAxisId="left" dataKey="einheiten" name="Verkaufte Artikel" fill={GRAPH_PALETTE[2]} />
                                    <Bar yAxisId="right" dataKey="umsatz" name="Umsatz" fill={GRAPH_PALETTE[0]} unit={" €"} />
                                    <Bar yAxisId="right" dataKey="gewinn" name="Gewinn" fill={GRAPH_PALETTE[4]} />
                                </BarChart>
                                {/*<BarChart data={props.sales_categories}>
                                    <CartesianGrid stroke="#eee" />
                                    <XAxis dataKey="name" padding={{ left: 30, right: 30}} />
                                    <YAxis/>
                                    <Tooltip/>
                                    <Bar dataKey="einheiten" />
                                </BarChart>*/}
                            </ResponsiveContainer>
                        )}
                    </DefaultPaper>
                </Grid>
            </Grid>
        </Container>
    );
}


const mapStateToProps = (state) => {
    return {
        sales_data: state.sales.data,
        sales_bestsellers: state.sales.bestsellers,
        sales_mainchart: state.sales.mainchart,
        sales_categories: state.sales.categorieschart
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    loadDashboardData: () => dispatch(doLoadSalesData()),
    loadDashboardMainGraph: () => dispatch(doLoadSalesMainGraph()),
    loadDashboardBestsellers: () => dispatch(doLoadSalesBestsellers()),
    loadCategoriesData: () => dispatch(doLoadCategoriesData())
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SalesDashboardPage);