import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import BaseStyle from "../Layout/BaseStyle";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import {InputLabel} from "@material-ui/core";
import useMountEffect from "./MountEffect";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import {addErrorItem, toggleBackdrop} from "../Actions/Actions";
import {connect} from "react-redux";
import {Link as RouterLink, withRouter} from "react-router-dom";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {parseNumberText} from "./CurrencyFormat";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const LagerBestellungDialog = (props) => {
    const basestyle = BaseStyle();
    const [newEntry,setNewEntry] = React.useState(true);
    const [form,setForm] = React.useState({
        BestellID: null,
        ReferenzNr: "",
        ProductID: 0,
        Menge: "",
        Arrival: null,
        Notes: "",
        Status: 1000,
        LieferantID: null,
        fillStock: false
    });

    const Change = (field,e) => {
        let val = e;
        if(e !== null){ if(typeof e.target !== "undefined"){ val = e.target.value; } }
        setForm({
            ...form,
            [field]: val
        });
        if(field === "LieferantID"){
            let liefertage = 1;
            let lieferant = props.getLieferanten.filter((s) => s.LieferantID===val);
            if(lieferant.length === 1){
                liefertage = lieferant[0].Lieferzeit;
            }
            setForm({
                ...form,
                [field]: val,
                Arrival: (new Date().addDays(parseInt(liefertage)))
            })
        }
    };
    const handleSave = () => {
        let valid = true;
        if(form.ReferenzNr.length < 2) valid = false;
        let menge = parseNumberText(form.Menge);
        if(isNaN(menge) || menge < 0) valid = false;
        if(form.Arrival == null) valid = false;

        if(valid){
            props.save({...form,Menge: menge});
        }else{
            props.addError("Bitte kontrollieren Sie die Pflichtfelder auf Vollständigkeit!");
        }

    };

    useMountEffect(() => {
           if(typeof props.data !== "undefined" && props.data !== null){
               setForm(props.data);
               setNewEntry(false);
           }else if(typeof props.productid !== "undefined" && props.productid !== null){
               setForm({
                   ...form,
                   ProductID: props.productid
               });
               Change("LieferantID",props.defaultLieferant);
           }
    });

    let lieferanten_liste = props.getLieferanten;
    if(newEntry){
        lieferanten_liste = lieferanten_liste.filter((e) => e.Status !== "1");
    }

    return (
        <Dialog open={true} onClose={props.abort} maxWidth={"md"} fullWidth>
            <DialogTitle>Bestellung (Lieferung) {newEntry && "hinzufügen"}{!newEntry && "bearbeiten"}</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="bestellung-status-label">Status</InputLabel>
                            <Select
                                required
                                labelId="bestellung-status-label"
                                value={form.Status}
                                onChange={(e) => Change("Status",e)}
                            >
                                {props.statuslist.map((item) => (
                                    <MenuItem key={item[0]} value={item[0]}>
                                        {item[1]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        {(!newEntry && window.location.href.indexOf("products/") === -1) && (
                            <div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    component={RouterLink}
                                    to={"/products/"+form.ProductID}
                                >
                                    Produkt anzeigen
                                </Button>
                            </div>
                        )}
                    </Grid>
                    <Grid item md={6}>
                        {(form.Status===1001 && props.data.Status!=1001) && (
                        <FormControlLabel control={<Switch checked={form.fillStock} onChange={(e) => setForm({...form,fillStock:e.target.checked})} />} label="Lager des Verpackungsmaterials auffüllen" />
                        )}
                    </Grid>
                </Grid>
                <div className={basestyle.spacer} />
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <TextField
                            required
                            value={form.ReferenzNr}
                            onChange={(e) => Change("ReferenzNr",e)}
                            autoFocus
                            label="Referenz-Nr (z.B. Auftragsnummer)"
                            type="text"
                            fullWidth
                            disabled={!newEntry}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            required
                            value={form.Menge}
                            onChange={(e) => Change("Menge",e)}
                            label="Menge"
                            type="text"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="bestellung-lieferant-label">Lieferant *</InputLabel>
                            <Select
                                required
                                labelId="bestellung-lieferant-label"
                                value={form.LieferantID}
                                onChange={(e) => Change("LieferantID",e)}
                                disabled={!newEntry}
                            >
                                {lieferanten_liste.map((item) => (
                                    <MenuItem key={item["LieferantID"]} value={item.LieferantID}>
                                        {item.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <FormControl fullWidth>
                                <DatePicker
                                    required
                                    autoOk
                                    format={"dd.MM.yyyy"}
                                    label="Vorrauss. Ankunft / Lieferzeitpunkt"
                                    views={["year", "month","date"]}
                                    openTo={"date"}
                                    value={form.Arrival}
                                    onChange={(e) => Change("Arrival",e)}
                                    animateYearScrolling
                                />
                            </FormControl>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={12}>
                            <TextField
                                value={form.Notes}
                                onChange={(e) => Change("Notes",e)}
                                label="Anmerkungen"
                                type="text"
                                fullWidth
                                rows={5}
                                multiline
                            />
                    </Grid>
                </Grid>
                <div className={basestyle.spacer} />
                <Typography variant={"subtitle2"}>* Pflichtfelder</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.abort} color="primary">
                    Abbrechen
                </Button>
                <Button onClick={handleSave} color="primary">
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>
    );
};
const mapStateToProps = (state) => {
    return {
        statuslist: state.account.user.StockOrderStatusList,
        getLieferanten: state.lager.lieferanten
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    addError: (msg,code) => dispatch(addErrorItem(msg,code))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(LagerBestellungDialog))