import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import BaseStyle from "../Layout/BaseStyle";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import { connect } from 'react-redux';
import {
    addErrorItem,
    addWarningItem,
    toggleBackdrop
} from "../Actions/Actions";
import useMountEffect from "../Helper/MountEffect";
import {doAddKeyword, doFetchKeywords, doFetchRankingPoints} from "../Actions/RankingActions";
import NotificationsIcon from '@material-ui/icons/Notifications';
import {withRouter} from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";


function AddBtnRanking(props){
    const basestyle = BaseStyle();
    const [open, setOpen] = React.useState(false);
    const [formdata, setFormdata] = React.useState({
        marketplace: "de",
        keyword: ""
    });
    //const [keywordhaserror,setKeywordError] = React.useState(false);
    //const [marketplacehaserror,setMarketplaceError] = React.useState(false);


    const handleClickOpen = () => {
        setFormdata({...formdata,keyword:""});
        if(props.getFreePoints > 0) {
            setOpen(true);
        }else{
            props.addError("Sie können keine weiteren Keywords anlegen!");
        }
        //const unsubscribe = spionStore.subscribe(() => console.log(spionStore.getState()));
        //spionStore.dispatch(doAddSpion('B1464599'));

        //props.dispatch(doFetchSpions("asdasdad"));
        //props.dispatch(doAddSpion("asdasdad"));

    };

    const handleClose = () => {
        setOpen(false);
    };

    //const handleChange = name => event => {
        //setFormdata({ ...formdata, marketplaces: { ...formdata.marketplaces,[name]: event.target.checked} });
    //};
    const handleKeywordChange = event => {
        setFormdata({ ...formdata, keyword: event.target.value.trim() });
    };
    const handleMpChange = val => {
        setFormdata({ ...formdata, marketplace: val.target.value });
    };
    const handleClickOpenAlarms = () => {
        props.history.push('/ranking/alarm');
    };
    const handleAddClick = () =>{
        let valid = true;
        //setKeywordError(false);
        //setMarketplaceError(false);
        if(formdata.keyword.length < 3) {
            valid = false;
            //setKeywordError(true);
            props.addWarning("Bitte geben Sie einen validen Suchbegriff ein.");
        }

        if(valid){
            props.toggleBackdrop(true);
            props.addKeyword(formdata.keyword, formdata.marketplace).then(res => {
                reloadView();
                props.toggleBackdrop(false);
                if(typeof res == "undefined" || (typeof res.code !== "undefined" && res.code === 500)){
                    //do not close
                }else {
                    handleClose();
                }
            });
        }
    };
    const reloadView = () => {
        props.fetchPoints();
    };

    //run once
    useMountEffect(() => {
        reloadView();
    });

    return (
        <div>
            <Tooltip title="Keyword hinzufügen" aria-label="add">
                <Fab color="primary" className={basestyle.fab} onClick={handleClickOpen}>
                    <AddIcon />
                </Fab>
            </Tooltip>
            <Tooltip title="Verwalten der Alarme" aria-label="add">
                <Fab color="default" variant={"extended"} className={basestyle.fab} onClick={handleClickOpenAlarms}>
                    <NotificationsIcon className={basestyle.extendedIcon} /> Benachrichtigungen
                </Fab>
            </Tooltip>
            <Dialog open={open} maxWidth={"md"} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Keyword hinzufügen</DialogTitle>
                <DialogContent>
                    <DialogContentText className={basestyle.warningColor}>
                        {props.getFreePoints < 1000000 && (
                        <b>Sie können noch {props.getFreePoints} Keywords hinzufügen.</b>
                        )}
                    </DialogContentText>
                    <Typography variant={"body1"}>
                        Marktplatz:
                    </Typography>
                    <FormControl>
                        <FormGroup row>
                            {["de","uk"].map(mp =>
                                <FormControlLabel
                                    control={
                                        <Radio checked={(formdata.marketplace == mp)} onChange={handleMpChange}
                                                  value={mp}/>
                                    }
                                    label={mp.toUpperCase()}
                                />
                            )}
                        </FormGroup>
                    </FormControl>
                    <FormGroup row>
                        <TextField
                            id="asin"
                            label="Keywords / Suchbegriffe"
                            multiline
                            rows="4"
                            onChange={handleKeywordChange}
                            variant="outlined"
                            fullWidth
                        />
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="default">
                        Abbrechen
                    </Button>
                    <Button onClick={handleAddClick} color="primary">
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        getCount: state.ranking.items.length,
        getFreePoints: state.ranking.points.free
    };
};
const mapDispatchToProps = dispatch => ({
    addKeyword: (kw,mp) => dispatch(doAddKeyword(kw,mp)),
    fetchKeywords: () => dispatch(doFetchKeywords()),
    fetchPoints: () => dispatch(doFetchRankingPoints()),
    toggleBackdrop: (open) => dispatch(toggleBackdrop(open)),
    addError: (msg,code) => dispatch(addErrorItem(msg,code)),
    addWarning: (msg,code) => dispatch(addWarningItem(msg,code))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AddBtnRanking))