import React from 'react';
import Container from "@material-ui/core/Container";
import BreadCrumb from "../HtmlComponents/BreadCrumb";
import {Helmet} from "react-helmet";
import makeTitle from "../Helper/makeTitle";
import TableLagerLieferanten from "../HtmlComponents/TableLagerLieferanten";

function LagerLieferantenPage(){
    return (
        <Container maxWidth="xl">
            <Helmet>
                <title>{makeTitle('Lager')}</title>
            </Helmet>
            <BreadCrumb links={[
                {label: 'Lager', url: '/lager'},
                {label: 'Lieferanten'}
            ]} help={"lager"} />
            <TableLagerLieferanten />
        </Container>
    );
}
export default LagerLieferantenPage;