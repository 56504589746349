import React from 'react';
import Container from "@material-ui/core/Container";
import BreadCrumb from "../HtmlComponents/BreadCrumb";
import TableRanking from "../HtmlComponents/TableRanking";
import {Helmet} from "react-helmet";
import makeTitle from "../Helper/makeTitle";

function RankingPage(){
    return (
        <Container maxWidth="xl">
            <Helmet>
                <title>{makeTitle('Ranking')}</title>
            </Helmet>
            <BreadCrumb links={[
                {label: 'Ranking'}
            ]} help={"ranking"} />
            <TableRanking />
        </Container>
    );
}
export default RankingPage;