import React from 'react';
import BaseStyle, {
    DefaultPaper,
    GRAPH_PALETTE_5
} from "../Layout/BaseStyle";
import { connect } from 'react-redux';
import {
    toggleBackdrop
} from "../Actions/Actions";
import useMountEffect from "../Helper/MountEffect";
import {withRouter} from 'react-router-dom'
import Grid from "@material-ui/core/Grid";
import {doFetchProductGewinn, doFetchSaveProductGewinn} from "../Actions/ProductActions";
import {CircularProgress} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {EuroFormat, FromEuroOutput, PercentageFormat, ToEuroOutput} from "../Helper/CurrencyFormat";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import Divider from "@material-ui/core/Divider";
import DragHandleIcon from '@material-ui/icons/DragHandle';
import {Cell, Legend, Pie, PieChart, ResponsiveContainer} from "recharts";
import Button from "@material-ui/core/Button";



const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x  = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy  + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} 	dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

function TableProductDetailGewinn(props){
    const basestyle = BaseStyle();
    const [formval,setFormval] = React.useState({ ek: null,ship:null,str:null,intEk:0,intShip:0});
    const [formerror,setFormerror] = React.useState({ ek: false,ship:false});

    const dofetch = () => {
        props.loadGewinn(props.productId,props.ek,props.ship,props.str);
        setFormval({
            ek: ToEuroOutput(props.ek),
            ship: ToEuroOutput(props.ship),
            str: props.str,
            intEk: (props.ek==null)?0:props.ek,
            intShip: (props.ship==null)?0:props.ship
        });
    };
    const handleEkPriceChange = (e) => {
        let newval = e.target.value;
        let newval_i = FromEuroOutput(newval);
        setFormval({
            ...formval,
            ek: newval,
            intEk: (isNaN(newval_i))?0:newval_i
        });
        setFormerror({ ...formerror,ek:false });
        if(isNaN(newval_i)){
            setFormerror({ ...formerror,ek:true });
        }
    };
    const handleEkPriceSet = (e) => {
        setFormval({
            ...formval,
            ek: ToEuroOutput(formval.intEk)
        });
    };
    const handleEkShipChange = (e) => {
        let newval = e.target.value;
        let newval_i = FromEuroOutput(newval);
        setFormval({
            ...formval,
            ship: newval,
            intShip: (isNaN(newval_i))?0:newval_i
        });
        if(isNaN(newval_i)){
            setFormerror({ ...formerror,ship:false });
        }
    };
    const handleEkShipSet = (e) => {
        setFormval({
            ...formval,
            ship: ToEuroOutput(formval.intShip)
        });
    };
    const handleEkTaxesChange = (e) => {
        setFormval({
            ...formval,
            str: e.target.value
        });
    };
    const handleSaveClick = () => {
        props.toggleBackdrop(true);
        props.setGewinn(props.productId,formval.intEk,formval.intShip,formval.str).then(()=>{
            props.toggleBackdrop(false);
        });
    };

    //run once
    useMountEffect(() => {
        dofetch();
    });

    let isloaded = false;
    let piechart_data = [];
    let piechart_data2 = [];
    if(typeof props.getGewinnCalc.Gewinn !== "undefined"){
        isloaded = true;
        piechart_data = [
            {name: 'Einkaufspreis', value: props.getGewinnCalc.EkPrice},
            {name: 'Versandkosten', value: props.getGewinnCalc.EkShip},
            {name: 'Gebühren', value: props.getGewinnCalc.Gebuehren.Anzeige}
        ];
        piechart_data2 = [
            {name: 'Ausgaben', value: props.getGewinnCalc.Ausgaben},
            {name: 'Gewinn', value: props.getGewinnCalc.Gewinn}
        ];
        if(props.getGewinnCalc.EkTaxes > 0){
            piechart_data = [
                ...piechart_data,
                {name: 'Mehrwertsteuer', value: props.getGewinnCalc.EkTaxes}
            ];
        }
    }


    return (
        <div>
            {!isloaded && (<div style={{textAlign:"center"}}><CircularProgress color={"secondary"} /></div>)}
            {isloaded && (
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={4}>
                        <DefaultPaper>
                            <Typography variant={"h1"}>Konfiguration</Typography>
                            <div className={basestyle.spacer} />
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="ek-price-1">Einkaufspreis</InputLabel>
                                <OutlinedInput
                                    id="ek-price-1"
                                    value={formval.ek}
                                    error={formerror.ek}
                                    onChange={handleEkPriceChange}
                                    onBlur={handleEkPriceSet}
                                    startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                    labelWidth={110}
                                />
                                <p><small>Ihr Netto-Einkaufspreis. Wenn Kleingewerbe, in Brutto.</small></p>
                            </FormControl>
                            <div className={basestyle.spacer} />
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="ek-price-2">Versandkosten</InputLabel>
                                <OutlinedInput
                                    id="ek-price-2"
                                    value={formval.ship}
                                    onChange={handleEkShipChange}
                                    onBlur={handleEkShipSet}
                                    startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                    labelWidth={110}
                                />
                                <p><small>Ihr realen Netto-Versandkosten zum Kunden. Wenn Kleingewerbe, in Brutto.</small></p>
                            </FormControl>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="ek-price-3-label">Mehrwertsteuersatz</InputLabel>
                                <Select
                                    labelId="ek-price-3-label"
                                    id="ek-price-3"
                                    value={formval.str}
                                    onChange={handleEkTaxesChange}
                                    labelWidth={140}
                                >
                                    <MenuItem value={0}>0% Mwst</MenuItem>
                                    <MenuItem value={7}>7% Mwst (7-12.20: 5%)</MenuItem>
                                    <MenuItem value={19}>19% Mwst (7-12.20: 16%)</MenuItem>
                                </Select>
                            </FormControl>
                            <div className={basestyle.spacer} />
                            <Grid container justify="flex-end">
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        startIcon={<SaveIcon />}
                                        onClick={handleSaveClick}
                                    >
                                        Speichern
                                    </Button>
                                </Grid>
                            </Grid>
                        </DefaultPaper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <DefaultPaper>
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={basestyle.successLightColorBg}>
                                            <AddIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Kaufpreis" secondary={<EuroFormat value={props.getGewinnCalc.VkPrice} />} />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={basestyle.successLightColorBg}>
                                            <AddIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Versandkosten" secondary={<EuroFormat value={props.getGewinnCalc.VkShip} />} />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={basestyle.errorLightColorBg}>
                                            <RemoveIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Amazon Gebühren" secondary={(<span><EuroFormat value={props.getGewinnCalc.Gebuehren.Anzeige} /> (<PercentageFormat value={props.getGewinnCalc.Gebuehren.Satz} />)</span>)} />
                                </ListItem>
                                {props.getGewinnCalc.EkTaxes > 0 && (
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar className={basestyle.errorLightColorBg}>
                                                <RemoveIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Mehrwertsteuer" secondary={(<span><EuroFormat value={props.getGewinnCalc.EkTaxes} /> (<PercentageFormat value={props.getGewinnCalc.Taxes} />)</span>)} />
                                    </ListItem>
                                )}
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={basestyle.errorLightColorBg}>
                                            <RemoveIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Einkaufspreis" secondary={<EuroFormat value={props.getGewinnCalc.EkPrice} />} />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={basestyle.errorLightColorBg}>
                                            <RemoveIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="reale Versandkosten" secondary={<EuroFormat value={props.getGewinnCalc.EkShip} />} />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <DragHandleIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Gewinn" secondary={<EuroFormat value={props.getGewinnCalc.Gewinn} />} />
                                </ListItem>
                            </List>
                        </DefaultPaper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <DefaultPaper>
                            <ResponsiveContainer width="100%" height={"40%"}>
                                <PieChart>
                                    <Pie
                                        data={piechart_data}
                                        labelLine={false}
                                        label={renderCustomizedLabel}
                                        fill="#8884d8"
                                        outerRadius={'100%'}
                                    >
                                    {
                                        piechart_data.map((entry, index) => <Cell fill={GRAPH_PALETTE_5[index]}/>)
                                    }
                                    </Pie>
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                            <div style={{height:'10%'}} />
                            <ResponsiveContainer width="100%" height={"40%"}>
                                <PieChart>
                                    <Pie
                                        data={piechart_data2}
                                        labelLine={false}
                                        label={renderCustomizedLabel}
                                        fill="#8884d8"
                                        outerRadius={'100%'}
                                    >
                                        {
                                            piechart_data2.map((entry, index) => <Cell fill={GRAPH_PALETTE_5[index % GRAPH_PALETTE_5.length]}/>)
                                        }
                                    </Pie>
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                        </DefaultPaper>
                    </Grid>
                </Grid>
            )}
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        getGewinnCalc: state.products.single_gewinncalc
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    setGewinn: (id,ek,ship,tax) => dispatch(doFetchSaveProductGewinn(id,ek,ship,tax)),
    loadGewinn: (id) => dispatch(doFetchProductGewinn(id))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TableProductDetailGewinn))