import React from 'react';
import MaterialTable from "material-table";
import { connect } from 'react-redux';
import {
    toggleBackdrop
} from "../Actions/Actions";
import useMountEffect from "../Helper/MountEffect";
import Box from "@material-ui/core/Box";
import {withRouter} from 'react-router-dom'
import tableIcons from "../Layout/TableIcons";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {doDeleteKeywords, doToggleKeywords, reloadRankingPage} from "../Actions/RankingActions";
import {TrendDisplay} from "../Helper/TrendDisplay";
import ConfirmDialog from "../Helper/ConfirmDialog";
import AddBtnRanking from "./AddBtnRanking";
import {FlagInTable} from "./TableSpion";


function TableRanking(props){
    const [dialog,setDialog] = React.useState(null);

    const dofetch = () => {
        props.reloadRankingPage();
    };
    const handleDetailOpen = (id) => {
        //setForward(<Redirect to={'/spion/'+id} />);
        props.history.push('/ranking/'+id);
    };
    const handleDelete = (arr) => {
      setDialog(<ConfirmDialog title={"Keyword(s) löschen?"} text={"Möchten Sie wirklich "+arr.length+" Keywords löschen?"} onClose={()=>{ setDialog(null); }} onClick={() => {
          props.deleteKeywords(arr);
          setDialog(null);
      }} />);
    };
    const handleDeactivate = (arr) => {
        setDialog(<ConfirmDialog title={"Keyword(s) deaktivieren?"} text={"Möchten Sie wirklich "+arr.length+" Keywords deaktivieren?"} onClose={()=>{ setDialog(null); }} onClick={() => {
            props.deactivateKeywords(arr);
            setDialog(null);
        }} />);
    };
    const handleActivate = (arr) => {
        setDialog(<ConfirmDialog title={"Keyword(s) aktivieren?"} text={"Möchten Sie wirklich "+arr.length+" Keywords aktivieren?"} onClose={()=>{ setDialog(null); }} onClick={() => {
            props.activateKeywords(arr);
            setDialog(null);
        }} />);
    };
    //run once
    useMountEffect(() => {
        dofetch();
    });

    return (
        <Grid container>
            {dialog}
            <Grid item xs={12}>
            <AddBtnRanking />
            <MaterialTable title="Keyword-Ranking" icons={tableIcons} columns={[
                {
                    title: 'Status',
                    field: 'status',
                    render: rowData => {

                        if(rowData.status === 0 && rowData.status_alarm > 0) {
                            return <Box color="success.main">Aktiv (Alarm aktiv)</Box>;
                        }else if(rowData.status === 0) {
                            return <Box color="success.main">Aktiv</Box>;
                        }else{
                            return <Box color="warning.main">Deaktiv</Box>;
                        }
                    },
                    lookup: { 0: "aktiv", 1: "deaktiv" }
                },
                {
                    title: 'Marktplatz',
                    field: 'marketplace',
                    /*customSort: (a, b) => {
                        return ([a.marketplace, b.marketplace].sort()[0] === b.marketplace)?0:-1;
                    },*/
                    render: rowData => {
                        let flag = rowData.marketplace;
                        return <FlagInTable country={flag} />;
                    }/*,
                    lookup: {
                        "de": (<FlagInTable country={"DE"} />),
                        "uk": (<FlagInTable country={"GB"} />),
                        "it": (<FlagInTable country={"IT"} />),
                        "es": (<FlagInTable country={"ES"} />),
                        "fr": (<FlagInTable country={"FR"} />)
                    }*/
                },
                { title: "Keyword", field: "keyword" },
                {
                    title: "Top Position",
                    field: "best_pos",
                    type: "numeric",
                    defaultSort: "asc",
                    customSort: (a, b) => {
                        return ((a.best_pos==null)?10000:a.best_pos) - ((b.best_pos==null)?10000:b.best_pos);
                    },
                    render: rowData => {
                        return (
                            <Grid container spacing={3} justify="flex-end">
                                <Grid item>
                                    <Typography variant={"h5"}>{rowData.best_pos}</Typography>
                                </Grid>
                                <Grid item >
                                    <TrendDisplay value={rowData.trend_pos} />
                                </Grid>
                            </Grid>
                        );
                    }
                },
                {
                    title: "Produkte",
                    field: "found_products",
                    type: "numeric",
                    render: rowData => {
                        return (
                            <Grid container spacing={3} justify="flex-end">
                                <Grid item>
                                    <Typography variant={"h5"}>{rowData.found_products}</Typography>
                                </Grid>
                                <Grid item >
                                    <TrendDisplay value={rowData.trend_count} reversed />
                                </Grid>
                            </Grid>
                        );
                    }
                },
                { title: "Stand vom",field: "last_check",type: "datetime" }
            ]}
               data={props.getKeywords}
               actions={[
                   rowData => ({
                       icon: tableIcons.Activate,
                       tooltip: 'Keyword aktivieren',
                       onClick: (event, rowData) => {
                           let id_arr = [];
                           for(let i=0; i<rowData.length; i++){
                               id_arr.push(rowData[i].id);
                           }
                           handleActivate(id_arr);
                       }
                   }),
                   rowData => ({
                       icon: tableIcons.Stop,
                       tooltip: 'Keyword deaktivieren',
                       onClick: (event, rowData) => {
                           let id_arr = [];
                           for(let i=0; i<rowData.length; i++){
                               id_arr.push(rowData[i].id);
                           }
                           handleDeactivate(id_arr);
                       }
                   }),
                   rowData => ({
                       icon: tableIcons.Delete,
                       tooltip: 'Keyword löschen',
                       onClick: (event, rowData) => {
                           let id_arr = [];
                           for(let i=0; i<rowData.length; i++){
                               id_arr.push(rowData[i].id);
                           }
                           handleDelete(id_arr);
                       }
                   })
               ]}
               onRowClick={(event,rowData) => {
                   handleDetailOpen(rowData.id);
               }}
               options={{
                   pageSize: 50,
                   pageSizeOptions: [10,25,50,100,200],
                   selection: true,
                   actionsColumnIndex: 0,
                   cellStyle: {
                       fontSize: 12
                   }
           }}/>
        </Grid>
    </Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        getKeywords: state.ranking.items,
        getUserData: state.account.user
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    reloadRankingPage: () => dispatch(reloadRankingPage()),
    deleteKeywords: (arr) => dispatch(doDeleteKeywords(arr)),
    activateKeywords: (arr) => dispatch(doToggleKeywords(arr,0)),
    deactivateKeywords: (arr) => dispatch(doToggleKeywords(arr,1))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TableRanking))