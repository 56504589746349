import React from 'react';
import {Helmet} from "react-helmet";
import makeTitle from "../Helper/makeTitle";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";

function AbonnementThankYou(props){

    return (
        <Container maxWidth={"sm"}>
            <Helmet>
                <title>{makeTitle("Abonnements")}</title>
            </Helmet>

            <Grid container spacing={7}>
                <Grid item xs={12} style={{textAlign:'center'}}>
                    <Alert severity="success">Vielen Dank für Ihre Bestellung! Sellerava wird stetig weiterentwickelt. Wir freuen uns, Ihnen langfristig bei Ihrem Amazon-Geschäft zur Seite zu stehen.</Alert>
                </Grid>
                <Grid item xs={12} style={{textAlign:'center'}}>
                    <Button component={Link} to="/" variant="contained" color="secondary" size={"large"}>
                        Zum Dashboard
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
}
const mapStateToProps = (state) => {
    return {
    };
};
const mapDispatchToProps = dispatch => ({
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AbonnementThankYou))