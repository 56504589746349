import React from 'react';
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import makeTitle from "../Helper/makeTitle";


function LoginExpiredPage(){
    return (
        <div>
            <Helmet>
                <title>{makeTitle("Seite nicht gefunden")}</title>
            </Helmet>
            <h1 className="jumbo_h1">404</h1>
            <h4>Ups... Seite nicht gefunden!</h4>
            <p>Die angeforderte Seite konnte nicht gefunden werden. Wenden Sie sich ggf. an unseren Support.</p>
            <Button component={Link} to="/" variant="contained" color="secondary">
                Zur Startseite
            </Button>
        </div>
    );
}
export default LoginExpiredPage;