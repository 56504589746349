import React from "react";
import {Link as RouterLink} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

function ListItemLink(props) {
    const { icon, primary, to, onClick, className } = props;

    const renderLink = React.useMemo(
        () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
        [to],
    );

    return (
        <li>
            <ListItem button component={renderLink} onClick={onClick} className={className}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
                {props.children}
            </ListItem>
        </li>
    );
}
export function ListItemLinkAbs(props) {
    const { icon, primary, to, onClick, className } = props;


    return (
        <li>
            <ListItem button component={"a"} href={to} onClick={onClick} className={className}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
                {props.children}
            </ListItem>
        </li>
    );
}
export default ListItemLink;