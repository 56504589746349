import React from 'react';
import {Helmet} from "react-helmet";
import makeTitle from "../Helper/makeTitle";
import BreadCrumb from "../HtmlComponents/BreadCrumb";
import Container from "@material-ui/core/Container";
import BaseStyle, {DefaultPaper, DefaultPaperAuto} from "../Layout/BaseStyle";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import useMountEffect from "../Helper/MountEffect";
import {
    addErrorItem,
    addWarningItem, doCancelAbo,
    doFetchAccountSettings, doFetchUserData,
    doSaveSettingsApi, doSaveSettingsNotifications, doSaveSettingsTarif, doSaveSettingsTaxes, doSendInvoice,
    setSettingData,
    toggleBackdrop
} from "../Actions/Actions";
import {connect} from "react-redux";
import LoadingPage from "./LoadingPage";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import MaterialTable from "material-table";
import tableIcons from "../Layout/TableIcons";
import ConfirmDialog from "../Helper/ConfirmDialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {addCustomLieferStatus, deleteCustomLieferStatus, saveLagerSettings} from "../Actions/LagerActions";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Alert from "@material-ui/lab/Alert";
import AddIcon from "@material-ui/icons/Add";

function ProfilePage(props){
    const basestyle = BaseStyle();
    const [dialog,setDialog] = React.useState(null);
    const [lagerInfo,setLagerInfo] = React.useState({ addText:"",gewichtung: [],notify_critical:0,notify_reorder:0 });
    const [haserror,setError] = React.useState({ vktoken:false,mwstoken:false,billingmail:false,notify_critical:false,notify_reorder:false });

    useMountEffect(() => {
       props.fetchData();
       setLagerInfo({...lagerInfo,gewichtung: props.user.StockGewichtung,notify_critical: props.user.StockNotifyCritical,notify_reorder: props.user.StockNotify});
    });

    /***** Amazon Schnittstelle ****/
    const handleVKIDChange = (e) => {
        let newval = e.target.value.trim();
        props.setSettingData("merchant_id",newval);
        setError({ ...haserror,vktoken:false});
        if(newval.length > 0) {
            if (newval.length < 12 && newval.length > 1) {
                setError({...haserror, vktoken: true});
            }
        }
    };
    const handleTokenChange = (e) => {
        let newval = e.target.value.trim();
        props.setSettingData("auth_key",newval);
        setError({ ...haserror,mwstoken:false});
        if(newval.length > 0) {
            if ((newval.length !== 45 && newval.length > 1) || newval.substr(0, 9) !== "amzn.mws.") {
                setError({...haserror, mwstoken: true});
            }
        }
    };
    const handleRequestOrdersChkbx = (e) => {
        props.setSettingData("api_request_orders",(e.target.checked)?1:0);
    };
    const handleRequestProductsChkbx = (e) => {
        props.setSettingData("api_request_products",(e.target.checked)?1:0);
    };
    const handleSaveApiClick = () => {
        props.saveApiData();
    };

    /***** Benachrichtigungen ****/
    const handleStockWarningChange = (e) => {
        props.setSettingData("outofstock_warning",e.target.value);
    };
    const handleMailIntervalChange = (e) => {
        props.setSettingData("email_interval",e.target.value);
    };
    const handleSaveNotificationsClick = () => {
        props.saveNotificationsData();
    };

    /***** Steuern,... ****/
    const handleKleingewerbeChkbx = (e) => {
        props.setSettingData("kleingewerbe",(e.target.checked)?1:0);
    };
    const handleSaveTaxesClick = () => {
        props.saveTaxesData();
    };

    /****** Tarif *******/
    const handleBillingMailChange = (e) => {
        let newval = e.target.value.trim();
        props.setSettingData("billing_email",newval);
        setError({ ...haserror,billingmail:false});
        if(newval.length > 0) {
            let newval_split = newval.split("@");
            if (newval.indexOf("@") > 1 && newval_split.length === 2 && newval_split[0].length > 1 && newval_split[1].length > 3) {
                //valid
            }else{
                setError({...haserror, billingmail: true});
            }
        }
    };
    const handleSaveTarifClick = () => {
        props.saveTarifData();
    };
    const handleSaveLagerClick = () => {
        let valid = true;
        setError({...haserror,notify_critical:false,notify_reorder:false});
        if(getGewichtungSumme() !== 100){
            valid = false;
        }
        if(isNaN(lagerInfo.notify_critical) || lagerInfo.notify_critical <= 0){
            valid = false;
            setError({...haserror,notify_critical:true});
        }
        if(isNaN(lagerInfo.notify_reorder) || lagerInfo.notify_reorder <= 0){
            valid = false;
            setError({...haserror,notify_reorder:true});
        }

        if(valid) {
            props.toggleBackdrop(true);
            props.saveLagerSettings(lagerInfo).then(() => {
                props.toggleBackdrop(false);
            });
        }else{
            props.addError("Bitte überprüfen Sie Ihre Eingaben.");
        }
    };
    const handleAddLagerStatus = () => {
        props.toggleBackdrop(true);
        props.addCustomLieferStatus(lagerInfo.addText).then(() => {
            props.fetchUserData();
            setLagerInfo({...lagerInfo,addText: ""});
            props.toggleBackdrop(false);
        });
    };
    const handleLagerAddTextChange = (e) => {
        setLagerInfo({...lagerInfo,addText: e.target.value});
    };
    const handleLieferungStatusDelete = (id) => {
        setDialog(<ConfirmDialog title={"Status löschen"} text={"Möchten Sie diesen benutzerdefinierten Status löschen? Lieferungen mit diesem Status werden anschließend mit dem Status \"Unbekannt\" gekennzeichnet."} onClose={()=>{ setDialog(null); }} onClick={() => {
            setDialog(null);
            props.toggleBackdrop(true);
            props.deleteCustomLieferStatus(id).then(() => {
                props.fetchUserData();
                props.toggleBackdrop(false);
            });
        }} />);
    };
    const handleCancelAbo = (id) => {
        setDialog(<ConfirmDialog title={"Abonnement beenden"} text={"Möchten Sie Ihr Abonnement wirklich kündigen?"} onClose={()=>{ setDialog(null); }} onClick={() => {
            props.cancelAbo(id);
            setDialog(null);
        }} />);
    };
    const handleInvoiceSend = (id) => {
        setDialog(<ConfirmDialog title={"Rechnung anfordern"} text={"Sie erhalten die Rechnung für den ausgewählten Zeitraum in kürze per Email."} onClose={()=>{ setDialog(null); }} onClick={() => {
            props.sendInvoice(id);
            setDialog(null);
        }} />);
    };
    const getGewichtungZeitraum = (index) => {
        if(index===0) return 3;
        if(index===1) return 7;
        if(index===2) return 30;
        if(index===3) return 60;
        if(index===4) return 180;
    };
    const handleGewichtungChange = (e,index) => {
        let new_gewichtung = lagerInfo.gewichtung;
        new_gewichtung[index] = parseInt(e.target.value);
        setLagerInfo({
            ...lagerInfo,
            gewichtung: new_gewichtung
        });
    };
    const getGewichtungSumme = () => {
        let sum = 0;
        lagerInfo.gewichtung.map(i => sum += i);
        return sum;
    };




    if(typeof props.settings.merchant_id !== "undefined") {
        return (
            <Container maxWidth="xl">
                <Helmet>
                    <title>{makeTitle('Account verwalten')}</title>
                </Helmet>
                {dialog}
                <BreadCrumb links={[
                    {label: 'Account'}
                ]}/>
                <DefaultPaperAuto>
                    <Typography variant={"h1"}>Account verwalten</Typography>
                </DefaultPaperAuto>

                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography>Amazon Schnittstelle</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <FormGroup row>
                                    <TextField
                                        error={haserror.vktoken}
                                        helperText={(haserror.vktoken) ? 'Ein Verkäufer-Token besteht aus mindestens 12 Zeichen' : null}
                                        id="vktoken"
                                        label="Ihre Verkäufer-ID / Ihr Verkäufertoken"
                                        onChange={handleVKIDChange}
                                        value={props.settings.merchant_id}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormGroup row>
                                    <TextField
                                        error={haserror.mwstoken}
                                        helperText={(haserror.mwstoken) ? 'Ein MWS-Token besteht aus 45 Zeichen und beginnt mit "amzn.mws."' : null}
                                        id="mwstoken"
                                        label="Token für die MWS-Authorisierung"
                                        onChange={handleTokenChange}
                                        value={props.settings.auth_key}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={props.settings.api_request_orders}
                                                      onChange={handleRequestOrdersChkbx}
                                                      value={1} />
                                        }
                                        key={"checkbox_1"}
                                        label={"Bestellungen über Schnittstelle laden"}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={props.settings.api_request_products}
                                                      onChange={handleRequestProductsChkbx}
                                                      value={1} />
                                        }
                                        key={"checkbox_2"}
                                        label={"Produkte über Schnittstelle laden"}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <Link component="a" href={"https://www.youtube.com/watch?v=8HDCy06ralQ"} target="_blank"><OpenInNewIcon className={basestyle.inlineTextIcon} /> Video-Anleitung öffnen (YouTube)</Link>
                            </Grid>
                            <Grid item xs={12}>
                                <Button onClick={handleSaveApiClick} color="primary" variant={"contained"}>
                                    Speichern
                                </Button>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography>Benachrichtigungen</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel id="select-notification-mail-label">Gesamtanalyse per Email</InputLabel>
                                    <Select
                                        labelId={"select-notification-mail-label"}
                                        labelWidth={190}
                                        value={props.settings.email_interval}
                                        onChange={handleMailIntervalChange}
                                    >
                                        <MenuItem value={1}>Täglich</MenuItem>
                                        <MenuItem value={7}>Wöchentlich</MenuItem>
                                        <MenuItem value={0}>Deaktiviert</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {/*<Grid item xs={12} md={6}>
                                <FormGroup row>
                                    <TextField
                                        label="Lagerbestandswarnung"
                                        onChange={handleStockWarningChange}
                                        value={props.settings.outofstock_warning}
                                        variant="outlined"
                                        type="number"
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">Tage</InputAdornment>,
                                        }}
                                    />
                                    <Typography variant={"subtitle2"}>Wenn ein Produkt rechnerisch in den nächsten X Tagen ausgehen wird, senden wir Ihnen früh genug eine E-Mail. Den Zeitpunkt können Sie hiermit konfigurieren. Tragen Sie 0 ein, wenn Sie keine E-Mail erhalten möchten. Standard ist 60 Tage.</Typography>
                                </FormGroup>
                            </Grid>*/}
                            <Grid item xs={12}>
                                <Button onClick={handleSaveNotificationsClick} color="primary" variant={"contained"}>
                                    Speichern
                                </Button>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography>Einstellungen für die Lagerverwaltung</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <DefaultPaper>
                                    <Typography variant={"h6"}>Lieferungen Status-Feld:</Typography>
                                    <div className={basestyle.spacer} />
                                    <List component="nav" aria-label="main mailbox folders">
                                        {props.user.StockOrderStatusList.map((s) => {
                                            let text = s[1];
                                            let text2 = "";
                                            let clickaction = null;
                                            if(s[0] >= 1000){
                                                text2 = "Systemstatus";
                                            }else{
                                                text2 = "Zum Löschen hier klicken.";
                                                clickaction = () => { handleLieferungStatusDelete(s[0]) };
                                            }
                                            return (<ListItem button onClick={clickaction}>
                                                <ListItemText primary={text} secondary={text2} />
                                            </ListItem>);
                                        })}
                                    </List>
                                    <Grid container>
                                        <Grid item xs={10} style={{paddingLeft:16}}>
                                            <TextField label="Neuer Status"
                                                       value={lagerInfo.addText}
                                                       onChange={handleLagerAddTextChange}
                                                       fullWidth />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <div style={{flexGrow:1}} />
                                            <Button onClick={handleAddLagerStatus} variant="contained" color="primary" component="span" style={{top: 12,marginLeft:10}}>
                                                <AddIcon />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </DefaultPaper>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <DefaultPaper>
                                    <Typography variant={"h6"}>Gewichtung der Lagerberechnung:</Typography>
                                    <div className={basestyle.spacer} />
                                    {lagerInfo.gewichtung.map((item,index) =>
                                        <div>
                                            <FormControl className={basestyle.inputMargin} variant="outlined" fullWidth>
                                                <OutlinedInput
                                                    label="Gewichtung"
                                                    value={item}
                                                    onChange={(e) => handleGewichtungChange(e,index)}
                                                    type={"number"}
                                                    inputProps={{
                                                        style: {textAlign: "right"}
                                                    }}
                                                    startAdornment={<InputAdornment position="start" style={{color:'#bbb'}}>{getGewichtungZeitraum(index)} Tage</InputAdornment>}
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                />
                                            </FormControl>
                                        </div>
                                    )}
                                    <Alert severity="error" style={{visibility: (getGewichtungSumme() !== 100) ? "visible" :"hidden"}}>Bitte ändern Sie die Gewichtung auf in Summe 100%. Aktuell: {getGewichtungSumme()}%</Alert>
                                </DefaultPaper>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <DefaultPaper>
                                    <Typography variant={"h6"}>Warnungen:</Typography>
                                    <div className={basestyle.spacer} />
                                    <div>
                                        <FormControl className={basestyle.inputMargin} variant="outlined" fullWidth>
                                            <OutlinedInput
                                                label="Kritischer Lagerbestand"
                                                value={lagerInfo.notify_critical}
                                                error={haserror.notify_critical}
                                                onChange={(e) => setLagerInfo({...lagerInfo,notify_critical: e.target.value})}
                                                type={"number"}
                                                inputProps={{
                                                    style: {textAlign: "right"}
                                                }}
                                                startAdornment={<InputAdornment position="start" style={{color:'#bbb'}}>Kritischer Lagerbestand</InputAdornment>}
                                                endAdornment={<InputAdornment position="end">Tage</InputAdornment>}
                                            />
                                        </FormControl>
                                    </div>
                                    <div>
                                        <FormControl className={basestyle.inputMargin} variant="outlined" fullWidth>
                                            <OutlinedInput
                                                label="Hinweis Lagerbestand"
                                                value={lagerInfo.notify_reorder}
                                                error={haserror.notify_reorder}
                                                onChange={(e) => setLagerInfo({...lagerInfo,notify_reorder: e.target.value})}
                                                type={"number"}
                                                inputProps={{
                                                    style: {textAlign: "right"}
                                                }}
                                                startAdornment={<InputAdornment position="start">Bestell-Hinweis</InputAdornment>}
                                                endAdornment={<InputAdornment position="end">Tage</InputAdornment>}
                                            />
                                        </FormControl>
                                    </div>
                                    <div>
                                        <Typography variant={"body1"}>
                                            Wir informieren Sie früh genug per E-Mail, sobald ein Produkt droht im Lager ausverkauft zu sein.<br/><br/>
                                            Die Berechnung der Vorwarnzeit setzt sich zusammen aus
                                            der Lieferzeit des Lieferanten, die Extra Vorwarnzeit des Lieferanten, die Bearbeitungszeit, die am Produkt hinterlegt ist, und dem Zeitraum, der hier angegeben ist.
                                        </Typography>
                                    </div>
                                </DefaultPaper>
                            </Grid>
                            <Grid item xs={12}>
                                <Button onClick={handleSaveLagerClick} color="primary" variant={"contained"}>
                                    Speichern
                                </Button>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography>Währungen, Steuern und Gewinnberechnung</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={props.settings.kleingewerbe}
                                                      onChange={handleKleingewerbeChkbx}
                                                      value={1} />
                                        }
                                        key={"checkbox_3"}
                                        label={"Kleinunternehmer-Regelung"}
                                    />
                                    <Typography variant={"subtitle2"}>Setzen Sie hier einen Haken, wenn Sie nicht vorsteuerabzugsberechtigt sind, keine Mehrwertsteuer ausweisen, oder gemäß § 19 UStG ein Kleinunternehmer sind. Diese Angabe benötigen wir für die Berechnung von Gewinn und Ausgaben.</Typography>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <Button onClick={handleSaveTaxesClick} color="primary" variant={"contained"}>
                                    Speichern
                                </Button>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography>Tarif &amp; Rechnungen</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>

                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Typography variant={"h5"}>
                                    Tarif wechseln
                                </Typography>
                                <Typography variant={"body1"}>
                                    Wechseln Sie in einen anderen Tarif oder bestellen Sie zusätzliche Keywords oder Spione.
                                </Typography>
                                <div className={basestyle.spacer} />
                                <Button component={RouterLink} to="/abonnements" variant="contained" color="default">
                                    Pakete buchen
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant={"h5"}>
                                    Rechnungsadresse und Zahlungsmethode
                                </Typography>
                                <Typography variant={"body1"}>
                                    Ändern Sie Ihre Rechnungsadresse und wählen Sie eine andere Zahlungsmethode.
                                </Typography>
                                <div className={basestyle.spacer} />
                                <Button component={RouterLink} to="/abonnements/payment" variant="contained" color="default">
                                    Zahlungsdaten bearbeiten
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant={"h5"}>
                                    Email-Adresse für Rechnungen
                                </Typography>
                                <Typography variant={"body1"}>
                                    Hinterlegen Sie eine separate Email-Adresse für den Versand der Sellerava-Rechnungen.
                                </Typography>
                                <div className={basestyle.spacer} />
                                <Grid container spacing={1} style={{maxWidth:400}}>
                                    <Grid item xs={12}>
                                        <FormGroup>
                                            <TextField
                                                error={haserror.billingmail}
                                                helperText={(haserror.billingmail) ? 'Bitte geben Sie eine valide Email-Adresse ein.' : null}
                                                label="Email für Rechnungen"
                                                onChange={handleBillingMailChange}
                                                value={props.settings.billing_email}
                                                size={"small"}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button onClick={handleSaveTarifClick} color="primary" variant={"contained"} fullWidth>
                                            Speichern
                                        </Button>
                                    </Grid>
                                </Grid>
                                <div className={basestyle.spacer} />
                            </Grid>
                            <Grid item xs={12}>
                                <MaterialTable title={"Ihre Pakete und Rechnungen"} icons={tableIcons} columns={[
                                    { title:"Paket",field:"name" },
                                    { title:"Von",field:"from" },
                                    { title:"Bis",field:"to" },
                                    { title:"Status",field:"statustext" }
                                ]}
                                data={props.settings.tarif_list}
                               actions={[
                                   rowData => ({
                                       icon: (rowData.cancelable)?tableIcons.Stop:"",
                                       tooltip: (rowData.cancelable)?"Abonnement beenden":"",
                                       onClick: (event, rowData) => {
                                           if(rowData.cancelable) {
                                               handleCancelAbo(rowData.paketid);
                                           }
                                       }
                                   }),
                                   rowData => ({
                                       icon: (rowData.invoice)?tableIcons.Download:"",
                                       tooltip: (rowData.invoice)?"Rechnung anfordern":"",
                                       onClick: (event, rowData) => {
                                           if(rowData.invoice) {
                                               handleInvoiceSend(rowData.tarifid);
                                           }
                                       }
                                   })
                               ]}
                               options={{
                                   actionsColumnIndex: 4
                               }}
                                />
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Container>
        );
    }else{
        return <LoadingPage />;
    }
}

const mapStateToProps = (state) => {
    return {
        settings: state.account.settings_form,
        user: state.account.user
    };
};
const mapDispatchToProps = dispatch => ({
    fetchUserData: () => dispatch(doFetchUserData()),
    fetchData: () => dispatch(doFetchAccountSettings()),
    saveApiData: () => dispatch(doSaveSettingsApi()),
    saveNotificationsData: () => dispatch(doSaveSettingsNotifications()),
    saveTaxesData: () => dispatch(doSaveSettingsTaxes()),
    saveTarifData: () => dispatch(doSaveSettingsTarif()),
    saveLagerSettings: (settings) => dispatch(saveLagerSettings(settings)),
    cancelAbo: (id) => dispatch(doCancelAbo(id)),
    sendInvoice: (id) => dispatch(doSendInvoice(id)),
    setSettingData: (prop,val) => dispatch(setSettingData(prop,val)),

    deleteCustomLieferStatus: (id) => dispatch(deleteCustomLieferStatus(id)),
    addCustomLieferStatus: (text) => dispatch(addCustomLieferStatus(text)),

    toggleBackdrop: (open) => dispatch(toggleBackdrop(open)),
    addError: (msg,code) => dispatch(addErrorItem(msg,code)),
    addWarning: (msg,code) => dispatch(addWarningItem(msg,code))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfilePage)