import React, {useEffect} from 'react';
import Container from "@material-ui/core/Container";
import BreadCrumb from "../HtmlComponents/BreadCrumb";
import {Helmet} from "react-helmet";
import makeTitle from "../Helper/makeTitle";
import BaseStyle, {DefaultPaperAuto} from "../Layout/BaseStyle";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {connect} from "react-redux";
import {addErrorItem, doSubmitContactForm, setContactFormSendStatus} from "../Actions/Actions";
import useMountEffect from "../Helper/MountEffect";

function FeedbackHelpPage(props){
    const basestyle = BaseStyle();
    const [getFormdata,setFormdata] = React.useState({ topic: "",text:"",attachments: [] });
    const submitRequest = () => {
        if(getFormdata.uploading === getFormdata.uploaded){
            if(getFormdata.topic.length > 3){
                if(getFormdata.text.length > 19){
                    props.submitContactForm(getFormdata.topic,getFormdata.text,getFormdata.attachments);
                }else{
                    props.addError("Bitte schreiben Sie uns eine Nachricht. (mind. 20 Zeichen)");
                }
            }else{
                props.addError("Bitte wählen Sie ein Anliegen aus.");
            }
        }else{
            props.addError("Bitte warten Sie bis die Bilder vollständig hochgeladen sind.");
        }
    };
    const handleCapture = ({ target }) => {
        const name = target.accept.includes('image') ? 'images' : 'videos';
        let count = target.files.length;
        if(count+getFormdata.attachments.length > 3){
            count = 3-getFormdata.attachments.length;
            props.addError("Sie können nur 3 Bilder hinzufügen!");
        }
        let filereaders = [];
        let uploads = [];
        for (let a = 0; a < count; a++) {
            filereaders[filereaders.length] = new FileReader();
            filereaders[filereaders.length-1].readAsDataURL(target.files[a]);
            filereaders[filereaders.length-1].onload = (e) => {
                //let newformdata = Object.assign([],getFormdata.attachments);
                //newformdata.push(e.target.result);
                if(e.target.result.substr(0,10) === "data:image") {
                    handleUploaded(e.target.result);
                }else{
                    props.addError("Dateityp ist fehlerhaft oder nicht erlaubt.");
                }
            };
        }


    };
    const handleUploaded = (new_attach) => {
        let newformdata = Object.assign({},getFormdata);
        newformdata.attachments.push(new_attach);
        setFormdata(newformdata);
    };
    const handleTopic = (e) => {
        setFormdata({...getFormdata,topic: e.target.value});
    };
    const handleText = (e) => {
        setFormdata({...getFormdata,text: e.target.value});
    };

    useEffect(e => {
       if(props.send_status === true){
           props.setSendStatus(null);
           setFormdata({
                topic: "",
                text: "",
                attachments:[]
           });
       }
    });
    useMountEffect(() => {
        if(window.location.href.indexOf("help/tarif") > -1){
            setFormdata({...getFormdata,topic: "payment_request"});
        }
    });

    return (
        <Container maxWidth="xl">
            <Helmet>
                <title>{makeTitle('Hilfe und Feedback')}</title>
            </Helmet>
            <BreadCrumb links={[
                {label: 'Hilfe', url: "/help"},
                {label: 'Kontakt'}
            ]} />
            <DefaultPaperAuto>
                <Typography variant={"h1"}>Hilfe und Feedback</Typography>
                <Typography variant={"body1"}>Nehmen Sie kontakt zu uns auf! Wir unterstützen Sie gerne und beantworten Ihre Fragen sehr schnell!</Typography>
                <div className={basestyle.spacer} />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="anliegen-select">
                                Ihr Anliegen:
                            </InputLabel>
                            <Select
                                value={getFormdata.topic}
                                onChange={handleTopic}
                                inputProps={{
                                    id: 'anliegen-select',
                                }}
                            >
                                <MenuItem value={"feature_request"}>Anfrage für neue Features / Ideen</MenuItem>
                                <MenuItem value={"payment_request"}>Fragen zum Tarif / Zahlungen</MenuItem>
                                <MenuItem value={"bug_report"}>Fehler oder Problem melden</MenuItem>
                                <MenuItem value={"general_request"}>Allgemeine Fragen</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id="anliegen-text"
                                multiline
                                label={"Ihre Nachricht:"}
                                value={getFormdata.text}
                                onChange={handleText}
                                rows="4"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormControl>
                            <input
                                accept="image/*"
                                id="outlined-button-file"
                                style={{display:"none"}}
                                multiple
                                onChange={handleCapture}
                                type="file"
                            />
                            <label htmlFor="outlined-button-file">
                                <Button variant="outlined" component="span">
                                    Bilder hochladen
                                </Button>
                            </label>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    {getFormdata.attachments.map((val,index) => {
                        return (<Grid item xs={4} md={1}>
                            <img src={val} style={{maxWidth:"100%"}} alt={"Bild "+index} />
                        </Grid>);
                    })}
                </Grid>
                <div className={basestyle.spacer} />
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{textAlign:"center"}}>
                        <Button color={"primary"} variant={"contained"} onClick={submitRequest}>
                            Anfrage senden
                        </Button>
                    </Grid>
                </Grid>
            </DefaultPaperAuto>
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        send_status: state.account.send_status
    };
};
const mapDispatchToProps = dispatch => ({
    addError: (msg,code) => dispatch(addErrorItem(msg,code)),
    submitContactForm: (topic,text,attach) => dispatch(doSubmitContactForm(topic,text,attach)),
    setSendStatus: (stat) => dispatch(setContactFormSendStatus(stat))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FeedbackHelpPage)