import React, {Suspense} from 'react';
import { Route, Switch } from 'react-router-dom';
import LoginExpiredPage from "./Pages/LoginExpiredPage";
import LoadingPage from "./Pages/LoadingPage";
import DashboardPage from "./Pages/DashboardPage";
import ProfilePage from "./Pages/ProfilePage";
import NotFoundPage from "./Pages/NotFoundPage";
import BaseStyle from "./Layout/BaseStyle";
import SpionPage from "./Pages/SpionPage";
import {connect} from "react-redux";
import {NON_REACT_HOME_URL} from "./Actions/Actions";
import SpionDetailPage from "./Pages/SpionDetailPage";
import ProductPage from "./Pages/ProductPage";
import Container from "@material-ui/core/Container";
import ProductDetailPage from "./Pages/ProductDetailPage";
import RankingPage from "./Pages/RankingPage";
import RankingDetailPage from "./Pages/RankingDetailPage";
import SalesGewinnEditPage from "./Pages/SalesGewinnEditPage";
import TutorialPage from "./Pages/TutorialPage";
import SalesDashboardPage from "./Pages/SalesDashboardPage";
import AbonnementPage from "./Pages/AbonnementPage";
import PreBodyComponents from "./HtmlComponents/PreBodyComponents";
import FeedbackHelpPage from "./Pages/FeedbackHelpPage";
import ProductStockPage from "./Pages/ProductStockPage";
import SalesGewinnDetailsPage from "./Pages/SalesGewinnDetailsPage";
import ChangelogSettingPage from "./Pages/ChangelogSettingPage";
import Drawer from "@material-ui/core/Drawer";
import RankingAlarmPage from "./Pages/RankingAlarmPage";
import AbonnementThankYou from "./Pages/AbonnementThankYou";
import ActivationPage from "./Pages/ActivationPage";
import RankingTrendPage from "./Pages/RankingTrendPage";
import LagerPage from "./Pages/LagerPage";
import LagerLieferantenPage from "./Pages/LagerLieferantenPage";
import LagerBestellungenPage from "./Pages/LagerBestellungenPage";
import HelpPage from "./Pages/HelpPage";
import AbonnementPaymentPage from "./Pages/AbonnementPaymentPage";


function AppBody(props){
    const basestyle = BaseStyle();
    var mainbody_component = null;


    const route_profile = <Route path="/profile" component={ProfilePage}/>;
    const route_help = <Route path="/help/feedback" exact={true} component={FeedbackHelpPage}/>;
    const route_logout = <Route path='/logout' component={() => {
        window.location.href = NON_REACT_HOME_URL + '/logout';
        return null;
    }}/>;

    let is_tutorial = false;

    if(props.user.isLoggedIn){
        if(props.user.BenutzerStatus === "1"){
            mainbody_component = <Switch>
                {route_profile}
                {route_help}
                {route_logout}
                <Route path='*' exact={true} component={ActivationPage}/>
            </Switch>;
        }else if(props.user.TarifStatus === false){
            mainbody_component = <Switch>
                {route_profile}
                {route_help}
                {route_logout}
                <Route path='/abonnements/thank-you' exact={true} component={AbonnementThankYou}/>
                <Route path='*' exact={true} component={AbonnementPage}/>
            </Switch>;
        }else if(props.user.TutorialStatus > 0){
            is_tutorial = true;
            mainbody_component = <Switch>
                {route_profile}
                {route_logout}
                {route_help}
                <Route path='*' exact={true} component={TutorialPage}/>
            </Switch>;
        }else {
            mainbody_component = <Suspense fallback={<NotFoundPage/>}>
                    <Switch>
                        <Route exact path="/" component={DashboardPage}/>
                        {route_help}
                        {route_profile}
                        {route_logout}
                        {props.user.Tarife[5] && (<Route exact path="/sales" component={SalesDashboardPage}/>)}
                        {props.user.Tarife[5] && (<Route exact path="/sales/gewinn" component={SalesGewinnDetailsPage}/>)}
                        {props.user.Tarife[5] && (<Route exact path="/sales/gewinn/edit" component={SalesGewinnEditPage}/>)}
                        {props.user.Tarife[4] && (<Route exact path="/ranking" component={RankingPage}/>)}
                        {props.user.Tarife[4] && (<Route exact path="/ranking/trend" component={RankingTrendPage}/>)}
                        {props.user.Tarife[4] && (<Route exact path="/ranking/alarm" component={RankingAlarmPage}/>)}
                        {props.user.Tarife[4] && (<Route path="/ranking/:rankingId" component={RankingDetailPage}/>)}
                        {(props.user.Tarife[5] || props.user.Tarife[4])  && (<Route exact path="/products" component={ProductPage}/>)}
                        {props.user.Tarife[5] && (<Route exact path="/products/stock" component={ProductStockPage}/>)}
                        {(props.user.Tarife[5] || props.user.Tarife[4]) && (<Route path="/products/:productId" component={ProductDetailPage}/>)}
                        {props.user.Tarife[5] && (<Route exact path="/lager" component={LagerPage}/>)}
                        {props.user.Tarife[5] && (<Route exact path="/lager/lieferanten" component={LagerLieferantenPage}/>)}
                        {props.user.Tarife[5] && (<Route exact path="/lager/bestellungen" component={LagerBestellungenPage}/>)}
                        {props.user.Tarife[6] && (<Route exact path="/spion" component={SpionPage}/>)}
                        {props.user.Tarife[6] && (<Route path="/spion/:spionId" component={SpionDetailPage}/>)}
                        {props.user.Tarife[3] && (<Route exact path="/changelog-setting" component={ChangelogSettingPage}/>)}
                        <Route path='/abonnements/thank-you' exact={true} component={AbonnementThankYou}/>
                        <Route path='/abonnements' exact={true} component={AbonnementPage}/>
                        <Route path='/abonnements/payment' exact={true} component={AbonnementPaymentPage}/>
                        <Route path='/help' exact={true} component={HelpPage}/>
                        <Route path='/help/:category' component={HelpPage}/>
                        <Route path='*' exact={true} component={NotFoundPage}/>
                    </Switch>
            </Suspense>;
        }
    }else if(props.user.isLoggedIn === false){
        mainbody_component = <LoginExpiredPage/>;
    }else{
        mainbody_component = <LoadingPage/>;
    }

    return (

        <Container maxWidth="xl">
            <div className={basestyle.spacer}/>
            {!is_tutorial && (<PreBodyComponents />)}
            {mainbody_component}
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.account.user,
        site_status: state.visuals.site_status
    };
};
export default connect(mapStateToProps)(AppBody)