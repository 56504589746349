import {
    addSuccessItem,
    API_URL, catchErrors,
    handleErrors,
    toggleBackdrop
} from "./Actions";

export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const RECEIVE_VERPACKUNG_LIST = 'RECEIVE_VERPACKUNG_LIST';
export const SET_PRODUCT_FILTER_STATUS = 'SET_PRODUCT_FILTER_STATUS';
export const SET_PRODUCT_FILTER_FULLFILMENT = 'SET_PRODUCT_FILTER_FULLFILMENT';
export const SET_PRODUCT_FILTER_CATEGORY = 'SET_PRODUCT_FILTER_CATEGORY';
export const SET_PRODUCT_FILTER_MARKETPLACE = 'SET_PRODUCT_FILTER_MARKETPLACE';
export const SET_PRODUCT_FILTER_LIEFERANT = 'SET_PRODUCT_FILTER_LIEFERANT';
export const SET_PRODUCT_FILTER_RESET = 'SET_PRODUCT_FILTER_RESET';
export const SET_PRODUCT_FILTER_PRODUCTTYPE = 'SET_PRODUCT_FILTER_PRODUCTTYPE';
export const RECEIVE_PRODUCT_CATEGORIES = 'RECEIVE_PRODUCT_CATEGORIES';
export const RECEIVE_PRODUCT_DETAIL = 'RECEIVE_PRODUCT_DETAIL';
export const RECEIVE_PRODUCT_GRAPH_SALES = 'RECEIVE_PRODUCT_GRAPH_SALES';
export const RECEIVE_PRODUCT_GRAPH_RANK = 'RECEIVE_PRODUCT_GRAPH_RANK';
export const RECEIVE_PRODUCT_RANKING = 'RECEIVE_PRODUCT_RANKING';
export const RECEIVE_PRODUCT_GRAPH_RATING = 'RECEIVE_PRODUCT_GRAPH_RATING';
export const RECEIVE_PRODUCT_GRAPH_PRICING = 'RECEIVE_PRODUCT_GRAPH_PRICING';
export const RECEIVE_PRODUCT_OFFERS = 'RECEIVE_PRODUCT_OFFERS';
export const RECEIVE_PRODUCT_GEWINN = 'RECEIVE_PRODUCT_GEWINN';
export const RECEIVE_PRODUCTS_STOCK = 'RECEIVE_PRODUCTS_STOCK';
export const RECEIVE_PRODUCTS_STOCK_COUNT = 'RECEIVE_PRODUCTS_STOCK_COUNT';

export function receiveProducts(json){
    return {
        type: RECEIVE_PRODUCTS,
        items: json.result
    }
}
export function receiveVerpackungList(json){
    return {
        type: RECEIVE_VERPACKUNG_LIST,
        items: json.result
    }
}

export function receiveProductsStock(json){
    return {
        type: RECEIVE_PRODUCTS_STOCK,
        items: json.result
    }
}
export function receiveProductsStockCount(json){
    return {
        type: RECEIVE_PRODUCTS_STOCK_COUNT,
        count: json.result
    }
}
export function receiveProductRankings(json){
    return {
        type: RECEIVE_PRODUCT_RANKING,
        items: json.result
    }
}
export function receiveProductOffers(json){
    return {
        type: RECEIVE_PRODUCT_OFFERS,
        items: json.result
    }
}

export function receiveProductCategories(json){
    return {
        type: RECEIVE_PRODUCT_CATEGORIES,
        categories: json.result
    }
}
export function receiveProductDetail(json){
    return {
        type: RECEIVE_PRODUCT_DETAIL,
        single: json
    }
}
export function resetProductDetail(){
    return {
        type: RECEIVE_PRODUCT_DETAIL,
        single: []
    }
}

export function receiveProductGraphSales(json){
    return {
        type: RECEIVE_PRODUCT_GRAPH_SALES,
        chart: json.result
    }
}
export function receiveProductGraphPricing(json){
    return {
        type: RECEIVE_PRODUCT_GRAPH_PRICING,
        chart: json.result
    }
}

export function receiveProductGraphRating(json){
    return {
        type: RECEIVE_PRODUCT_GRAPH_RATING,
        chart: json.result
    }
}
export function receiveProductGraphRank(json){
    return {
        type: RECEIVE_PRODUCT_GRAPH_RANK,
        chart: json.result
    }
}
export function receiveProductGewinn(json){
    return {
        type: RECEIVE_PRODUCT_GEWINN,
        data: json.result
    }
}

export function resetFilterProduct(){
    return {
        type: SET_PRODUCT_FILTER_RESET
    }
}
export function setFilterProduct(filter,val){
    if(filter === "status"){
        return {type: SET_PRODUCT_FILTER_STATUS, val: val };
    }else if(filter === "fullfilment"){
        return {type: SET_PRODUCT_FILTER_FULLFILMENT, val: val };
    }else if(filter === "category"){
        return {type: SET_PRODUCT_FILTER_CATEGORY, val: val };
    }else if(filter === "marketplace"){
        return {type: SET_PRODUCT_FILTER_MARKETPLACE, val: val };
    }else if(filter === "lieferant"){
        return {type: SET_PRODUCT_FILTER_LIEFERANT, val: val };
    }else if(filter === "producttype"){
        return {type: SET_PRODUCT_FILTER_PRODUCTTYPE, val: val };
    }
}
export function setFilterProductAndRefresh(filter,val,is_stock_page){
    return async (dispatch, getState) => {
        dispatch(setFilterProduct(filter, val));
        dispatch(reloadProductsPage(is_stock_page));
    }
}
export function resetFilterProductAndRefresh(is_stock_page){
    return async (dispatch, getState) => {
        dispatch(resetFilterProduct());
        dispatch(reloadProductsPage(is_stock_page));
    }
}


/***FETCH API***/


export function reloadProductsPage(is_stock_page){
    return async (dispatch, getState) => {
        dispatch(toggleBackdrop(true));
        dispatch(doFetchProducts(is_stock_page))
            //.then(() => dispatch(doFetchSpionGraph()))
            .then(() => dispatch(toggleBackdrop(false)));
    }
}
export function doFetchProductCategories(){
    return async (dispatch, getState) => {
        await fetch(API_URL +'/Product.php?action=Categories', {
            credentials: 'include'
        }).then(handleErrors).then(
            response => {
                dispatch(receiveProductCategories(response));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}
export function doFetchProducts(is_stock_page){
    return async (dispatch, getState) => {
        let filter_mp = getState().products.filter.marketplace;
        let filter_status = getState().products.filter.status;
        let filter_full = getState().products.filter.fullfilment;
        let filter_cat = encodeURI(getState().products.filter.category);
        let filter_lieferant = encodeURI(getState().products.filter.lieferant);
        let filter_producttype = encodeURI(getState().products.filter.producttype);

        let extra = "";
        if(typeof is_stock_page !== "undefined" && is_stock_page === true) extra = "&stock=true";
        await fetch(API_URL +'/Product.php?action=Products&marketplace='+filter_mp+'&status='+filter_status+'&fullfilment='+filter_full+'&category='+filter_cat+"&lieferant="+filter_lieferant+"&producttype="+filter_producttype+extra, {
            credentials: 'include'
        }).then(handleErrors).then(
            response => {
                dispatch(receiveProducts(response));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}
export function doFetchProductsStock(){
    return async (dispatch, getState) => {
        dispatch(toggleBackdrop(true));
        await fetch(API_URL +'/Product.php?action=StockWarnings', {
            credentials: 'include'
        }).then(handleErrors).then(
            response => {
                dispatch(receiveProductsStock(response));
                dispatch(toggleBackdrop(false));
            }
        ).catch(s => {dispatch(catchErrors(s));dispatch(toggleBackdrop(false))});
    };
}
export function doFetchProductsStockCount(){
    return async (dispatch, getState) => {
        await fetch(API_URL +'/Product.php?action=StockWarningsCount', {
            credentials: 'include'
        }).then(handleErrors).then(
            response => {
                dispatch(receiveProductsStockCount(response));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}


export function doFetchProductDetail(id){
    return async (dispatch, getState) => {
        return await fetch(API_URL +'/Product.php?action=Product&id='+id, {
            credentials: 'include'
        }).then(handleErrors).then(
            response => {
                dispatch(receiveProductDetail(response.result));
                return response.result;
            }
        ).catch(s => { dispatch(catchErrors(s)); dispatch(receiveProductDetail(false)); });
    };
}
export function doFetchProductRankings(id){
    return async (dispatch, getState) => {
        await fetch(API_URL +'/Product.php?action=ProductRanking&id='+id, {
            credentials: 'include'
        }).then(handleErrors).then(
            response => {
                dispatch(receiveProductRankings(response));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}
export function doFetchProductOffers(id){
    return async (dispatch, getState) => {
        await fetch(API_URL +'/Product.php?action=ProductOffers&id='+id, {
            credentials: 'include'
        }).then(handleErrors).then(
            response => {
                dispatch(receiveProductOffers(response));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}

export function doFetchProductGraphSales(id){
    return async (dispatch, getState) => {
        await fetch(API_URL +'/Product.php?action=ProductSalesGraph&id='+id, {
            credentials: 'include'
        }).then(handleErrors).then(
            response => {
                dispatch(receiveProductGraphSales(response));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}
export function doFetchProductGraphPricing(id){
    return async (dispatch, getState) => {
        await fetch(API_URL +'/Product.php?action=PriceGraph&id='+id, {
            credentials: 'include'
        }).then(handleErrors).then(
            response => {
                dispatch(receiveProductGraphPricing(response));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}
export function doFetchProductGraphRank(id){
    return async (dispatch, getState) => {
        await fetch(API_URL +'/Product.php?action=ProductRankGraph&id='+id, {
            credentials: 'include'
        }).then(handleErrors).then(
            response => {
                dispatch(receiveProductGraphRank(response));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}
export function doFetchProductGraphRating(id){
    return async (dispatch, getState) => {
        await fetch(API_URL +'/Product.php?action=RatingGraph&id='+id, {
            credentials: 'include'
        }).then(handleErrors).then(
            response => {
                dispatch(receiveProductGraphRating(response));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}
export function doFetchProductGewinn(id){
    return async (dispatch, getState) => {
        await fetch(API_URL +'/Product.php?action=ProductGewinn&id='+id, {
            credentials: 'include'
        }).then(handleErrors).then(
            response => {
                dispatch(receiveProductGewinn(response));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}
export function doFetchVerpackungList(id){
    return async (dispatch, getState) => {
        await fetch(API_URL +'/Product.php?action=VerpackungList', {
            credentials: 'include'
        }).then(handleErrors).then(
            response => {
                dispatch(receiveVerpackungList(response));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}
export function doFetchSaveProductGewinn(id,ek,ship,tax){
    return async (dispatch, getState) => {
        await fetch(API_URL +'/Product.php?action=ProductGewinn', {
            credentials: 'include',
            method: "POST",
            body: JSON.stringify({
                "id": id,
                "ek": ek,
                "ship": ship,
                "tax": tax
            })
        }).then(handleErrors).then(
            response => {
                dispatch(addSuccessItem("Einkaufspreise gespeichert!"));
                dispatch(doFetchProductGewinn(id));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}

export function doAddProduct(data){
    return (dispatch, getState) => {

        return fetch(API_URL +'/Product.php?action=Product', {
            credentials: 'include',
            method: "POST",
            body: JSON.stringify(data)
        })
            .then(handleErrors)
            .then( response => dispatch(addSuccessItem("Produkt erfolgreich angelegt!")),dispatch(doFetchProducts()))
            .catch(s => dispatch(catchErrors(s)));
    };
}
