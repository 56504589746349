import React, {useEffect} from 'react';
import {Helmet} from "react-helmet";
import makeTitle from "../Helper/makeTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {
    addErrorItem, addSuccessItem,
    addWarningItem, doChangePayment,
    doCreateBooking,
    doFetchBillingToken, doFetchUserData
} from "../Actions/Actions";
import {connect} from "react-redux";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AbonnementPricing from "../HtmlComponents/AbonnementPricing";
import {withRouter} from "react-router-dom";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import useMountEffect from "../Helper/MountEffect";
import DropIn from "braintree-web-drop-in-react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {EuroFormat} from "../Helper/CurrencyFormat";
import Alert from "@material-ui/lab/Alert";

function AbonnementPaymentPage(props){
    const [dropinInstance,setDropinInstance] = React.useState(null);
    const [booking_btn_disabled,set_booking_btn_disabled] = React.useState(false);

    const [address,setAddress] = React.useState({ name:"",vorname:"",firma1:"",firma2:"",strasse:"",ort:"",plz:"" });
    const [hasError,setError] = React.useState({ name:false,vorname:false,firma1:false,firma2:false,strasse:false,ort:false,plz:false });

    const handleAddressChange = (type,obj) => {
        let newval = obj.target.value.trim();
        setAddress({...address,[type]: newval});
        checkValidation(type,newval);
    };
    const checkValidation = (type,newval) => {
        if(typeof newval === "undefined"){
            let newval = address[type];
        }
        let return_status = false;
        if(type==="plz") {
            if (newval.length !== 5) {
                setError({...hasError, [type]: true});
                return_status = true;
            } else {
                setError({...hasError, [type]: false});
            }
        }
        if(type==="vorname" || type==="name" || type==="ort" || type==="strasse") {
            if (newval.length < 2) {
                setError({...hasError, [type]: true});
                return_status = true;
            } else {
                setError({...hasError, [type]: false});
            }
        }
        return return_status;
    };

    const handleBookingSubmit = () => {
        let tmp_status = null;
        let has_errors = false;
        for(const itm of Object.keys(address)){
            tmp_status = checkValidation(address[itm]);
            if(tmp_status){
                has_errors = true;
            }
        }

        if(has_errors){
            props.addWarning("Bitte überprüfen Sie Ihre Eingaben.");
        }else{
            set_booking_btn_disabled(true);
            dropinInstance.requestPaymentMethod().then((p) => {
                props.handlePaymentChange(address,p.nonce).then((r) => {
                    if(typeof r.result !== "undefined" && r.result === true){
                        props.history.push('/profile');
                        props.addSuccess("Rechnungsdaten und Zahlungsart erfolgreich gespeichert!");
                        props.fetchUserData();//neue rechnungsadresse laden.

                    }else{
                        props.addError("Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie uns über das Kontaktformular.");
                    }
                });
            }).catch((e) => {
                props.addWarning("Bitte überprüfen Sie Ihre Zahlungsmethode.");
                set_booking_btn_disabled(false);
            });
        }
    };

    const handleDropInInstance = (instance) => {
        setDropinInstance(instance);
    };
    useEffect(() => {
        setAddress({
            name: props.user.BenutzerBillingName,
            vorname: props.user.BenutzerBillingVorname,
            firma1: props.user.BenutzerBillingFirma1,
            firma2: props.user.BenutzerBillingFirma2,
            strasse: props.user.BenutzerBillingStrasse,
            ort: props.user.BenutzerBillingOrt,
            plz: props.user.BenutzerBillingPLZ
        });
        props.fetchToken();
    },[props.user]);

    return (
        <div>
            <Helmet>
                <title>{makeTitle("Abonnements")}</title>
            </Helmet>
            <Container maxWidth={"sm"}>
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{textAlign:"center"}}>
                        <Typography component="h3" variant="h3" align="center" color="textPrimary" gutterBottom>
                            Rechnungsdetails &amp; Zahlungsart bearbeiten
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{textAlign:"center"}}>
                        <Typography component="h5" variant="h5" align="left" color="textPrimary" gutterBottom>
                            Ihre Rechnungsadresse:
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <FormGroup row>
                                    <TextField
                                        label="Firma"
                                        type="text"
                                        value={address.firma1}
                                        onChange={(t) => handleAddressChange("firma1",t)}
                                        fullWidth
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormGroup row>
                                    <TextField
                                        label="Firma (2. Zeile)"
                                        type="text"
                                        value={address.firma2}
                                        onChange={(t) => handleAddressChange("firma2",t)}
                                        fullWidth
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormGroup row>
                                    <TextField
                                        label="Vorname"
                                        type="text"
                                        value={address.vorname}
                                        onChange={(t) => handleAddressChange("vorname",t)}
                                        fullWidth
                                        error={hasError.vorname}
                                        helperText={hasError.vorname && "Pflichtfeld"}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormGroup row>
                                    <TextField
                                        label="Name"
                                        type="text"
                                        value={address.name}
                                        onChange={(t) => handleAddressChange("name",t)}
                                        fullWidth
                                        error={hasError.name}
                                        helperText={hasError.name && "Pflichtfeld"}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormGroup row>
                                    <TextField
                                        label="Straße + Hausnummer"
                                        type="text"
                                        value={address.strasse}
                                        onChange={(t) => handleAddressChange("strasse",t)}
                                        fullWidth
                                        error={hasError.strasse}
                                        helperText={hasError.strasse && "Pflichtfeld"}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormGroup row>
                                    <TextField
                                        label="Postleitzahl"
                                        type="text"
                                        value={address.plz}
                                        onChange={(t) => handleAddressChange("plz",t)}
                                        fullWidth
                                        error={hasError.plz}
                                        helperText={hasError.plz && "Pflichtfeld"}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <FormGroup row>
                                    <TextField
                                        label="Ort / Stadt"
                                        type="text"
                                        value={address.ort}
                                        onChange={(t) => handleAddressChange("ort",t)}
                                        fullWidth
                                        error={hasError.ort}
                                        helperText={hasError.ort && "Pflichtfeld"}
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{textAlign:"center"}}>
                        <Typography component="h5" variant="h5" align="left" color="textPrimary" gutterBottom>
                            Ihre Zahlungsmethode:
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                {props.billing_token && (
                                <DropIn
                                    options={{
                                        authorization: props.billing_token,
                                        paypal: {
                                            flow: 'vault'
                                        },
                                        locale: 'de_DE',
                                        threeDSecure: {
                                            amount: 0.01,
                                            email: props.user.BenutzerEmail,
                                            productCode: "DIG",
                                            billingAddress: {
                                                givenName: address.vorname,
                                                surname: address.name,
                                                streetAddress: address.strasse,
                                                locality: address.ort,
                                                postalCode: address.plz,
                                            },
                                            onLookupComplete: function (data, next) {
                                                // use `data` here, then call `next()`
                                                console.log(data);
                                                next();
                                            }
                                        }
                                    }}
                                    onInstance={(instance) => handleDropInInstance(instance)}
                                />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{textAlign:"center"}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Button variant={"contained"} color={"secondary"} size={"large"} disabled={booking_btn_disabled} onClick={handleBookingSubmit}>Speichern</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        user: state.account.user,
        billing_token:state.account.billing_token
    };
};
const mapDispatchToProps = dispatch => ({
    addError: (msg,code) => dispatch(addErrorItem(msg,code)),
    addWarning: (msg,code) => dispatch(addWarningItem(msg,code)),
    addSuccess: (msg,code) => dispatch(addSuccessItem(msg)),
    fetchToken: () => dispatch(doFetchBillingToken()),
    handlePaymentChange: (address,nonce) => dispatch(doChangePayment(address,nonce)),
    fetchUserData: () => dispatch(doFetchUserData())
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AbonnementPaymentPage))