import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {Link as RouterLink} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import shrinkText from "../Helper/shrinkText";
import React from "react";
import BaseStyle from "../Layout/BaseStyle";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import HelpIcon from '@material-ui/icons/Help';
import {Link} from "react-router-dom";


function BreadCrumb(props){
    const basestyle = BaseStyle();
    let l = [];
    if(typeof props.links !== "undefined" && props.links !== null) {
        l = props.links;
    }
    return (
        <div>
            <Grid container spacing={3}>
                <Grid item md={"auto"} style={{paddingTop: 18}}>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <RouterLink key={"breadcrumb_home"} className={basestyle.routerLink} color="inherit" to="/">
                            Home
                        </RouterLink>
                        {l.map((linkdata,index) => {
                            if(index === l.length-1){
                                return <Typography key={"breadcrumb_"+index} color="textPrimary">{shrinkText(linkdata.label,45)}</Typography>;
                            }else {
                                return (<RouterLink key={"breadcrumb_"+index} className={basestyle.routerLink} color="inherit" to={linkdata.url}>
                                    {linkdata.label}
                                </RouterLink>);
                            }
                        })
                        }


                    </Breadcrumbs>
                </Grid>
                <div className={basestyle.grow} />
                <Grid item md={"auto"} style={{textAlign:"right"}}>
                    {props.help && (
                        <Button variant={"text"} component={Link} to={"/help/"+props.help}><HelpIcon />&nbsp;&nbsp;Hilfe</Button>
                    )}
                </Grid>
            </Grid>
            <div className={basestyle.spacer} />
        </div>
    );
}
export default BreadCrumb;