import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import {applyMiddleware, createStore} from "redux";
import AppReducer from "./Actions/Reducers";
import thunk from "redux-thunk";
import {IS_LOCAL, IS_TEST} from "./Actions/Actions";

export const store = createStore(AppReducer, {}, applyMiddleware(thunk));
if(IS_LOCAL || IS_TEST) {
    store.subscribe(() => console.log(store.getState()));
}
ReactDOM.render((<Provider store={store}><App /></Provider>), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
