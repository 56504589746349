import React from 'react';
import MaterialTable from "material-table";
import { connect } from 'react-redux';
import {
    toggleBackdrop
} from "../Actions/Actions";
import useMountEffect from "../Helper/MountEffect";
import Box from "@material-ui/core/Box";
import {withRouter} from 'react-router-dom'
import tableIcons from "../Layout/TableIcons";
import Grid from "@material-ui/core/Grid";
import {doFetchProductRankings} from "../Actions/ProductActions";
import {NumberFormat} from "../Helper/NumberFormat";
import {CircularProgress} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AddBtnAlarm from "./AddBtnAlarm";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";

function TableProductDetailRanking(props){
    const [dialog,setDialog] = React.useState(null);

    const dofetch = () => {
        props.loadRankings(props.productId);
        setDialog(null);
    };
    const handleDetailOpen = (id) => {
        //setForward(<Redirect to={'/spion/'+id} />);
        props.history.push('/ranking/'+id);
    }
    //run once
    useMountEffect(() => {
        dofetch();
    });

    const handleCreateAlarm = (kw,product) => {
        setDialog(<AddBtnAlarm keyword={kw} product={product} direct={"create"} onAbort={() => setDialog(null)} onCreate={() => dofetch()} />);
    };
    const handleDeleteAlarm = (kw,product) => {
        setDialog(<AddBtnAlarm keyword={kw} product={product} direct={"delete"} onAbort={() => setDialog(null)} onDelete={() => dofetch()} />);
    };

    /*let title_app = "";
    if(props.ranking_loaded === 0){
        title_app = <Typography variant={"subtitle2"} className={basestyle.smallLoading}><CircularProgress size={14} /> Laden...</Typography>;
    }*/
    let title = (<span><Typography>Ranking: Keywords</Typography></span>);

    return (
        <Grid container>
            <Grid item xs={12}>
                {dialog}
                {props.ranking_loaded === 0 && (<div style={{textAlign:"center"}}><CircularProgress color={"secondary"} /></div>)}

                {props.ranking_loaded !== 0 && (
                    <MaterialTable title={title} icons={tableIcons} columns={[
                        { title: "Keyword", field: "keyword" },
                        {
                            title: "Status",
                            field: "found_status",
                            render: rowData => {
                                if(rowData.found_status === "found") {
                                    return <Box color="success.main">Platziert</Box>;
                                }else if(rowData.found_status === "not_found") {
                                    return <Box color="warning.main">Nicht platziert</Box>;
                                }else if(rowData.found_status === "fetch_open") {
                                    return <Box color="warning.main">Ausstehend</Box>;
                                }else{
                                    return <Box color="error.main">Unbekannt</Box>;
                                }
                            },
                            lookup: { "found": "Platziert", "not_found": "Nicht platziert", "fetch_open": "Ausstehend", "unknown":"Unbekannt" }
                        },
                        {
                            title: "Durchschn. Position",
                            field: "position",
                            type: 'numeric',
                            defaultSort: 'asc',
                            render: rowData => {
                                if(rowData.position !== 10000) {
                                    return <NumberFormat value={rowData.position}/>;
                                }else{
                                    return '';
                                }
                            }
                        },
                        { title: "Zuletzt gefunden", field: "last", type: 'date' }
                    ]}
                                   data={props.getRankings}
                                   actions={[
                                       rowData => ({
                                           icon: () => {
                                               if(rowData.alarm_status===0){
                                                   return <AddAlertIcon />;
                                               }else{
                                                   return <NotificationsOffIcon color={"secondary"} />;
                                               };
                                           },
                                           tooltip: ((rowData.alarm_status===0)?'Alarm aktivieren':'Alarm löschen'),
                                           onClick: (event, rowData) => {
                                               if(rowData.alarm_status===0) {
                                                   handleCreateAlarm(rowData.id,props.productId);
                                               }else{
                                                   handleDeleteAlarm(rowData.id,props.productId);
                                               }
                                           }
                                       }),
                                   ]}
                                   onRowClick={(event,rowData) => {
                                       handleDetailOpen(rowData.id);
                                   }}
                                   options={{
                                       actionsColumnIndex: 0,
                                       cellStyle: {
                                           fontSize: 12
                                       }
                   }}/>
                )}
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        getRankings: state.products.single_ranking,
        ranking_loaded: state.products.single_ranking.length
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    loadRankings: (id) => dispatch(doFetchProductRankings(id))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TableProductDetailRanking))