import React, {useEffect} from 'react';
import {connect} from "react-redux";
import BaseStyle, {DefaultPaper, GRAPH_PALETTE_4} from "../Layout/BaseStyle";
import {
    doLoadSalesMainGraph, isMainGraphLoading
} from "../Actions/SalesActions";
import Typography from "@material-ui/core/Typography";
import {EuroFormat} from "../Helper/CurrencyFormat";
import {
    Bar,
    CartesianGrid,
    ComposedChart,
    Line,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import {Area} from "recharts";
import {fade} from "@material-ui/core";
import InnerLoading from "../Helper/innerLoading";


const CustomTooltip = ({ active, payload, label,tmpdata }) => {
    if (active && payload) {
        return (
            <DefaultPaper>
                <Typography variant={"h5"}>
                    {label}
                </Typography>
                {payload.map((payl,index) => {
                    let umsatz = 0;
                    let gewinn = 0;
                    tmpdata.map((tmpindex) => {
                        if(tmpindex.date === payl.payload.date){
                            umsatz = tmpindex.umsatz;
                            gewinn = tmpindex.gewinn;
                        }
                        return tmpindex;
                    });
                    return (<div key={"custom_tooltip_graph_sales_"+index}>
                        <p style={{margin:0}}>
                            <span style={{fontSize:18,display:'inline-block',minWidth:50,paddingRight:5,fontWeight: "bold",color:GRAPH_PALETTE_4[index]}}>
                                {payl.value}
                            </span>
                            {payl.name}
                        </p>
                        {index === 1 && (
                            <div>
                                <p style={{margin:0}}><span style={{fontSize:18,display:'inline-block',minWidth:50,paddingRight:5,fontWeight: "bold",color:GRAPH_PALETTE_4[2]}}><EuroFormat value={umsatz} /></span>Umsatz</p>
                                <p style={{margin:0}}><span style={{fontSize:18,display:'inline-block',minWidth:50,paddingRight:5,fontWeight: "bold",color:GRAPH_PALETTE_4[3]}}><EuroFormat value={gewinn} /></span>Gewinn</p>
                            </div>
                        )}
                    </div>);
                })}

            </DefaultPaper>
        );
    }

    return null;
};

const Tick = ({
                  payload: { value },
                  verticalAnchor,
                  visibleTicksCount,
                  ...rest
              }) => (
    <text {...rest} className="bar-chart-tick" dy={12}>
        {value}
    </text>
);

function GraphSales(props){
    const basestyle = BaseStyle();


    //run once
    useEffect(() => {
        if(typeof props.changed === "undefined" || props.changed !== 5) {//initial value
            props.isLoading(true);
            props.loadDashboardMainGraph(props.preset, props.grouping).then(() => props.isLoading(false));
        }
    },[props.changed,props.preset]);
    if(props.sales_mainchart) {
        return (
            <div>
                {props.mainchart_loading && (
                    <div style={{position: "absolute",top:0,left:0,width:"100%",height:"100%"}}>
                        <InnerLoading />
                    </div>
                )}
                <ResponsiveContainer width="100%" height={300}>
                    <ComposedChart data={props.sales_mainchart} barSize={10}>
                        <CartesianGrid stroke="#eee"/>
                        <XAxis dataKey="date" tick={<Tick/>} tickMargin={10} allowDuplicatedCategory={false}
                               padding={{left: 30, right: 30}}/>
                        <YAxis yAxisId="left" dataKey="einheiten" interval={1} tickMargin={10} padding={{top: 30}}/>
                        <YAxis yAxisId="right" orientation="right" dataKey="umsatz" tickLine={false} domain={[0, 'auto']}
                               tickMargin={10} padding={{top: 30}}/>
                        <Bar yAxisId="right" dataKey="umsatz" name="Umsatz" fill={GRAPH_PALETTE_4[2]}/>
                        <Bar yAxisId="right" dataKey="gewinn" name="Gewinn" fill={GRAPH_PALETTE_4[3]}/>
                        <Area yAxisId="left" type="monotone" dataKey="count" name="Bestellungen" dot={{strokeWidth: 2}}
                              strokeWidth={3} stroke={GRAPH_PALETTE_4[0]} fill={fade(GRAPH_PALETTE_4[0], 0.2)}/>
                        <Line yAxisId="left" type="monotone" dataKey="einheiten" name="Einheiten"
                              stroke={GRAPH_PALETTE_4[1]}/>
                        <Tooltip offset={50} content={<CustomTooltip tmpdata={props.sales_mainchart}/>}/>
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        );
    }else{
        return <InnerLoading />;
    }
}


const mapStateToProps = (state) => {
    return {
        sales_mainchart: state.sales.mainchart,
        mainchart_loading: state.sales.mainchart_loading
    };
};
const mapDispatchToProps = dispatch => ({
    loadDashboardMainGraph: (preset,grouping) => dispatch(doLoadSalesMainGraph(preset,grouping)),
    isLoading: (bool) => dispatch(isMainGraphLoading(bool))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GraphSales);