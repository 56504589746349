import React from 'react';
import MaterialTable from "material-table";

import BaseStyle from "../Layout/BaseStyle";
import { connect } from 'react-redux';
import {
    toggleBackdrop
} from "../Actions/Actions";
import useMountEffect from "../Helper/MountEffect";
import {withRouter} from 'react-router-dom';
import tableIcons from "../Layout/TableIcons";
import Grid from "@material-ui/core/Grid";
import {doCreateLieferant, doFetchLieferanten} from "../Actions/LagerActions";
import SelectDialog from "../Helper/SelectDialog";
import LieferantenDialog from "../Helper/LieferantenDialog";
import Chip from "@material-ui/core/Chip";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";

function TableLagerLieferanten(props){
    const basestyle = BaseStyle();
    const [popup,setPopup] = React.useState(null);

    const dofetch = () => {
        props.toggleBackdrop(true);
        props.doFetchLieferanten().then(() => {
            props.toggleBackdrop(false);
        });
    };
    const handleNewClick = () => {
        handleEditOpen(0);
    };
    const getDetail = (id) => {
        let data = props.getLieferanten.filter((s) => s.LieferantID === id);
        return (data.length === 1)?data[0]:null;
    };
    const handleEditOpen = (id) => {

        setPopup(<LieferantenDialog
            data={getDetail(id)}
            abort={() => setPopup(null)}
            save={(data) => {
                props.toggleBackdrop(true);
                props.createLieferant(data).then(() => {
                    setPopup(null);
                    dofetch();
                });
            }}
        />);
    };
    const handleWebOpen = (id) => {
        setPopup(null);
        let data = getDetail(id);
        let tmpurl = data.Website;
        if(tmpurl.indexOf("http://") === -1 || tmpurl.indexOf("https://") === -1) {
            tmpurl = "https://"+tmpurl;
        }
        window.open(tmpurl);
    };
    const handleProductsOpen = (id) => {
        setPopup(null);
        props.toggleBackdrop(true);
        window.location.href = "/lager?lieferant="+id;
    };

    const handleDetailOpen = (id) => {
        let options = [{
            text: "Lieferant bearbeiten",
            value: "edit"
        },
        {
            text: "Produkte anzeigen",
            value: "show_products"
        }];
        let detail = getDetail(id);
        if(detail.Website && detail.Website.length > 4){
            options.push({
                text: "Website besuchen",
                value: "open_web"
            });
        }
        if(options.length === 1){
            handleEditOpen(id);
        }else {
            setPopup(<SelectDialog
                abort={() => setPopup(null)}
                select={(choosen) => {
                    if (choosen === "edit") handleEditOpen(id);
                    if (choosen === "open_web") handleWebOpen(id);
                    if (choosen === "show_products") handleProductsOpen(id);
                }}
                text={"Aktion wählen"}
                options={options}
            />);
        }
    };
    //run once
    useMountEffect(() => {
        dofetch();
    });

    return (
        <Grid container>
            <Grid item xs={12}>
            <Tooltip title="Lieferant hinzufügen" aria-label="add">
                <Fab color="primary" className={basestyle.fab} onClick={handleNewClick}>
                    <AddIcon />
                </Fab>
            </Tooltip>
            {popup}
            <MaterialTable title="Lieferanten" icons={tableIcons} columns={[
                {
                    title: "Status",
                    field: "Status",
                    render: rowData => {
                        if(rowData.Status==0) return <Chip color={"primary"} size="small" label="Aktiv" />;
                        if(rowData.Status==1) return <Chip size="small" label="Ruhend / inaktiv" />;
                        if(rowData.Status==2) return <Chip size="small" label="Archiviert" />;
                    }
                },
                {
                    title: "Name",
                    field: "Name"
                },
                {
                    title: "Website",
                    field: "Website"
                },
                {
                    title: "Telefon",
                    field: "Telefon"
                },
                {
                    title: "Offene Bestellungen",
                    field: "Offene_Lieferungen"
                },
                {
                    title: "Letzte Bestellung",
                    field: "Letzte_Lieferung"
                },
                {
                    title: "Produkte",
                    field: "Count_Products"
                },
            ]}
               data={props.getLieferanten}
               actions={[
               ]}
               onRowClick={(event,rowData) => {
                   handleDetailOpen(rowData.LieferantID);
               }}
               options={{
                   pageSize: 50,
                   pageSizeOptions: [10,25,50,100,200],
                   actionsColumnIndex: 0,
                   cellStyle: {
                       fontSize: 12
                   }
           }}/>
        </Grid>
    </Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        getLieferanten: state.lager.lieferanten,
        getUserData: state.account.user
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    doFetchLieferanten: () => dispatch(doFetchLieferanten()),
    createLieferant: (data) => dispatch(doCreateLieferant(data))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TableLagerLieferanten))