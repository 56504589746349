import React, {useEffect} from 'react';
import BaseStyle, {DefaultPaper} from "./Layout/BaseStyle";
import Menu from "@material-ui/core/Menu";
import {AccountCircle} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from '@material-ui/icons/Notifications';
import {doFetchNotifications, doFetchUserData, doSetNotificationsRead, NON_REACT_HOME_URL} from "./Actions/Actions";
import {connect} from "react-redux";
import useMountEffect from "./Helper/MountEffect";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import {Link as RouterLink} from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSnackbar } from 'notistack';
import Button from "@material-ui/core/Button";
import LinkButton from "./HtmlComponents/LinkButton";
import {doFetchProductsStockCount} from "./Actions/ProductActions";

const AccountControl = (props) => {
    const basestyle = BaseStyle();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElNotification, setAnchorElNotification] = React.useState(null);
    const open = Boolean(anchorEl);
    const openNotification = Boolean(anchorElNotification);
    const [NotificationWindow, setNotificationWindow] = React.useState(null);
    const [snackbar, setSnackbar] = React.useState({ refreshProducts: null,refreshOrders: null });
    const { enqueueSnackbar,closeSnackbar } = useSnackbar();
    useEffect(() => {
        // TODO: funktioniert nicht... props.user ist leer
        if(props.user.MWSProcessProductActive && snackbar.refreshProducts === null) {
            setSnackbar({
                ...snackbar,
                refreshProducts: enqueueSnackbar(<span>
                                            <CircularProgress className={basestyle.smallLoadingIcon} size={14} color={"secondary"} thickness={8}/>
                    &nbsp;Produkte werden aktualisiert
                                        </span>, { persist: true })
            });
        }else if(!props.user.MWSProcessProductActive && snackbar.refreshProducts !== null) {
            closeSnackbar(snackbar.refreshProducts);
            setSnackbar({
                ...snackbar,
                refreshProducts: null
            });
        }
        if(props.user.MWSProcessOrderActive && snackbar.refreshOrders === null) {
            setSnackbar({
                ...snackbar,
                refreshOrders: enqueueSnackbar(<span>
                                            <CircularProgress className={basestyle.smallLoadingIcon} size={14} color={"secondary"} thickness={8}/>
                    &nbsp;Bestellungen werden aktualisiert
                                        </span>, { persist: true })
            });
        }else if(!props.user.MWSProcessOrderActive && snackbar.refreshOrders !== null) {
            closeSnackbar(snackbar.refreshOrders);
            setSnackbar({
                ...snackbar,
                refreshOrders: null
            });
        }
    },[props.user,closeSnackbar,enqueueSnackbar,snackbar,basestyle.smallLoadingIcon]);
    useMountEffect(() => {
        doFetch();
    });
    const doFetch = () => {
        props.fetchNotifications();
        props.fetchUserData();
        setTimeout(function(){
            doFetch();
        },30000);
    };
    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleNotificationMenu = event => {
        props.setNotificationsRead();
        setAnchorElNotification(event.currentTarget);
    };
    const handleNotificationClose = () => {
        props.fetchNotifications();
        setAnchorElNotification(null);
    };
    const openNotificationDetail = (data) => {
        handleNotificationClose();
        setNotificationWindow((<Dialog open={true} maxWidth={"md"} onClose={closeNotificationDetail} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{data.message}</DialogTitle>
            <DialogContent>
                <DialogContentText dangerouslySetInnerHTML={{__html: data.text}}>
                </DialogContentText>
            </DialogContent>
            </Dialog>
        ));
    };
    const closeNotificationDetail = () => {
      setNotificationWindow(null);
    };
    const getNewNotifications = () => {
        let newcount = 0;
        props.notifications.forEach((s)=>{
            if (s.isnew) newcount++;
        });
        return newcount;
    };



    return (
        <div className={basestyle.NavbarsectionRight}>
            {props.user.isLoggedIn && (
                <div>

                        {/*}
                        {props.user.MWSProcessOrderActive && (
                            <Snackbar
                                key={"snackbar_mws_order"}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                open={true}
                                message={<span><CircularProgress className={basestyle.smallLoadingIcon} size={14} color={"secondary"} thickness={8} />&nbsp;Bestellungen werden geladen</span>}
                            />
                        )}*/}
                    <LinkButton to={"/help/feedback"} color={"secondary"} style={{ marginRight: 15 }}>
                        Feedback & Kontakt
                    </LinkButton>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="notification-menu"
                        aria-haspopup="true"
                        onClick={handleNotificationMenu}
                        color="inherit"
                        style={{ marginRight: 15 }}>
                        <Badge badgeContent={getNewNotifications()} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <DefaultPaper component={Menu}
                        id="notification-menu"
                        className={basestyle.DropdownListPaper}
                        anchorEl={anchorElNotification}
                        keepMounted
                        open={openNotification}
                        onClose={handleNotificationClose}>

                            {props.notifications.map(notification => {
                                var tmpclass = basestyle.MessageRead;
                                if(notification.isnew){
                                    tmpclass = basestyle.MessageUnread;
                                }
                                return (
                                <ListItem className={[basestyle.DropdownListItem,tmpclass].join(' ')} key={notification.id} onClick={openNotificationDetail.bind(this,notification)}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <NotificationsIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={notification.message} secondary={notification.since} />
                                </ListItem>)
                            })}

                    </DefaultPaper>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        {/*<MenuItem component={"a"} href={NON_REACT_HOME_URL+"/account"} onClick={handleClose}>Account</MenuItem>*/}
                        <MenuItem component={RouterLink} to="/profile" onClick={handleClose}>Account</MenuItem>
                        {/*<MenuItem component={RouterLink} to="/ranking/alarm" onClick={handleClose}>Alarme verwalten</MenuItem>*/}
                        <MenuItem component={RouterLink} to="/logout" onClick={handleClose}>Abmelden</MenuItem>
                    </Menu>
                    {NotificationWindow}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        notifications: state.account.notifications,
        user: state.account.user
    };
};
const mapDispatchToProps = dispatch => ({
    fetchUserData: () => dispatch(doFetchUserData()),
    fetchNotifications: () => dispatch(doFetchNotifications()),
    setNotificationsRead: () => dispatch(doSetNotificationsRead())
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountControl)