import ListItemLink, {ListItemLinkAbs} from "./ListItemLink";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import {FaBarcode,FaUserSecret,FaToolbox,FaSync} from 'react-icons/fa';
import ListItemText from "@material-ui/core/ListItemText";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import React from "react";
import BaseStyle from "../Layout/BaseStyle";
import {connect} from "react-redux";
import {NON_REACT_HOME_URL, toggleBackdrop} from "../Actions/Actions";
import {doFetchProductsStockCount} from "../Actions/ProductActions";
import useMountEffect from "../Helper/MountEffect";
import Badge from "@material-ui/core/Badge";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';



function NavContent(props) {
    const basestyle = BaseStyle();
    const [subnav_nr, setSubnav] = React.useState(0);

    useMountEffect(() => {
        props.loadStockCount();
    });

    const toggleSubnav = (nr) => {
        if(subnav_nr === nr){
            setSubnav(0);
        }else{
            setSubnav(nr);
        }
    };
    const toggleSubnavOne = () => {
        toggleSubnav(1);
    };
    const toggleSubnavThree = () => {
        toggleSubnav(3);
    };

    const toggleSubnavTwo = () => {
        toggleSubnav(2);
    };

    let stock_warn_sum = parseInt(props.stock_count) + parseInt(props.stock_count_critical) + parseInt(props.stock_count_out);

    return (
        <div>
            <List component="nav">
                <ListItemLink to="/" primary="Dashboard" icon={<DashboardIcon/>} onClick={props.toggleDrawer(false)}/>


                {/*<ListItemLink to="/sales" primary="Verkauf" icon={<EuroSymbolIcon/>} onClick={props.toggleDrawer(false)}/>*/}
                {props.user.Tarife[5] && (
                    <ListItem button onClick={() => toggleSubnav(2)}>
                        <ListItemIcon><EuroSymbolIcon/></ListItemIcon>
                        <ListItemText>Verkauf</ListItemText>
                        {(subnav_nr === 2) ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                )}
                <Collapse in={(subnav_nr === 2)} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding className={basestyle.nestedList}>
                        <ListItemLink to="/sales" primary="Übersicht" onClick={props.toggleDrawer(false)}/>
                        <ListItemLink to="/sales/gewinn" primary="Gewinn" onClick={props.toggleDrawer(false)}/>
                    </List>
                </Collapse>
                {props.user.Tarife[4] && (
                    <ListItem button onClick={() => toggleSubnav(4)}>
                        <ListItemIcon><TrendingUpIcon/></ListItemIcon>
                        <ListItemText>Ranking</ListItemText>
                        {(subnav_nr === 4) ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                )}
                <Collapse in={(subnav_nr === 4)} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding className={basestyle.nestedList}>
                        <ListItemLink to="/ranking" primary="Übersicht" onClick={props.toggleDrawer(false)}/>
                        <ListItemLink to="/ranking/trend" primary="Sichtbarkeit" onClick={props.toggleDrawer(false)}/>
                    </List>
                </Collapse>
                {(props.user.Tarife[5] || props.user.Tarife[4]) && (
                    <ListItem button onClick={() => toggleSubnav(3)}>
                        <ListItemIcon><FaBarcode/></ListItemIcon>
                        <ListItemText>Produkte</ListItemText>
                        {(subnav_nr === 3) ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                )}
                <Collapse in={(subnav_nr === 3)} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding className={basestyle.nestedList}>
                        <ListItemLink to="/products" primary="Übersicht" onClick={props.toggleDrawer(false)}/>
                    </List>
                </Collapse>
                {props.user.Tarife[5] && (
                    <ListItem button onClick={() => toggleSubnav(5)}>
                        <ListItemIcon>{stock_warn_sum > 0 ? (<Badge badgeContent={stock_warn_sum} color={"secondary"}><LocalShippingIcon/></Badge>) : <LocalShippingIcon/>}</ListItemIcon>
                        <ListItemText>Lager</ListItemText>
                        {(subnav_nr === 5) ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                )}
                <Collapse in={(subnav_nr === 5)} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding className={basestyle.nestedList}>
                        <ListItemLink to="/lager" primary="Übersicht" onClick={props.toggleDrawer(false)}/>
                        <ListItemLink to="/lager/lieferanten" primary="Lieferanten" onClick={props.toggleDrawer(false)} />
                        <ListItemLink to="/lager/bestellungen" primary="Bestellungen" onClick={props.toggleDrawer(false)} />
                    </List>
                </Collapse>
                {props.user.Tarife[3] && (
                <ListItemLink to="/changelog-setting" primary="Änderungsalarm" icon={<FaSync/>} onClick={props.toggleDrawer(false)}/>
                )}
                {props.user.Tarife[6] && (
                    <ListItemLink to="/spion" primary="Spion" icon={<FaUserSecret/>} onClick={props.toggleDrawer(false)}/>
                )}
                {(props.user.Tarife[7] || props.user.Tarife[8]) && (
                    <ListItem button onClick={() => toggleSubnav(1)}>
                        <ListItemIcon><FaToolbox/></ListItemIcon>
                        <ListItemText>Weitere Tools</ListItemText>
                        {(subnav_nr === 1) ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                )}
                <Collapse in={(subnav_nr === 1)} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding className={basestyle.nestedList}>
                        {props.user.Tarife[8] && (
                            <ListItemLinkAbs to={NON_REACT_HOME_URL+"/adreport"} primary="Werbeanalyse" onClick={props.toggleDrawer(false)}/>
                        )}
                        {props.user.Tarife[7] && (
                            <ListItemLinkAbs to={NON_REACT_HOME_URL+"/keyword-generator"} primary="Keyword-Finder" onClick={props.toggleDrawer(false)}/>
                        )}
                    </List>
                </Collapse>
                <ListItemLink to="/help" primary="Hilfe" icon={<HelpOutlineIcon/>} onClick={props.toggleDrawer(false)}/>
            </List>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        user: state.account.user,
        stock_count: state.products.items_stock_c,
        stock_count_critical: state.products.items_stock_c_critical,
        stock_count_out: state.products.items_stock_out
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    loadStockCount: () => dispatch(doFetchProductsStockCount())
});
export default connect(mapStateToProps,mapDispatchToProps)(NavContent)