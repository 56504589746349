import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import BaseStyle from "../Layout/BaseStyle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const SelectDialog = (props) => {
    const basestyle = BaseStyle();

    return (
        <Dialog onClose={props.abort} open={true}>
            <DialogTitle>{props.text}</DialogTitle>
            <List>
                {props.options.map((option) => (
                    <ListItem button onClick={() => props.select(option.value)} key={option.value}>
                        <ListItemAvatar>
                            <Avatar>
                                <ArrowForwardIosIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={option.text} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
};
export default SelectDialog;