import React from 'react';
import MaterialTable from "material-table";

import BaseStyle from "../Layout/BaseStyle";
import { connect } from 'react-redux';
import {
    toggleBackdrop
} from "../Actions/Actions";
import useMountEffect from "../Helper/MountEffect";
import Box from "@material-ui/core/Box";
import {withRouter} from 'react-router-dom';
import tableIcons from "../Layout/TableIcons";
import AddBtnProduct, {AddBtnProductVerpackung} from "./AddBtnProduct";
import FilterProduct from "./FilterProduct";
import Grid from "@material-ui/core/Grid";
import {reloadProductsPage} from "../Actions/ProductActions";
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import Rating from "@material-ui/lab/Rating";
import {FlagInTable} from "./TableSpion";


function TableProducts(props){
    const basestyle = BaseStyle();

    const dofetch = () => {
        if(props.variant && props.variant === "lager") {
            props.reloadProductsPage(true);
        }else{
            props.reloadProductsPage();
        }
    };
    const handleDetailOpen = (id) => {
        //setForward(<Redirect to={'/spion/'+id} />);
        props.history.push('/products/'+id);
    };
    //run once
    useMountEffect(() => {
        dofetch();
    });

    let spalten_config = [
        {
            title: "Bild",
            field: "pic",
            sorting: false,
            cellStyle: { paddingTop:0,paddingBottom:0,textAlign: "center" },
            export: false,
            render: rowData => <img src={rowData.pic} alt={"Produktbild"} className={basestyle.TableImage}/>
        },
        {
            title: 'Marktplatz',
            field: 'marketplace',
            /*customSort: (a, b) => {
                return ([a.marketplace, b.marketplace].sort()[0] === b.marketplace)?0:-1;
            },*/
            render: rowData => {
                let flag = rowData.marketplace;
                return <FlagInTable country={flag} />;
            }/*,
                    lookup: {
                        "de": (<FlagInTable country={"DE"} />),
                        "uk": (<FlagInTable country={"GB"} />),
                        "it": (<FlagInTable country={"IT"} />),
                        "es": (<FlagInTable country={"ES"} />),
                        "fr": (<FlagInTable country={"FR"} />)
                    }*/
        },
        { title: "ASIN", field: "asin" },
        { title: "SKU", field: "sku" },
        {
            title: "Status",
            field: "status",
            render: rowData => {
                if(rowData.status === "0") {
                    if(parseInt(rowData.stock) <= 0){
                        return <Box color="error.main">Out-Of-Stock</Box>;
                    }else{
                        return <Box color="success.main">Aktiv</Box>;
                    }
                }else{
                    return <Box color="warning.main">Inaktiv</Box>;
                }
            },
            lookup: { 0: "Aktiv", 1: "Inaktiv" }
        },
        {
            title: "Versand",
            field: "fullfilment",
            render: rowData => {
                if(rowData.fullfilment === "1") {
                    return <Box color="success.main">FBA</Box>;
                }else{
                    return <Box color="primary.main">Eigenversand</Box>;
                }
            },
            lookup: { 0: "Eigenversand", 1: "FBA" }
        },
        { title: "Produkt", field: "title" },
        { title: "Kategorie", field: "category" },
        {
            title: "VK-Rang",
            field: "sales_rank",
            type: "numeric",
            customSort: (a, b) => {
                return (a.sales_rank == null || a.sales_rank > b.sales_rank)?0:-1;
            },
        },
        {
            title: "Buy-Box",
            field: "buybox",
            type: "numeric",
            render: rowData => {
                if(rowData.buybox === "1") {
                    return <Box color="success.main"><CheckIcon /></Box>;
                }else{
                    return <Box color="error.main"><ClearIcon /></Box>;
                }
            }
        },
        {
            title: "Bewertung",
            field: "rating",
            type: "numeric",
            render: rowData => {
                return (
                    <div style={{textAlign:'center'}}>
                        <Rating name="read-only" value={parseFloat(rowData.rating)} size="small" readOnly />
                        <br /><small>{rowData.rating_count} Bewertungen</small>
                    </div>
                );
            }
        },
        { title: "Preis", field: "price",type: "currency",currencySetting: { locale: 'de', currencyCode: "EUR" } },
        { title: "Lager", field: "stock", type: 'numeric' },
        { title: "Gewinn", field: "gewinn",type: "currency",currencySetting: { locale: 'de', currencyCode: "EUR" } },
        { title: "Lager bis", field: "out_of_stock_date",type: "date",defaultSort: "asc" }
    ];

    let spalten = [
        spalten_config.filter((s) => s.title==="Bild")[0],
        spalten_config.filter((s) => s.title==="Marktplatz")[0],
        spalten_config.filter((s) => s.title==="ASIN")[0],
        spalten_config.filter((s) => s.title==="SKU")[0],
        spalten_config.filter((s) => s.title==="Status")[0],
        spalten_config.filter((s) => s.title==="Versand")[0],
        spalten_config.filter((s) => s.title==="Produkt")[0],
        spalten_config.filter((s) => s.title==="Kategorie")[0],
        spalten_config.filter((s) => s.title==="VK-Rang")[0],
        spalten_config.filter((s) => s.title==="Buy-Box")[0],
        spalten_config.filter((s) => s.title==="Bewertung")[0],
        spalten_config.filter((s) => s.title==="Preis")[0],
        spalten_config.filter((s) => s.title==="Lager")[0],
        spalten_config.filter((s) => s.title==="Gewinn")[0]
    ];
    let pagesize = 50;

    if(props.variant && props.variant === "lager"){
        pagesize = 25;
        spalten = [
            spalten_config.filter((s) => s.title==="ASIN")[0],
            spalten_config.filter((s) => s.title==="SKU")[0],
            spalten_config.filter((s) => s.title==="Status")[0],
            spalten_config.filter((s) => s.title==="Versand")[0],
            spalten_config.filter((s) => s.title==="Produkt")[0],
            spalten_config.filter((s) => s.title==="Preis")[0],
            spalten_config.filter((s) => s.title==="Lager")[0],
            spalten_config.filter((s) => s.title==="Lager bis")[0]
        ];
    }

    return (
        <Grid container>
            <Grid item xs={12}>
            <FilterProduct variant={props.variant} />
            {!(props.variant && props.variant === "lager") && (<AddBtnProduct />)}
            {(props.variant && props.variant === "lager") && (<AddBtnProductVerpackung />)}

            <MaterialTable title="Produkte" icons={tableIcons} columns={spalten}
               data={props.getProducts}
               actions={[
               ]}
               onRowClick={(event,rowData) => {
                   handleDetailOpen(rowData.id);
               }}
               options={{
                   pageSize: pagesize,
                   pageSizeOptions: [10,25,50,100,200],
                   /*selection: true, not working with actions*/
                   actionsColumnIndex: 0,
                   columnsButton: !(props.variant && props.variant === "lager"),
                   cellStyle: {
                       fontSize: 12
                   }
           }}/>
        </Grid>
    </Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        getProducts: state.products.items,
        getUserData: state.account.user
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    reloadProductsPage: (is_stock_page) => dispatch(reloadProductsPage(is_stock_page))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TableProducts))