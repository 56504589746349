import {addSuccessItem, API_URL, catchErrors, handleErrors, toggleBackdrop} from "./Actions";
import {getPickerDataQuery} from "../Helper/getPickerDataQuery";


export const RECIEVE_GEWINN_TABLE = 'RECIEVE_GEWINN_TABLE';
export const SAVE_GEWINN = 'SAVE_GEWINN';
export const LOAD_SALES_DATA = 'LOAD_SALES_DATA';
export const LOAD_SALES_MAINCHART = 'LOAD_SALES_MAINCHART';
export const LOAD_SALES_BESTSELLERS = 'LOAD_SALES_BESTSELLERS';
export const LOAD_SALES_CATEGORIESDATA = 'LOAD_SALES_CATEGORIESDATA';
export const RECIEVE_GEWINN_DETAIL_TABLE = 'RECIEVE_GEWINN_DETAIL_TABLE';
export const RECIEVE_GEWINN_DETAIL_POSTEN_TABLE = 'RECIEVE_GEWINN_DETAIL_POSTEN_TABLE';
export const MAINCHART_LOADING = 'MAINCHART_LOADING';

export function receiveGewinnProducts(json){
    return {
        type: RECIEVE_GEWINN_TABLE,
        items: json.result
    }
}
export function saveGewinn(new_obj){
    return {
        type: SAVE_GEWINN,
        item: new_obj
    }
}
export function receiveSalesData(json){
    return {
        type: LOAD_SALES_DATA,
        result: json.result
    }
}
export function receiveSalesMainGraph(json){
    return {
        type: LOAD_SALES_MAINCHART,
        result: json.result
    }
}
export function isMainGraphLoading(bool){
    return {
        type: MAINCHART_LOADING,
        result: bool
    }
}

export function receiveSalesBestsellers(json){
    return {
        type: LOAD_SALES_BESTSELLERS,
        result: json.result
    }
}
export function receiveCategoriesData(json){
    return {
        type: LOAD_SALES_CATEGORIESDATA,
        result: json.result
    }
}
export function receiveGewinnDetailsOrder(json){
    return {
        type: RECIEVE_GEWINN_DETAIL_TABLE,
        items: json.result
    }
}
export function receiveGewinnDetailsPosten(json){
    return {
        type: RECIEVE_GEWINN_DETAIL_POSTEN_TABLE,
        items: json.result
    }
}

/***FETCH API***/

export function doReloadGewinnPage(){
    return async (dispatch, getState) => {
        await fetch(API_URL +'/Sales.php?action=GewinnTable', {
            credentials: 'include'
        }).then(handleErrors).then(
            response => {
                dispatch(receiveGewinnProducts(response));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}
export function doSaveGewinn(new_obj){
    return async (dispatch, getState) => {
        await fetch(API_URL +'/Sales.php?action=Gewinn', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                "id": new_obj.id,
                "ek": new_obj.ek,
                "versand": new_obj.versand,
                "tax": new_obj.tax
            })
        }).then(handleErrors).then(
            response => {
                dispatch(saveGewinn(new_obj));
                dispatch(addSuccessItem("Erfolgreich gespeichert!"));
            }
        ).catch(s => dispatch(catchErrors(s)));
    };
}

export function doLoadSalesData(){
    return async (dispatch, getState) => {
        dispatch(receiveSalesData({result: null}));
        let request_date = getPickerDataQuery(getState().account.pickerdata);
        await fetch(API_URL +'/Sales.php?action=MainData&'+request_date, {
            credentials: 'include'
        }).then(handleErrors)
        .then(response => dispatch(receiveSalesData(response)))
        .catch(s => dispatch(catchErrors(s)));
    };
}
export function doLoadSalesMainGraph(force_preset = null,force_grouping = null){
    return async (dispatch, getState) => {
        let request_date = getState().account.pickerdata;
        if(force_preset){
            request_date["preset"] = force_preset;
            request_date["preset_real"] = force_preset;
        }
        if(force_grouping){
            request_date["grouping"] = force_grouping;
        }
        request_date = getPickerDataQuery(request_date);
        return fetch(API_URL +'/Sales.php?action=MainGraph&'+request_date, {
            credentials: 'include'
        }).then(handleErrors)
            .then(response => dispatch(receiveSalesMainGraph(response)))
            .catch(s => dispatch(catchErrors(s)));
    };
}
export function doLoadSalesBestsellers(){
    return async (dispatch, getState) => {
        dispatch(receiveSalesBestsellers({result: null}));
        let request_date = getPickerDataQuery(getState().account.pickerdata);
        await fetch(API_URL +'/Sales.php?action=BestSeller&'+request_date, {
            credentials: 'include'
        }).then(handleErrors)
            .then(response => dispatch(receiveSalesBestsellers(response)))
            .catch(s => dispatch(catchErrors(s)));
    };
}
export function doLoadCategoriesData(){
    return async (dispatch, getState) => {
        dispatch(receiveCategoriesData({result: null}));
        let request_date = getPickerDataQuery(getState().account.pickerdata);
        await fetch(API_URL +'/Sales.php?action=CategoriesData&'+request_date, {
            credentials: 'include'
        }).then(handleErrors)
            .then(response => dispatch(receiveCategoriesData(response)))
            .catch(s => dispatch(catchErrors(s)));
    };
}

export function doResetGewinnDetailsData(){
    return async (dispatch, getState) => {
        dispatch(receiveGewinnDetailsOrder({result: null}));
        dispatch(receiveGewinnDetailsPosten({result: null}));
    }
}
export function doFetchGewinnDetailsOrder(){
    return async (dispatch, getState) => {
        dispatch(toggleBackdrop(true));
        let request_date = getPickerDataQuery(getState().account.pickerdata);
        await fetch(API_URL +'/Sales.php?action=GewinnDetailTableOrder&'+request_date, {
            credentials: 'include'
        }).then(handleErrors)
            .then(response => {dispatch(receiveGewinnDetailsOrder(response));dispatch(toggleBackdrop(false))})
            .catch(s => {dispatch(catchErrors(s));dispatch(toggleBackdrop(false))});
    };
}
export function doFetchGewinnDetailsPosten(){
    return async (dispatch, getState) => {
        dispatch(toggleBackdrop(true));
        let request_date = getPickerDataQuery(getState().account.pickerdata);
        await fetch(API_URL +'/Sales.php?action=GewinnDetailTableItems&'+request_date, {
            credentials: 'include'
        }).then(handleErrors)
            .then(response => {dispatch(receiveGewinnDetailsPosten(response));dispatch(toggleBackdrop(false))})
            .catch(s => {dispatch(catchErrors(s));dispatch(toggleBackdrop(false))});
    };
}
