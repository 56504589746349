import React from 'react';
import Container from "@material-ui/core/Container";
import { toggleBackdrop} from "../Actions/Actions";
import {connect} from "react-redux";
import {useParams} from "react-router";
import BaseStyle, {DefaultPaper} from "../Layout/BaseStyle";
import useMountEffect from "../Helper/MountEffect";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LoadingPage from "./LoadingPage";
import CountryFlag from "../HtmlComponents/CountryFlag";
import Link from "@material-ui/core/Link";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import BreadCrumb from "../HtmlComponents/BreadCrumb";
import {doFetchKeywordDetail} from "../Actions/RankingActions";
import TableRankingDetail from "../HtmlComponents/TableRankingDetail";
import {Helmet} from "react-helmet";
import makeTitle from "../Helper/makeTitle";
import AddBtnAlarm from "../HtmlComponents/AddBtnAlarm";

function RankingDetailPage(props){
    const basestyle = BaseStyle();
    let { rankingId } = useParams();

    const reloadView = () => {
        props.loadKeyword(rankingId);
    };

    //run once
    useMountEffect(() => {
        reloadView();
    },[]);

    if(typeof props.keywordDetail.keyword !== "undefined") {
        return (
            <Container maxWidth="xl">
                <Helmet>
                    <title>{makeTitle('Keyword: '+props.keywordDetail.keyword)}</title>
                </Helmet>
                <BreadCrumb links={[
                    {label: 'Ranking',url:'/ranking'},
                    {label: 'Keyword: '+props.keywordDetail.keyword}
                ]} help={"ranking"} />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DefaultPaper style={{marginBottom:0}}>
                            <Typography variant={"h1"}>
                                <span style={{position:'relative',top:6}}><CountryFlag country={props.keywordDetail.marketplace} /></span>
                                &nbsp;Keyword: {props.keywordDetail.keyword}
                            </Typography>
                            <Typography variant={"subtitle1"}>
                                <Link component="a" href={props.keywordDetail.url} target="_blank"><OpenInNewIcon className={basestyle.inlineTextIcon} /> {props.keywordDetail.url}</Link>
                            </Typography>
                        </DefaultPaper>
                    </Grid>
                    <Grid item xs={12}>
                        <AddBtnAlarm keyword={props.keywordDetail.id} status={props.keywordDetail.alarm_status} onDelete={() => reloadView()} onCreate={() => reloadView()} />
                    </Grid>
                    <Grid item xs={12}>
                        <TableRankingDetail rankingId={rankingId} />
                    </Grid>
                </Grid>
            </Container>
        );
    }else{
        return <LoadingPage />;
    }
}


const mapStateToProps = (state) => {
    return {
        keywordDetail: state.ranking.single
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    loadKeyword: (id) => dispatch(doFetchKeywordDetail(id))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RankingDetailPage);