import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

function LoginExpiredPage(){
    return (
        <Container maxWidth="sm">
            <Box spacing={3} textAlign='center'>
                <CircularProgress color="secondary" />
            </Box>
        </Container>
    );
}
export default LoginExpiredPage;