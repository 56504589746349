import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import React from "react";
import BaseStyle from "../Layout/BaseStyle";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import { connect } from 'react-redux';
import {
    addErrorItem,
    addWarningItem,
    toggleBackdrop
} from "../Actions/Actions";
import {
    doAddAlarm,
    doDeleteAlarm
} from "../Actions/RankingActions";
import AddAlertIcon from '@material-ui/icons/AddAlert';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import ConfirmDialog from "../Helper/ConfirmDialog";

function AddBtnAlarm(props){
    const basestyle = BaseStyle();
    const [open, setOpen] = React.useState(null);
    const [formdata, setFormdata] = React.useState({
        position: ""
    });
    const [deleteOpen,setDeleteOpen] = React.useState(null);
    //const [keywordhaserror,setKeywordError] = React.useState(false);
    //const [marketplacehaserror,setMarketplaceError] = React.useState(false);


    const handleClickOpen = () => {
        setFormdata({...formdata,position:""});
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setDeleteOpen(false);
        if(typeof props.onAbort === "function") props.onAbort();
    };

    const handleDelete = () => {
        setDeleteOpen(true);
    };

    const handlePositionChange = event => {
        let newval = event.target.value.trim();
        if(!isNaN(newval) && newval !== "") {
            setFormdata({...formdata, position: newval});
        }else{
            props.addWarning("Bitte geben Sie eine Zahl ein.");
        }
    };
    const handleAddClick = () =>{
        let valid = true;

        if(isNaN(formdata.position) || formdata.position == "") {
            valid = false;
            props.addWarning("Bitte geben Sie eine valide Zahl ein.");
        }

        if(valid){
            props.toggleBackdrop(true);
            props.addAlarm(props.keyword,props.product,formdata.position).then(res => {
                props.onCreate();
                props.toggleBackdrop(false);
                handleClose();
            });
        }
    };

    let tooltip_btn = null;
    let main_dialog = null;
    let del_dialog = null;
    if(typeof props.direct === "undefined"){
        if(props.status===0){
            tooltip_btn =  <Tooltip title="Alarm aktivieren" aria-label="add">
                <Fab color="primary" className={basestyle.fab} onClick={handleClickOpen}>
                    <AddAlertIcon />
                </Fab>
            </Tooltip>;
        }else{
            tooltip_btn =  <Tooltip title="Alarm löschen" aria-label="add">
                <Fab color="secondary" className={basestyle.fab} onClick={handleDelete}>
                    <NotificationsOffIcon />
                </Fab>
            </Tooltip>;
        }
    }
    if(deleteOpen===true || (deleteOpen===null && props.direct === "delete")){
        del_dialog = <ConfirmDialog title={"Alarm löschen?"} text={"Möchten Sie den Alarm-Service für dieses Keyword deaktivieren?"} onClose={()=>{ handleClose(); }} onClick={() => {
            props.removeAlarm(props.keyword,props.product).then(() => {
                props.onDelete();
            });
            setDeleteOpen(false);
        }} />;
    }
    if(open===true || (open===null && props.direct === "create")){
        main_dialog = <Dialog open={true} maxWidth={"md"} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Alarm aktivieren</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Bitte tragen Sie die Position ein, ab der wir Sie informieren sollen. Z.B. "5"
                </DialogContentText>
                <FormGroup row>
                    <TextField
                        id="position"
                        label="Ranking-Position"
                        onChange={handlePositionChange}
                        variant="outlined"
                        fullWidth
                    />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="default">
                    Abbrechen
                </Button>
                <Button onClick={handleAddClick} color="primary">
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>;
    }

    return (
        <div>
            {del_dialog}
            {tooltip_btn}
            {main_dialog}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {};
};
const mapDispatchToProps = dispatch => ({
    addAlarm: (kw,product,position) => dispatch(doAddAlarm(kw,product,position)),
    removeAlarm: (kw,product) => dispatch(doDeleteAlarm(kw,product)),
    toggleBackdrop: (open) => dispatch(toggleBackdrop(open)),
    addError: (msg,code) => dispatch(addErrorItem(msg,code)),
    addWarning: (msg,code) => dispatch(addWarningItem(msg,code))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddBtnAlarm)