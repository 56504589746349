import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import BaseStyle from "../Layout/BaseStyle";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import { connect } from 'react-redux';
import {
    addErrorItem,
    addWarningItem,
    toggleBackdrop
} from "../Actions/Actions";
import useMountEffect from "../Helper/MountEffect";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import {doAddSpion, doFetchSpionPoints, doFetchSpions} from "../Actions/SpionActions";


function AddBtnSpion(props){
    const basestyle = BaseStyle();
    const [open, setOpen] = React.useState(false);
    const [formdata, setFormdata] = React.useState({
        marketplaces: { de: true,uk: false,it: false,fr: false,es:false,us:false},
        asin: ""
    });
    const [asinhaserror,setAsinError] = React.useState(false);
    const [marketplacehaserror,setMarketplaceError] = React.useState(false);


    const handleClickOpen = () => {
        if(props.getFreePoints > 0) {
            setOpen(true);
        }else{
            props.addError("Sie können keine weiteren Spione anlegen!");
        }
        //const unsubscribe = spionStore.subscribe(() => console.log(spionStore.getState()));
        //spionStore.dispatch(doAddSpion('B1464599'));

        //props.dispatch(doFetchSpions("asdasdad"));
        //props.dispatch(doAddSpion("asdasdad"));

    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = name => event => {
        setFormdata({ ...formdata, marketplaces: { ...formdata.marketplaces,[name]: event.target.checked} });
    };
    const handleAsinChange = event => {
        setFormdata({ ...formdata, asin: event.target.value.trim() });
    };
    const handleAddClick = () =>{
        let valid = true;
        setAsinError(false);
        setMarketplaceError(false);
        if(formdata.asin.length !== 10) {
            valid = false;
            setAsinError(true);
            props.addWarning("Bitte geben Sie eine ASIN ein. Eine ASIN besteht aus 10 Zeichen (z.B. B0794XQK5S)");
        }
        if(Object.values(formdata.marketplaces).indexOf(true) === -1) {
            valid = false;
            setMarketplaceError(true);
        }

        if(valid){
            props.toggleBackdrop(true);
            props.addSpion(formdata.asin, formdata.marketplaces).then(res => {
                reloadView();
                props.toggleBackdrop(false);
                handleClose();
            });
        }
    };
    const reloadView = () => {
        props.fetchPoints();
    };

    //run once
    useMountEffect(() => {
        reloadView();
    });

    return (
        <div>
            <Tooltip title="Spion hinzufügen" aria-label="add">
                <Fab color="primary" className={basestyle.fab} onClick={handleClickOpen}>
                    <AddIcon />
                </Fab>
            </Tooltip>
            <Dialog open={open} maxWidth={"md"} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Spion hinzufügen</DialogTitle>
                <DialogContent>
                    <DialogContentText className={basestyle.warningColor}>
                        {props.getFreePoints < 1000000 && (
                        <b>Sie können noch {props.getFreePoints} Produkte zum Spion hinzufügen.</b>
                        )}
                    </DialogContentText>
                    <Typography variant={"body1"}>
                        Marktplatz:
                    </Typography>
                    <FormControl error={marketplacehaserror}>
                        <FormGroup row>
                            {["de","uk","es","it","fr","us"].map((mp,index) =>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={formdata.marketplaces[mp]} onChange={handleChange(mp)}
                                                  value={mp}/>
                                    }
                                    key={"mp_checkbox_"+index}
                                    label={mp.toUpperCase()}
                                />
                            )}
                        </FormGroup>
                        <FormHelperText>{(marketplacehaserror && "Bitte wählen Sie mindestens 1 Marktplatz!")}</FormHelperText>
                    </FormControl>
                    <FormGroup row>
                        <TextField
                            margin="dense"
                            id="asin"
                            label="ASIN"
                            type="text"
                            onChange={handleAsinChange}
                            fullWidth
                            error={asinhaserror}
                            helperText={(asinhaserror && "Bitte valide ASIN eingeben!")}
                        />
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="default">
                        Abbrechen
                    </Button>
                    <Button onClick={handleAddClick} color="primary">
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        getCount: state.spions.items.length,
        getFreePoints: state.spions.points.free
    };
};
const mapDispatchToProps = dispatch => ({
    addSpion: (asin,mps) => dispatch(doAddSpion(asin,mps)),
    fetchSpions: () => dispatch(doFetchSpions()),
    fetchPoints: () => dispatch(doFetchSpionPoints()),
    toggleBackdrop: (open) => dispatch(toggleBackdrop(open)),
    addError: (msg,code) => dispatch(addErrorItem(msg,code)),
    addWarning: (msg,code) => dispatch(addWarningItem(msg,code))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddBtnSpion)