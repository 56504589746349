import React from 'react';
import {Helmet} from "react-helmet";
import makeTitle from "../Helper/makeTitle";
import Grid from "@material-ui/core/Grid";
import BaseStyle, {
    DefaultPaper, DefaultPaperAuto,
    GRAPH_PALETTE,
    sellerava_green
} from "../Layout/BaseStyle";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {doFetchDasboardData, toggleBackdrop} from "../Actions/Actions";
import {Link as RouterLink, withRouter} from "react-router-dom";
import {
    CartesianGrid,
    Cell,
    ComposedChart,
    Line,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    XAxis
} from "recharts";
import {connect} from "react-redux";
import useMountEffect from "../Helper/MountEffect";
import LoadingPage from "./LoadingPage";
import GraphSales from "../HtmlComponents/GraphSales";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import { TIME_PRESETS } from "../Actions/Actions";
import {EuroFormat, PercentageFormat} from "../Helper/CurrencyFormat";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import {doFetchRankingTrendChart} from "../Actions/RankingActions";
import InnerLoading from "../Helper/innerLoading";
import {NumberFormat} from "../Helper/NumberFormat";
import Alert from "@material-ui/lab/Alert";


function DashboardPage(props){
    const basestyle = BaseStyle();

    useMountEffect(() => {
        props.loadDashboardData();
        props.loadRankingTrend();
    });
    if(typeof props.dashboard_data !== "undefined") {
        let COLORS_Pie1 = [sellerava_green, '#cbcbcb'];
        let vk_rating_pie_data = [{name: 'Group A', value: props.dashboard_data.vk_rating}, {
            name: 'Group B',
            value: 100 - props.dashboard_data.vk_rating
        }];

        let name = "";
        if(props.user.BenutzerBillingName && props.user.BenutzerBillingName.length > 0 && props.user.BenutzerBillingVorname.length > 0) {
            name = props.user.BenutzerBillingVorname + " " + props.user.BenutzerBillingName;
        }else if(props.user.BenutzerBillingFirma1 && props.user.BenutzerBillingFirma1.length > 0 && props.user.BenutzerBillingFirma1.length < 50){
            name = props.user.BenutzerBillingFirma1;
        }else{
            name = props.user.BenutzerName;
        }

        return (
            <div>
                <Helmet>
                    <title>{makeTitle("Dashboard")}</title>
                </Helmet>
                <div>
                    <Alert severity={"error"} variant={"outlined"}>
                        Sellerava.com wird zum 30.04.2023 abgeschaltet. Wir bedanken uns für Ihren Support und freuen uns auf anderweitige Zusammenarbeit!
                    </Alert>
                </div>
                <Typography variant={"h4"}>Sellerava-Dashboard</Typography>
                <Typography variant={"subtitle1"} style={{fontSize: 22}}>Hallo {name}!</Typography>

                {(props.user.ProductCount >= props.user.MaxProducts) && (
                    <div>
                        <Alert severity={"warning"} variant={"outlined"}>
                            Produkt-Limit erreicht! Ihr Limit von {props.user.MaxProducts} Produkten wurde erreicht. Es wurden {props.user.ProductCountSkipped} Produkte nicht importiert. Importierte Produkte: {props.user.ProductCount}
                        </Alert>
                        <Button component={RouterLink} to={"/abonnements"} variant={"contained"} color={"secondary"} style={{marginTop: 13}}>Ändern Sie Ihr Paket</Button>
                    </div>
                )}
                <div className={basestyle.spacer} />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} style={{textAlign: "center"}}>
                        <DefaultPaper>
                            <Grid container direction={"column"} alignItems={"stretch"} style={{height:"100%"}}>
                                <Grid item>
                                    <Typography variant={"h5"}>VK-Bewertung</Typography>
                                </Grid>
                                <Grid item className={basestyle.grow}>
                                    <Grid container
                                          justify="center"
                                          alignItems="center" style={{height: "100%"}}>
                                        <Grid item>
                                            {typeof props.dashboard_data.vk_rating == "undefined" && (
                                                <InnerLoading />)}
                                            {!isNaN(props.dashboard_data.vk_rating) && (
                                            <div>
                                                <ResponsiveContainer width="100%" height={90}>
                                                    <PieChart>
                                                        <Pie
                                                            data={vk_rating_pie_data}
                                                            startAngle={180} cy="80" endAngle={0} outerRadius={80}
                                                        >
                                                            {
                                                                vk_rating_pie_data.map((entry, index) => <Cell key={"pie_cell_"+index} fill={COLORS_Pie1[index]}/>)
                                                            }
                                                        </Pie>
                                                    </PieChart>
                                                </ResponsiveContainer>
                                                <Typography variant={"h2"}
                                                            className={basestyle.selleravaColor}>{props.dashboard_data.vk_rating}%</Typography>
                                                <Typography variant={"subtitle1"}>Verkäuferbewertungen
                                                    gesamt: {props.dashboard_data.vk_rating_count}</Typography>
                                            </div>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DefaultPaper>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <DefaultPaper>
                            <Typography variant={"h5"}>Ihr Verkaufsvolumen</Typography>
                            <GraphSales preset={"7_days"} grouping={"daily"} />
                        </DefaultPaper>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <DefaultPaper>
                            {typeof props.dashboard_data.sales_table == "undefined" && (
                                <InnerLoading />)}
                            {typeof props.dashboard_data.sales_table !== "undefined" && (
                            <TableContainer>
                                <Table size={"small"}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>&nbsp;</TableCell>
                                            <TableCell align="right"><span className={basestyle.tablehead_1}>Umsatz</span></TableCell>
                                            <TableCell align="right"><span className={basestyle.tablehead_2}>Trend</span></TableCell>
                                            <TableCell align="right"><span className={basestyle.tablehead_3}>Gewinn</span></TableCell>
                                            <TableCell align="right"><span className={basestyle.tablehead_4}>Marge (%)</span></TableCell>
                                            <TableCell align="right"><span className={basestyle.tablehead_5}>Verkäufe</span></TableCell>
                                            <TableCell align="right"><span className={basestyle.tablehead_6}>Einheiten</span></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                            {TIME_PRESETS.map((item,index) => {
                                                let trend = props.dashboard_data.sales_table[item.name].diff_umsatz;
                                                let trend_positiv = true;
                                                if(trend < 0) trend_positiv = false;
                                                return (<TableRow key={"dashboard_table_row_"+index}>
                                                    <TableCell component="th" scope="row">{item.friendly}</TableCell>
                                                    <TableCell style={{fontSize: 20}} align="right"><EuroFormat value={props.dashboard_data.sales_table[item.name].umsatz} decimal={0} /></TableCell>
                                                    <TableCell style={{fontSize: 20}} align="right" className={trend_positiv?basestyle.success:basestyle.errorColor}>{trend_positiv?"+":""}<PercentageFormat value={trend} /></TableCell>
                                                    <TableCell style={{fontSize: 20}} align="right"><EuroFormat value={props.dashboard_data.sales_table[item.name].gewinn} decimal={0} /></TableCell>
                                                    <TableCell style={{fontSize: 20}} align="right"><PercentageFormat value={props.dashboard_data.sales_table[item.name].marge} /></TableCell>
                                                    <TableCell style={{fontSize: 20}} align="right">{props.dashboard_data.sales_table[item.name].count}</TableCell>
                                                    <TableCell style={{fontSize: 20}} align="right">{props.dashboard_data.sales_table[item.name].einheiten}</TableCell>
                                                </TableRow>);
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>)}
                        </DefaultPaper>
                    </Grid>
                    <Grid item xs={12} md={4} style={{textAlign: "center"}}>
                        <DefaultPaper>
                            <Grid container direction={"column"} alignItems={"stretch"} style={{height:"100%"}}>
                                <Grid item>
                                    <Typography variant={"h5"}>Lagerbestand</Typography>
                                </Grid>
                                <Grid item className={basestyle.grow}>
                                    {typeof props.stock_inf == "undefined" && (
                                        <InnerLoading />)}
                                    {typeof props.stock_inf !== "undefined" && (
                                        <Grid container
                                              justify="center"
                                              alignItems="center" style={{height: "100%"}}>
                                            <Grid item>
                                                <div>
                                                    <Typography variant={"h3"} className={basestyle.errorColor}>
                                                        {props.stock_inf.items_stock_c_critical}
                                                    </Typography>
                                                    <Typography variant={"subtitle1"}>Kritischer Lagerbestand</Typography>
                                                </div>
                                                <div>
                                                    <Typography variant={"h3"} className={basestyle.warningColor}>
                                                        {props.stock_inf.items_stock_c}
                                                    </Typography>
                                                    <Typography variant={"subtitle1"}>Nachbestellung erforderlich</Typography>
                                                </div>
                                                <div className={basestyle.spacer}/>
                                                <Button component={RouterLink} to={"/lager"} variant="contained" color="primary">Übersicht</Button>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </DefaultPaper>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} style={{textAlign: "center"}}>
                        <DefaultPaper>
                            <Grid container direction={"column"} alignItems={"stretch"} style={{height:"100%"}}>
                                <Grid item>
                                    <Typography variant={"h5"}>Ranking-Alarm</Typography>
                                </Grid>
                                <Grid item className={basestyle.grow}>
                                    {typeof props.dashboard_data.alarm == "undefined" && (
                                        <InnerLoading />)}
                                    {typeof props.dashboard_data.alarm !== "undefined" && (
                                        <Grid container
                                              justify="center"
                                              alignItems="center" style={{height: "100%"}}>
                                            <Grid item>
                                                {props.dashboard_data.alarm.active > 0 && (
                                                    <div>
                                                        <Typography variant={"h2"} style={{fontSize:60}} className={basestyle.errorColor}>
                                                            {props.dashboard_data.alarm.active} / {props.dashboard_data.alarm.sum}
                                                        </Typography>
                                                        <Typography variant={"subtitle1"}>Ranking-Alarme aktiv</Typography>
                                                    </div>
                                                )}
                                                {props.dashboard_data.alarm.active === 0 && (
                                                    <div>
                                                        <Typography variant={"h2"} className={basestyle.success}>
                                                            <DoneAllIcon  style={{fontSize:60}} />
                                                        </Typography>
                                                        <Typography variant={"subtitle1"}>Keine Warnungen vorhanden</Typography>
                                                    </div>
                                                )}
                                                <div className={basestyle.spacer}/>
                                                <Button component={RouterLink} to={"/ranking/alarm"} variant="contained" color="primary">Übersicht</Button>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </DefaultPaper>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <DefaultPaper>
                            <Grid container direction={"column"} alignItems={"stretch"} style={{height:"100%"}}>
                                <Grid item>
                                    <Typography variant={"h5"}>
                                        Sichtbarkeitstrend{(props.ranking_trend&&props.ranking_trend.length > 0) && (<span>: <NumberFormat value={ props.ranking_trend[props.ranking_trend.length-1].overall} /></span>)}

                                        <Button component={RouterLink} to={"/ranking/trend"} variant="contained" color="primary" style={{left: 10,top: -2}}>Übersicht</Button>
                                    </Typography>
                                </Grid>
                                <Grid item className={basestyle.grow}>
                                                {props.ranking_trend === null && (
                                                    <InnerLoading />)}
                                                {(props.ranking_trend&&props.ranking_trend.length === 0) && (
                                                    <Typography variant={"h5"}>Keine Daten verfügbar.</Typography>)}
                                                {(props.ranking_trend&&props.ranking_trend.length > 0) && (
                                                    <ResponsiveContainer width="99%" height={300}>
                                                        <ComposedChart data={props.ranking_trend}>
                                                            <CartesianGrid stroke="#eee"/>
                                                            <Line connectNulls type="monotone" dataKey="overall" name="Trend"
                                                                  dot={false} strokeWidth={3} stroke={GRAPH_PALETTE[0]}/>
                                                            <XAxis dataKey="date" tickMargin={15} allowDuplicatedCategory={true} padding={{ left: 20, right: 20}} interval={30} axisLine={false} />
                                                            <Tooltip />
                                                        </ComposedChart>
                                                    </ResponsiveContainer>
                                                )}
                                </Grid>
                            </Grid>
                        </DefaultPaper>
                    </Grid>
                </Grid>
                <div className={basestyle.spacer} />
                <div className={basestyle.spacer} />
                <div className={basestyle.spacer} />
                <div className={basestyle.spacer} />
                <Typography variant={"h3"} style={{textAlign: "center"}}>Weitere Informationen abrufen</Typography>
                <div className={basestyle.spacer} />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3} style={{textAlign: "center"}}>
                        <DefaultPaper>
                            <Typography variant={"h4"}>Verkäufe</Typography>
                            <Typography variant={"body1"}>Analysieren Sie Ihre Umsätze und Verkaufszahlen.</Typography>
                            <div className={basestyle.spacer}/>
                            <Button component={RouterLink} to={"/sales"} variant="contained" color="default">
                                Verkäufe anzeigen
                            </Button>
                        </DefaultPaper>
                    </Grid>
                    <Grid item xs={12} md={3} style={{textAlign: "center"}}>
                        <DefaultPaper>
                            <Typography variant={"h4"}>Produkte</Typography>
                            <Typography variant={"body1"}>Verbessern Sie die Performance einzelner Produkte.</Typography>
                            <div className={basestyle.spacer}/>
                            <Button component={RouterLink} to={"/products"} variant="contained" color="default">
                                Produkte anzeigen
                            </Button>
                        </DefaultPaper>
                    </Grid>
                    <Grid item xs={12} md={3} style={{textAlign: "center"}}>
                        <DefaultPaper>
                            <Typography variant={"h4"}>Ranking</Typography>
                            <Typography variant={"body1"}>Behalten Sie Ihr Ranking stets im Blick.</Typography>
                            <div className={basestyle.spacer}/>
                            <Button component={RouterLink} to={"/ranking"} variant="contained" color="default">
                                Ranking anzeigen
                            </Button>
                        </DefaultPaper>
                    </Grid>
                    <Grid item xs={12} md={3} style={{textAlign: "center"}}>
                        <DefaultPaper>
                            <Typography variant={"h4"}>Spion</Typography>
                            <Typography variant={"body1"}>Steigen Sie in neue Märkte ein oder beobachten Sie die Konkurrenz.</Typography>
                            <div className={basestyle.spacer}/>
                            <Button component={RouterLink} to={"/spion"} variant="contained" color="default">
                                Spione anzeigen
                            </Button>
                        </DefaultPaper>
                    </Grid>
                </Grid>
            </div>
        );
    }else{
        return (<LoadingPage />);
    }
}

const mapStateToProps = (state) => {
    return {
        dashboard_data: state.account.dashboard_data,
        stock_inf: state.products,
        user: state.account.user,
        ranking_trend: state.ranking.trend
    };
};
const mapDispatchToProps = dispatch => ({
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    loadDashboardData: () => dispatch(doFetchDasboardData()),
    loadRankingTrend: () => dispatch(doFetchRankingTrendChart())
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DashboardPage))