import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";

function InnerLoading(){
    return (
        <Grid container
              justify="center"
              alignItems="center" style={{height: "100%"}}>
            <Grid item>
                <CircularProgress color="secondary" />
            </Grid>
        </Grid>
    );
}
export default InnerLoading;