import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import BaseStyle from "../Layout/BaseStyle";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import Typography from "@material-ui/core/Typography";
import { connect } from 'react-redux';
import {
    addErrorItem,
    addWarningItem,
    toggleBackdrop
} from "../Actions/Actions";
import useMountEffect from "../Helper/MountEffect";
import Divider from "@material-ui/core/Divider";
import {doAddProduct} from "../Actions/ProductActions";
import {parseNumberText} from "../Helper/CurrencyFormat";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";


function AddBtnProduct(props){
    const basestyle = BaseStyle();
    const [open, setOpen] = React.useState(false);
    const [formdata, setFormdata] = React.useState({
        marketplaces: { de: true,uk: false,it: false,fr: false,es:false},
        asin: ""
    });
    const [asinhaserror,setAsinError] = React.useState(false);
    const [marketplacehaserror,setMarketplaceError] = React.useState(false);


    const handleClickOpen = () => {
        if(props.getUserData.isMWSApiEnabledProducts === false) {
            setOpen(true);
        }else{
            props.addWarning("Ihre Produkte werden automatisch über die Schnittstelle geladen!");
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    /*const handleChange = name => event => {
        setFormdata({ ...formdata, marketplaces: { ...formdata.marketplaces,[name]: event.target.checked} });
    };*/
    const handleAsinChange = event => {
        setFormdata({ ...formdata, asin: event.target.value.trim() });
    };
    const handleMpChange = mp => {
        setFormdata({ ...formdata, marketplaces: { ...formdata.marketplaces,[mp]: !formdata.marketplaces[mp] }});
    };
    const handleAddClick = () =>{
        let valid = true;
        setAsinError(false);
        //setMarketplaceError(false);
        if(formdata.asin.length !== 10) {
            valid = false;
            setAsinError(true);
            props.addWarning("Bitte geben Sie eine ASIN ein. Eine ASIN besteht aus 10 Zeichen (z.B. B0794XQK5S)");
        }
        if(Object.values(formdata.marketplaces).indexOf(true) === -1) {
            valid = false;
            //setMarketplaceError(true);
        }

        if(valid){
            props.toggleBackdrop(true);
            props.addProduct({ asin: formdata.asin, marketplaces: formdata.marketplaces }).then(res => {
                props.toggleBackdrop(false);
                handleClose();
            });
        }
    };


    return (
        <div>
            <Tooltip title="Produkt hinzufügen" aria-label="add">
                <Fab color="primary" className={basestyle.fab} onClick={handleClickOpen}>
                    <AddIcon />
                </Fab>
            </Tooltip>
            <Dialog open={open} maxWidth={"md"} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Produkt hinzufügen</DialogTitle>
                <DialogContent>
                    <Typography variant={"body1"}>
                        Marktplatz:
                    </Typography>
                    <Divider />
                    <div  className={basestyle.spacer} />
                    <FormControl error={marketplacehaserror}>
                        <FormGroup row>
                            {["de","uk"].map(mp =>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={formdata.marketplaces[mp]} onChange={() => handleMpChange(mp)}
                                                  value={mp}/>
                                    }
                                    label={mp.toUpperCase()}
                                />
                            )}
                        </FormGroup>
                        <FormHelperText>{(marketplacehaserror && "Bitte wählen Sie mindestens 1 Marktplatz!")}</FormHelperText>
                    </FormControl>
                    <FormGroup row>
                        <TextField
                            margin="dense"
                            id="asin"
                            label="ASIN"
                            type="text"
                            onChange={handleAsinChange}
                            fullWidth
                            error={asinhaserror}
                            helperText={(asinhaserror && "Bitte valide ASIN eingeben!")}
                        />
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="default">
                        Abbrechen
                    </Button>
                    <Button onClick={handleAddClick} color="primary">
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
function AddBtnProductVerpackung_f(props){
    const basestyle = BaseStyle();
    const [open, setOpen] = React.useState(false);
    const [formdata, setFormdata] = React.useState({
        Identifier: "",
        ProductTitle: "",
        ProductStock: 0,
        ProductStockEinheit: "Stück"
    });
    const [identHasError,setIdentError] = React.useState(false);
    const [textHasError,setTextError] = React.useState(false);
    const [stockHasError,setStockError] = React.useState(false);

    const handleClose = () => setOpen(false);

    const handleChange = (key,event) => {
        setFormdata({ ...formdata, [key]: event.target.value.trim() });
    };
    const handleAddClick = () =>{
        let valid = true;
        let errortext = "";
        setIdentError(false);
        setTextError(false);
        setStockError(false);
        if(formdata.Identifier.length < 1) {
            valid = false;
            setIdentError(true);
            if(!errortext) errortext = "Bitte geben Sie eine eindeutige Kennung ein.";
        }
        if(formdata.ProductTitle.length < 1) {
            valid = false;
            setTextError(true);
            if(!errortext) errortext = "Bitte geben Sie eine Bezeichnung ein.";
        }
        let productStock = parseNumberText(formdata.ProductStock);
        if(isNaN(productStock) || productStock < 0) {
            valid = false;
            setStockError(true);
            if(!errortext) errortext = "Bitte geben Sie einen validen Lagerbestand ein.";
        }

        if(valid){
            props.toggleBackdrop(true);
            props.addProduct({ type: 2,identifier: formdata.Identifier, title: formdata.ProductTitle, stock: productStock, stock_einheit: formdata.ProductStockEinheit}).then(res => {
                props.toggleBackdrop(false);
                handleClose();
            });
        }else if(errortext !== ""){
            props.addWarning(errortext);
        }
    };

    return (
        <div>
            <Tooltip title="Verpackungsmaterial hinzufügen" aria-label="add">
                <Fab color="primary" className={basestyle.fab} variant="extended" onClick={() => setOpen(true)}>
                    <AddIcon /><span style={{top:1,position:"relative"}}> Verpackungsmaterial</span>
                </Fab>
            </Tooltip>
            <Dialog open={open} maxWidth={"md"} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Verpackungsmaterial hinzufügen</DialogTitle>
                <DialogContent>

                    <FormGroup row>
                        <TextField
                            margin="dense"
                            id="identifier"
                            label="Kennung / SKU"
                            type="text"
                            onChange={(e) => handleChange("Identifier",e)}
                            value={formdata.Identifier}
                            fullWidth
                            error={identHasError}
                            helperText={(identHasError && "Bitte eine eindeutige SKU / Kennung eingeben!")}
                        />
                    </FormGroup>
                    <FormGroup row>
                        <TextField
                            margin="dense"
                            id="title"
                            label="Bezeichnung"
                            type="text"
                            onChange={(e) => handleChange("ProductTitle",e)}
                            value={formdata.ProductTitle}
                            fullWidth
                            error={textHasError}
                            helperText={(textHasError && "Bitte eine Bezeichnung eingeben!")}
                        />
                    </FormGroup>

                    <FormGroup row>
                        <TextField
                            margin="dense"
                            id="lagerbestand"
                            label="Initialer Lagerbestand"
                            type="text"
                            onChange={(e) => handleChange("ProductStock",e)}
                            value={formdata.ProductStock}
                            fullWidth
                            error={stockHasError}
                            helperText={(stockHasError && "Bitte einen validen Wert eingeben!")}
                        />
                    </FormGroup>

                    <FormGroup row>
                        <FormControl fullWidth>
                            <InputLabel shrink htmlFor="stockeinheit-label">
                                Einheit:
                            </InputLabel>
                            <Select
                                value={formdata.ProductStockEinheit}
                                onChange={(e) => handleChange("ProductStockEinheit",e)}
                                inputProps={{
                                    id: 'stockeinheit-label',
                                }}
                            >
                                {props.stock_einheiten.map((i) => <MenuItem key={i} value={i}>{i}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="default">
                        Abbrechen
                    </Button>
                    <Button onClick={handleAddClick} color="primary">
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        getUserData: state.account.user,
        stock_einheiten: state.products.stock_einheiten
    };
};
const mapDispatchToProps = dispatch => ({
    addProduct: (data) => dispatch(doAddProduct(data)),
    toggleBackdrop: (open) => dispatch(toggleBackdrop(open)),
    addError: (msg,code) => dispatch(addErrorItem(msg,code)),
    addWarning: (msg,code) => dispatch(addWarningItem(msg,code))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddBtnProduct);

export const AddBtnProductVerpackung = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddBtnProductVerpackung_f);