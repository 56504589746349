import React from 'react';
import Container from "@material-ui/core/Container";
import BreadCrumb from "../HtmlComponents/BreadCrumb";
import {Helmet} from "react-helmet";
import makeTitle from "../Helper/makeTitle";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import tableIcons from "../Layout/TableIcons";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useMountEffect from "../Helper/MountEffect";
import {toggleBackdrop} from "../Actions/Actions";
import {connect} from "react-redux";
import {doDeleteAlarms, doFetchAlarms, doFetchKeywords} from "../Actions/RankingActions";
import ConfirmDialog from "../Helper/ConfirmDialog";
import {DefaultPaperAuto} from "../Layout/BaseStyle";
import {withRouter} from "react-router-dom";

function RankingAlarmPage(props){
    const [dialog,setDialog] = React.useState(null);

    useMountEffect(() => {
        props.toggleBackdrop(true);
        props.fetchAlarms().then(() => props.toggleBackdrop(false));
    });
    const handleDetailOpen = (id) => {
        props.history.push('/ranking/'+id);
    };

    const handleDelete = (idlist) => {
        setDialog(<ConfirmDialog title={idlist.length+" Alarme löschen?"} text={"Möchten Sie wirklich "+idlist.length+" Alarme löschen?"} onClose={()=>{ setDialog(null); }} onClick={() => {
            props.toggleBackdrop(true);
            props.deleteAlarms(idlist).then(() => {
                props.toggleBackdrop(false);
                props.fetchAlarms();
            });
            setDialog(null);
        }} />);
    };

    return (
        <Container maxWidth="xl">
            <Helmet>
                <title>{makeTitle('Ranking-Alarme verwalten')}</title>
            </Helmet>
            <BreadCrumb links={[
                {label: 'Ranking',url: "/ranking"},
                {label: 'Alarm'}
            ]} help={"ranking"} />
            {dialog}
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <DefaultPaperAuto>
                        <Typography variant={"h1"}>Ranking-Alarme verwalten</Typography>
                        <Typography variant={"body1"}>
                            Sie können auf der Detailseite eines Keywords oder eines Produkts einen Ranking-Alarm aktivieren. Hier erhalten Sie eine Übersicht über die erstellten Alarme und deren Rankingstatus.
                        </Typography>
                    </DefaultPaperAuto>
                </Grid>
                <Grid item xs={12}>
                    <MaterialTable title="Keyword-Alarme" icons={tableIcons} columns={[
                        { title: "Keyword", field: "keyword" },
                        { title: "Produkt", field: "product" },
                        {
                            title: "Status",
                            field: "status",
                            render: rowData => {
                                if(rowData.status === 0) {
                                    return <Box color="success.main">Rankingziel erreicht</Box>;
                                }else{
                                    return <Box color="error.main">Rankingtrend unter Ziel</Box>;
                                }
                            }
                        },
                        { title: "Zielposition", field: "position" },
                        { title: "Letzte Auslösung", field: "last", type: "date" },
                        { title: "Erstellt", field: "created", type: "date" }
                    ]}
                                   data={props.alarms}

                                   onRowClick={(event,rowData) => {
                                       handleDetailOpen(rowData.rankingid);
                                   }}
                                   actions={[
                                       rowData => ({
                                           icon: tableIcons.Delete,
                                           tooltip: 'Alarm(e) löschen',
                                           onClick: (event, rowData) => {
                                               let id_arr = [];
                                               for(let i=0; i<rowData.length; i++){
                                                   id_arr.push(rowData[i].id);
                                               }
                                               handleDelete(id_arr);
                                           }
                                       })
                                   ]}
                                   options={{
                                       pageSize: 50,
                                       pageSizeOptions: [10,25,50,100,200],
                                       selection: true,
                                       actionsColumnIndex: 0,
                                       cellStyle: {
                                           fontSize: 12
                                       }
                                   }}/>
                </Grid>
            </Grid>
        </Container>
    );
}
const mapStateToProps = (state) => {
    return {
        user: state.account.user,
        alarms: state.ranking.alarms
    };
};
const mapDispatchToProps = dispatch => ({
    fetchAlarms: () => dispatch(doFetchAlarms()),
    toggleBackdrop: (stat) => dispatch(toggleBackdrop(stat)),
    deleteAlarms: (idlist) => dispatch(doDeleteAlarms(idlist))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(RankingAlarmPage))